import React from 'react';
import { makeStyles } from '@material-ui/core';
import SmallRedArrowIcon from '../../../assets/img/SmallRedArrowIcon.svg';
import SmallBranchRedImage from '../../../assets/img/SmallBranchRedImage.svg';
import BottomBigBranchRedImage from '../../../assets/img/BottomBigBranchRedImage.svg';
import LeftVerticalBigRedArrowIcon from '../../../assets/img/LeftVerticalBigRedArrowIcon.svg';
import BranchRedTop from '../../../assets/img/BranchRedTop.svg';
import TopRedBigArrowIcon from '../../../assets/img/TopRedBigArrowIcon.svg';
import TopRedArrowSmallIcon from '../../../assets/img/TopRedArrowSmallIcon.svg';
import TopRedArrowRight from '../../../assets/img/TopRedArrowRight.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
  },
  main: {
    position: 'relative',
    marginTop: '60px',
    width: '435px',
    marginLeft: '65px',
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '2px solid #C33C54',
    margin: '0 15px 0 0 ',
    background: '#FAEFF1',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#C33C54',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#FAEFF1',
    borderRadius: '200px',
    border: '2px solid #C33C54',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#C33C54',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  headingBox: {
    backgroundColor: '#FFEBEF',
    border: '1px solid #C33C54',
    width: 465,
    height: 34,
    color: '#C33C54',
    fontSize: '14px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // position: "relative",
  },
  blueBoxSmall: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#9FCEFC',
    height: '34px',
    width: '174px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  redBorderGrayBox: {
    width: '78px',
    height: '34px',
    borderRadius: '7px',
    border: '1px solid #C33C54',
    backgroundColor: '#EEF0F0',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  GreenBox: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#2BB670',
    height: '30px',
    width: '174px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: '7%',
  },
}));

const SmallArrow = ({ styleData }) => {
  const classes = useStyles();
  return (
    <div className={classes.flexBox} style={styleData}>
      <img src={SmallRedArrowIcon} alt='small red arrow' />
    </div>
  );
};

const Desktop3 = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      {/* <div className={classes.timelineAppBar}>
        <div className={classes.timelineHeading}>
          {!noNumber && <div className={classes.timelineCircleBG}>X</div>}
          <div className={classes.timelineTitle}>Title</div>
        </div>
      </div> */}
      <div className={classes.headingBox}>
        <p>Screening Tests</p>
      </div>
      <div style={{ width: '465px' }}>
        <div
          className={classes.flexBox}
          style={{ marginTop: '24px', alignItems: 'flex-start' }}
        >
          <div>
            <div className={classes.blueBoxSmall} style={{ margin: '0 4px' }}>
              <p>Rapid Test</p>
            </div>
            <div
              className={classes.flexBox}
              style={{ justifyContent: 'center' }}
            >
              <img src={BranchRedTop} alt='branch' />
            </div>
            <div
              className={classes.flexBox}
              style={{
                width: '180px',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <div>
                <div className={classes.redBorderGrayBox}>
                  <p>Positive</p>
                </div>
                <div
                  className={classes.flexBox}
                  style={{ justifyContent: 'center', marginLeft: '-10px' }}
                >
                  <img src={TopRedBigArrowIcon} alt='branch' />
                </div>
              </div>

              <div style={{ position: 'relative' }}>
                <div className={classes.redBorderGrayBox}>
                  <p>Negative</p>
                </div>
                <div
                  className={classes.flexBox}
                  style={{ justifyContent: 'center', marginLeft: '16px' }}
                >
                  <img src={TopRedArrowSmallIcon} alt='branch' />
                </div>
                <div className={classes.GreenBox}>
                  <p>HIV Negative</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.blueBoxSmall} style={{ margin: '0 4px' }}>
              <p>4th Gen HIV Test</p>
            </div>
            <div
              className={classes.flexBox}
              style={{ justifyContent: 'center' }}
            >
              <img src={BranchRedTop} alt='branch' />
            </div>
            <div
              style={{
                width: '180px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <div className={classes.redBorderGrayBox}>
                  <p>Negative</p>
                </div>
                <div
                  className={classes.flexBox}
                  style={{ justifyContent: 'center', marginLeft: '-10px' }}
                >
                  <img src={TopRedArrowSmallIcon} alt='branch' />
                </div>
              </div>
              <div>
                <div
                  className={classes.redBorderGrayBox}
                  style={{ height: '50px' }}
                >
                  <p>Indertiminate of Positive</p>
                </div>

                <div
                  className={classes.flexBox}
                  style={{ justifyContent: 'center', marginLeft: '20px' }}
                >
                  <img src={TopRedArrowRight} alt='branch' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classes.headingBox}
          style={{
            marginTop: '-2px',
            color: '#fff',
            backgroundColor: '#C33C54',
          }}
        >
          <p>Get Confirmatory Test Done</p>
        </div>

        <div
          className={classes.headingBox}
          style={{
            marginTop: '50px',
          }}
        >
          <p>Confirmatory Tests</p>
        </div>
        <div
          className={classes.blueBoxSmall}
          style={{
            justifyContent: 'center',
            width: '350px',
            height: '51px',
            margin: '24px auto 0px auto',
          }}
        >
          <p>HIV-1/HIV-2 Antibody Differentiation Immunoassay</p>
        </div>
        <div className={classes.flexBox} style={{ justifyContent: 'center' }}>
          <img src={BottomBigBranchRedImage} alt='branch' />
        </div>
        <div
          className={classes.flexBox}
          style={{
            width: '350px',
            justifyContent: 'space-between',
            margin: '0px auto 24px auto',
            alignItems: 'flex-start',
          }}
        >
          <div>
            <div
              className={classes.redBorderGrayBox}
              style={{ width: '122px', height: '43px' }}
            >
              <p>Positive</p>
            </div>
            <div className={classes.flexBox}>
              <img src={LeftVerticalBigRedArrowIcon} alt='small red arrow' />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                className={classes.redBorderGrayBox}
                style={{
                  color: '#fff',
                  backgroundColor: '#C33C54',
                }}
              >
                <p>HIV</p>
              </div>
            </div>
          </div>
          <div>
            <div
              className={classes.redBorderGrayBox}
              style={{ marginLeft: 'auto', width: '122px', height: '43px' }}
            >
              <p>Indertiminate of Positive</p>
            </div>
            <SmallArrow
              styleData={{ justifyContent: 'center', marginLeft: '64px' }}
            />
            <div style={{ width: '185px' }}>
              <div
                className={classes.blueBoxSmall}
                style={{
                  width: '142px',
                  position: 'relative',
                  right: '-52px',
                }}
              >
                <p>Neucleic Acid Test</p>
              </div>
              <div
                className={classes.flexBox}
                style={{ justifyContent: 'center', marginLeft: '60px' }}
              >
                <img src={SmallBranchRedImage} alt='branch' />
              </div>
              <div
                className={classes.flexBox}
                style={{
                  justifyContent: 'space-between',
                  margin: '0px 0 20px 13px',
                  alignItems: 'flex-start',
                }}
              >
                <div>
                  <div className={classes.redBorderGrayBox}>
                    <p>Positive</p>
                  </div>
                  <SmallArrow styleData={{ marginLeft: '-4px' }} />
                  <div
                    className={classes.redBorderGrayBox}
                    style={{
                      color: '#fff',
                      backgroundColor: '#C33C54',
                    }}
                  >
                    <p>HIV</p>
                  </div>
                </div>
                <div style={{ marginLeft: '50px' }}>
                  <div
                    className={classes.redBorderGrayBox}
                    style={{ marginLeft: '10px' }}
                  >
                    <p>Negative</p>
                  </div>
                  <SmallArrow styleData={{ marginLeft: '-4px' }} />
                  <div
                    className={classes.blueBoxSmall}
                    style={{
                      width: '119px',
                      marginLeft: '-10px',
                      backgroundColor: '#2BB670',
                      fontWeight: '400',
                    }}
                  >
                    <p>HIV Negative</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desktop3;
