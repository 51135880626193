import React from 'react';

const T2DDiagnosisMobile = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginBottom: 10,
        }}
      >
        <div
          style={{
            backgroundColor: '#FFEBEF',
            border: '1px solid #C33C54',
            color: '#C33C54',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p>Screening Tests</p>
        </div>
      </div>

      <div
        style={{
          width: '80%',
          padding: '5px 10px',
          backgroundColor: '#A4C2F4',
          borderRadius: 5,
          fontWeight: 'bold',
          fontSize: 14,
          textAlign: 'center',
          alignSelf: 'center',
        }}
      >
        <p>Rapid Test</p>
      </div>

      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: '#C33C54',
          alignSelf: 'center',
        }}
      ></div>

      <div
        style={{
          width: '80%',
          backgroundColor: '#C33C54',
          height: 1,
          alignSelf: 'center',
        }}
      ></div>

      <div style={{ alignSelf: 'center', width: '80%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          ≤ 99 mg/dL ({`<5.6`} mmol/L)
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          100-125 mg/dL (5.6-6.9 mmol/L)
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #2BB670',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          ≥ 126 mg/dL (7 mmol/L)
        </p>
      </div>

      <div style={{ alignSelf: 'center', width: '80%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <div
          style={{
            width: '40%',
            padding: '5px 10px',
            backgroundColor: '#2BB670',
            borderRadius: 5,
            fontWeight: 'bold',
            fontSize: 14,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // alignSelf: 'center',
            color: '#fff',
            height: '90%',
          }}
        >
          <p>Diabetes: Negative</p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '67%',
              backgroundColor: '#C33C54',
              height: 1,
            }}
          ></div>
          <div
            style={{
              height: 50,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>

      <div
        style={{
          width: '80%',
          padding: '5px 10px',
          backgroundColor: '#A4C2F4',
          borderRadius: 5,
          fontWeight: 'bold',
          fontSize: 14,
          textAlign: 'center',
          alignSelf: 'center',
        }}
      >
        <p>Random Plasma Glucose Test</p>
      </div>

      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: '#C33C54',
          alignSelf: 'center',
        }}
      ></div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '67%',
            backgroundColor: '#C33C54',
            height: 1,
          }}
        ></div>
      </div>

      <div style={{ alignSelf: 'center', width: '67%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          ≤ 99 mg/dL ({`<5.6`} mmol/L)
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #2BB670',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          ≥ 126 mg/dL (7 mmol/L)
        </p>
      </div>

      <div style={{ alignSelf: 'center', width: '67%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '67%',
            backgroundColor: '#C33C54',
            height: 1,
          }}
        ></div>
      </div>

      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: '#C33C54',
          alignSelf: 'center',
        }}
      ></div>
      <div
        style={{
          backgroundColor: '#FFEBEF',
          border: '1px solid #C33C54',
          color: '#C33C54',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p>Confirmatory Tests</p>
      </div>
      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: '#C33C54',
          alignSelf: 'center',
        }}
      ></div>
      <div
        style={{
          width: '80%',
          padding: '5px 10px',
          backgroundColor: '#A4C2F4',
          borderRadius: 5,
          fontWeight: 'bold',
          fontSize: 14,
          textAlign: 'center',
          alignSelf: 'center',
        }}
      >
        <p>HbA1c Test</p>
      </div>

      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: '#C33C54',
          alignSelf: 'center',
        }}
      ></div>

      <div
        style={{
          width: '80%',
          backgroundColor: '#C33C54',
          height: 1,
          alignSelf: 'center',
        }}
      ></div>

      <div style={{ alignSelf: 'center', width: '80%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {`<5.5`}
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {`> 6.5%`}
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #2BB670',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          5.7-6.4%
        </p>
      </div>

      <div style={{ alignSelf: 'center', width: '80%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>

      <div style={{ alignSelf: 'center', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              width: '30%',
              padding: '5px 10px',
              backgroundColor: '#2BB670',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // alignSelf: 'center',
              color: '#fff',
            }}
          >
            <p>Diabetes: Negative</p>
          </div>
          <div
            style={{
              width: '30%',
              padding: '5px 10px',
              backgroundColor: '#980000',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // alignSelf: 'center',
              color: '#fff',
            }}
          >
            <p>Diabetes: Positive</p>
          </div>
          <div
            style={{
              width: '30%',
              padding: '5px 10px',
              backgroundColor: '#FFA10A',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // alignSelf: 'center',
              color: '#000',
            }}
          >
            <p>Pre-diabetes</p>
          </div>
        </div>
      </div>

      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: '#C33C54',
          alignSelf: 'center',
        }}
      ></div>

      <div
        style={{
          width: '80%',
          padding: '5px 10px',
          backgroundColor: '#A4C2F4',
          borderRadius: 5,
          fontWeight: 'bold',
          fontSize: 14,
          textAlign: 'center',
          alignSelf: 'center',
        }}
      >
        <p>C-Peptide Test</p>
      </div>

      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: '#C33C54',
          alignSelf: 'center',
        }}
      ></div>

      <div
        style={{
          width: '80%',
          backgroundColor: '#C33C54',
          height: 1,
          alignSelf: 'center',
        }}
      ></div>

      <div style={{ alignSelf: 'center', width: '80%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {`> 250`}
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          81-249
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {`< 80`}
        </p>
      </div>

      <div style={{ alignSelf: 'center', width: '80%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>

      <div style={{ alignSelf: 'center', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              width: '30%',
              padding: '5px 10px',
              backgroundColor: '#980000',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // alignSelf: 'center',
              color: '#fff',
            }}
          >
            <p>Type II</p>
          </div>
          <div
            style={{
              width: '30%',
              padding: '5px 10px',
              backgroundColor: '#980000',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // alignSelf: 'center',
              color: '#fff',
            }}
          >
            <p>MODY or Type I</p>
          </div>
          <div
            style={{
              width: '30%',
              padding: '5px 10px',
              backgroundColor: '#980000',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // alignSelf: 'center',
              color: '#fff',
            }}
          >
            <p>Type I</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default T2DDiagnosisMobile;
