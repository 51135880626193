import DiseasesReducer from './DiseasesReducer';
import PatientJourneyReducer from './PatientJourneyReducer';
import QuestionBankReducer from './QuestionBankReducer';
import TrialsReducer from './TrialsReducer';
import UiReducer from './UiReducer';

const rootReducer = {
  ui: UiReducer.reducer,
  diseases: DiseasesReducer.reducer,
  patientJourney: PatientJourneyReducer.reducer,
  trials: TrialsReducer.reducer,
  questions: QuestionBankReducer.reducer,
};

export default rootReducer;
