import React from 'react';

const OvalDiv = ({ bg, borderColor, text, textColor, width, editable='false' }) => {
  return (
    <div
      style={{
        backgroundColor: bg,
        border: `1px solid ${borderColor}`,
        borderRadius: 20,
        textAlign: 'center',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: width ?? 'unset',
      }}
    >
      <p contentEditable={editable} style={{ padding: 10, color: textColor ?? 'black' }}>{text}</p>
    </div>
  );
};

export default OvalDiv;
