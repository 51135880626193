import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import './StagesHTMLFiles/Stages.css';
import LoadingWidget from '../../containers/formField/LoadingWidget';
import TrialsMobileCard from './TrialsMobileCard';
import TrialsDesktopCard from './TrialsDesktopCard';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTrialsData } from '../../reducers/TrialsReducer';
import { Paper } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const TrialsDatabase = () => {
  const { diseaseName } = useParams();
  const dispatch = useDispatch();
  const [Trials, setTrials] = useState({});
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState('desktop');
  const trials = useSelector((state) => state.trials);
  const { allTrials } = trials;

  useEffect(() => {
    setLoading(true);
    dispatch(getAllTrialsData());
    setLoading(false);
  }, []);

  return (
    <>
      <Paper
        style={{
          height: 50,
          display: 'flex',
          alignItems: 'center',
          padding: 20,
        }}
      >
        <ArrowBackIcon />
      </Paper>

      {loading ? (
        <div style={{ marginTop: '20%' }}>
          <LoadingWidget />{' '}
        </div>
      ) : (
        <div className='p-3' style={{ display: 'flex' }}>
          <div style={{ width: '10%' }}>filters</div>
          <div style={{ width: '90%', alignSelf: 'flex-end' }}>
            {/* <div style={{ display: 'flex', fontWeight: 'bold' }}>
              <div
                style={{ marginRight: 20 }}
                onClick={() => setView('mobile')}
              >
                mobile
              </div>
              <div onClick={() => setView('desktop')}>desktop</div>
            </div> */}
            {view === 'mobile' ? (
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'wrap',
                }}
              >
                <TrialsMobileCard />
                <TrialsMobileCard />
                <TrialsMobileCard />
                <TrialsMobileCard />
                <TrialsMobileCard />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'wrap',
                }}
              >
                {allTrials.map((item, index) => (
                  <TrialsDesktopCard trialDetails={item} />
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TrialsDatabase;
