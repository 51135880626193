import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TrialsModal from './ActiveTrialsModal';

const ActiveTrialsPhasesSection = ({ item }) => {
  const [DevicesRowClicked, setDevicesRowClicked] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]); // State to keep track of selected item
  const [showModal, setShowModal] = useState(false); // State to toggle modal visibility
  const urlParams = new URLSearchParams(window.location.search);
  console.log("ITEM: ", item);
  return (
    <>
      <div 
        className={`phase-trials-row appr-subheading ${DevicesRowClicked ? 'expanded' : ''}`}
        onClick={() => setDevicesRowClicked(!DevicesRowClicked)}
      >
        <div className='phase-trials-cell'>
          {item[0]}
        </div>
        <div
          className='phase-trials-cell'
          style={{textAlign: 'right'}}
        >
          {Object.values(item[1]).map((arr) => arr["Phase 1"].length).reduce((acc, curr) => acc + curr, 0) < 1 ? '-' : Object.values(item[1]).map((arr) => arr["Phase 1"].length).reduce((acc, curr) => acc + curr, 0)}
        </div>
        <div
          className='phase-trials-cell'
          style={{textAlign: 'right'}}
        >
          {Object.values(item[1]).map((arr) => arr["Phase 2"].length).reduce((acc, curr) => acc + curr, 0) < 1 ? '-' : Object.values(item[1]).map((arr) => arr["Phase 2"].length).reduce((acc, curr) => acc + curr, 0)}
        </div>
        <div
          className='phase-trials-cell'
          style={{textAlign: 'right'}}
        >
          {Object.values(item[1]).map((arr) => arr["Phase 3"].length).reduce((acc, curr) => acc + curr, 0) < 1 ? '-' : Object.values(item[1]).map((arr) => arr["Phase 3"].length).reduce((acc, curr) => acc + curr, 0)}
        </div>
        <div
          className='phase-trials-cell'
          style={{textAlign: 'right'}}
        >
          {Object.values(item[1]).map((arr) => arr["Phase 4"].length).reduce((acc, curr) => acc + curr, 0) < 1 ? '-' : Object.values(item[1]).map((arr) => arr["Phase 4"].length).reduce((acc, curr) => acc + curr, 0)}
          <div style={{ display: 'inline' }}>
            {DevicesRowClicked ? (
              <ExpandLessIcon
                style={{ color: DevicesRowClicked ? 'white' : 'black' }}
              />
            ) : (
              <ExpandMoreIcon />
            )}
          </div>
        </div>
      </div>

      {DevicesRowClicked === true &&
        Object.entries(item[1]).map((subitem, index) => {
          return (
            <div 
              className='phase-trials-row'
            >
              <div className='phase-trials-cell'>
                {subitem[0]}
              </div>
              <div className='phase-trials-cell'
              style={{textAlign: 'right'}}
              onClick={() => {
                if (subitem[1]["Phase 1"].length > 0) {
                  setSelectedItem([subitem[0], subitem[1]["Phase 1"]]);
                  setShowModal(true);
                }
              }}
              >
                {subitem[1]["Phase 1"].length > 0 ? subitem[1]["Phase 1"].length : '-'}
              </div>
              <div className='phase-trials-cell'
              style={{textAlign: 'right'}}
              onClick={() => {
                if (subitem[1]["Phase 2"].length > 0) {
                  setSelectedItem([subitem[0], subitem[1]["Phase 2"]]);
                  setShowModal(true);
                }
              }}
              >
                {subitem[1]["Phase 2"].length > 0 ? subitem[1]["Phase 2"].length : '-'}
              </div>
              <div className='phase-trials-cell'
              style={{textAlign: 'right'}}
              onClick={() => {
                if (subitem[1]["Phase 3"].length > 0) {
                  setSelectedItem([subitem[0], subitem[1]["Phase 3"]]);
                  setShowModal(true);
                }
              }}
              >
                {subitem[1]["Phase 3"].length > 0 ? subitem[1]["Phase 3"].length : '-'}
              </div>
              <div className='phase-trials-cell'
              style={{textAlign: 'right', paddingRight: '28px'}}
              onClick={() => {
                if (subitem[1]["Phase 4"].length > 0) {
                  setSelectedItem([subitem[0], subitem[1]["Phase 4"]]);
                  setShowModal(true);
                }
              }}
              >
                {subitem[1]["Phase 4"].length > 0 ? subitem[1]["Phase 4"].length : '-'}
              </div>
            </div>
          );
        })}
        {/* Render the modal if it should be shown */}
        {showModal && selectedItem && (
          <TrialsModal item={selectedItem} onClose={() => setShowModal(false)} />
        )}
    </>
  );
};

export default ActiveTrialsPhasesSection;
