import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import BreastCancerDiagnosisMobile from './BreastCancerDiagnosisMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
    // border: "1px solid #000",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  headingBox: {
    backgroundColor: '#FFEBEF',
    border: '1px solid #C33C54',
    width: '100%',
    height: 34,
    color: '#C33C54',
    fontSize: '14px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  blueBoxSmall: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#9FCEFC',
    height: '50px',
    width: '100%',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',

    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  // Arrow
  arrow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
  },
  arrowtail: {
    background: '#C33C54',
    height: '20px',
    width: '1.5px',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '6px solid #C33C54',
    borderRight: '6px solid transparent',
    borderLeft: '6px solid transparent',
  },
  // branch
  branchWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  branchMain: {
    borderRight: '1.5px solid #C33C54',
    borderLeft: '1.5px solid #C33C54',
    borderTop: '1.5px solid #C33C54',
  },
  branchMiddleLine: {
    justifySelf: 'center',
    background: '#C33C54',
  },
  redBorderGrayBox: {
    width: '98px',
    height: '34px',
    borderRadius: '7px',
    border: '1px solid #C33C54',
    backgroundColor: '#EEF0F0',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      fontSize: '9px',
    },
  },
  GreenBox: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#2BB670',
    height: '30px',
    width: '204px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '103px',
    marginLeft: '120px',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      fontSize: '10px',
      //   left: "-18%",
    },
  },
  breastCancerRed: {
    backgroundColor: '#C33C54',
    border: '1px solid #C33C54',
    width: '100%',
    height: 34,
    color: '#FFF',
    fontSize: '14px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const Arrow = ({ tailHeight, tailWidth, arrowPointStyle, arrowMainStyle }) => {
  const classes = useStyles();
  return (
    <div class={classes.arrow} style={arrowMainStyle}>
      <div class={classes.arrowtail} style={{ height: tailHeight }}></div>
      <div class={classes.arrowpoint}></div>
    </div>
  );
};

const Branch = ({
  branchStyle,
  middleLineStyle,
  branchClassName,
  middleLineClassName,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.branchWrap}>
      <div className={classes.branchMiddleLine} style={middleLineStyle}></div>
      <div className={classes.branchMain} style={branchStyle}></div>
    </div>
  );
};

const BreastCancerDiagnosis = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const data = {
    pageTitle: 'Breast Cancer: Diagnosis',
    screenTest: 'Screening Test',
    level1: [
      { test: 'Mammography', level2: ['Positive', 'Negative'] },
      {
        test: 'Digital Breast Tomosynthesis(DBT)  ',
        level2: ['Negative', 'Positive'],
      },
      //   { test: "Fundoscopy", level2: ["Positive", "Negative"] },
      //   { test: "Tonometry", level2: ["Negative", "Positive"] },
    ],
    ultrasound: { title: 'Ultrasound', level2: ['Positive', 'Negative'] },
    resultNegative: 'Breast Cancer: negative',
    testNameConfirmatory: 'Confirmatory test',
    confirmatoryTest: {
      screenTest: 'Confirmatory test',
      level1: [
        { test: 'Core needle Biopsy (CNB)', level2: ['Positive', 'Negative'] },
        {
          test: 'Fine Needle Aspiration (FNA)',
          level2: ['Negative', 'Positive'],
        },
      ],
    },
  };

  return isMobile ? (
    <BreastCancerDiagnosisMobile />
  ) : (
    <>
      <div className={classes.root} style={{ float: !noFloat && 'right' }}>
        <h2>{data.pageTitle}</h2>
        <div className={classes.headingBox} style={{ marginTop: '30px' }}>
          <p> {data.screenTest}</p>
        </div>
        <div style={{ marginTop: 30 }}>
          <Grid container spacing={1}>
            {data.level1.map((test, i) => (
              <Grid item xs={5} md={5}>
                <div className={classes.blueBoxSmall}>
                  <p>{test.test}</p>
                </div>
                <Branch
                  middleLineStyle={{
                    width: '1.5px',
                    height: '20px',
                    marginLeft: '-10px',
                  }}
                  branchStyle={{
                    width: '110px',
                    height: '30px',
                    marginLeft: '-10px',
                  }}
                />
                <div style={{ display: 'flex', position: 'relative' }}>
                  <div
                    style={{
                      marginRight: '7px',
                    }}
                    className={classes.redBorderGrayBox}
                  >
                    <p>{test.level2[0]}</p>
                  </div>
                  <Arrow
                    arrowMainStyle={{ left: '20px', top: '31px' }}
                    tailHeight={i === 0 || i === 2 ? 168 : 100}
                  />

                  <div
                    style={{
                      marginRight: '7px',
                    }}
                    className={classes.redBorderGrayBox}
                  >
                    <p>{test.level2[1]}</p>
                  </div>
                  <Arrow
                    arrowMainStyle={{ right: '20px', top: '31px' }}
                    tailHeight={i === 1 || i === 3 ? 168 : 100}
                  />
                </div>
                {(i === 0 || i === 2) && (
                  <div className={classes.GreenBox}>
                    <p>{data.resultNegative}</p>
                  </div>
                )}
              </Grid>
            ))}

            <Grid container spacing={1} style={{ marginTop: 30 }}>
              <Grid item xs={10} md={10}>
                <div
                  className={classes.blueBoxSmall}
                  style={{ width: '100%', fontSize: '16px' }}
                >
                  <p>{data.ultrasound.title}</p>
                </div>
                <Branch
                  middleLineStyle={{
                    width: '1.5px',
                    height: '20px',
                    marginLeft: '10px',
                  }}
                  branchStyle={{
                    width: '130px',
                    height: '30px',
                    marginLeft: '10px',
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      marginRight: '7px',
                    }}
                    className={classes.redBorderGrayBox}
                  >
                    <p>{data.ultrasound.level2[0]}</p>
                  </div>
                  <Arrow
                    arrowMainStyle={{ left: '35%', top: '33px' }}
                    tailHeight={46}
                  />
                  <div
                    style={{
                      marginRight: '7px',
                    }}
                    className={classes.redBorderGrayBox}
                  >
                    <p>
                      <p>{data.ultrasound.level2[1]}</p>
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* </div> */}

          <div className={classes.headingBox} style={{ marginTop: '55px' }}>
            <p> {data.confirmatoryTest.screenTest}</p>
          </div>
          <div style={{ marginTop: 30 }}>
            <Grid container spacing={1}>
              {data.confirmatoryTest.level1.map((test, i) => (
                <Grid item xs={5} md={5}>
                  <div className={classes.blueBoxSmall}>
                    <p>{test.test}</p>
                  </div>
                  <Branch
                    middleLineStyle={{
                      width: '1.5px',
                      height: '20px',
                      marginLeft: '-10px',
                    }}
                    branchStyle={{
                      width: '110px',
                      height: '30px',
                      marginLeft: '-10px',
                    }}
                  />
                  <div style={{ display: 'flex', position: 'relative' }}>
                    <div
                      style={{
                        marginRight: '7px',
                      }}
                      className={classes.redBorderGrayBox}
                    >
                      <p>{test.level2[0]}</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{ left: '20px', top: '33px' }}
                      tailHeight={i === 0 || i === 2 ? 168 : 98}
                    />

                    <div
                      style={{
                        marginRight: '7px',
                      }}
                      className={classes.redBorderGrayBox}
                    >
                      <p>{test.level2[1]}</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{ right: '20px', top: '33px' }}
                      tailHeight={i === 1 || i === 3 ? 168 : 98}
                    />
                  </div>
                  {(i === 0 || i === 2) && (
                    <div className={classes.GreenBox}>
                      <p>{data.resultNegative}</p>
                    </div>
                  )}
                </Grid>
              ))}
            </Grid>

            <div
              className={classes.breastCancerRed}
              style={{ marginTop: '40px' }}
            >
              <p>Breast Cancer</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BreastCancerDiagnosis;
