import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { Popover } from 'react-tiny-popover';
import { Link } from 'react-router-dom';
import '../../scss/report.scss';

const TextPopover = (props) => {
  const { refData } = props;

  const [text, setText] = useState();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [positionValue, setPositionValue] = useState({
    top: '200px',
    left: '200px',
  });
  const [displayPopoverContent, setDisplayPopoverContent] = useState({});
  const findPosition = (event) => {
    const s = window.getSelection();
    s.modify('extend', 'backward', 'word');
    const b = s.toString();

    s.modify('extend', 'forward', 'word');
    const a = s.toString();
    s.modify('move', 'forward', 'character');
    var x = event.clientX;
    var y = event.clientY;
    if (b + a && parseInt(b + a)) {
      setPositionValue({
        top: (y - 200 < 0 ? 0 : y - 0) + 'px',
        left: `${x}px`,
      });
      const refId = parseInt(b + a);
      const refDetail = refData.find((res) => res.id === refId);
      setDisplayPopoverContent(refDetail);
      refDetail && setIsPopoverOpen(true);
    } else {
      setIsPopoverOpen(false);
    }
  };
  useEffect(() => {
    setText(get(props, 'text', ''));
  }, [get(props, 'text', '')]);

  const popOverContent = () => {
    const showUpto =
      get(displayPopoverContent, 'source_url', '').length / 3;
    const data = !(
      localStorage.getItem('usergroup') &&
      (localStorage.getItem('usergroup') || []).length
    )
      ? `${get(displayPopoverContent, 'source_url', '').slice(
          0,
          parseInt(showUpto)
        )} ...`
      : get(displayPopoverContent, 'source_url', '');
    return (
      <div className='p-1 m-1'>
        {/* <div>
          <img
            src="https://homepages.cae.wisc.edu/~ece533/images/pool.png"
            height="100px"
            width="100px"
          />
        </div> */}
        <div style={{ width: '80%' }}>
          {/* <span>{data}</span> */}
          <span
            dangerouslySetInnerHTML={{
              __html: data,
            }}
          ></span>
          {/* <span dangerouslySetInnerHTML={{
                      __html: data,
                    }}></span> */}
          {/* <CLabel  
            dangerouslySetInnerHTML={{
                      __html: data,
                    }}></CLabel> */}
        </div>
        {!(
          localStorage.getItem('usergroup') &&
          (localStorage.getItem('usergroup') || []).length
        ) ? (
          <div>
            <Link
              to={{ pathname: '/login', nextRoute: window.location.pathname }}
              className=' p-2 btn btn-outline-primary'
            >
              {/* <CButton active color="primary" size="md" aria-pressed="true">
              {" "} */}
              Login
              {/* </CButton> */}
            </Link>
          </div>
        ) : null}
      </div>
    );
  };
  return (
    <div>
      {text || '' ? (
        <div className='my-component'>
          <Popover
            containerClassName='my-component-popover text-center card col-sm-3 p-0'
            containerStyle={positionValue}
            isOpen={isPopoverOpen}
            align='end'
            position='top'
            onClickOutside={() => setIsPopoverOpen(false)}
            disableReposition
            padding={8}
            content={({ position, targetRect, popoverRect }) => (
              <div className='my-component-popover-content'>
                {popOverContent()}
              </div>
            )}
          >
            <div
              id='popoverPosition'
              onClick={findPosition}
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          </Popover>
        </div>
      ) : null}
    </div>
  );
};

export default TextPopover;
