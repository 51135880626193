import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Container,
  Collapse,
  Typography,
  Paper,
} from '@material-ui/core';
import WhiteArrowIcon from '../../../assets/img/WhiteArrowIcon.svg';
import DarkBlueArrow from '../../../assets/img/DarkBlueArrow.svg';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setStagesData } from '../../../reducers/PatientJourneyReducer';
import OvalDiv from './MUI/OvalDiv';
import DropdownBox from './MUI/DropdownBox';
import { isMobile } from 'react-device-detect';
// import WhiteArrowIcon from "../../assets/assets/img/img/WhiteArrowIcon.svg";
// import DarkBlueArrow from "../../assets/assets/img/img/DarkBlueArrow.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem ',
    },
  },
  main: {
    position: 'relative',
    // marginTop: '60px',
    width: '435px',
    marginLeft: '65px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 'unset',
    },
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '2px solid #266EBA',
    margin: '0 15px 0 0 ',
    background: '#EFFAFB',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#266EBA',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#EFFAFB',
    borderRadius: '200px',
    border: '2px solid #266EBA',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#266EBA',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },
  titleBox: {
    backgroundColor: '#EEF0F0',
    border: '2px solid #696A6C',
    borderRadius: '9px',
    width: 125,
    // height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    textAlign: 'center',
  },
  mainTitleText: {
    color: '#000',
    fontWeight: 'bold',
    // fontSize: '0.75vw',
  },
  arrow: {
    height: '110%',
    position: 'absolute',
    // top: '33px',
    left: '52px',
  },
  arrowtail: {
    // marginTop: '14px',
    width: '6px',
    background: '#696A6C',
    height: '100%',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '24px solid #696A6C',
    borderRight: '12px solid transparent',
    borderLeft: '12px solid transparent',
    marginLeft: '-9px',
  },
  bigArrowCont: {
    height: '100%',
    position: 'absolute',
    // top: '73px',
    left: '-40px',
    [theme.breakpoints.down('sm')]: {
      left: '-5px',
    },
  },
  bigArrowtail: {
    marginTop: '14px',
    width: '25px',
    background: '#266EBA',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '20px',
    },
  },
  bigArrowTitleText: {
    fontWeight: 'bold',
    fontSize: '14px',
    transform: 'rotate(90deg)',
    position: 'relative',
    top: '-70%',
    left: '-8px',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      left: '-10px',
    },
  },
  bigArrowpoint: {
    width: 40,
    height: 0,
    borderTop: '36px solid #266EBA',
    borderRight: '20px solid transparent',
    borderLeft: '20px solid transparent',
    // marginLeft: '-12px',
    marginLeft: '-10px',
    marginRight: -10,
    [theme.breakpoints.down('sm')]: {
      borderTop: '29px solid #266EBA',
      borderRight: '20px solid transparent',
      borderLeft: '20px solid transparent',
      marginLeft: '-10px',
      marginRight: -10,
    },
  },
  boxCont: {
    display: 'flex',
    marginTop: '30px',
    marginLeft: '30px',
    position: 'relative',
    marginBottom: '40px',
  },
  circle: {
    width: '91px',
    border: '2px solid #266EBA',
    height: '43px',
    display: 'flex',
    zIndex: 99,
    background: '#EEF5FC',
    alignItems: 'center',
    fontWeight: 'bold',
    color: '#266EBA',
    borderRadius: '42px',
    justifyContent: 'center',
    position: 'absolute',
    left: '-20px',
    top: '50px',
    '&:first-child:before': {
      content: '" "',
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '61px',
      height: '35px',
      left: '-6px',
    },
  },
  branchBarWrap: {
    width: '88px',
    position: 'relative',
    marginLeft: '22px',
    [theme.breakpoints.down('sm')]: {
      width: '48px',
    },
  },
  branchBar: {
    width: '100%',
    border: '2px solid #696A6C',
    height: '3px',
    backgroundColor: '#696A6C',
    position: 'absolute',
    top: '22px',
  },
  dropdownContainer: {
    width: '280px',
    borderRadius: 9,
    [theme.breakpoints.down('sm')]: {
      minWidth: '180px',
      maxWidth: '250px',
    },
  },
  dropdownHeader: {
    border: '2px solid #266EBA',
    textAlign: 'center',
    width: '280px',
    borderRadius: 9,
    cursor: 'pointer',
    position: 'relative',
    '& img': {
      position: 'absolute',
      right: '10px',
      top: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      maxWidth: '250px',
      '& img': {
        position: 'absolute',
        right: '10px',
        top: '20%',
      },
    },
  },
  sometext: {
    marginTop: 0,
    marginBottom: '4px',
    fontSize: 14,
  },
  ddHeadingText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '4px',
  },
  dropdownBody: {
    backgroundColor: '#EEF5FC',
    padding: '0.5rem 1rem',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },
  dropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
}));

// const DropdownBox = ({ stages }) => {
//   const classes = useStyles();
//   const [openDropdown, setDropdown] = useState(false);

//   const handleDropdown = () => {
//     setDropdown((prev) => !prev);
//   };

//   return (
//     <div className={classes.dropdownContainer}>
//       <div
//         className={classes.dropdownHeader}
//         onClick={handleDropdown}
//         style={{
//           backgroundColor: openDropdown ? '#266EBA' : ' #EEF5FC',
//           color: openDropdown ? '#fff' : '#266EBA',
//           paddingTop: 10,
//           paddingBottom: 10,
//         }}
//       >
//         <div style={{ width: '90%' }}>
//           <p className={classes.ddHeadingText}>{stages.boxTitle}</p>
//         </div>
//         {openDropdown ? (
//           <img src={WhiteArrowIcon} alt='arrow drop down' />
//         ) : (
//           <img src={DarkBlueArrow} alt='green arrow drop down' />
//         )}
//       </div>
//       <Collapse in={openDropdown}>
//         <Paper className={classes.dropdownBody}>
//           <Typography>
//             <p className={classes.dropDownbodyText}>
//               <b>Statement: </b>
//               {stages.statement}
//             </p>
//             <p className={classes.dropDownbodyText}>
//               <b>Duration: </b>
//               {stages.Duration}
//             </p>
//             <p className={classes.dropDownbodyText}>
//               <b>Symptoms:</b>
//               {stages.Symptoms}
//             </p>
//             <p className={classes.dropDownbodyText}>
//               <b>What happens:</b>
//               {stages.whatHappens}
//             </p>
//           </Typography>
//         </Paper>
//       </Collapse>
//     </div>
//   );
// };

const Desktop5 = ({ noFloat, noNumber }) => {
  const { diseaseName, diseaseId } = useParams();
  const classes = useStyles();
  const patientJourney = useSelector((state) => state.patientJourney);
  const { stages } = patientJourney;
  const stagesLength = Object.keys(stages).length;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setStagesData(diseaseId));
  }, []);

  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      <div className={classes.timelineAppBar}>
        {stagesLength !== 0 && (
          <div className={classes.main}>
            {stages.topBoxTitleText.length > 0 && (
              <div className={classes.titleBox}>
                <div className={classes.mainTitleText}>
                  {stages.topBoxTitleText}
                </div>
              </div>
            )}
            <div class={classes.arrow}>
              <div class={classes.arrowtail}></div>
              <div class={classes.arrowpoint}></div>
              {stages.bottomBoxTitleText.length > 0 && (
                <div
                  className={classes.titleBox}
                  style={{ position: 'relative', left: '-42%' }}
                >
                  <div className={classes.mainTitleText}>
                    {stages.bottomBoxTitleText}
                  </div>
                </div>
              )}
            </div>
            <div class={classes.bigArrowCont}>
              <div
                style={{
                  marginTop: 20,
                  height: '100%',
                  backgroundColor: '#266EBA',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 20,
                }}
              >
                <div
                  style={{
                    // transform: 'rotate(90deg)',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#fff',
                    height: '100%',
                    // width: 40,
                    writingMode: 'vertical-rl',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <p>{stages.leftArrowTitleText}</p>
                </div>
              </div>
              <div class={classes.bigArrowpoint}></div>
            </div>
            {/* <div class={classes.bigArrowCont}>
              <div class={classes.bigArrowtail}></div>
              <div class={classes.bigArrowpoint}></div>
              <p className={classes.bigArrowTitleText}>
                {stages.leftArrowTitleText}
              </p>
            </div> */}
            {stages.data.map((b, i) => (
              <div className={classes.boxCont} key={i}>
                {b.circleTitle.length > 1 ? (
                  <div
                    style={{
                      position: 'absolute',
                      top: isMobile ? 40 : 50,
                      left: isMobile ? 0 : -25,
                      display: 'flex',
                      justifyContent: 'center',
                      zIndex: 1,
                    }}
                  >
                    <OvalDiv
                      text={b.circleTitle}
                      bg='#EEF5FC'
                      width={isMobile ? 50 : 100}
                      borderColor='#266EBA'
                      textColor='#266EBA'
                      editable='true'
                    />
                  </div>
                ) : null}
                <div className={classes.branchBarWrap}>
                  <div className={classes.branchBar}></div>
                </div>
                <DropdownBox stages={b} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Desktop5;
