import React, { useEffect, useState } from 'react';
import { makeStyles, Paper, Collapse, Typography } from '@material-ui/core';
import CareTeamArrow from '../../../../assets/img/CareTeamArrow.svg';
import WhiteArrowIcon from '../../../../assets/img/WhiteArrowIcon.svg';
import DarkBlueArrow from '../../../../assets/img/DarkBlueArrow.svg';

const useStyles = makeStyles((theme) => ({
  dropdownContainer: {
    backgroundColor: '#EFFAFB',
    width: '280px',
    borderRadius: 9,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '250px',
      minWidth: '200px',
      width: 'unset',
    },
  },
  stagesDropdownContainer: {
    width: '280px',
    borderRadius: 9,
    [theme.breakpoints.down('sm')]: {
      minWidth: '180px',
      maxWidth: '250px',
    },
  },
  monitoringDropdownContainer: {
    // backgroundColor: "#674EA7",
    width: '100%',
    borderRadius: 9,
    marginBottom: '1.05rem',
  },
  dropdownHeader: {
    display: 'flex',
    border: '2px solid #2B979E',
    textAlign: 'center',
    width: '280px',
    borderRadius: 9,
    cursor: 'pointer',
    position: 'relative',
    // '& img': {
    //   position: 'absolute',
    //   right: '10px',
    //   top: '40%',
    // },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '250px',
      minWidth: '200px',
      width: 'unset',
      // '& img': {
      //   top: '20%',
      // },
    },
  },
  stagesDropdownHeader: {
    border: '2px solid #266EBA',
    textAlign: 'center',
    width: '280px',
    borderRadius: 9,
    cursor: 'pointer',
    position: 'relative',
    '& img': {
      position: 'absolute',
      right: '10px',
      top: '40%',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      maxWidth: '250px',
      '& img': {
        position: 'absolute',
        right: '10px',
        top: '20%',
      },
    },
  },
  monitoringDropdownHeader: {
    border: '2px solid #674EA7',
    backgroundColor: '#674EA7',
    color: 'white',
    width: '100%',
    borderRadius: 9,
    cursor: 'pointer',
    padding: '10px 12px',
    '& p': {
      margin: '0 !important',
    },
    display: 'flex',
    justifyContent: 'space-between',
  },
  ddHeadingText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '4px',
  },
  monitoringHeadingText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '4px',
  },
  dropdownBody: {
    backgroundColor: '#EFFAFB',
    padding: '0.5rem 1rem',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },
  stagesDropdownBody: {
    backgroundColor: '#EEF5FC',
    padding: '0.5rem 1rem',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },
  dropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
  stagesDropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
  sometext: {
    marginTop: 0,
    marginBottom: '4px',
    fontSize: 14,
  },
  headingText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '8px',
  },
  monitoringDropdownBody: {
    backgroundColor: '#F3F1F8',
    padding: '0.5rem 1rem',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },
  monitoringDropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
  boxContainer: {
    width: '292px',
  },
  monitoringBoxContainerHeader: {
    backgroundColor: '#674EA7',
    border: '2px solid #674EA7',
    borderRadius: '9px',
    textAlign: 'center',
    color: 'white',
  },
  subHeadingText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: '4px',
    marginTop: '0',
  },
  boxBody: {
    backgroundColor: '#F3F1F8',
    border: '2px solid #674EA7',
    width: '100%',
    // minHeight: '100%',
    borderRadius: '9px',
    padding: '1.25rem 1rem',
    position: 'relative',
    top: -10,
  },
}));

const DropdownBox = ({ careTeam, stages, monitoring }) => {
  const classes = useStyles();
  const [openDropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  return careTeam ? (
    <div
      className={classes.dropdownContainer}
      style={{
        backgroundColor: openDropdown ? '#2B979E' : ' #EFFAFB',
        color: openDropdown ? '#fff' : '#000',
      }}
    >
      <div className={classes.dropdownHeader} onClick={handleDropdown}>
        <div style={{ width: '90%', padding: 4 }}>
          <p className={classes.headingText}>{careTeam.boxTitle}</p>
          <p className={classes.sometext}>{careTeam.statement}</p>
        </div>

        {openDropdown ? (
          <img src={WhiteArrowIcon} alt='arrow drop down' />
        ) : (
          <img src={CareTeamArrow} alt='green arrow drop down' />
        )}
      </div>
      <Collapse in={openDropdown}>
        <Paper className={classes.dropdownBody}>
          <Typography>
          {careTeam.when && (
            <p className={classes.dropDownbodyText}>
              <b>When: </b>
              {careTeam.when}
            </p>
          )}
          {careTeam.why && (
            <p className={classes.dropDownbodyText}>
              <b>Why:</b>
              {careTeam.why}
            </p>
          )}
          {careTeam.care && (
            <p className={classes.dropDownbodyText}>
              <b>Care Provided by Caregiver: </b>
              {careTeam.care}
            </p>
          )}
          {careTeam.frequency && (
            <p className={classes.dropDownbodyText}>
              <b>Frequency:</b>
              {careTeam.frequency}
            </p>
          )}
          </Typography>
        </Paper>
      </Collapse>
    </div>
  ) : stages ? (
    <div className={classes.stagesDropdownContainer}>
      <div
        className={classes.stagesDropdownHeader}
        onClick={handleDropdown}
        style={{
          backgroundColor: openDropdown ? '#266EBA' : ' #EEF5FC',
          color: openDropdown ? '#fff' : '#266EBA',
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <div style={{ width: '90%' }}>
          <p className={classes.ddHeadingText}>{stages.boxTitle}</p>
        </div>
        {openDropdown ? (
          <img src={WhiteArrowIcon} alt='arrow drop down' />
        ) : (
          <img src={DarkBlueArrow} alt='green arrow drop down' />
        )}
      </div>
      <Collapse in={openDropdown}>
        <Paper className={classes.stagesDropdownBody}>
          <Typography>
            <p className={classes.stagesDropDownbodyText}>
              <b>Explanation: </b>
              {stages.statement}
            </p>
          </Typography>
        </Paper>
      </Collapse>
    </div>
  ) : (
    <div className={classes.monitoringDropdownContainer}>
      <div
        className={classes.monitoringDropdownHeader}
        onClick={handleDropdown}
      >
        <p className={classes.monitoringHeadingText}>{monitoring.subtitle}</p>
        <img src={CareTeamArrow} alt='arrow drop down' />
      </div>
      <Collapse in={openDropdown}>
        <Paper className={classes.monitoringDropdownBody}>
          <Typography>
            <p className={classes.monitoringDropDownbodyText}>
              <b>What is measured: </b>
              {monitoring.what}
            </p>
            <p className={classes.monitoringDropDownbodyText}>
              <b>Why:</b>
              {monitoring.why}
            </p>
            {/* <p className={classes.monitoringDropDownbodyText}>
              <b>Scale:</b>
              <br />
              {monitoring.scale}
            </p> */}
          </Typography>
        </Paper>
      </Collapse>
    </div>
  );
};

export default DropdownBox;
