import { React, useState } from 'react';
import {
  makeStyles,
  Collapse,
  Paper,
  Typography,
  ClickAwayListener,
} from '@material-ui/core';
import LightGreenArrowIcon from '../../../assets/img/LightGreenArrowIcon.svg';
import WhiteArrowIcon from '../../../assets/img/WhiteArrowIcon.svg';
// import LightGreenArrowIcon from "../../assets/assets/img/LightGreenArrowIcon.svg";
// import WhiteArrowIcon from "../../assets/assets/img/img/WhiteArrowIcon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '550px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  main: {
    position: 'relative',
    width: '100%',
    // display: "grid",
    // gridTemplateColumns: "20% 80%",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #2BB670',
    margin: '0 15px 0 0 ',
    background: '#EEFBF5',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2BB670',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#EEFBF5',
    borderRadius: '200px',
    border: '1px solid #2BB670',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#2BB670',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },

  headingSmallBox: {
    borderRadius: '9px',
    width: 'calc(100% / 4)',
    height: '50px',
    backgroundColor: '#2BB670',
    border: '2px solid #2BB670',
    color: '#fff',
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '14px',
    marginRight: '8px',
    '&:last-child': {
      marginRight: '0',
    },
    [theme.breakpoints.down('sm')]: {
      '& p': {
        marginTop: '10px',
        fontSize: '12px',
      },
    },
  },

  smallSubheadingCont: {
    width: '50%',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dropdownContainer: {
    width: '100%',
    borderRadius: 9,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dropdownHeader: {
    border: '1px solid #2BB670',
    textAlign: 'center',
    width: '100%',
    borderRadius: 9,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ddHeadingText: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '0px',

    [theme.breakpoints.down('sm')]: {
      // wordBreak: 'break-all',
      fontSize: '12px',
    },
  },
  dropdownBody: {
    backgroundColor: '#EEFBF5',
    padding: '0.5rem 1rem',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },
  dropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
  DropdownboxMainCont: {
    display: 'flex',
    position: 'relative',
    marginBottom: 10,
  },
  drugListItemContainer: {
    marginTop: '8px',
    padding: '0 0px 0 18px',
    '& li': {
      fontSize: '14px',
      wordWrap: 'break-word',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 0px 0 16px',
    },
  },
  drugShortDescriptionContainer: {
    backgroundColor: '#EEE',
    fontSize: '14px',
    padding: '1rem',
    borderRadius: '9px',
    width: '100%',
    margin: '0 0 1.5rem 0',
  },

  // Arrow
  bigArrowCont: {
    width: '50%',
    marginLeft: 'auto',
    position: 'relative',
    '& p': {
      position: 'absolute',
      top: 30,
      fontWeight: '600',
      fontSize: '11px',
      left: '45%',
      transform: 'translateX(-50%)',
    },

    [theme.breakpoints.down('sm')]: {
      '& p': {
        fontSize: '10px',
      },
    },
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.75rem 0 0.5rem 0',
  },
  arrowtail: {
    background: '#EEE',
    // border: "1px solid #000",
    height: '34px',
    width: 'calc(100% - 20px)',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '34px solid transparent',
    borderLeft: '34px solid #eee',
    borderBottom: '34px solid transparent',
  },

  blackDivider: {
    position: 'absolute',
    width: '2px',
    height: '100%',
    backgroundColor: '#000',
    top: 0,
    zIndex: -1,
  },
}));

const DropdownBox = ({
  dropdownData,
  styleData,
  subheadingTextStyle,
  arrowImgstyle,
}) => {
  const classes = useStyles();
  const [openDropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  const handleClickAway = () => {
    setDropdown(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={classes.dropdownContainer}
        style={
          styleData && {
            width: openDropdown ? '100%' : styleData.width,
            marginRight: styleData.marginRight,
            marginLeft: styleData.marginLeft,
          }
        }
      >
        <div
          // className={classes.dropdownHeader}
          onClick={handleDropdown}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '30px',
            border: '1px solid #2BB670',
            textAlign: 'center',
            width: '100%',
            borderRadius: 9,
            cursor: 'pointer',
            alignItems: 'center',
            wordBreak: 'break-word',
            backgroundColor: openDropdown ? '#2BB670' : ' #EEFBF5',
            color: openDropdown ? '#fff' : '#000',
          }}
        >
          <p className={classes.ddHeadingText}>
            <p style={{ padding: 5 }}>{dropdownData.title}</p>
          </p>

          {openDropdown ? (
            <img
              src={WhiteArrowIcon}
              alt='arrow drop down'
              style={arrowImgstyle ? arrowImgstyle : {}}
            />
          ) : (
            <img
              src={LightGreenArrowIcon}
              alt='green arrow drop down'
              style={arrowImgstyle ? arrowImgstyle : {}}
            />
          )}
        </div>
        <Collapse in={openDropdown}>
          <Paper className={classes.dropdownBody}>
            <div className={classes.drugcontBodyWrap}>
              <ul className={classes.drugListItemContainer}>
                {dropdownData.drugDetail.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            </div>
          </Paper>
        </Collapse>
      </div>
    </ClickAwayListener>
  );
};

const Covid19Treatment = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const data = {
    mainTitleText: 'Treatment',
    mainTitleNumber: '8',
    arrowTitle: 'Declining FEV1 & SPO2 (<94%)',
    stages: ['Stage 1', 'Stage 2', 'Stage 3', 'Stage 4'],
    drugShortDesc:
      ' There is no current drug that instantly eliminates virus. The virus is cleared by the body’s own defense mechanisms. Treatment is based on out/inpatient and severity levels.',
    subheading: [
      { title: 'Antiviral (Remdesivir)', drugDetail: ['Remedisivir'] },
      {
        title: 'Corticosteroids (Dexamethasone)',
        drugDetail: [
          'Dexamethasone',
          'Hydrocortisone',
          'Prednisone',
          'methylprednisolone',
        ],
      },
      {
        title: 'Antithrombotics (Warfarin)',
        drugDetail: ['Preventing venous'],
      },
      {
        title: 'Immunomodulatory',
        drugDetail: ['Tocilizumab', 'Sarilumab'],
      },
      {
        title: 'Oxygen Support (If SPO2 falls below 90%)',
        drugDetail: ['To come'],
      },
      {
        title: 'Ventilation/Intubation',
        drugDetail: ['To come'],
      },
    ],
    diagnosticSteps: [
      'Treatment of disease',
      'Alleviating Symptoms (based on stage)',
      'Treatment of Complications ',
    ],
    lastStepGrayText:
      'Variety of complications occur depending on severity and require care related to lung, heart, kidney, vascular system (DVT), and skin',
    stage1Text: 'No Treatment recommended',
  };

  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      <div className={classes.main}>
        <div style={{ display: 'flex' }}>
          <div className={classes.drugShortDescriptionContainer}>
            <p style={{ marginTop: '0', marginBottom: '0' }}>
              {data.drugShortDesc}
            </p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            style={{
              width: '100%',
              position: 'relative',
            }}
          >
            {Array(1, 2, 3, 4).map((item, i) => (
              <div
                style={{
                  left: `${25 * i}%`,
                  height: '100%',
                  top: '10%',
                }}
                key={i}
                className={classes.blackDivider}
              ></div>
            ))}

            <div style={{ display: 'flex' }}>
              {data.stages.map((headTitle, i) => (
                <div className={classes.headingSmallBox} key={i}>
                  <p>{headTitle}</p>
                </div>
              ))}
            </div>

            <div style={{ display: 'flex' }}>
              <p
                style={{ marginTop: '34px', marginLeft: '10px', width: '30%' }}
              >
                {data.stage1Text}
              </p>
              <div className={classes.bigArrowCont}>
                <div class={classes.arrow}>
                  <div class={classes.arrowtail}></div>
                  <div class={classes.arrowpoint}></div>
                </div>
                <p>{data.arrowTitle}</p>
              </div>
            </div>

            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[0]}
                styleData={{ width: '75%', marginLeft: 'auto' }}
              />
            </div>
            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[1]}
                styleData={{ width: '62%', marginLeft: 'auto' }}
              />
            </div>
            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[2]}
                styleData={{ width: '50%', marginLeft: 'auto' }}
              />
            </div>
            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[3]}
                styleData={{ width: '50%', marginLeft: 'auto' }}
              />
            </div>
            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[4]}
                styleData={{
                  width: '50%',
                  marginLeft: 'auto',
                  // width: Number(itemIn) == 1 ? '75%' : '100%',
                  // marginLeft: 'auto',
                }}
                subheadingTextStyle={
                  {
                    // width: itemIn == 1 ? 'unset' : '80%',
                    // textAlign: 'center',
                    // marginTop: '6px',
                    // marginBottom: '6px',
                    // wordBreak: itemIn == 1 ? 'break-all' : 'normal',
                    // fontSize: itemIn == 1 ? '12px' : '14px',
                  }
                }
                arrowImgstyle={{}}
              />
            </div>
            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[5]}
                styleData={{
                  width: '25%',
                  marginLeft: 'auto',
                }}
                subheadingTextStyle={{}}
                arrowImgstyle={{}}
              />
            </div>
            {/* <div className={classes.smallSubheadingCont}>
              {data.subheading
                .slice(
                  Number(data.subheading.length - 2),
                  Number(data.subheading.length)
                )
                .map((drugItem, itemIn) => {
                  return (
                    
                  );
                })}
            </div> */}
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div
            className={classes.drugShortDescriptionContainer}
            style={{ marginLeft: 'auto', width: '50%' }}
          >
            <p style={{ marginTop: '0', marginBottom: '0' }}>
              {data.lastStepGrayText}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Covid19Treatment;
