import React, { useState } from 'react';
import {
  makeStyles,
  Collapse,
  Paper,
  ClickAwayListener,
  useMediaQuery,
} from '@material-ui/core';
// import LightGreenArrowIcon from "../../../assets/img/LightGreenArrowIcon.svg";
// import WhiteArrowIcon from "../../../assets/img/WhiteArrowIcon.svg";
import LightGreenArrowIcon from '../../../assets/img/LightGreenArrowIcon.svg';
import WhiteArrowIcon from '../../../assets/img/WhiteArrowIcon.svg';
import { isMobile } from 'react-device-detect';
import AsthmaTreatmentMobile from './AsthmaTreatmentMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  main: {
    position: 'relative',
    // marginTop: '20px',
    width: '100%',
    // display: "grid",
    // gridTemplateColumns: "20% 80%",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #2BB670',
    margin: '0 15px 0 0 ',
    background: '#EEFBF5',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2BB670',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#EEFBF5',
    borderRadius: '200px',
    border: '1px solid #2BB670',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#2BB670',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },

  headingSmallBox: {
    borderRadius: '9px',
    width: 'calc(100% / 4)',
    height: '50px',
    backgroundColor: '#2BB670',
    border: '2px solid #2BB670',
    color: '#fff',
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '14px',
    marginRight: '8px',
    '&:last-child': {
      marginRight: '0',
    },
    [theme.breakpoints.down('sm')]: {
      '& p': {
        marginTop: '10px',
        fontSize: '12px',
      },
    },
  },

  smallSubheadingCont: {
    width: '50%',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dropdownContainer: {
    width: '100%',
    borderRadius: 9,
    postion: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dropdownHeader: {
    border: '1px solid #2BB670',
    textAlign: 'center',
    width: '100%',
    borderRadius: 9,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    wordBreak: 'break-word',
    minHeight: '30px',

    '& img': {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ddHeadingText: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '0px',
    [theme.breakpoints.down('sm')]: {
      wordBreak: 'break-all',
    },
  },
  dropdownBody: {
    backgroundColor: '#EEFBF5',
    padding: '0.5rem 1rem',
    position: 'absolute',
    top: '30px',
    zIndex: '100',
    // width: "100%",
    marginRight: 'auto',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },
  dropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
  DropdownboxMainCont: {
    display: 'flex',
    position: 'relative',
    marginBottom: '0.5rem',
  },
  dropDownDetailText: {
    fontSize: '12px',
  },
  drugListItemContainer: {
    marginTop: '8px',
    padding: '0 0px 0 18px',
    '& li': {
      fontSize: '12px',
      wordWrap: 'break-word',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 0px 0 16px',
    },
  },
  drugShortDescriptionContainer: {
    backgroundColor: '#EEE',
    fontSize: '14px',
    padding: '1rem',
    borderRadius: '9px',
    width: '100%',
    margin: '0 0 1.5rem 0',
  },

  // Arrow
  bigArrowCont: {
    width: '100%',
    marginLeft: 'auto',
    position: 'relative',
    '& p': {
      position: 'absolute',
      top: 28,
      fontWeight: '600',
      fontSize: '12px',
      left: '45%',
      transform: 'translateX(-50%)',
    },

    [theme.breakpoints.down('sm')]: {
      '& p': {
        top: 28,
        fontSize: '10px',
      },
    },
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.75rem 0 0.5rem 0',
  },
  arrowtail: {
    background: '#EEE',
    // border: "1px solid #000",
    height: '24px',
    width: 'calc(100% - 20px)',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '24px solid transparent',
    borderLeft: '24px solid #eee',
    borderBottom: '24px solid transparent',
  },

  blackDivider: {
    position: 'absolute',
    width: '2px',
    height: '92%',
    backgroundColor: '#000',
    top: 0,
    zIndex: -1,
  },

  darkGrayBar: {
    backgroundColor: '#888',
    minHeight: '20px',
    fontWeight: 'bold',
    fontSize: '14px',
    textAlign: 'center',
    borderRadius: '5px',
    margin: '6px 0',
  },
  DDArrowIcon: {
    height: '10px',
    width: '20px',
  },
  blueBoxSmall: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#9FCEFC',
    height: '40px',
    width: '240px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    textAlign: 'center',
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  // Branch Arrow
  brancharrow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
  },
  brancharrowtail: {
    background: '#C33C54',
    height: '20px',
    width: '1.5px',
  },
  brancharrowpoint: {
    width: 0,
    height: 0,
    borderTop: '6px solid #C33C54',
    borderRight: '6px solid transparent',
    borderLeft: '6px solid transparent',
  },
  // branch
  branchWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  branchMain: {
    borderRight: '1.5px solid #C33C54',
    borderLeft: '1.5px solid #C33C54',
    borderTop: '1.5px solid #C33C54',
  },
  branchMiddleLine: {
    justifySelf: 'center',
    background: '#C33C54',
  },
  blackBorderBox: {
    width: '144px',
    minHeight: '24px',
    borderRadius: '7px',
    border: '1px solid #C33C54',
    backgroundColor: '#EEF0F0',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      fontSize: '9px',
    },
  },
}));

const Arrow = ({ tailHeight, tailWidth, arrowPointStyle, arrowMainStyle }) => {
  const classes = useStyles();
  return (
    <div class={classes.brancharrow} style={arrowMainStyle}>
      <div class={classes.brancharrowtail} style={{ height: tailHeight }}></div>
      <div class={classes.brancharrowpoint}></div>
    </div>
  );
};

const Branch = ({
  branchStyle,
  middleLineStyle,
  branchClassName,
  middleLineClassName,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.branchWrap}>
      <div className={classes.branchMiddleLine} style={middleLineStyle}></div>
      <div className={classes.branchMain} style={branchStyle}></div>
    </div>
  );
};

const DropdownBox = ({
  dropdownData,
  styleData,
  subheadingTextStyle,
  arrowImgstyle,
}) => {
  const classes = useStyles();
  const [openDropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    console.log(dropdownData);
    setDropdown((prev) => !prev);
  };

  const handleClickAway = () => {
    setDropdown(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={classes.dropdownContainer}
        style={
          styleData && {
            width: openDropdown ? '100%' : styleData.width,
            marginRight: styleData.marginRight,
            marginLeft: styleData.marginLeft,
          }
        }
      >
        <div
          // className={classes.dropdownHeader}
          onClick={handleDropdown}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '30px',
            border: '1px solid #2BB670',
            textAlign: 'center',
            width: '100%',
            borderRadius: 9,
            cursor: 'pointer',
            alignItems: 'center',
            wordBreak: 'break-word',
            backgroundColor: openDropdown ? '#2BB670' : ' #EEFBF5',
            color: openDropdown ? '#fff' : '#000',
          }}
        >
          <p
            className={classes.ddHeadingText}
            style={subheadingTextStyle ? subheadingTextStyle : {}}
          >
            {dropdownData.title}
          </p>

          {openDropdown ? (
            <img
              src={WhiteArrowIcon}
              className={classes.DDArrowIcon}
              alt='arrow drop down'
              style={{ width: '10%' }}
            />
          ) : (
            <img
              src={LightGreenArrowIcon}
              className={classes.DDArrowIcon}
              alt='green arrow drop down'
              style={{ width: '10%' }}
            />
          )}
        </div>
        {openDropdown && (
          <Collapse in={openDropdown}>
            <Paper className={classes.dropdownBody}>
              <div className={classes.drugcontBodyWrap}>
                <p className={classes.dropDownDetailText}>
                  {dropdownData.desc}
                </p>
                <h5>Drugs</h5>
                {dropdownData.drugDetail &&
                  dropdownData.drugDetail.length >= 1 && (
                    <ul className={classes.drugListItemContainer}>
                      {dropdownData.drugDetail.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  )}
              </div>
            </Paper>
          </Collapse>
        )}
      </div>
    </ClickAwayListener>
  );
};

const AsthmaTreatment = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const data = {
    mainTitleText: 'Treatment',
    mainTitleNumber: '8',
    arrowTitle: 'Declining FEV1 & SPO2 (<94%)',
    stages: ['Stage 1', 'Stage 2', 'Stage 3', 'Stage 4', 'Stage 5'],
    drugShortDesc:
      ' There is no current drug that instantly eliminates virus. The virus is cleared by the body’s own defense mechanisms. Treatment is based on out/inpatient and severity levels.',
    subheading: [
      {
        title: 'SABA',
        desc: 'These are given for relaxing the muscles in the lungs and for widening the respiratory airways. ',
        drugDetail: [
          'Short acting beta agonists (SABA)',
          'Salbutamol',
          'Albuterol',
          'Levalbuterol',
          'Terbutaline',
        ],
      },
      {
        title: 'LAMA',
        desc: 'LAMAs are taken as mist inhalers, used as add on therapy to treat patients with a history of exacerbations. It is indicated for patients 12 years old and above.',
        drugDetail: [
          'Short acting beta agonists (SABA)',
          'Ipratropium Bromide (Atrovent or Ipvent)',
          'Tiotropium (Spriva)',
        ],
      },
      {
        title: 'LABA',
        desc: 'These are given for relaxing the muscles in the lungs and for widening the respiratory airways. ',
        drugDetail: ['Salmeterol (Serevent) | Formoterol (Foratec or Oxis)'],
      },
      {
        title: 'ICS',
        desc: "ICS monotherapy is a first-line maintenance therapy for both children and adults. Regular use reduces symptoms and exacerbations, to improve lung function. ICS 'do not Cure' asthma, but provide symptomatic relief. The doses of ICS to be given varies by -age other factors like – patients' response to ICS therapy",
        drugDetail: [
          'Ciclesonide (Alvesco)',
          'Beclomethasone (Beclate, QVAR)',
          'Budesonide (Budeflam, Revolizer, Inflammide, Pulmocort)',
          'Fluticasone propionate (Flovent or Flixotide)',
        ],
      },
      {
        title: 'LTRA',
        desc: 'Safe, effective and well-tolerated treatment of asthma. However, these are less effective than ICS treatment and are usually preferred for unwilling or unable patients to ICS. LTRAs can be used as add-on therapy in adults. LTRs are considered as the second line of treatment after ICS. ',
        drugDetail: [
          'Monteleukast (Singulair)',
          'Zafirlukast',
          '5-lipoxygentase inhibitor zileuton',
        ],
      },

      {
        title: 'Oral Corticosteroids',
        desc: '',
      },
      {
        title: 'Biologics (IgE or Eosinophils)',
        desc: 'For those patients with IgE mediated allergic asthma',
        drugDetail: ['Omalizumab'],
      },
      {
        title: 'Oxygen Support (If SPO2 < 90%)',
        desc: "ICS monotherapy is a first-line maintenance therapy for both children and adults. Regular use reduces symptoms and exacerbations, to improve lung function. ICS 'do not Cure' asthma, but provide symptomatic relief. The doses of ICS to be given varies by -age other factors like – patients' response to ICS therapy",
        drugDetail: [
          'Ciclesonide (Alvesco)',
          'Beclomethasone (Beclate, QVAR)',
          'Budesonide (Budeflam, Revolizer, Inflammide, Pulmocort)',
          'Fluticasone propionate (Flovent or Flixotide)',
        ],
      },
      {
        title: 'Ventilation/Intubation ',
        desc: '',
      },
      {
        title: 'Bronchial Thermoplasty (rare)',
        desc: 'Treatment of airways with radiofrequency impulses, causing to heat the tissue and reduce the amount of smooth muscles present in the airway. Generally this method is considered for adults.',
      },
      {
        title: 'Anti Ige Biologics ',
        desc: '',
        drugDetail: ['Ciclesonide (Alvesco)Omalizumab'],
      },
      {
        title: 'Anti-IL Biologics',
        desc: '',
        drugDetail: [
          'Dupixient',
          'Nucala (Intravenous)',
          'Cinquair (Subcutaneous injection; not for children)',
          'Fanasara (Subcutaneous injection)',
        ],
      },
    ],
    diagnosticSteps: [
      'Treatment of disease',
      'Alleviating Symptoms (based on stage)',
      'Treatment of Complications ',
    ],
    lastStepGrayText:
      'Variety of complications occur depending on severity and require care related to lung, heart, kidney, vascular system (DVT), and skin',
  };
  const matches = useMediaQuery('(max-width:600px)');
  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      <div className={classes.main}>
        <div style={{ display: 'flex' }}>
          <div
            className={classes.bigArrowCont}
            style={{ width: '340px', marginLeft: 'auto' }}
          >
            <div class={classes.arrow}>
              <div class={classes.arrowtail}></div>
              <div class={classes.arrowpoint}></div>
            </div>
            <p>{data.arrowTitle}</p>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              width: '100%',
              position: 'relative',
            }}
          >
            {Array(1, 2, 3, 4, 5).map((item, i) => (
              <div
                style={{
                  left: `${20 * i}%`,

                  top: '10%',
                }}
                key={i}
                className={classes.blackDivider}
              ></div>
            ))}

            <div style={{ display: 'flex' }}>
              {data.stages.map((headTitle, i) => (
                <div className={classes.headingSmallBox} key={i}>
                  <p>{headTitle}</p>
                </div>
              ))}
            </div>

            {/* <div style={{ display: "flex" }}>
              <div className={classes.bigArrowCont}>
                <div class={classes.arrow}>
                  <div class={classes.arrowtail}></div>
                  <div class={classes.arrowpoint}></div>
                </div>
                <p>{data.arrowTitle}</p>
              </div>
            </div> */}
            <div className={classes.darkGrayBar}>
              <p style={{ color: '#fff' }}>Relievers</p>
            </div>
            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[0]}
                styleData={{ width: '100%', marginLeft: 'auto' }}
              />
            </div>
            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[1]}
                styleData={{ width: '100%', marginLeft: 'auto' }}
              />
            </div>
            <div
              className={classes.darkGrayBar}
              style={{ width: '78%', marginLeft: 'auto' }}
            >
              <p style={{ color: '#fff' }}>Controllers</p>
            </div>
            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[2]}
                styleData={{ width: '78%', marginLeft: 'auto' }}
              />
            </div>
            <div
              className={classes.darkGrayBar}
              style={{ width: '78%', marginLeft: 'auto' }}
            >
              <p style={{ color: '#fff' }}>Preventers</p>
            </div>
            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[3]}
                styleData={{ width: '78%', marginLeft: 'auto' }}
              />
            </div>
            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[4]}
                styleData={{ width: '78%', marginLeft: 'auto' }}
              />
            </div>
            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[5]}
                styleData={{ width: '40%', marginLeft: 'auto' }}
              />
            </div>
            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[6]}
                styleData={{ width: '60%', marginLeft: 'auto' }}
                subheadingTextStyle={{ fontSize: '11px' }}
              />
            </div>
            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[7]}
                styleData={{ width: '25%', marginLeft: 'auto' }}
                subheadingTextStyle={{ fontSize: '10px', padding: 5 }}
              />
            </div>
            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[8]}
                styleData={{ width: '25%', marginLeft: 'auto' }}
                subheadingTextStyle={{ fontSize: '10px', padding: 5 }}
              />
            </div>
            <div className={classes.DropdownboxMainCont}>
              <DropdownBox
                dropdownData={data.subheading[9]}
                styleData={{ width: '80%' }}
              />
            </div>
          </div>
        </div>

        {isMobile ? (
          <AsthmaTreatmentMobile />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '40px',
            }}
          >
            <div style={{ position: 'relative' }}>
              <div className={classes.blueBoxSmall}>
                <p>Test IgE, IgM & Eosinophils</p>
              </div>
              <Branch
                middleLineStyle={{
                  width: '1.5px',
                  height: '26px',
                }}
                branchStyle={{
                  width: matches ? '70%' : '120%',
                  height: '30px',
                }}
              />
              <Branch
                middleLineStyle={{
                  width: '1.5px',
                  height: '28px',
                  position: 'absolute',
                  top: '67px',
                }}
                branchStyle={{
                  display: 'none',
                }}
              />
              <div style={{ display: 'flex', position: 'relative' }}>
                <div>
                  <div
                    className={classes.blackBorderBox}
                    style={{
                      width: '130px',
                      height: '40px',
                      marginLeft: '-80px',
                    }}
                  >
                    <p>High IgE Allergen Positive</p>
                  </div>
                  <Arrow
                    arrowMainStyle={{
                      left: matches ? '30px' : '-30px',
                      top: matches ? '60px' : '40px',
                    }}
                    tailHeight={matches ? 120 : 70}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      left: '-90px',
                      marginTop: '10px',
                    }}
                  >
                    {/* <div className={classes.blackBorderBox}>
                    <p style={{ margin: "6px 0" }}>
                      Anti Ige Biologics{" "}
                      <p style={{ margin: "0" }}>Omalizumab (Xolair)</p>
                    </p>
                  </div> */}
                    <DropdownBox
                      dropdownData={data.subheading[data.subheading.length - 2]}
                      styleData={{ width: '100%', marginLeft: 'auto' }}
                    />
                    <div
                      className={classes.blackBorderBox}
                      style={{
                        marginTop: '18px',
                        backgroundColor: '#C33C54',
                        color: '#fff',
                      }}
                    >
                      <p>No Response</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className={classes.blackBorderBox}
                    style={{
                      width: '130px',
                      height: '40px',
                      position: 'absolute',
                      left: '55px',
                    }}
                  >
                    <p>Low IgE High Eosinophils</p>
                  </div>
                  <Arrow
                    arrowMainStyle={{
                      left: matches ? '30px' : '48%',
                      top: matches ? '60px' : '40px',
                    }}
                    tailHeight={matches ? 120 : 260}
                  />
                </div>
                <div>
                  <div
                    className={classes.blackBorderBox}
                    style={{
                      width: '130px',
                      height: '40px',
                      position: 'absolute',
                      right: -80,
                    }}
                  >
                    <p>Low IgE Low Eosinophils</p>
                  </div>
                </div>
              </div>
              <div>
                <Branch
                  middleLineStyle={{
                    width: '1.5px',
                    height: '26px',
                    marginTop: 82,
                    marginLeft: '-280px',
                  }}
                  branchStyle={{
                    width: matches ? '70%' : '50%',
                    height: '30px',
                    marginLeft: '-280px',
                  }}
                />
                <div style={{ display: 'flex', position: 'relative' }}>
                  <div>
                    <div
                      className={classes.blackBorderBox}
                      style={{
                        width: '130px',
                        height: '40px',
                        marginLeft: '-120px',
                        width: '100px',
                      }}
                    >
                      <p>Low Eosinophils</p>
                    </div>
                  </div>
                  <div>
                    <div
                      className={classes.blackBorderBox}
                      style={{
                        width: '130px',
                        height: '40px',
                        // marginLeft: "-120px",
                        width: '100px',
                      }}
                    >
                      <p>High Eosinophils</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{
                        left: matches ? '30px' : '28%',
                        top: matches ? '60px' : '40px',
                      }}
                      tailHeight={matches ? 120 : 55}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.DropdownboxMainCont}>
                <DropdownBox
                  dropdownData={data.subheading[data.subheading.length - 1]}
                  styleData={{
                    width: '180px',

                    marginLeft: '30px',
                    marginTop: '60px',
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AsthmaTreatment;
