import React from 'react';
import { makeStyles, useMediaQuery, Grid } from '@material-ui/core';
import AlzheimersDiagnosisMobile from './AlzheimersDiagnosisMobile';
import { isMobile } from 'react-device-detect';
import GlaucomaDiagnosisMobile from './GlaucomaDiagnosisMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow:
        'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  headingBox: {
    backgroundColor: '#FFEBEF',
    border: '1px solid #C33C54',
    width: '100%',
    height: 34,
    color: '#C33C54',
    fontSize: '14px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  blueBoxSmall: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#9FCEFC',
    height: '50px',
    width: '100%',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  // Arrow
  arrow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
  },
  arrowtail: {
    background: '#C33C54',
    height: '20px',
    width: '1.5px',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '6px solid #C33C54',
    borderRight: '6px solid transparent',
    borderLeft: '6px solid transparent',
  },
  // branch
  branchWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  branchMain: {
    borderRight: '1.5px solid #C33C54',
    borderLeft: '1.5px solid #C33C54',
    borderTop: '1.5px solid #C33C54',
  },
  branchMiddleLine: {
    justifySelf: 'center',
    background: '#C33C54',
  },
  redBorderGrayBox: {
    width: '100%',
    height: '70px',
    padding: '0.75rem',
    borderRadius: '7px',
    border: '1px solid #C33C54',
    backgroundColor: '#EEF0F0',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '13px',
    '& p': {
      wordBreak: 'break-all',
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '10px',
    },
  },
  GreenBox: {
    color: '#000',
    fontSize: '14px',
    backgroundColor: '#2BB670',
    height: '36px',
    width: '144px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '65px',
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      fontSize: '10px',
    },
  },
  positiveNegativeCont: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    // left: "20%",
  },
}));

const Arrow = ({ tailHeight, tailWidth, arrowPointStyle, arrowMainStyle }) => {
  const classes = useStyles();
  return (
    <div class={classes.arrow} style={arrowMainStyle}>
      <div class={classes.arrowtail} style={{ height: tailHeight }}></div>
      <div class={classes.arrowpoint}></div>
    </div>
  );
};

const Branch = ({
  branchStyle,
  middleLineStyle,
  branchClassName,
  middleLineClassName,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.branchWrap}>
      <div className={classes.branchMiddleLine} style={middleLineStyle}></div>
      <div className={classes.branchMain} style={branchStyle}></div>
    </div>
  );
};

const GlaucomaDiagnosisConfirmatoryTest = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const data = {
    pageTitle: 'Glaucoma: Diagnosis - Confirmatory Test',
    mainTest: 'Confirmatory Test',
    level1: [
      { test: 'Visual Fields Testing (Perimetry, Humphrey, Goldmann)' },
      {
        test: 'Gonioscopy',
      },
      { test: 'OCT / Optic Nerve Imaging' },
    ],
    resultType: ['Positive', 'Negative'],
    finalresult: ['Glaucoma', 'Glaucoma Negative'],
  };
  const matches = useMediaQuery('(max-width:600px)');

  return isMobile ? (
    <GlaucomaDiagnosisMobile />
  ) : (
    <div style={{ width: 600, float: !noFloat && 'right' }}>
      <GlaucomaDiagnosisMobile />
    </div>
    // <div className={classes.root} style={{ float: !noFloat && 'right' }}>
    //   <div style={{ marginTop: 30 }}>
    //     <Grid container spacing={3}>
    //       {data.level1.map((test, i) => (
    //         <Grid item xs={4} md={4}>
    //           <div className={classes.redBorderGrayBox}>
    //             <p>{test.test}</p>
    //           </div>
    //           {i == 1 && (
    //             <Branch
    //               middleLineStyle={{
    //                 width: '1.5px',
    //                 height: '50px',
    //               }}
    //               branchStyle={{
    //                 width: matches ? '240px' : '400px',
    //                 height: '50px',
    //                 borderBottom: '1.5px solid #C33C54',
    //                 borderTop: '0px',
    //                 marginTop: '-50px',
    //               }}
    //             />
    //           )}
    //         </Grid>
    //       ))}
    //     </Grid>
    //     <Branch
    //       middleLineStyle={{
    //         width: '1.5px',
    //         height: '30px',
    //       }}
    //       branchStyle={{
    //         width: '200px',
    //         height: '30px',
    //       }}
    //     />
    //     <div className={classes.positiveNegativeCont}>
    //       <div>
    //         <div
    //           className={classes.redBorderGrayBox}
    //           style={{ width: '120px', height: '40px', marginRight: '10px' }}
    //         >
    //           <p>{data.resultType[0]}</p>
    //         </div>
    //         <Arrow
    //           arrowMainStyle={{
    //             left: matches ? '70px' : '155px',
    //             top: '40px',
    //           }}
    //           tailHeight={60}
    //         />
    //         <div
    //           className={classes.GreenBox}
    //           style={{
    //             marginLeft: matches ? '0px' : '-10px',
    //             backgroundColor: '#C33C54',
    //             color: 'white',
    //           }}
    //         >
    //           <p>{data.finalresult[0]}</p>
    //         </div>
    //       </div>
    //       <div>
    //         <div
    //           className={classes.redBorderGrayBox}
    //           style={{ width: '120px', height: '40px', marginLeft: '60px' }}
    //         >
    //           <p>{data.resultType[1]}</p>
    //         </div>
    //         <Arrow
    //           arrowMainStyle={{
    //             right: matches ? '70px' : '155px',
    //             top: '40px',
    //           }}
    //           tailHeight={60}
    //         />
    //         <div
    //           className={classes.GreenBox}
    //           style={{ marginLeft: matches ? '70px' : '50px' }}
    //         >
    //           <p>{data.finalresult[1]}</p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default GlaucomaDiagnosisConfirmatoryTest;
