import React from 'react';
import { makeStyles, Collapse, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },


    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  main: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    // display: "grid",
    // gridTemplateColumns: "20% 80%",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #2BB670',
    margin: '0 15px 0 0 ',
    background: '#EEFBF5',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2BB670',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#EEFBF5',
    borderRadius: '200px',
    border: '1px solid #2BB670',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#2BB670',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },

  headingSmallBox: {
    borderRadius: '9px',
    width: 'calc(100% / 4)',
    height: '60px',
    backgroundColor: '#2BB670',
    border: '2px solid #2BB670',
    color: '#000',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '14px',
    marginRight: '8px',
    '&:last-child': {
      marginRight: '0',
    },
    [theme.breakpoints.down('sm')]: {
      '& p': {
        marginTop: '10px',
        fontSize: '12px',
      },
    },
  },

  smallSubheadingCont: {
    width: '50%',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  // Arrow
  bigArrowCont: {
    width: '100%',
    marginLeft: 'auto',
    position: 'relative',
    '& p': {
      position: 'absolute',
      top: 26,
      fontWeight: '600',
      fontSize: '12px',
      left: '45%',
      transform: 'translateX(-50%)',
    },

    [theme.breakpoints.down('sm')]: {
      '& p': {
        top: '20px',
        fontSize: '10px',
      },
    },
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.75rem 0 0.5rem 0',
  },
  arrowtail: {
    background: '#EEE',
    height: '24px',
    width: 'calc(100% - 20px)',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '24px solid transparent',
    borderLeft: '24px solid #eee',
    borderBottom: '24px solid transparent',
  },

  darkGrayBar: {
    backgroundColor: '#D3d3d3',
    height: '40px',
    fontWeight: 'bold',
    fontSize: '14px',
    textAlign: 'center',
    borderRadius: '5px',
    margin: '6px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem 0',
    '& p': {
      margin: '0',
    },
  },

  smallGreenBox: {
    width: 'calc(100% / 5)',
    borderRadius: '6px',
    border: '1px solid #2BB670',
    backgroundColor: '#EEFBF5',
    fontSize: '12px',
    fontWeight: 'bold',
    marginRight: '8px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '55px',
    '& p': {
      margin: '0',
    },
  },
}));

const BreastCancerTreatment = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const data = {
    mainTitleText: 'Treatment',
    mainTitleNumber: '8',
    arrowTitle: 'Spread of Cancer',
    stages: [
      'Stage 0',
      'Stage IA & IB',
      'Stage IIA &IB',
      'Stage IIIA & IIIB',
      'Stage IV',
    ],
    level1: ['Mastectomy', 'Breast-Conserving Surgery'],
    level2: 'Radiation Therapy',
    level3: ['DNA Damaging Agents', 'Taxanes', 'PARP Inhibitors'],
    level4: ['Antimetabolite', 'mTOR Inhibitors'],
    level5: [
      'Selective Estrogen Receptor Modulators',
      'Selective Estrogen Receptor Down Regulators',
    ],
    level6: 'Aromatase Inhibitors',
    level7: ['Tyrosine kinase inhibitors', 'Monoclonal Antibody'],
    level8: ['PD-1 Inhibitors'],
  };
  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      <div className={classes.timelineAppBar}>
        <div className={classes.timelineHeading}>
          {!noNumber && (
            <div className={classes.timelineCircleBG}>
              {data.mainTitleNumber}
            </div>
          )}
          <div className={classes.timelineTitle}>{data.mainTitleText}</div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div className={classes.bigArrowCont}>
          <div class={classes.arrow}>
            <div class={classes.arrowtail}></div>
            <div class={classes.arrowpoint}></div>
          </div>
          <p>{data.arrowTitle}</p>
        </div>
      </div>

      <div style={{ display: 'flex' }}>
        {data.stages.map((headTitle, i) => (
          <div className={classes.headingSmallBox} key={i}>
            <p>{headTitle}</p>
          </div>
        ))}
      </div>
      <div className={classes.darkGrayBar} style={{ width: '78%' }}>
        <p>Surgery: Lumpectomy OR Mastectomy</p>
      </div>
      <div style={{ display: 'flex' }}>
        {data.level1.map((info, i) => (
          <div key={i} className={classes.smallGreenBox}>
            <p>{info}</p>
          </div>
        ))}
      </div>

      <div
        className={classes.smallGreenBox}
        style={{
          width: '100%',
          fontSize: '14px',
          padding: '10px',
          margin: '1rem 0',
        }}
      >
        <p>{data.level2}</p>
      </div>
      <div
        className={classes.darkGrayBar}
        style={{ width: '50%', marginLeft: 'auto' }}
      >
        <p>Chemotherapy</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {data.level3.map((info, i) => (
          <div key={i} className={classes.smallGreenBox}>
            <p>{info}</p>
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'flex',

          margin: '1rem 0 1rem 185px',
        }}
      >
        {data.level4.map((info, i) => (
          <div
            key={i}
            className={classes.smallGreenBox}
            style={{ width: '100px' }}
          >
            <p>{info}</p>
          </div>
        ))}
      </div>
      <div
        className={classes.darkGrayBar}
        style={{ marginLeft: 'auto', width: '43%' }}
      >
        <p>Hormone Therapy</p>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {data.level5.map((info, i) => (
          <div
            key={i}
            className={classes.smallGreenBox}
            style={{ height: '80px' }}
          >
            <p>{info}</p>
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'flex',

          margin: '0.5rem 0 0.5rem 295px',
        }}
      >
        <div className={classes.smallGreenBox} style={{ width: '100px' }}>
          <p>{data.level6}</p>
        </div>
      </div>
      <div
        className={classes.darkGrayBar}
        style={{ marginLeft: 'auto', width: '43%' }}
      >
        <p>Targeted Therapy</p>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {data.level7.map((info, i) => (
          <div key={i} className={classes.smallGreenBox}>
            <p>{info}</p>
          </div>
        ))}
      </div>
      <div
        className={classes.darkGrayBar}
        style={{ marginLeft: 'auto', width: '43%' }}
      >
        <p>Immunotherapy</p>
      </div>

      <div
        style={{
          display: 'flex',

          margin: '0.5rem 0 0.5rem 295px',
        }}
      >
        <div className={classes.smallGreenBox} style={{ width: '100px' }}>
          <p>{data.level8}</p>
        </div>
      </div>
    </div>
  );
};

export default BreastCancerTreatment;
