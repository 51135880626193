import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },


    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '2px solid #C33C54',
    margin: '0 15px 0 0 ',
    background: '#FAEFF1',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#C33C54',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#FAEFF1',
    borderRadius: '200px',
    border: '2px solid #C33C54',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#C33C54',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },
  redBoxBig: {
    border: '1px solid #C33C54',
    backgroundColor: '#FAEFF1',
    width: '100%',
    height: '86px',
    borderRadius: '50px',
    padding: '0 1.25rem',
    marginTop: '30px',
    [theme.breakpoints.down('sm')]: {
      height: '94px',
    },
  },
  testAssessmentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  redBoxSmall: {
    border: '1px solid #C33C54',
    backgroundColor: '#FAEFF1',
    height: '100px',
    borderRadius: '50px',
    padding: '0.5rem 1.5rem',
    '& .redSmallBoxTitle': {
      fontSize: '12px',
      textAlign: 'center',
      color: '#C33C54',
      fontWeight: 'bold',
      marginBottom: '0',
      marginTop: '2px',
    },
    '& .redSmallBoxDescription': {
      fontSize: '8px',
      marginTop: '0px',
      marginBottom: '0',
    },
    [theme.breakpoints.down('sm')]: {
      height: '140px',
      padding: '10px 12px',
      '& .redSmallBoxTitle': {
        fontSize: '10px',
        textAlign: 'center',
        color: '#C33C54',
      },
      '& .redSmallBoxDescription': {
        marginTop: '4px',
      },
    },
  },
  redMediumSmall: {
    border: '1px solid #C33C54',
    backgroundColor: '#FAEFF1',
    height: '94px',
    borderRadius: '50px',
  },
  classificationBoxContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  classificationRedBox: {
    border: '1px solid #C33C54',
    backgroundColor: '#FAEFF1',
    width: '200px',
    height: '60px',
    borderRadius: '50px',
    padding: '0.5rem 1rem',
  },
  gridWrap: {
    marginTop: '34px',
  },

  // Arrow
  arrow: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: -8,
  },
  arrowtail: {
    background: '#000',
    height: '2px',
    width: '3px',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '6px solid transparent',
    borderBottom: '6px solid transparent',
    borderLeft: '6px solid #000',
  },
  // branch
  branchWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  branchMain: {
    borderRight: '1px solid #000',
    borderLeft: '1px solid #000',
    borderBottom: '1px solid #000',
    width: '100%',
    height: '10px',
    position: 'absolute',
    top: 0,
  },
}));

const SmallArrow = ({ tailHeight, tailWidth, arrowPointStyle }) => {
  const classes = useStyles();
  return (
    <div class={classes.arrow}>
      <div class={classes.arrowtail}></div>
      <div class={classes.arrowpoint}></div>
    </div>
  );
};

const Branch = () => {
  const classes = useStyles();
  return (
    <div className={classes.branchWrap}>
      <div className={classes.branchMain} style={{ width: '70%' }}></div>
      <div
        style={{
          height: '20px',
          justifySelf: 'center',
          width: '1px',
          background: '#000',
          position: 'absolute',
          top: 0,
        }}
      ></div>
    </div>
  );
};

const Slide5 = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const data = {
    mainTitleText: 'Diagnosis',
    mainTitleNumber: '7',
    diseaseDetail: {
      titlle: 'Asthma Rule Out',
      description:
        'Patient may be given an inhaled bronchodilator (albuterol or salmeterol) to assess for reversibility of obstruction. There is a certain cut-off (8 or 12%?) above which suggests asthma rather than COPD.',
    },
    testAndAssessment: [
      {
        title: 'Spirometry Test',
        Measure: 'FEV1 & FVC If FEV1 / FEC < 0.7 = obstruction',
        Staging: '1-4 (Based on FEV1)',
      },
      {
        title: 'CAT Assessment',
        Measure: '0-40 score (8 qns)',
        Staging: 'Low / High',
      },
      {
        title: 'Exacerbation History',
        Measure: 'No. of non-hospitalized  exacerbations in 12 months',
        CAT: 'Low risk / High risk',
      },
    ],
    classification: { title: 'GOLD Classification ', cat: 'A-D' },
  };

  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      <div className={classes.timelineAppBar}>
        <div className={classes.timelineHeading}>
          {!noNumber && (
            <div className={classes.timelineCircleBG}>
              {data.mainTitleNumber}
            </div>
          )}
          <div className={classes.timelineTitle}>{data.mainTitleText}</div>
        </div>
      </div>
      <div className={classes.redBoxBig}>
        <p
          style={{
            fontSize: '14px',
            textAlign: 'center',
            color: '#C33C54',
            fontWeight: 'bold',
            marginBottom: '0',
            marginTop: '4px',
          }}
        >
          {data.diseaseDetail.titlle}
        </p>
        <p style={{ fontSize: '10px', fontWeight: 'bold', marginTop: '0px' }}>
          {data.diseaseDetail.description}
        </p>
      </div>

      <div className={classes.gridWrap}>
        <Grid container spacing={1}>
          {data.testAndAssessment.map((test, i) => (
            <Grid item xs={4} md={4}>
              <div style={{ position: 'relative' }}>
                <div className={classes.redBoxSmall}>
                  <p className='redSmallBoxTitle'>{test.title}</p>
                  {Object.entries(test)
                    .slice(1)
                    .map(([k, v]) => (
                      <p className='redSmallBoxDescription'>
                        <b>{k}</b>:{v}
                      </p>
                    ))}
                </div>
                {i !== 0 && <SmallArrow />}
              </div>
            </Grid>
          ))}
        </Grid>
      </div>

      <div style={{ position: 'relative' }}>
        <Branch />
        <div className={classes.classificationBoxContainer}>
          <div className={classes.classificationRedBox}>
            <p
              style={{
                fontSize: '14px',
                textAlign: 'center',
                color: '#C33C54',
                fontWeight: 'bold',
                marginBottom: '0',
                marginTop: '0px',
              }}
            >
              {data.classification.title}
            </p>
            {Object.entries(data.classification)
              .slice(1)
              .map(([k, v]) => (
                <p
                  style={{
                    fontSize: '10px',
                    marginTop: '4px',
                    marginBottom: '0',
                  }}
                >
                  <b>{k}</b>:{v}
                </p>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slide5;
