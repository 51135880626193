import React from 'react';

const BreastCancerDiagnosisMobile = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          backgroundColor: '#FFEBEF',
          border: '1px solid #C33C54',
          color: '#C33C54',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 10,
        }}
      >
        <p>Screening Test</p>
      </div>
      <div
        style={{
          width: '80%',
          padding: '5px 10px',
          backgroundColor: '#A4C2F4',
          borderRadius: 5,
          fontWeight: 'bold',
          fontSize: 14,
          textAlign: 'center',
          alignSelf: 'center',
        }}
      >
        <p>Mammography or Tomosynthesis</p>
      </div>
      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: '#C33C54',
          alignSelf: 'center',
        }}
      ></div>
      <div
        style={{
          width: '50%',
          backgroundColor: '#C33C54',
          height: 1,
          alignSelf: 'center',
        }}
      ></div>
      <div style={{ alignSelf: 'center', width: '50%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '80%',
          alignSelf: 'center',
        }}
      >
        <p
          style={{
            wordBreak: 'break-word',
            width: '40%',
            border: '3px solid #2BB670',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
          }}
        >
          Positive
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '40%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
          }}
        >
          Negative
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          alignSelf: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div
          style={{ display: 'flex', width: '50%', justifyContent: 'center' }}
        >
          <div
            style={{
              height: 80,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              // width: '80%',
              padding: '5px 10px',
              backgroundColor: '#2BB670',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              alignSelf: 'center',
              color: '#fff',
            }}
          >
            <p>Breast Cancer: negative</p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '80%',
          padding: '5px 10px',
          backgroundColor: '#A4C2F4',
          borderRadius: 5,
          fontWeight: 'bold',
          fontSize: 14,
          textAlign: 'center',
          alignSelf: 'center',
        }}
      >
        <p>Ultrasound</p>
      </div>
      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: '#C33C54',
          alignSelf: 'center',
        }}
      ></div>
      <div
        style={{
          width: '50%',
          backgroundColor: '#C33C54',
          height: 1,
          alignSelf: 'center',
        }}
      ></div>
      <div style={{ alignSelf: 'center', width: '50%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '80%',
          alignSelf: 'center',
        }}
      >
        <p
          style={{
            wordBreak: 'break-word',
            width: '40%',
            border: '3px solid #2BB670',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
          }}
        >
          Positive
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '40%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
          }}
        >
          Negative
        </p>
      </div>
      <div style={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
        <div
          style={{
            height: 80,
            width: 1,
            backgroundColor: '#C33C54',
          }}
        ></div>
      </div>
      <div
        style={{
          backgroundColor: '#FFEBEF',
          border: '1px solid #C33C54',
          color: '#C33C54',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 10,
        }}
      >
        <p>Confirmatory Tests</p>
      </div>
      <div
        style={{
          width: '80%',
          padding: '5px 10px',
          backgroundColor: '#A4C2F4',
          borderRadius: 5,
          fontWeight: 'bold',
          fontSize: 14,
          textAlign: 'center',
          alignSelf: 'center',
        }}
      >
        <p>Core needle Biopsy (CNB) or Fine Needle Aspiration</p>
      </div>
      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: '#C33C54',
          alignSelf: 'center',
        }}
      ></div>
      <div
        style={{
          width: '50%',
          backgroundColor: '#C33C54',
          height: 1,
          alignSelf: 'center',
        }}
      ></div>
      <div style={{ alignSelf: 'center', width: '50%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '80%',
          alignSelf: 'center',
        }}
      >
        <p
          style={{
            wordBreak: 'break-word',
            width: '40%',
            border: '3px solid #2BB670',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
          }}
        >
          Positive
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '40%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
          }}
        >
          Negative
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          alignSelf: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div
          style={{ display: 'flex', width: '50%', justifyContent: 'center' }}
        >
          <div
            style={{
              height: 80,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              // width: '80%',
              padding: '5px 10px',
              backgroundColor: '#2BB670',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              alignSelf: 'center',
              color: '#fff',
            }}
          >
            <p>Breast Cancer: negative</p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '80%',
          padding: '5px 10px',
          backgroundColor: '#980000',
          borderRadius: 5,
          fontWeight: 'bold',
          fontSize: 14,
          textAlign: 'center',
          alignSelf: 'center',
          color: 'white',
        }}
      >
        <p>Breast Cancer Positive</p>
      </div>
    </div>
  );
};

export default BreastCancerDiagnosisMobile;
