import React from 'react';
import { Chip, Avatar } from '@material-ui/core';
import './AxlChips.scss';
import { Link } from 'react-router-dom';

const AxlChips = ({ options }) => {
    const { chips, color, size, variant, dynamicPage='', columnName='', } = options;
    const itemsToRemove = ['other', 'others'];
    let splitChips = '';
    if(typeof chips == 'object'){
        splitChips = chips;
    }else{
        if(chips){
            splitChips = chips.includes(';') || chips.includes('|') 
            ? chips.split(/;|\|/).map(chip => chip.trim()).filter(chip => !itemsToRemove.includes(chip.toLowerCase())) 
            : [chips.trim()];
        }else{
            splitChips = []
        }

    }

    const backgroundColorMap = {
        'devices': '#FFEBB7',         // Light pastel yellow
        'diagnostics': '#FFD9A6',     // Pastel orange
        'drugs': '#FFABAB',           // Soft coral red
        'vaccine': '#FFABAB',         // Soft coral red
        'vaccines': '#FFABAB',        // Soft coral red
        'biologics': '#FFABAB',       // Soft coral red
        'biotech': '#FFAEAE',         // Light pastel red
        'digital health': '#F8BBD0',  // Pastel pink
        'healthcare services': '#FFB7B2',  // Light salmon pink
        'healthcare it': '#E1BEE7',   // Soft purple
        'healthcare': '#E1BEE7',      // Soft purple
        'pharma': '#FFAEAE',          // Light pastel red
        'biotechnology': '#FFAEAE',   // Light pastel red
        'medtech': '#FFEBB7',         // Light pastel yellow
        'health delivery': '#FFB7B2', // Light pastel salmon pink
        'biomanufacturing': '#FFAEAE',// Light pastel red
        'bio-it': '#F8BBD0',          // Pastel pink
        'health-it': '#E1BEE7',       // Soft pastel purple
        'health services': '#FFB7B2', // Light pastel salmon pink
        'global health': '#FFABAB',   // Soft coral red
        'molecular diagnostics': '#FFD9A6',  // Pastel orange
        'foodtech': '#FFABAB',        // Soft coral red
        'agtech': '#FFABAB',          // Soft coral red
        'healthtech': '#F8BBD0',      // Pastel pink
        'success': '#D7F5D3'  ,       // Light pastel green
        'error': '#FFABAB',
        'early': '#D7F5D3'  ,       // Light pastel green
        'delay': '#FFABAB',           // Soft coral red
        'no delay': '#D7F5D3'  ,       // Light pastel green
    };
    
    // Color maps for text, using dark text for light backgrounds and white for darker shades
    const colorMap = {
        'devices': 'black',
        'diagnostics': 'black',
        'drugs': 'white',
        'vaccine': 'white',
        'vaccines': 'white',
        'biologics': 'white',
        'biotech': 'white',
        'digital health': 'black',
        'healthcare services': 'white',
        'healthcare it': 'black',
        'healthcare': 'black',
        'pharma': 'white',
        'biotechnology': 'white',
        'medtech': 'black',
        'health delivery': 'black',
        'biomanufacturing': 'white',
        'bio-it': 'black',
        'health-it': 'black',
        'health services': 'black',
        'global health': 'white',
        'molecular diagnostics': 'black',
        'foodtech': 'white',
        'agtech': 'white',
        'healthtech': 'black',
    };
        
    let chipsArray = []
    // Map the chips to corresponding styles
    if(dynamicPage == 'VenturnCapital'){
        chipsArray = splitChips.map(chip => {
            const lowerCaseChip = chip.axl_company_type.toLowerCase(); // Make the chip lowercase
            return {
                key: chip.axl_company_type,
                label: chip.axl_company_type,
                avatar: chip.count,
                color: variant === 'outlined' ? color : colorMap[lowerCaseChip] || 'black', // Default to black if no match
                backgroundColor: variant === 'outlined' ? 'white' : backgroundColorMap[lowerCaseChip] || '#ddd', // Default to grey if no match
            };
        });
    }else{
        chipsArray = splitChips.map(chip => {
            const lowerCaseChip = chip.toLowerCase(); // Make the chip lowercase
            return {
                key: chip,
                label: chip,
                avatar: '',
                color: variant === 'outlined' ? color : colorMap[lowerCaseChip] || 'black', // Default to black if no match
                backgroundColor: variant === 'outlined' ? 'white' : backgroundColorMap[lowerCaseChip] || '#ddd', // Default to grey if no match
            };
        });
    }

    return (
        <>
            {chipsArray.map((chip, index) => (
                dynamicPage == 'Generic'?
                <Link target="_blank" to={`/${dynamicPage}/0/${chip.label}/overview`}
                >
                    <Chip
                    key={index}
                    title={columnName?columnName+':- '+chip.label:chip.label}
                    label={chip.label}
                    className='axl-chip'
                    // avatar={<Avatar>22</Avatar>}
                    variant={variant ? variant : 'default'}
                    size={size}
                    style={{ color: chip.color, backgroundColor: chip.backgroundColor }}
                    />
                </Link>:

                <Chip
                    key={index}
                    title={columnName?columnName+':- '+chip.label:chip.label}
                    label={chip.label}
                    className='axl-chip'
                    avatar={chip.avatar&&<Avatar>{chip.avatar}</Avatar>}
                    variant={variant ? variant : 'default'}
                    size={size}
                    style={{ color: chip.color, backgroundColor: chip.backgroundColor }}
                />
            ))}
        </>
    );
};

export default AxlChips;
