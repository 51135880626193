import { React, useState } from 'react';
import {
  makeStyles,
  Collapse,
  Paper,
  useMediaQuery,
  ClickAwayListener,
  Grid,
} from '@material-ui/core';
import LightGreenArrowIcon from '../../../assets/img/LightGreenArrowIcon.svg';
import WhiteArrowIcon from '../../../assets/img/WhiteArrowIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },

    // border: "1px solid #000",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  main: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #2BB670',
    margin: '0 15px 0 0 ',
    background: '#EEFBF5',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2BB670',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#EEFBF5',
    borderRadius: '200px',
    border: '1px solid #2BB670',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#2BB670',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },
  bigArrowCont: {
    width: '100%',
    marginLeft: 'auto',
    position: 'relative',
    '& p': {
      position: 'absolute',
      top: '8px',
      fontWeight: '600',
      fontSize: '14px',
      left: '45%',
      transform: 'translateX(-50%)',
    },

    [theme.breakpoints.down('sm')]: {
      '& p': {
        top: '20px',
        fontSize: '10px',
      },
    },
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.75rem 0 0.5rem 0',
  },
  arrowtail: {
    background: '#EEE',
    height: '24px',
    width: 'calc(100% - 30px)',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '20px solid transparent',
    borderLeft: '20px solid #eee',
    borderBottom: '20px solid transparent',
  },

  grayBoxBarMainWrap: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  dropdownContainer: {
    width: '100%',
    borderRadius: 9,
    margin: '2px 0',
    backgroundColor: '#EEFBF5',
    position: 'relative',
    '&:first-child': {
      margin: '0px 0',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dropdownHeader: {
    border: '1.5px solid #2BB670',
    borderRadius: '8px',
    width: '100%',
    height: '55px',
    margin: '2px 0',
    '&:first-child': {
      margin: '0px 0',
    },
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ddHeadingText: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '0px',
    [theme.breakpoints.down('sm')]: {
      wordBreak: 'break-all',
      fontSize: '12px',
    },
  },
  dropdownBody: {
    backgroundColor: '#EEFBF5',
    position: 'absolute',
    zIndex: '10',
    border: '1px solid #000',
    padding: '0.5rem 1rem',
    width: '100%',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },
  dropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
  DropdownboxMainCont: {
    display: 'flex',
    position: 'relative',
    marginBottom: '1.5rem',
  },
  greenStageBox: {
    color: '#fff',
    fontSize: '14px',
    backgroundColor: '#2BB670',
    height: '30px',
    width: '100%',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    textAlign: 'center',
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },

  darkGrayBar: {
    backgroundColor: '#888',
    height: '30px',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '5px',
    margin: '16px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem 0',
    '& p': {
      margin: '0',
    },
  },

  GreenHeadingBox: {
    borderRadius: '9px',
    width: '45%',
    height: '60px',
    backgroundColor: '#2BB670',
    border: '2px solid #2BB670',
    color: '#fff',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '14px',
    marginRight: '8px',
    '&:last-child': {
      marginRight: '0',
    },
    [theme.breakpoints.down('sm')]: {
      '& p': {
        marginTop: '10px',
        fontSize: '12px',
      },
    },
  },
}));

const DiseaseDropdown = ({
  diseaseData,
  styleData,
  subheadingTextStyle,
  arrowImgstyle,
}) => {
  const classes = useStyles();
  const [openDropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  const handleClickAway = () => {
    setDropdown(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={classes.dropdownContainer}
        style={
          styleData && {
            width: openDropdown ? '100%' : styleData.width,
            marginRight: styleData.marginRight,
            marginLeft: styleData.marginLeft,
            marginTop: styleData.marginTop,
            marginBottom: styleData.marginBottom,
          }
        }
      >
        <div
          // className={classes.dropdownHeader}
          onClick={handleDropdown}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '30px',
            border: '1px solid #2BB670',
            textAlign: 'center',
            width: '100%',
            borderRadius: 9,
            cursor: 'pointer',
            alignItems: 'center',
            wordBreak: 'break-word',
            backgroundColor: openDropdown ? '#2BB670' : ' #EEFBF5',
            color: openDropdown ? '#fff' : '#000',
          }}
        >
          <p
            className={classes.ddHeadingText}
            style={subheadingTextStyle ? subheadingTextStyle : {}}
          >
            {diseaseData.name}
          </p>

          {openDropdown ? (
            <img
              src={WhiteArrowIcon}
              alt='arrow drop down'
              style={{ width: '10%', height: 15 }}
            />
          ) : (
            <img
              src={LightGreenArrowIcon}
              alt='green arrow drop down'
              style={{ width: '10%', height: 15 }}
            />
          )}
        </div>
        {diseaseData.details && (
          <div>
            <Collapse in={openDropdown}>
              <Paper className={classes.dropdownBody} elevation={0}>
                <div className={classes.drugcontBodyWrap}>
                  {diseaseData.details.howAdministered && (
                    <p style={{ fontSize: '12px' }}>
                      <b>How is it administered:</b>
                      {diseaseData.details.howAdministered}
                    </p>
                  )}
                  {diseaseData.details.whatItDoes && (
                    <p style={{ fontSize: '12px' }}>
                      <b>What It does:</b>
                      {diseaseData.details.whatItDoes}
                    </p>
                  )}
                  {diseaseData.details.HowItDoes && (
                    <p style={{ fontSize: '12px' }}>
                      <b>How it does it:</b>
                      {diseaseData.details.HowItDoes}
                    </p>
                  )}
                  {diseaseData.details.What && (
                    <p style={{ fontSize: '12px' }}>
                      <b>What is it:</b>
                      {diseaseData.details.What}
                    </p>
                  )}
                </div>
              </Paper>
            </Collapse>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

const WetAMDTreatment = ({ noFloat, noNumber }) => {
  const classes = useStyles();

  const matches = useMediaQuery('(max-width:600px)');
  const data = {
    mainTitleText: 'Treatment',
    mainTitleNumber: '8',
    grayBar1: 'First Line Treatment',
    grayBar2: 'Adjuvant Therapy',
    diseases: [
      {
        name: 'brolucizumab (Beovu, Vsiqq)',
        details: {
          howAdministered:
            'Injected into the eye or a port surgically inserted in the eye.',
          whatItDoes:
            ' Inhibits VEGF. Preliminarily shown to reduce fluid under central retina even better than aflibercept. ',
          HowItDoes: 'Binds to VEGF-A ',
          What: 'Single/short chain humanized antibody fragment containing a fusion of light and heavy IgG antibodies linked with peptides of 20-25 amino acids. ',
        },
      },
      {
        name: 'ranibizumab (Lucentis)',
        details: {
          howAdministered:
            'Injected into the eye monthly. Has same efficacy in trials as aflibercept, but you need injections twice as often.',
          whatItDoes:
            'Reduces the growth of new blood vessels in the eye, thereby reducing risk of leakage',
          HowItDoes:
            ': Binds to the VEGF Isi forms and prevents the dimerisation of receptors on the cell surface ',
          What: ' Antigen binding fragment and not the full antibody. These fragments are abbreviated as fab (fragment, antigen-binding). ',
        },
      },
      {
        name: 'bevacizumab (Avastin)',
        details: {
          howAdministered: ' Injected into the eye every 4-6 weeks. ',
          whatItDoes:
            'Reduces the growth of new blood vessels in the eye, thereby reducing risk of leakage. ',
          HowItDoes:
            ' It binds to and inhibits the action of Vascular Endothelial Growth Factor-A (VEGF-A). VEGF-A is involved in the pathways that result in growth of new blood vessels of the eye.',
          What: ': Humanized recombinant monoclonal antibody. It is approved for cancer, but pharmacists use it offlabel for AMD. Lucentis is the [same / similar] approved for AMD. ',
        },
      },
      {
        name: 'aflibercept (Eyelea)',
        details: {
          howAdministered:
            'Injected into the eye. 3 x monthly injections, followed by injections every 8 weeks',
          whatItDoes:
            'Reduces the growth of new blood vessels in the eye, thereby reducing risk of leakage.',
          HowItDoes:
            'It mimics the VEGF protein and hence acts as decoy in binding to the VEGF receptor with greater affinity and inhibiting angiogenesis.',
          What: ' Recombinant fusion protein consisting of the portions that bind to the VEGF receptor combined with a specific portion of the human IgG (Immunoglobulin) antibody.',
        },
      },
      {
        name: 'Photocoagulation/Laser therapy',
        details: {
          //   howAdministered: ": Injected into the eye monthly. Has same efficacy in trials as aflibercept, but you need injections twice as often.",
          whatItDoes:
            'Photocoagulation uses a slit lamp delivery system to focus laser right onto the affected parts of the retina. The thermal energy generated by the laser light seals the leaky blood vessels. 47% chances of recurrent neovascularization within 5 years.',
          //   HowItDoes: " Binds to the VEGF Isi forms and prevents the dimerisation of receptors on the cell surface",
          //   What: "Antigen binding fragment and not the full antibody. These fragments are abbreviated as fab (fragment, antigen-binding).",
        },
      },
      {
        name: 'Photodynamic therapy',
        details: {
          //   howAdministered: "Injected into the eye",
          whatItDoes:
            'A photosensitizer drug, known as Verteporfin, is administered intravenously. The drug is selective to the blood vessels of the eye and upon focusing a low power laser on the retina, the drug is  activated and releases free radicals. The free radicals elicit a series events of that ultimately result in the sealing of blood vessels. PDT is a superior alternative as compared to laser therapy.',
          //   HowItDoes: "ABC",
          //   What: "ABC",
        },
      },
    ],
  };
  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      <div className={classes.darkGrayBar}>First Line Treatment</div>
      <div
        style={{
          width: '90%',
          marginRight: 'auto',
          marginLeft: 'auto',
          position: 'relative',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 20,
          }}
        >
          <div className={classes.GreenHeadingBox}>
            <p>PRN</p>
          </div>

          <div className={classes.GreenHeadingBox}>
            <p>Treat & Extend</p>
          </div>
        </div>

        <p style={{ fontWeight: 'bold', marginBottom: 5 }}>Choose One</p>

        {data.diseases.slice(0, 4).map((item, id) => (
          <DiseaseDropdown
            key={id}
            diseaseData={item}
            styleData={{
              marginTop: 10,
              marginBottom: 10,
              marginRight: 0,
              marginLeft: 0,
            }}
          />
        ))}
        <div
          className={classes.darkGrayBar}
          style={{ width: '200px', marginLeft: 'auto' }}
        >
          Adhuvant Therapy
        </div>
        {data.diseases.slice(4).map((item, id) => (
          <DiseaseDropdown
            key={id}
            diseaseData={item}
            styleData={{
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 'auto',
              marginRight: 0,
              width: '200px',
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default WetAMDTreatment;
