import React from 'react';
import ExpandableRow from './reusableComponents/Accordion/ExpandableRow';



const ActiveTrialsSection = ({ item, dataSwitch, no_of_keys }) => {
  
  return (
    <ExpandableRow item={item} dataSwitch={dataSwitch} no_of_keys={no_of_keys} />
  );
};

export default ActiveTrialsSection;
