import React from 'react';

const OblongDiv = ({
  bg,
  borderColor,
  text,
  textColor,
  bottomBoxText,
  bottomBoxTextColor,
  bottomBg,
  bottomBoxBorderColor,
  square,
  width,
  maxWidth,
  minWidth,
}) => {
  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: bg,
        borderRadius: 10,
        minHeight: square ? 100 : 40,
        minWidth: width || minWidth ? 'unset' : 200,
        width: width ?? 'unset',
        maxWidth: maxWidth && 200,
        textAlign: 'center',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${borderColor}`,
        wordBreak: 'break-word',
      }}
    >
      <p style={{ padding: 10, color: textColor ?? 'black' }}>{text}</p>
      {bottomBoxText && (
        <div
          style={{
            position: 'absolute',
            bottom: -10,
            borderRadius: 10,
            minWidth: 50,
            border: `1px solid ${bottomBoxBorderColor ?? 'black'}`,
            backgroundColor: bottomBg ?? 'white',
          }}
        >
          <p style={{ color: bottomBoxTextColor ?? 'black' }}>
            {bottomBoxText}
          </p>
        </div>
      )}
    </div>
  );
};

export default OblongDiv;
