import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import HIVDiagnosisMobile from './HIVDiagnosisMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },

    // border: "1px solid #000",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  headingBox: {
    backgroundColor: '#FFEBEF',
    border: '1px solid #C33C54',
    width: '100%',
    height: 34,
    color: '#C33C54',
    fontSize: '14px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  blueBoxSmall: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#9FCEFC',
    height: '50px',
    width: '100%',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    textAlign: 'center',
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  // Arrow
  arrow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
  },
  arrowtail: {
    background: '#C33C54',
    height: '20px',
    width: '1.5px',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '6px solid #C33C54',
    borderRight: '6px solid transparent',
    borderLeft: '6px solid transparent',
  },
  // branch
  branchWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  branchMain: {
    borderRight: '1.5px solid #C33C54',
    borderLeft: '1.5px solid #C33C54',
    borderTop: '1.5px solid #C33C54',
  },
  branchMiddleLine: {
    justifySelf: 'center',
    background: '#C33C54',
  },
  redBorderGrayBox: {
    width: '98px',
    height: '44px',
    borderRadius: '7px',
    border: '1px solid #C33C54',
    backgroundColor: '#EEF0F0',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      fontSize: '9px',
    },
  },
  GreenBox: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#2BB670',
    height: '30px',
    width: '204px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '103px',
    marginLeft: '120px',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      fontSize: '10px',
      //   left: "-18%",
    },
  },
  breastCancerRed: {
    backgroundColor: '#C33C54',
    border: '1px solid #C33C54',
    width: '100%',
    height: 34,
    color: '#FFF',
    fontSize: '14px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const Arrow = ({ tailHeight, tailWidth, arrowPointStyle, arrowMainStyle }) => {
  const classes = useStyles();
  return (
    <div class={classes.arrow} style={arrowMainStyle}>
      <div class={classes.arrowtail} style={{ height: tailHeight }}></div>
      <div class={classes.arrowpoint}></div>
    </div>
  );
};

const Branch = ({
  branchStyle,
  middleLineStyle,
  branchClassName,
  middleLineClassName,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.branchWrap}>
      <div className={classes.branchMiddleLine} style={middleLineStyle}></div>
      <div className={classes.branchMain} style={branchStyle}></div>
    </div>
  );
};

const HIVDiagnosis = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const data = {
    pageTitle: 'HIV: Diagnosis',
    screenTest: 'Screening Test',
    level1: [
      { test: 'Rapid Test', level2: ['Positive Yes', 'Negative No'] },
      {
        test: '4th Generation HIV Test',
        level2: ['Negative', 'Indeterminate or Positive'],
      },
    ],
    ultrasound: { title: 'Ultrasound', level2: ['Positive', 'Negative'] },
    resultNegative: 'Breast Cancer: negative',
    testNameConfirmatory: 'Confirmatory test',
    confirmatoryTest: {
      screenTest: 'Confirmatory test',
      level1: [
        {
          test: 'HIV-1/HIV-2 Antibody Differentiation Immunoassay',
          level2: ['Hiv (Positive)', 'Negative or Intermediate'],
        },
      ],
      fianlStep: {
        test: 'Nucleic Acid Test',
        level2: ['HIV', 'HIV negative'],
      },
    },
  };

  return isMobile ? (
    <HIVDiagnosisMobile />
  ) : (
    <>
      <div className={classes.root} style={{ float: !noFloat && 'right' }}>
        <h2>{data.pageTitle}</h2>
        <div className={classes.headingBox} style={{ marginTop: '30px' }}>
          <p> {data.screenTest}</p>
        </div>
        <div style={{ marginTop: 30 }}>
          <Grid container spacing={1}>
            {data.level1.map((test, i) => (
              <Grid item xs={5} md={5}>
                <div className={classes.blueBoxSmall}>
                  <p>{test.test}</p>
                </div>
                <Branch
                  middleLineStyle={{
                    width: '1.5px',
                    height: '20px',
                    marginLeft: '-10px',
                  }}
                  branchStyle={{
                    width: '110px',
                    height: '30px',
                    marginLeft: '-10px',
                  }}
                />
                <div style={{ display: 'flex', position: 'relative' }}>
                  <div
                    style={{
                      marginRight: '7px',
                      backgroundColor: i == 1 && '#2BB670',
                    }}
                    className={classes.redBorderGrayBox}
                  >
                    <p>{test.level2[0]}</p>
                  </div>
                  {i === 0 && (
                    <Arrow
                      arrowMainStyle={{ left: '20px', top: '43px' }}
                      tailHeight={70}
                    />
                  )}

                  <div
                    style={{
                      marginRight: '7px',
                      width: i == 1 ? '140px' : '80px',
                      textAlign: 'center',
                      backgroundColor: i == 0 && '#2BB670',
                    }}
                    className={classes.redBorderGrayBox}
                  >
                    <p>{test.level2[1]}</p>
                  </div>
                  {i === 1 && (
                    <Arrow
                      arrowMainStyle={{ right: '20px', top: '43px' }}
                      tailHeight={70}
                    />
                  )}
                </div>
              </Grid>
            ))}
          </Grid>

          <div className={classes.headingBox} style={{ marginTop: '75px' }}>
            <p> {data.confirmatoryTest.screenTest}</p>
          </div>
          <div style={{ marginTop: 30 }}>
            <Grid container spacing={1} justifyContent='center'>
              {data.confirmatoryTest.level1.map((test, i) => (
                <Grid item xs={10} md={8}>
                  <div className={classes.blueBoxSmall}>
                    <p>{test.test}</p>
                  </div>
                  <Branch
                    middleLineStyle={{
                      width: '1.5px',
                      height: '20px',
                    }}
                    branchStyle={{
                      width: '75%',
                      height: '30px',
                    }}
                  />
                  <div style={{ display: 'flex', position: 'relative' }}>
                    <div
                      style={{
                        marginRight: '7px',
                        backgroundColor: '#C33C54',
                        color: '#fff',
                      }}
                      className={classes.redBorderGrayBox}
                    >
                      <p>{test.level2[0]}</p>
                    </div>

                    <div
                      style={{
                        marginLeft: 'auto',
                      }}
                      className={classes.redBorderGrayBox}
                    >
                      <p>{test.level2[1]}</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{ right: '38px', top: '43px' }}
                      tailHeight={40}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
            <div
              style={{
                width: '50%',
                marginLeft: 'auto',
              }}
            >
              <div
                style={{
                  width: '140px',
                  marginTop: '44px',
                  marginLeft: 'auto',
                  marginRight: '60px',
                }}
              >
                <div className={classes.blueBoxSmall}>
                  <p style={{ fontSize: '12px' }}>
                    {data.confirmatoryTest.fianlStep.test}
                  </p>
                </div>
              </div>
              <Branch
                middleLineStyle={{
                  width: '1.5px',
                  height: '20px',
                  marginLeft: '0px',
                }}
                branchStyle={{
                  width: '50%',
                  height: '30px',
                  marginLeft: '0px',
                }}
              />
              <div style={{ display: 'flex', position: 'relative' }}>
                <div
                  style={{
                    marginRight: '7px',
                    backgroundColor: '#C33C54',
                    color: '#fff',
                  }}
                  className={classes.redBorderGrayBox}
                >
                  <p>{data.confirmatoryTest.fianlStep.level2[0]}</p>
                </div>
                <div
                  style={{
                    marginLeft: 'auto',
                    backgroundColor: '#2BB670',
                  }}
                  className={classes.redBorderGrayBox}
                >
                  <p>{data.confirmatoryTest.fianlStep.level2[1]}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HIVDiagnosis;
