import React from 'react';
import ClassificationDropdown from './MUI/ClassificationDropdown';
import OblongDiv from './MUI/OblongDiv';

const GlaucomaClassification = () => {
  return (
    <div>
      {/* FIRST SECTION */}
      <div>
        <OblongDiv bg='#0093F5' text='Types of Glaucoma' textColor='white' />

        <div className='flexTop20'>
          <div className='halfWidthColumn'>
            <div className='flexColumn'>
              <div className='alignSelfCenter'>
                <ClassificationDropdown
                  text='Primary Glaucoma'
                  bottomBoxText='90%'
                  desc='Primary glaucoma is of two types:'
                  points={['Open-angle (OAG)', 'Angle-closure glaucoma (ACG)']}
                />
              </div>

              <div className='verticalLine'></div>

              <div className='halfWidthHorizontalLine'></div>

              <div className='justifyAround'>
                <div className='verticalLine'></div>
                <div className='verticalLine'></div>
              </div>

              <div className='justifyAround'>
                <div className='w45'>
                  <ClassificationDropdown
                    text='Open-angle glaucoma (OAG)'
                    bottomBoxText='90%'
                    desc='Open-angle refers to the angle between the iris and the cornea being normal. It is the most common form of glaucoma. It is of two types:'
                    points={[
                      'Primary Open Angle Glaucoma',
                      'Normal-Tension Glaucoma',
                    ]}
                  />
                </div>
                <div className='w45'>
                  <ClassificationDropdown
                    text='Angle-closure glaucoma (ACG)'
                    bottomBoxText='10%'
                    desc='Angle-closure glaucoma relates to the closure of the angle between the iris and the cornea. It results in a sudden blockage to the flow of aqueous fluid from the eye, causing a steep rise in IOP. It can cause blindness in a few days.'
                    points={[]}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='halfWidthColumn'>
            <div className='flexColumn'>
              <div className='alignSelfCenter'>
                <ClassificationDropdown
                  text='Secondary Glaucoma'
                  bottomBoxText='10%'
                  desc='Secondary glaucoma is due to an underlying condition or factor that raises the IOP such as trauma, inflammation, tumors, steroid medications, abnormal blood vessels or diabetes.  There are two types:'
                  points={['With pupillary block', 'Without pupillary block']}
                />
              </div>

              <div className='verticalLine'></div>

              <div className='halfWidthHorizontalLine'></div>

              <div className='justifyAround'>
                <div className='verticalLine'></div>
                <div className='verticalLine'></div>
              </div>

              <div className='justifyAround'>
                <div className='w45'>
                  <ClassificationDropdown
                    text='With 
                        Pupillary Block'
                    bottomBoxText='%'
                    desc='In the Pupillary block, the aqueous humor cannot flow freely through the pupil and out of the eye, raising the IOP. It is of three types:'
                    points={[
                      'Pre-trabecular obstruction',
                      'Trabecular obstruction',
                      'Post-trabecular obstruction',
                    ]}
                  />
                </div>
                <div className='w45'>
                  <ClassificationDropdown
                    text='Without Pupillary Block'
                    bottomBoxText='%'
                    desc="In secondary glaucoma without pupillary block, other reasons, such as damage to normal drainage or growths that block normal drainage, cause a rise in IOP."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SECOND SECTION */}
      <div style={{ marginTop: 20 }}>
        <OblongDiv bg='#0093F5' text='Primary Glaucoma' textColor='white' />

        <div className='flexTop20'>
          <div className='halfWidthColumn'>
            <div className='flexColumn'>
              <div className='alignSelfCenter'>
                <ClassificationDropdown
                  text='Open Angle Glaucoma (OAG)'
                  bottomBoxText='90%'
                  desc="Open-angle refers to the angle between the iris and the cornea being normal. It is the most common form of glaucoma. It is of two types:"
                  points={[
                    'Primary Open Angle Glaucoma',
                    'Normal-Tension Glaucoma',
                  ]}
                />
              </div>

              <div className='verticalLine'></div>

              <div className='halfWidthHorizontalLine'></div>

              <div className='justifyAround'>
                <div className='verticalLine'></div>
                <div className='verticalLine'></div>
              </div>

              <div className='justifyAround'>
                <div className='w45'>
                  <ClassificationDropdown
                    text='Primary Open Angle Glaucoma (POAG)'
                    bottomBoxText='70-80%'
                    desc='In POAG, the trabecular meshwork (drains of the eye) shows an increased resistance towards aqueous humor (the clear fluid that nourishes the eye), raising the pressure in the eye and gradually damaging optic nerve affecting vision.'
                  />
                </div>
                <div className='w45'>
                  <ClassificationDropdown
                    text='Normal Tension Glaucoma'
                    bottomBoxText='20-30%'
                    desc='In NTG, damage occurs to the optic nerve despite normal IOP. Patients generally lose larger areas of peripheral vision and eventually central vision as it progresses with no warning. By the time NTG is detected, a large amount of irreversible damage to the optic nerve may have occurred, causing permanent vision loss.'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='halfWidthColumn'>
            <div className='flexColumn'>
              <div className='alignSelfCenter'>
                <ClassificationDropdown
                  text='Angle-closure glaucoma (ACG)'
                  bottomBoxText='10%'
                  desc="Angle-closure glaucoma relates to the closure of the angle between the iris and the cornea. It results in a sudden blockage to the flow of aqueous fluid from the eye, causing a steep rise in IOP. It can cause blindness in a few days."
                />
              </div>

              <div className='verticalLine' style={{ height: 40 }}></div>

              <div className='justifyAround'>
                <div className='w45'>
                  <ClassificationDropdown
                    text='Primary Angle Closure Glaucoma'
                    bottomBoxText='%'
                  />
                </div>
              </div>
            </div>
            <div className='verticalLine'></div>
            <div className='halfWidthHorizontalLine'></div>

            <div className='justifyAround'>
              <div className='verticalLine'></div>
              <div className='verticalLine'></div>
            </div>

            <div className='justifyAround'>
              <div className='w45'>
                <ClassificationDropdown
                  text='Chronic
                      Closed Angle Glaucoma'
                  bottomBoxText='80-90%'
                />
              </div>
              <div className='w45'>
                <ClassificationDropdown
                  text='Acute
                      Closed Angle Glaucoma'
                  bottomBoxText='10-20%'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* THIRD SECTION */}
      <div style={{ marginTop: 20 }}>
        <OblongDiv bg='#0093F5' text='Secondary Glaucoma' textColor='white' />

        <div className='flexTop20'>
          <div className='width75Column'>
            <div className='flexColumn'>
              <div className='alignSelfCenter'>
                <ClassificationDropdown
                  text='With 
                  Pupillary Block'
                  bottomBoxText='%'
                  desc='In the Pupillary block, the aqueous humor cannot flow freely through the pupil and out of the eye, raising the IOP. It is of three types:'
                  points={[
                    'Pre-trabecular obstruction',
                    'Trabecular obstruction',
                    'Post-trabecular obstruction',
                  ]}
                />
              </div>
              <div className='verticalLine'></div>
              <div className='width75HorizontalLine'></div>
              <div className='justifyAround'>
                <div className='verticalLine'></div>
                <div className='verticalLine'></div>
                <div className='verticalLine'></div>
              </div>
              <div className='justifyAround'>
                <div className='w25'>
                  <ClassificationDropdown
                    text='Pre Trabecular Obstruction'
                    bottomBoxText='%'
                    desc='There is an obstruction in the aqueous humor outflow before it reaches the trabecular meshwork caused by Inflammation, tumors, or abnormal blood vessels. It is of three types:'
                    points={[
                      'Neovascular glaucoma',
                      'Irido-endothelial corneal syndrome',
                      'Epithelial ingrowth',
                    ]}
                  />
                </div>
                <div className='w25'>
                  <ClassificationDropdown
                    text='Trabecular Obstruction'
                    bottomBoxText='%'
                    desc='Trabecular obstruction is a type of Secondary Open Angle Glaucoma with pupillary block. It could be due to many types of obstructions, leaks or inflammation in the trabecular network. These are:'
                    points={[
                      'Pigmentary glaucoma',
                      'Red cell glaucoma',
                      'Ghost cell glaucoma',
                      'Phacolytic glaucoma',
                      'Pseudoexfoliation glaucoma',
                      'Hypertensive uveitis',
                    ]}
                  />
                </div>
                <div className='w25'>
                  <ClassificationDropdown
                    text='Post-Trabecular Glaucoma'
                    bottomBoxText='%'
                    desc='A rise in episcleral venous pressure (EVP) can lead to a rise in IOP. The episcleral venous system is a network of veins that drain blood from the eye and surrounding tissues. A few different types are:'
                    points={[
                      'Stuge Weber Syndrome',
                      'Carotid-cavernous fistula',
                      'Superior vena cava obstruction',
                    ]}
                  />
                </div>
              </div>
              <div className='justifyAround'>
                <div className='verticalLine'></div>
                <div className='verticalLine'></div>
                <div className='verticalLine'></div>
              </div>
              <div className='justifyAround'>
                <div className='w25'>
                  <ClassificationDropdown
                    text='Neovascular glaucoma
                    Irido-endothelial corneal syndrome
                    Epithelial ingrowth'
                    bottomBoxText='%'
                  />
                </div>
                <div className='w25'>
                  <ClassificationDropdown
                    text='Pigmentary
                    red cell
                    ghost cell
                    phacolytic
                    pseudoexfoliation, and 
                    hypertensive uveitis'
                    bottomBoxText='%'
                  />
                </div>
                <div className='w25'>
                  <ClassificationDropdown
                    text='Stuge Weber Syndrome
                    Carotid-cavernous fistula 
                    Superior vena cava obstruction'
                    bottomBoxText='%'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='width25Column'>
            <div className='flexColumn'>
              <div className='alignSelfCenter'>
                <ClassificationDropdown
                  text='Without Pupillary Block'
                  bottomBoxText='%'
                  desc='In secondary glaucoma without pupillary block, other reasons, such as damage to normal drainage or growths that block normal drainage, cause a rise in IOP.'
                  points={[]}
                />
              </div>

              <div className='verticalLine' style={{ height: 40 }}></div>

              <div className='justifyAround'>
                <div style={{ width: '100%' }}>
                  <ClassificationDropdown
                    text='Primary Angle Closure Glaucoma'
                    bottomBoxText='%'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FOURTH SECTION */}
      <div style={{ marginTop: 20 }}>
        <OblongDiv bg='#0093F5' text='By Age' textColor='white' />

        <div className='flexTop20' style={{ justifyContent: 'space-between' }}>
          <div className='alignSelfCenter' style={{ width: '30%' }}>
            <ClassificationDropdown
              text='Congenital Glaucoma (Birth-10 years)'
              bottomBoxText='%'
            />
          </div>

          <div className='alignSelfCenter' style={{ width: '30%' }}>
            <ClassificationDropdown
              text='Juvenile Glaucoma (10-35 years)'
              bottomBoxText='%'
            />
          </div>

          <div className='alignSelfCenter' style={{ width: '30%' }}>
            <ClassificationDropdown
              text='Glaucoma (Adult) ( >35 years)'
              bottomBoxText='%'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlaucomaClassification;
