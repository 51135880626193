import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '550px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  main: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #2BB670',
    margin: '0 15px 0 0 ',
    background: '#EEFBF5',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2BB670',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#EEFBF5',
    borderRadius: '200px',
    border: '1px solid #2BB670',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#2BB670',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },

  stageWrap: {
    display: 'flex',

    '& .stageItem': {
      width: 'calc(94% / 3) ',

      '&:not(:first-child)': {
        marginLeft: '15px',
      },
    },
  },
  stageName: {
    margin: '0',
    fontSize: '12px',
    fontWeight: 600,
    wordBreak: 'break-word',
  },
  blackDivider: {
    position: 'absolute',
    width: '2px',
    height: '100%',
    backgroundColor: '#000',
    top: 0,
    zIndex: -1,
  },
  stageGreenBox: {
    borderRadius: '9px',
    border: '1px solid #000',
    background: '#EEFBF5',
    position: 'relative',
    padding: '0.5rem 0.5rem',
    '& p': {
      fontSize: '12px',
      margin: 0,
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        // wordBreak: 'break-all',
      },
    },
  },
  percentCont: {
    borderRadius: '10px',
    background: '#EEFBF5',
    height: '18px',
    width: '45px',
    position: 'absolute',
    right: -8,
    top: -10,
    border: '1px solid #2BB670',
    fontWeight: 600,
    color: '#2BB670',
    textAlign: 'center',
    fontSize: '12px',
  },
  step3Text: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },

  left: {
    position: 'relative',
    '& p': {
      position: 'absolute',
      bottom: '-10%',
      fontSize: '12px',
      marginBottom: '0',
      color: '#2BB670',
    },
  },
  bottomBlackText: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '0',

    position: 'absolute',
    bottom: '-10%',
    left: 30,
    [theme.breakpoints.down('sm')]: {
      left: 50,
    },
  },
}));

const CysticFibrosisTreatment = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const data = {
    mainTitleText: 'Treatment',
    mainTitleNumber: '8',
    stages: [
      'Early Stage (Chronic Management)',
      'Intermediate Stage (Complications/Exacerbation)',
      'End Stage',
    ],
    step1: {
      step: [
        [{ name: 'Protein Modulators', percent: '100%' }],
        [{ name: 'Lung Transplantation', percent: '16.4%' }],
      ],
    },
    step2: {
      step: [
        [
          {
            name: 'Airway Clearance Exercises Devices Inhaled medications',
            percent: '100%',
          },
        ],
        [
          { name: 'Antibiotics', percent: '100%' },
          {
            name: 'Adjunctive Therapies Bronchodilators Steroids Anti-inflammatory Drugs',
            percent: '60%',
          },
        ],
        [{ name: 'Palliative Care', percent: '100%' }],
      ],
    },
    step3: {
      step: [[{ name: 'Supplemental Oxygen Sometimes Continuous' }]],
    },
    complicationTreatment: 'Treatment of Complications',
    affectedOrganTreatment: 'Treatment based on affected organ(s)',
  };
  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      <div className={classes.main}>
        <div className='right' style={{ position: 'relative' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {data.stages.map((stage, i) => (
              <div
                style={{
                  width: '30%',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  backgroundColor: '#2BB670',
                  alignItems: 'center',
                  color: '#fff',
                  borderRadius: 7,
                  padding: 5,
                }}
              >
                <p className={classes.stageName}>{stage}</p>
              </div>
            ))}
          </div>
          <div style={{ marginTop: '20px' }}>
            <div style={{ display: 'flex' }}>
              {data.step1.step[0].map((item, ind) => (
                <div className={classes.stageGreenBox} style={{ width: '65%' }}>
                  <p>{item.name}</p>
                  <div className={classes.percentCont}>{item.percent}</div>
                </div>
              ))}
              {data.step1.step[1].map((item, ind) => (
                <div
                  className={classes.stageGreenBox}
                  style={{ width: '30%', marginLeft: 'auto' }}
                >
                  <p>{item.name}</p>
                  <div className={classes.percentCont}>{item.percent}</div>
                </div>
              ))}
            </div>
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <div style={{ width: 'calc(90% / 3)', marginRight: '24px' }}>
                {data.step2.step[0].map((item, ind) => (
                  <div className={classes.stageGreenBox}>
                    <p>{item.name}</p>
                    <div className={classes.percentCont}>{item.percent}</div>
                  </div>
                ))}
              </div>

              <div style={{ width: 'calc(90% / 3)', marginRight: '24px' }}>
                {data.step2.step[1].map((item, ind) => (
                  <div
                    className={classes.stageGreenBox}
                    style={{
                      marginBottom: '25px',
                    }}
                  >
                    <p>{item.name}</p>
                    <div className={classes.percentCont}>{item.percent}</div>
                  </div>
                ))}
              </div>
              <div style={{ width: 'calc(90% / 3)' }}>
                {data.step2.step[2].map((item, ind) => (
                  <div
                    className={classes.stageGreenBox}
                    style={{ marginTop: '65px' }}
                  >
                    <p>{item.name}</p>
                    <div className={classes.percentCont}>{item.percent}</div>
                  </div>
                ))}
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              {data.step3.step[0].map((item, ind) => (
                <div
                  className={classes.stageGreenBox}
                  style={{
                    marginLeft: 'auto',
                    width: '65%',
                  }}
                >
                  <p className={classes.step3Text}>{item.name}</p>
                </div>
              ))}
            </div>
            <div
              style={{
                left: '32%',
                height: '80%',
              }}
              className={classes.blackDivider}
            ></div>
            <div
              style={{
                left: '68%',
              }}
              className={classes.blackDivider}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CysticFibrosisTreatment;
