import React from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import './AllTrials.scss'

const TrialsDesktopCard = ({ trialDetails }) => {
  console.log(trialDetails);
  const {
    axl_start_date,
    axl_completion_date,
    axl_po_measure_1,
    axl_comparison_intervention,
    enrollment,
    axl_title,
    axl_trial_classification,
    axl_primary_intervention,
    axl_class1,
    axl_roa,
    axl_moa,
    axl_phase,
    axl_status,
    axl_type_of_condition,
    condition_id,
    axl_condition,
    axl_company,
  } = trialDetails;

  return (
    <div className='trial-card'>
      <div className='trial-card-header'>
        <div className='trial-header-title'>
          <div>{axl_primary_intervention}</div>
          <div style={{ display: 'flex' }}>
            {axl_condition && (
              <div style={{ marginRight: 5 }}>{axl_condition}</div>
            )}
            <div className='trial-header-subtitle'>{axl_phase} | {axl_status}</div>
          </div>
        </div>
        <div className='trial-header-right'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div>{axl_class1}</div>
          <div>{axl_company}</div>
        </div>
      </div>

      <div className='trial-card-body'>
        <div className='trial-card-leftbody'>
          <div style={{ display: 'flex', marginBottom: 10 }}>
            <DateRangeIcon />
            <div
              style={{
                marginLeft: 10,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ fontWeight: 'bold' }}>Started </div>
              <div>{axl_start_date}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 10 }}>
            <DateRangeIcon />
            <div
              style={{
                marginLeft: 10,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ fontWeight: 'bold' }}>Ended</div>
              <div>{axl_completion_date}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 10 }}>
            <AssessmentOutlinedIcon />
            <div
              style={{
                marginLeft: 10,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ fontWeight: 'bold' }}>Measures</div>
              <div>{axl_po_measure_1}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 10 }}>
            <CompareArrowsIcon />
            <div
              style={{
                marginLeft: 10,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ fontWeight: 'bold' }}>Compared to</div>
              <div>{axl_comparison_intervention}</div>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <PeopleOutlineIcon />
            <div
              style={{
                marginLeft: 10,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ fontWeight: 'bold' }}>Participants</div>
              <div>{enrollment}</div>
            </div>
          </div>
        </div>
        <div className='trial-card-rightbody'>
          <div style={{ marginBottom: 10 }}>
            {axl_title}. This {axl_trial_classification} trial compares{' '}
            {axl_primary_intervention} to {axl_comparison_intervention}
          </div>
          <div style={{ marginBottom: 10 }}>
            {axl_primary_intervention} {axl_class1} {axl_roa} {axl_moa} The
            Primary outcome measured is {axl_po_measure_1}
          </div>
          <div style={{ marginBottom: 10 }}>
            This is a {axl_phase} trial for {axl_type_of_condition} in{' '}
            {condition_id}. The trial started on {axl_start_date} and ended on{' '}
            {axl_completion_date}. The trial enrolled {enrollment} patients.
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrialsDesktopCard;
