import React, { useState, useEffect, useRef} from 'react';
import { TextField, Select, Checkbox, FormControlLabel } from '@material-ui/core';
import { debounce } from 'lodash';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const AccordionCheckFilters = ({checkBoxs, updateCheckBoxs}) => {

  const handleCheckBox = function(val, index){
    const newItems = checkBoxs.map((item, i) => 
      i === index ? { ...item, value: val } : item
    );
    let checkKeys = newItems.filter(item => item.value).map(item => item.key).join(','); 
    updateCheckBoxs(newItems, checkKeys);
  }

  return (
    <>
      {checkBoxs.map((item, index) => {
        return (
          <FormControlLabel
            // if item.key is 'USonly' then align style to right
            style={{position: item.key === 'Yes' ? 'absolute' : 'relative', right: item.key === 'Yes' ? '0' : 'auto'}}
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                name={item.key}
                onChange={(e) => handleCheckBox(e.target.checked, index)}
                checked={item.value}
              />
            }
            label={item.label}
          />
          );
        })}
    </>
  );
};

export default AccordionCheckFilters;
