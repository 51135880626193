import React, { useEffect } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import BlueArrowIcon from '../../../assets/img/BlueArrowIcon.svg';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setClassificationData } from '../../../reducers/PatientJourneyReducer';
import OblongDiv from './MUI/OblongDiv';
import { isMobile } from 'react-device-detect';
import './ClassificationCard.scss';
import GlaucomaClassification from './GlaucomaClassification';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow:
        'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: 0,
    },
  },
  main: {
    // marginTop: "40px",
  },

  sectionContainer: {
    // marginTop: "30px",
  },
  sectHeader: {
    textAlign: 'center',
    backgroundColor: '#0093F5',
    padding: '2px 0',
    borderRadius: '7px',
    marginTop: '32px',
  },
  sectHeaderText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #0093F5',
    margin: '0 20px 0 0 ',
    background: '#EBF7FF',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#0093F5',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#EBF7FF',
    borderRadius: '200px',
    border: '1px solid #0093F5',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#0093F5',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },
  arrowDropdown: {
    position: 'absolute',
    right: '6px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  cardBox: {
    backgroundColor: '#EFFAFB',
    borderRadius: 20,
    height: 100,
    position: 'relative',
    border: '2px solid #0093F5',
    textAlign: 'center',
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Desktopfour = ({ noFloat, noNumber }) => {
  const { diseaseName, diseaseId } = useParams();
  const classes = useStyles();
  const patientJourney = useSelector((state) => state.patientJourney);
  const { classification } = patientJourney;
  const classificationLength = Object.keys(classification).length;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setClassificationData(diseaseId));
  }, []);

  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      {diseaseId === '128' ? (
        <GlaucomaClassification />
      ) : (
        <div>
          {classificationLength !== 0 && (
            <div className={classes.main}>
              {classification.data.map((info, i) => (
                <div
                  className={classes.sectionContainer}
                  key={i}
                  style={{ marginTop: i > 0 && 30 }}
                >
                  <OblongDiv bg='#0093F5' text={info.title} textColor='white' />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      flexWrap: 'wrap',
                    }}
                  >
                    {info.card.map((item, index) => (
                      <div
                        key={index}
                        style={{ marginTop: 40, width: isMobile ? 100 : 200 }}
                      >
                        <OblongDiv
                          bg='#EFFAFB'
                          text={item.cardTitle}
                          borderColor='#0093F5'
                          bottomBoxText={item.percent}
                          square
                          width={isMobile ? 100 : 200}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Desktopfour;
