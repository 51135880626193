import React from 'react';

const AlzheimersDiagnosisMobile = () => {
  const data = {
    pageTitle: "Alzheimer's: Diagnosis",
    screenTest: 'Screening Tests',
    clockDrawingTest: [
      { info: '10: Cognitive Impairment Unlikely' },
      { info: '<8: Cognitive Impairment Unlikely' },
      { info: '<5: Cognitive Impairment Unlikely' },
    ],
    moca: [
      { info: '10-25: Mild CI' },
      { info: '10-17: Moderate CI' },
      { info: '<10: Severe CI' },
    ],
    adasCog: [
      { info: '0: No Errors' },
      { info: '12: Best Cutoff for AD' },
      { info: '70: Severe Impairment' },
    ],

    resultNegative: 'Glaucoma negative',
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        borderLeft: '1px solid #C33C54',
      }}
    >
      <div
        style={{
          //   marginTop: '40px',
          backgroundColor: '#FFEBEF',
          borderTop: '1px solid #C33C54',
          borderRight: '1px solid #C33C54',
          borderBottom: '1px solid #C33C54',
          color: '#C33C54',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p>{data.screenTest}</p>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-evenly',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{ width: '30%', display: 'flex', alignItems: 'center' }}
            >
              <div
                style={{ height: 1, width: '100%', backgroundColor: '#C33C54' }}
              ></div>
            </div>
            <div
              style={{
                width: '40%',
                padding: '5px',
                backgroundColor: '#A4C2F4',
                borderRadius: 5,
                fontWeight: 'bold',
                fontSize: 14,
                textAlign: 'center',
              }}
            >
              Clock Drawing Test
            </div>
          </div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
              alignSelf: 'center',
            }}
          ></div>
          <div
            style={{
              width: '50%',
              backgroundColor: '#C33C54',
              height: 1,
              alignSelf: 'center',
            }}
          ></div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {data.clockDrawingTest.map((item, index) => (
              <p
                key={index}
                style={{
                  wordBreak: 'break-word',
                  width: '22%',
                  outline:
                    index === 0
                      ? '3px solid #2BB670'
                      : index === 1
                      ? '3px solid #FFA10A'
                      : '3px solid #980000',
                  borderRadius: 10,
                  padding: '5px 2px',
                  textAlign: 'center',
                }}
              >
                {item.info}
              </p>
            ))}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{ width: '30%', display: 'flex', alignItems: 'center' }}
            >
              <div
                style={{ height: 1, width: '100%', backgroundColor: '#C33C54' }}
              ></div>
            </div>
            <div
              style={{
                width: '40%',
                padding: '5px',
                backgroundColor: '#A4C2F4',
                borderRadius: 5,
                fontWeight: 'bold',
                fontSize: 14,
                textAlign: 'center',
              }}
            >
              Montreal Cognitive Assessment Test (MoCA)
            </div>
          </div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
              alignSelf: 'center',
            }}
          ></div>
          <div
            style={{
              width: '50%',
              backgroundColor: '#C33C54',
              height: 1,
              alignSelf: 'center',
            }}
          ></div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {data.moca.map((item, index) => (
              <p
                key={index}
                style={{
                  wordBreak: 'break-word',
                  width: '22%',
                  outline:
                    index === 0
                      ? '3px solid #2BB670'
                      : index === 1
                      ? '3px solid #FFA10A'
                      : '3px solid #980000',
                  borderRadius: 10,
                  padding: '5px 2px',
                  textAlign: 'center',
                }}
              >
                {item.info}
              </p>
            ))}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{ width: '30%', display: 'flex', alignItems: 'center' }}
            >
              <div
                style={{ height: 1, width: '100%', backgroundColor: '#C33C54' }}
              ></div>
            </div>
            <div
              style={{
                width: '40%',
                padding: '5px',
                backgroundColor: '#A4C2F4',
                borderRadius: 5,
                fontWeight: 'bold',
                fontSize: 14,
                textAlign: 'center',
              }}
            >
              ADAS Cog
            </div>
          </div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
              alignSelf: 'center',
            }}
          ></div>
          <div
            style={{
              width: '50%',
              backgroundColor: '#C33C54',
              height: 1,
              alignSelf: 'center',
            }}
          ></div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {data.adasCog.map((item, index) => (
              <p
                key={index}
                style={{
                  wordBreak: 'break-word',
                  width: '22%',
                  outline:
                    index === 0
                      ? '3px solid #2BB670'
                      : index === 1
                      ? '3px solid #FFA10A'
                      : '3px solid #980000',
                  borderRadius: 10,
                  padding: '5px 2px',
                  textAlign: 'center',
                }}
              >
                {item.info}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlzheimersDiagnosisMobile;
