import { Collapse, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import DarkBlueArrow from '../../../../assets/img/DarkBlueArrow.svg';

const useStyles = makeStyles((theme) => ({
  stagesDropdownBody: {
    backgroundColor: '#EEF5FC',
    padding: '0.5rem 1rem',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },

  stagesDropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
}));

const ClassificationDropdown = ({
  text,
  textColor,
  bottomBoxText,
  bottomBoxTextColor,
  bottomBg,
  bottomBoxBorderColor,
  desc,
  points,
}) => {
  const classes = useStyles();
  const [openDropdown, setDropdown] = useState(false);
  return (
    <div style={{ maxWidth: 150 }}>
      <div
        style={{
          position: 'relative',
          backgroundColor: '#EFFAFB',
          borderRadius: 10,
          height: 75,
          width: '100%',
          textAlign: 'center',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #0093F5',
          wordBreak: 'break-word',
          padding: 10,
        }}
        onClick={() => setDropdown(!openDropdown)}
      >
        <p
          style={{
            color: textColor ?? 'black',
            whiteSpace: text.length > 35 ? 'nowrap' : 'normal',
            overflow: 'hidden',
            fontSize: 11,
            textOverflow: 'ellipsis',
          }}
        >
          {text}
        </p>
        <img src={DarkBlueArrow} alt='green arrow drop down' />
        {bottomBoxText && (
          <div
            style={{
              position: 'absolute',
              bottom: -10,
              borderRadius: 10,
              minWidth: 50,
              border: `1px solid ${bottomBoxBorderColor ?? 'black'}`,
              backgroundColor: bottomBg ?? 'white',
            }}
          >
            <p
              style={{
                color: bottomBoxTextColor ?? 'black',
              }}
            >
              {bottomBoxText}
            </p>
          </div>
        )}
      </div>
      <Collapse style={{ 
          width: '100%',
          maxWidth: 450,
          zIndex: 100,
          position: 'absolute',
        }} 
        in={openDropdown}>
        <Paper className={classes.stagesDropdownBody}>
          <Typography>
            <p className={classes.stagesDropDownbodyText}>
              <b>{desc}</b>
            </p>
            {points &&
              points.length >= 1 &&
              points.map((item, index) => <div>- {item}</div>)}
          </Typography>
        </Paper>
      </Collapse>
    </div>
  );
};

export default ClassificationDropdown;
