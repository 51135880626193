import React, {useState} from 'react';
import { Modal, Backdrop, Fade, Card, CardHeader, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { lightGreen } from '@material-ui/core/colors';
import { useParams, Link } from 'react-router-dom';
import { nameToInitials, splitText } from './Helper';
import CIcon from '@coreui/icons-react';
import ListPopover from '../../containers/formField/ListPopover';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: '80%',
    maxWidth: '70%',
    backgroundColor: '#fff',
    overflowY: 'auto',
    maxHeight: '80%',
    position: 'relative',
    boxShadow: 'none',
  },
  header: {
    textAlign: 'center',
    background: '#2bb670',
    fontWeight: 'bold',
    padding: '10px 0',
    position: 'sticky',
    top: 0,
    '& span': {
      color: '#fff !important',
    }
  },
  cards: {
    marginTop: 20,
    padding: 0,
  },
  cardsHeader: {
    display: 'flex',
    background: '#c5ecda',
    fontWeight: 'bold',
    padding: '10px 10px',
    '& h3' : {
      fontSize: 18,
      fontWeight: 'bold',
      margin: 0,
    },
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  leftCard: {
    width: '70%',
  },
  rightCard: {
    width: '30%',
    textAlign: 'right',
  },
  cardsBody: {
    display: 'flex',
    padding: 0,
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  cardsBodyLeft: {
    width: '40%',
    padding: 10,
  },
  cardsBodyRight: {
    width: '60%',
    padding: 10,
    borderLeft: '1px solid #ccc',
    paddingLeft: 10,
  },
  closeBtn: {
    position: 'fixed',
    top: 0,
    right: 0,
    background: '#c5ecda',
    border: 'none',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#387249',
    padding: '11px 20px',
    cursor: 'pointer',
  },
}));

function PeopleCEOModal({ item, onClose }) {
  const { diseaseName } = useParams();
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  return (
    {item} &&
      <Modal
        className={classes.modal}
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Card className={classes.card}>
            <CardHeader style={{zIndex:'9999'}}
              className={classes.header}
              title={item['name']}
            />
            <button className={classes.closeBtn} onClick={handleClose}>x</button>
            <CardContent>
              <Card className={classes.cards}>
                <CardContent style={{padding:0}}>
                  <div className="container">
                    <div className="row pt-3">
                      <div className="col-12">
                        <table class="table table-bordered modal-table modal-table-deals modal-table-ceo first-letter-capitalize">
                            {item['ceo']['name'] && <tr>
                              <th class='font-weight-bold'>Name</th>
                              <th class="text-capitalize">{item['ceo']['name']}
                                {item['ceo']['linkedin'] && <Link
                                  style={{color:'#6b6767'}}
                                  target="_blank"
                                  title="Linkedin"
                                  to={{
                                    pathname: item['ceo']['linkedin'],
                                  }}>
                                    <LinkedInIcon/>
                                </Link>}
                                {item['ceo']['website'] && <Link
                                  style={{color:'#6b6767'}}
                                  target="_blank"
                                  title="Website URL"
                                  to={{
                                    pathname: item['ceo']['website'],
                                  }}>
                                    <LanguageIcon/>
                                </Link>}
                              </th>
                            </tr>}
                            {item['ceo']['titles'] &&<tr>
                              <th class='font-weight-bold'>Titles</th>
                              <th class="text-capitalize">
                                {splitText(item['ceo']['titles'],';',100)}
                              </th>
                            </tr>}
                            {item['ceo']['awards_new']&&<tr>
                              <th class='font-weight-bold'>Awards</th>
                              <th>{item['ceo']['awards_new']}</th>
                            </tr>}
                            {item['ceo']['axl_class'] &&<tr>
                              <th class='font-weight-bold'>Class</th>
                              <th>{item['ceo']['axl_class']}</th>
                            </tr>}
                            {item['ceo']['axl_company'] &&<tr>
                              <th class='font-weight-bold'>Company</th>
                              <th class="text-capitalize">{item['ceo']['axl_company']}</th>
                            </tr>}
                            {item['ceo']['boards'] &&<tr>
                              <th class='font-weight-bold'>Boards</th>
                              <th class="text-capitalize">{item['ceo']['boards']}</th>
                            </tr>}
                            {item['ceo']['category'] &&<tr>
                              <th class='font-weight-bold'>Category</th>
                              <th>{item['ceo']['category']}</th>
                            </tr>}
                            {item['ceo']['context'] &&<tr>
                              <th class='font-weight-bold'>Context</th>
                              <th>{item['ceo']['context']}</th>
                            </tr>}
                            {item['ceo']['contribution'] &&<tr>
                              <th class='font-weight-bold'>Contribution</th>
                              <th>{item['ceo']['contribution']}</th>
                            </tr>}
                            {item['ceo']['condition'] &&<tr>
                              <th class='font-weight-bold'>Condition</th>
                              <th>
                                {splitText(item['ceo']['condition'],';',100)}
                              </th>
                            </tr>}
                            {item['ceo']['deceased'] &&<tr>
                              <th class='font-weight-bold'>Deceased</th>
                              <th>{item['ceo']['deceased']}</th>
                            </tr>}
                            {item['ceo']['department'] &&<tr>
                              <th class='font-weight-bold'>Department</th>
                              <th>{item['ceo']['department']}</th>
                            </tr>}
                            {item['ceo']['education'] &&<tr>
                              <th class='font-weight-bold'>Education</th>
                              <th>
                                {splitText(item['ceo']['education'],';',1000)}
                              </th>
                            </tr>}
                            {item['ceo']['email'] &&<tr>
                              <th class='font-weight-bold'>Email</th>
                              <th>{item['ceo']['email']}</th>
                            </tr>}
                            {item['ceo']['founded'] &&<tr>
                              <th class='font-weight-bold'>Founded</th>
                              <th>{item['ceo']['founded']}</th>
                            </tr>}
                            {item['ceo']['industry_experiences'] &&<tr>
                              <th class='font-weight-bold'>Industry Experiences</th>
                              <th>
                                {splitText(item['ceo']['industry_experiences'],';',1000)}
                              </th>
                            </tr>}
                            {item['ceo']['institution'] &&<tr>
                              <th class='font-weight-bold'>Institution</th>
                              <th>{item['ceo']['institution']}</th>
                            </tr>}
                            {item['ceo']['organization_affiliation'] &&<tr>
                              <th class='font-weight-bold'>Organization Affiliation</th>
                              <th>
                                {splitText(item['ceo']['organization_affiliation'],';',1000)}
                              </th>
                            </tr>}
                            {item['ceo']['organization_boards'] &&<tr>
                              <th class='font-weight-bold'>Organization Boards</th>
                              <th>
                                {splitText(item['ceo']['organization_boards'],';',100)}
                              </th>
                            </tr>}
                            {item['ceo']['perplexity_response'] &&<tr>
                              <th class='font-weight-bold'>Perplexity</th>
                              <th>{item['ceo']['perplexity_response']}</th>
                            </tr>}
                            {item['ceo']['perplexity_year'] &&<tr>
                              <th class='font-weight-bold'>Perplexity Year</th>
                              <th>{item['ceo']['perplexity_year']}</th>
                            </tr>}
                            {item['ceo']['professional_background'] &&<tr>
                              <th class='font-weight-bold'>Professional</th>
                              <th>{item['ceo']['professional_background']}</th>
                            </tr>}
                            {item['ceo']['bio'] &&<tr>
                              <th class='font-weight-bold'>Bio</th>
                              <th>{item['ceo']['bio']}</th>
                            </tr>}
                        </table>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
  );
};

export default PeopleCEOModal;