import React, { useState } from 'react';
import { makeStyles, Collapse, Paper, useMediaQuery } from '@material-ui/core';
import LightGreenArrowIcon from '../../../assets/img/LightGreenArrowIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  main: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #2BB670',
    margin: '0 15px 0 0 ',
    background: '#EEFBF5',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2BB670',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#EEFBF5',
    borderRadius: '200px',
    border: '1px solid #2BB670',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#2BB670',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },
  bigArrowCont: {
    width: '100%',
    position: 'relative',
    marginBottom: 20,
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.75rem 0 0 0',
  },
  arrowtail: {
    background: '#476930',
    height: '60px',
    width: 'calc(100% - 30px)',
    '&:after': {
      content: '" "',
      borderTop: '30px solid transparent',
      borderLeft: '30px solid #476930',
      borderBottom: '30px solid transparent',
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  smallArrowWrap: {
    display: 'flex',
  },

  smallArrow: {
    width: '25%',
    height: '60px',
    position: 'relative',
    '&:nth-child(1)': {
      backgroundColor: '#DFF5CE',
      '& p': {
        left: '10%',
      },
    },
    '&:nth-child(2)': {
      backgroundColor: '#86B049',
      '& p': {
        left: '20%',
      },
    },
    '&:nth-child(3)': {
      backgroundColor: '#72cc50  ',
      '& p': {
        left: '25%',
      },
    },
    '&:nth-child(4)': {
      backgroundColor: '#476930  ',
      '& p': {
        left: '24%',
      },
    },
    '&:first-child:before': {
      borderLeft: '30px solid transparent',
    },
    '&:nth-child(2):before': {
      borderLeft: '30px solid #DFF5CE',
    },
    '&:nth-child(3):before': {
      borderLeft: '30px solid #86B049',
    },
    '&:nth-child(4):before': {
      borderLeft: '30px solid #72cc50',
    },
    '&:before': {
      content: '" "',
      borderTop: '30px solid transparent',
      borderBottom: '30px solid transparent',
      position: 'absolute',
      left: 0,
    },

    color: 'white',
    fontSize: '12px',
    textAlign: 'center',

    '& p': {
      marginTop: 0,
      marginBottom: 0,
      position: 'absolute',
      transform: 'translateY(-50%)',
      top: '50%',
    },
  },

  grayBoxBarMainWrap: {
    width: '100%',
    marginRight: '50px',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  dropdownContainer: {
    width: '100%',
    borderRadius: 9,
    margin: '2px 0',
    backgroundColor: '#f0f0f0',
    position: 'relative',
    marginBottom: 10,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dropdownHeader: {
    border: '1.5px solid #000',
    borderRadius: '6px',
    width: '100%',
    minHeight: '30px',
    maxHeight: '40px',
    margin: '2px 0',
    '&:first-child': {
      margin: '0px 0',
    },
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ddHeadingText: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '0px',

    [theme.breakpoints.down('sm')]: {
      wordBreak: 'break-all',
      fontSize: '12px',
    },
  },
  dropdownBody: {
    backgroundColor: '#EEFBF5',
    minWidth: '300px',
    position: 'absolute',
    right: 0,
    zIndex: '10',
    // marginRight: "auto",
    border: '1px solid #000',
    padding: '0.5rem 1rem',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },
  dropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
  DropdownboxMainCont: {
    display: 'flex',
    position: 'relative',
    marginBottom: '1.5rem',
  },
}));

const DiseaseDropdown = ({
  diseaseData,
  styleData,
  subheadingTextStyle,
  arrowImgstyle,
}) => {
  const classes = useStyles();
  const [openDropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  return (
    <div
      className={classes.dropdownContainer}
      style={styleData ? styleData : {}}
    >
      <div
        // className={classes.dropdownHeader}
        onClick={handleDropdown}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: '30px',
          border: '1px solid #2BB670',
          textAlign: 'center',
          width: '100%',
          borderRadius: 9,
          cursor: 'pointer',
          alignItems: 'center',
          wordBreak: 'break-word',
          backgroundColor: openDropdown ? '#2BB670' : ' #EEFBF5',
          color: openDropdown ? '#fff' : '#000',
        }}
      >
        <p className={classes.ddHeadingText}>
          <p style={subheadingTextStyle ? subheadingTextStyle : {}}>
            {diseaseData.name}
          </p>
        </p>

        <img
          src={LightGreenArrowIcon}
          alt='green arrow drop down'
          style={arrowImgstyle ? arrowImgstyle : {}}
        />
      </div>
      {diseaseData.description && (
        <div>
          <Collapse in={openDropdown}>
            <Paper className={classes.dropdownBody} elevation={0}>
              <div className={classes.drugcontBodyWrap}>
                <p>{diseaseData.description}</p>

                {Object.keys(diseaseData).includes('Drugs') && (
                  <>
                    <h4 style={{ fontSize: '14px' }}>Drugs</h4>
                    <p>{diseaseData.Drugs}</p>
                  </>
                )}
              </div>
            </Paper>
          </Collapse>
        </div>
      )}
    </div>
  );
};

const AtherosclerosisTreatment = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:600px)');
  const data = {
    mainTitleText: 'Treatment',
    mainTitleNumber: '4',
    stages: [
      'Stage 1: Fatty Streak',
      'Stage 2: Plaque Formation',
      'Stage 3: Stable and Unstable Plaque',
      'Stage 4: Blocked Artery',
    ],
    disease: [
      {
        name: 'Statin',
        description:
          'These cholesterol lowering drugs will reduce LDL levels. High LDL levels weaken the arterial walls. Statins inhibit the HMG CoA enzyme which is responsible for cholesterol biosynthesis',
        Drugs:
          'Atorvastatin [61% reduction of LDL for 80 mg dose] Fluvastatin [28% reduction of LDL for 40 mg dose]',
      },
      {
        name: 'ACE Inhibitors',
        description:
          'Angiotensin causes vasoconstriction and increases blood pressure. ACE inhibitors will inhibit  Angiotensin-II which lowers the blood pressure and also prevent narrowing of the arteries.           ',
        Drugs: 'Enalapril [%]  Quinapril [%]',
      },
      {
        name: 'Antiplatelet drugs',
        description:
          'Rupturing of plaque causes platelet adhesion and aggregation. Antiplatelet drugs are used to prevent platelet adhesion and aggregation thereby reducing the formation of blood clots. Aspirin will be given once atherosclerosis is detected or in patients with risk factors (diabetes). Clopidogrel will be added after certain procedures (i.e. stenting).  ',
        Drugs: 'Clopidogrel [%] Aspirin [%]',
      },
      {
        name: 'Anti-coagulants',
        description:
          'Used in Afib patients who are at risk for stroke and during a heart attack or stroke. A plaque rupture may lead to thrombosis (blood clot). Anticoagulants may break up the blood clot and relieve clogging of the artery. ',
        Drugs: 'Heparin Warfarin [%] Rivaroxaban [%]',
      },
      {
        name: 'Fibrates',
        description:
          'Individuals with elevated triglyceride levels benefit from fibrates. Fibrates inhibits the release of triglycerides from liver. It also increases the synthesis of lipoprotein lipase which is responsible for the breakdown of triglycerides.',
        Drugs:
          'Fenofibrate [18% to 25% reduced LDL and 40-48% reduced TG] Clofibrate [%]',
      },
      {
        name: 'Bile sequestrants',
        description:
          'These drugs are given to prevent reabsorption of bile salts into the enterohepatic circulation. This leads to the depletion of bile salts thereby increasing the metabolism of cholesterol for bile salt synthesis. Hence, reducing LDL levels in plasma',
        Drugs: 'Cholestyramine [32% reduction in LDL levels]',
      },
      {
        name: 'Endarterectomy',
      },

      {
        name: 'Balloon Angioplasty and Stents',
        description:
          'Angiography is performed to see where exactly the blockage is present. Once the location of the blockage is known, a catheter (long thin tube) with a deflated balloon at one end is inserted into the blocked artery. The balloon is then inflated leading to compression of the plaque.  ',
      },
      {
        name: 'Coronary Artery Bypass Graft (CABG)',
        description:
          'This technique is used to treat coronary artery disease. In this process, the blood vessels from the leg or sometimes from the chest are used as a graft and attached to the blocked coronary artery in such a way that one end of the graft is above the blockage and another end is placed below. This allows blood to flow around the blocked artery. ',
      },
    ],
    // arrowTitle: "Declining FEV1 & SPO2 (<94%)",
  };

  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      <div className={classes.main}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          {data.stages.map((stage, i) => (
            <div
              style={{
                width: '22%',
                backgroundColor: '#2BB670',
                borderRadius: 7,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <p style={{ color: '#fff' }}>{stage}</p>
            </div>
          ))}
        </div>
        {/* <div className={classes.bigArrowCont}>
          <div class={classes.arrow}>
            <div class={classes.arrowtail}>
              <div className={classes.smallArrowWrap}>
                {data.stages.map((stage, i) => (
                  <div className={classes.smallArrow}>
                    <p style={{ color: '#000' }}>{stage}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div> */}
        <div className={classes.grayBoxBarMainWrap}>
          <DiseaseDropdown
            diseaseData={data.disease[0]}
            styleData={{ width: '100%' }}
          />
          <DiseaseDropdown
            diseaseData={data.disease[1]}
            styleData={{ width: '100%' }}
          />
          <DiseaseDropdown
            diseaseData={data.disease[2]}
            styleData={{ width: '40%', marginLeft: 'auto' }}
          />
          <DiseaseDropdown
            diseaseData={data.disease[3]}
            styleData={{
              width: '20%',
              marginLeft: 'auto',
            }}
          />
          <DiseaseDropdown
            diseaseData={data.disease[4]}
            styleData={{
              width: '65%',
              marginLeft: 'auto',
            }}
          />
          <DiseaseDropdown
            diseaseData={data.disease[5]}
            styleData={{
              width: '65%',
              marginLeft: 'auto',
            }}
          />
          <DiseaseDropdown
            diseaseData={data.disease[6]}
            styleData={{
              width: '40%',
              marginLeft: 'auto',
              backgroundColor: '#D3d3d3',
            }}
          />
          <DiseaseDropdown
            diseaseData={data.disease[7]}
            styleData={{
              width: '40%',
              marginLeft: 'auto',
              backgroundColor: '#D3d3d3',
            }}
          />
          <DiseaseDropdown
            diseaseData={data.disease[8]}
            styleData={{
              width: '40%',
              marginLeft: 'auto',
              backgroundColor: '#D3d3d3',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AtherosclerosisTreatment;
