import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setPatientJourneyData } from '../../../reducers/PatientJourneyReducer';
import SkeletonImage from '../../../assets/img/SkeletonImage.svg';
import OblongDiv from './MUI/OblongDiv';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '0.75rem',
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: "#fff",
      width: '100%',
      paddingLeft: 0,
    },
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #FFA10A',
    margin: '0 3px 0 0 ',
    background: '#FFF7EB',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#FFA10A',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#FFF7EB',
    borderRadius: '200px',
    border: '1px solid #FFA10A',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#FFA10A',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },
  colHeadingText: {
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  colCont: {
    marginTop: 30,
  },
  colOneBox: {
    borderRadius: '9px',
    backgroundColor: '#FFF7EB',
    border: '1px solid #FFA10A',
    // width: "104px",
  },
  colTwobox: {
    backgroundColor: '#FFA10A',
    border: '1px solid #000',
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '9px',
    width: '200px',
    '& p': {
      marginTop: '9px',
      marginBottom: '9px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  colThreeBox: {
    backgroundColor: '#FFF7EB',
    border: '1px solid #595959',
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '9px',
    position: 'relative',
    marginBottom: '24px',
    '& p': {
      marginTop: '9px',
      marginBottom: '9px',
    },
  },
  colThreeSmallBox: {
    position: 'absolute',
    bottom: '-10px',
    backgroundColor: '#fff',
    width: 50,
    height: 18,
    borderRadius: 9,
    border: '1px solid #000',
    transform: 'translateX(-50%)',
    left: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      fontSize: '12px',
      marginTop: '12px',
    },
  },
  columnMainContainer: {
    display: 'flex',
    // marginTop: '30px',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: '33% 67%',
    },
  },
  col1wrap: {
    // width: '104px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  skeletonImage: {
    marginTop: 26,
    height: '100%',
    width: '100%',
    // [theme.breakpoints.down("sm")]: {
    //   // display: "none",
    // },
  },
  otherColMainWrap: {
    width: 'calc(100% - 104px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  col2wrap: {
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  middleRightColumnMain: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '50% 50%',
    },
  },
}));

const DesktopOne = ({ noFloat, noNumber }) => {
  const { diseaseName, diseaseId } = useParams();
  const classes = useStyles();
  const patientJourney = useSelector((state) => state.patientJourney);
  const { patientJourneyOverview } = patientJourney;
  const patientJourneyOverviewLength = Object.keys(
    patientJourneyOverview
  ).length;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPatientJourneyData(diseaseId));
  }, []);

  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      {patientJourneyOverviewLength !== 0 && (
        <div className={classes.columnMainContainer}>
          <div>
            <p className={classes.colHeadingText}>
              {patientJourneyOverview.columnHeading[0]}
            </p>
            {patientJourneyOverview.columnOneData.map((coldata) => (
              <div className={classes.col1wrap}>
                {coldata.title.length != 0 && (
                  <div style={{ marginTop: isMobile ? 30 : 10 }}>
                    <OblongDiv
                      text={coldata.title}
                      bg='#FFF7EB'
                      borderColor='#FFA10A'
                      textColor='#FFA10A'
                      width={'100%'}
                    />
                  </div>
                )}
                <img
                  src={SkeletonImage}
                  alt='skeleton'
                  className={classes.skeletonImage}
                />
              </div>
            ))}
          </div>
          <div className={classes.otherColMainWrap}>
            {patientJourneyOverview.columnRowData.map((rowData, index) => (
              <div className={classes.middleRightColumnMain} key={index}>
                <div
                  style={{
                    marginLeft: '12px',
                    marginRight: '12px',
                  }}
                >
                  <div className={classes.col2wrap}>
                    {index == 0 && (
                      <p className={classes.colHeadingText}>
                        {patientJourneyOverview.columnHeading[1]}
                      </p>
                    )}
                    {rowData.colTwoBoxTitle.length > 0 && (
                      <div
                        className={classes.colCont}
                        style={{
                          width: '100%',
                        }}
                      >
                        <div className={classes.colTwobox}>
                          <p>{rowData.colTwoBoxTitle}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div style={{}}>
                  {rowData.colThree.map((d, i) => (
                    <div className={classes.col2wrap}>
                      {i == 0 && index == 0 && (
                        <p className={classes.colHeadingText}>
                          {patientJourneyOverview.columnHeading[2]}
                        </p>
                      )}
                      <div
                        className={classes.colCont}
                        style={{
                          width: '100%',
                        }}
                      >
                        <OblongDiv
                          text={d.title}
                          bg='#FFF7EB'
                          borderColor='black'
                          bottomBoxBorderColor='black'
                          bottomBoxText={d.percent}
                          minWidth={isMobile}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DesktopOne;
