import React from "react";

const LoadingWidget = () => {
  return (
    <div className={"row d-flex col-sm-12 p-0 justify-content-center"}>
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default LoadingWidget;
