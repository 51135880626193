import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  FormControl,
  makeStyles,
  Paper,
  Select,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedOption,
  setSymptomsData,
} from '../../../reducers/PatientJourneyReducer';
import { useParams } from 'react-router-dom';
import OblongDiv from './MUI/OblongDiv';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 20,
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
  },
  numberAvatar: {
    backgroundColor: '#FF809E',
    color: 'white',
    marginRight: 10,
  },
  secondAvatar: {
    color: 'black',
  },
  formControl: {
    minWidth: 200,
  },
  selectEmpty: {
    textAlign: 'center',
  },
}));

const PatientJourneySymptoms = ({ noFloat, noNumber, noMarginTop }) => {
  const { diseaseName, diseaseId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const patientJourney = useSelector((state) => state.patientJourney);
  const { symptoms, selectedOption } = patientJourney;

  const options = Object.keys(symptoms);
  const symptomsLength = Object.keys(symptoms).length;
  // const [selectedOption, setSelectedOption] = useState('');
  const [data, setData] = useState({});

  const handleChange = (event) => {
    dispatch(setSelectedOption(event.target.value));
  };

  useEffect(() => {
    Object.keys(symptoms).length === 0 &&
      dispatch(setSymptomsData(diseaseId));
    dispatch(setSelectedOption(options[0]));
  }, []);

  useEffect(() => {
    if (symptomsLength > 0) {
      setData(Object.values(symptoms)[0]);
    }
  }, [symptoms]);

  const onOptionSelected = (index) => {
    console.log(index);
    setData(Object.values(symptoms)[index]);
    // setSelectedOption(options[index]);
  };

  return (
    symptomsLength !== 0 && (
      <div
        className={classes.root}
        style={{
          minHeight: 500,
          width: isMobile ? '100%' : 600,
          float: !noFloat && 'right',
          paddingTop: !noMarginTop && 40,
          paddingBottom: 40,
          paddingRight: isMobile ? 0 : 20,
          paddingLeft: isMobile ? 0 : 40,
        }}
      >
        <FormControl
          className={classes.formControl}
          style={{
            backgroundColor: '#EEEEEE',
            borderRadius: 100,
            marginBottom: 20,
            border: '1px solid #FF809E',
          }}
          fullWidth
        >
          <Select
            value={selectedOption}
            onChange={handleChange}
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'Without label' }}
            // defaultValue={selectedOption}
          >
            {options.map((item, index) => (
              <MenuItem
                onClick={() => onOptionSelected(index)}
                value={item}
                index={index}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {Object.keys(data).length > 0 &&
          data.map((item, index) => {
            const inner = item.innerData;
            return (
              <>
                {item.buttonName && (
                  <OblongDiv
                    text={item.buttonName}
                    bg='#FF809E'
                    textColor='white'
                  />
                )}
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginBottom: 50,
                    justifyContent: 'space-evenly',
                  }}
                >
                  {inner.map((item, index) => {
                    return (
                      <div
                        style={{ marginTop: 40, width: isMobile ? 100 : 200 }}
                      >
                        <OblongDiv
                          bg='#ffcdd8'
                          text={item.disease}
                          textColor='black'
                          bottomBoxText={item.percent}
                          square
                          width={isMobile ? 100 : 200}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
      </div>
    )
  );
};

export default PatientJourneySymptoms;
