import { React, useState } from 'react';
import { makeStyles, Collapse, Paper, Typography } from '@material-ui/core';
import GrayArrowBigIcon from '../../../assets/img/GrayArrowBigIcon.svg';
import LightGreenArrowIcon from '../../../assets/img/LightGreenArrowIcon.svg';
import WhiteArrowIcon from '../../../assets/img/WhiteArrowIcon.svg';
// import GrayArrowBigIcon from "../../assets/assets/img/GrayArrowBigIcon.svg";
// import LightGreenArrowIcon from "../../assets/assets/img/LightGreenArrowIcon.svg";
// import WhiteArrowIcon from "../../assets/assets/img/img/WhiteArrowIcon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  main: {
    position: 'relative',
    marginTop: '40px',
    width: '539px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #2BB670',
    margin: '0 15px 0 0 ',
    background: '#EEFBF5',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2BB670',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#EEFBF5',
    borderRadius: '200px',
    border: '1px solid #2BB670',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#2BB670',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },

  headingSmallBox: {
    borderRadius: '9px',
    width: '100px',
    height: '50px',
    backgroundColor: '#2BB670',
    border: '2px solid #2BB670',
    color: '#fff',
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '14px',
    marginRight: '8px',
    '&:last-child': {
      marginRight: '0',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% / 5)',

      '& p': {
        marginTop: '10px',
        fontSize: '12px',
      },
    },
  },

  bigArrowCont: {
    width: '100%',
    position: 'relative',
    '& p': {
      position: 'absolute',
      top: '15px',
      fontWeight: '600',
      fontSize: '14px',
      left: '45%',
    },

    [theme.breakpoints.down('sm')]: {
      '& p': {
        top: '8px',
        fontSize: '13px',
      },
    },
  },
  bigArrowGray: {
    width: '100%',
    margin: '0.75rem 0 0.5rem 0',
  },
  smallSubheadingCont: {
    width: '110px',
    marginLeft: 'auto',
  },
  dropdownContainer: {
    width: '100%',
    borderRadius: 9,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dropdownHeader: {
    border: '1px solid #2BB670',
    textAlign: 'center',
    width: '100%',
    borderRadius: 9,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  ddHeadingText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '0px',
  },
  dropdownBody: {
    backgroundColor: '#EEFBF5',
    padding: '0.5rem 1rem',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },
  dropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
  DropdownboxMainCont: {
    display: 'flex',
    position: 'relative',
    marginBottom: '1.5rem',
  },
}));

const DropdownBox = ({
  dropdownData,
  styleData,
  subheadingTextStyle,
  arrowImgstyle,
}) => {
  const classes = useStyles();
  const [openDropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  return (
    <div
      className={classes.dropdownContainer}
      style={styleData ? styleData : {}}
    >
      <div
        className={classes.dropdownHeader}
        onClick={handleDropdown}
        style={{
          backgroundColor: openDropdown ? '#2BB670' : ' #EEFBF5',
          color: openDropdown ? '#fff' : '#000',
        }}
      >
        <p className={classes.ddHeadingText}>
          <p style={subheadingTextStyle ? subheadingTextStyle : {}}>
            {dropdownData.title}{' '}
          </p>
        </p>

        {openDropdown ? (
          <img
            src={WhiteArrowIcon}
            alt='arrow drop down'
            style={arrowImgstyle ? arrowImgstyle : {}}
          />
        ) : (
          <img
            src={LightGreenArrowIcon}
            alt='green arrow drop down'
            style={arrowImgstyle ? arrowImgstyle : {}}
          />
        )}
      </div>
      <Collapse in={openDropdown}>
        <Paper className={classes.dropdownBody}>
          <Typography>
            <p className={classes.dropDownbodyText}>Lorem Ipsum Dolor Sit</p>
          </Typography>
        </Paper>
      </Collapse>
    </div>
  );
};

const Desktop8Part1 = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const data = {
    mainTitleText: 'Title',
    mainTitleNumber: '7',
    arrowTitle: 'Title 1',
    headings: ['Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5'],
    subheading: [
      { title: 'Sub-Heading 1', desc: 'Lorem Ipsum Dolor Sit' },
      { title: 'Sub-Heading 2', desc: 'Lorem Ipsum Dolor Sit' },
      { title: 'Sub-Heading 3', desc: 'Lorem Ipsum Dolor Sit' },
      { title: 'Sub-Heading 4', desc: 'Lorem Ipsum Dolor Sit' },
      { title: 'Sub-Heading 5', desc: 'Lorem Ipsum Dolor Sit' },
      { title: 'Sub-Heading 6', desc: 'Lorem Ipsum Dolor Sit' },
      { title: 'Sub-Heading 7', desc: 'Lorem Ipsum Dolor Sit' },
    ],
  };

  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      {/* <div className={classes.timelineAppBar}>
        <div className={classes.timelineHeading}>
          {!noNumber && (
            <div className={classes.timelineCircleBG}>
              {data.mainTitleNumber}
            </div>
          )}
          <div className={classes.timelineTitle}>{data.mainTitleText}</div>
        </div>
      </div> */}
      <div className={classes.main}>
        <div style={{ display: 'flex' }}>
          {data.headings.map((headTitle, i) => (
            <div className={classes.headingSmallBox} key={i}>
              <p>{headTitle}</p>
            </div>
          ))}
        </div>
        <div className={classes.bigArrowCont}>
          <img
            src={GrayArrowBigIcon}
            alt='arrow big '
            className={classes.bigArrowGray}
          />
          <p>{data.arrowTitle}</p>
        </div>

        {data.subheading.slice(0, 3).map((firstThreedata) => (
          <div className={classes.DropdownboxMainCont}>
            <DropdownBox dropdownData={firstThreedata} />
          </div>
        ))}
        <div className={classes.DropdownboxMainCont}>
          <DropdownBox
            dropdownData={data.subheading[3]}
            styleData={{ width: '428px', marginLeft: 'auto' }}
          />
        </div>
        <div className={classes.smallSubheadingCont}>
          {data.subheading
            .slice(
              Number(data.subheading.length - 3),
              Number(data.subheading.length)
            )
            .map((firstThreedata) => (
              <div className={classes.DropdownboxMainCont}>
                <DropdownBox
                  dropdownData={firstThreedata}
                  styleData={{
                    width: '100%',
                  }}
                  subheadingTextStyle={{
                    width: '80%',
                    textAlign: 'center',
                    marginTop: '6px',
                    marginBottom: '6px',
                  }}
                  arrowImgstyle={{ right: '6px', top: '40%' }}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Desktop8Part1;
