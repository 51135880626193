import React from 'react';
import { Modal, Backdrop, Fade, Card, CardHeader, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { lightGreen } from '@material-ui/core/colors';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: '80%',
    maxWidth: '70%',
    backgroundColor: '#fff',
    overflowY: 'auto',
    maxHeight: '80%',
    position: 'relative',
    boxShadow: 'none',
  },
  cards: {
    marginTop: 30,
    padding: 0,
  },
  cardsHeader: {
    display: 'flex',
    background: '#c5ecda',
    fontWeight: 'bold',
    padding: '10px 10px',
    '& h3' : {
      fontSize: 24,
      fontWeight: 'bold',
      margin: 0,
    },
    '& p' : {
      fontSize: 18,
      margin: 0,
    },
  },
  leftCard: {
    width: '70%',
  },
  rightCard: {
    width: '30%',
    textAlign: 'right',
  },
  cardsBody: {
    padding: '10px 10px',
    '& p' : {
      fontSize: 16,
      margin: 0,
    },
  },
  header: {
    textAlign: 'center',
    background: '#2bb670',
    color: '#fff',
    fontWeight: 'bold',
    padding: '10px 0',
    position: 'sticky',
    top: 0,
    '& span' : {
      color: '#fff',
    },
  },
  closeBtn: {
    position: 'fixed',
    top: 0,
    right: 0,
    background: '#c5ecda',
    border: 'none',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#387249',
    padding: '11px 20px',
    cursor: 'pointer',
  },
}));

function GenericModal({ item, onClose, category, goToEfficacy }) {
  const { diseaseName } = useParams();
  let listTitle = item['name'];
  // let listItem = [...Object.values(item[1])];
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };

  // // Reorder listItem by axl_approval_year
  // if(listItem.length > 1)
  //   listItem.sort((a, b) => (a.axl_approval_year > b.axl_approval_year) ? -1 : 1);

  const handleGoToEfficacy = () => {
    if(category === 'Drug') {
      goToEfficacy(listTitle);
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={true}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.header}
            title={listTitle}
            onClick={handleGoToEfficacy}
            style={{cursor: category === 'Drug' ? 'pointer' : 'default', textDecoration: category === 'Drug' ? 'underline' : 'none', zIndex:'9999',}}
          />
          <button className={classes.closeBtn} onClick={handleClose}>x</button>
          <CardContent>
            {item['nextData'].map((card) => (
              <Card className={classes.cards} key={card}>
                <CardContent style={{padding:0}}>
                  <div className={classes.cardsHeader}>
                    <div className={classes.leftCard}>
                      <h3>{card.brand_name&&card.brand_name}</h3>
                      <p style={{fontSize:'18px'}}>{card.route&&card.route+' | '}{card.axl_generic_name +' | '}{card.axl_generic_category&&card.axl_generic_category+' | '}{card.axl_generic_class&&card.axl_generic_class}</p>
                    </div>
                    <div className={classes.rightCard}>
                      <p> </p>
                      <p>{card.manufacturer_name}</p>
                    </div>
                  </div>
                  {card.description &&
                  <div class="row modal-data p-2">
                    <div className="col-12 mb-3">
                      <div style={{backgroundColor:'#c5ecda'}} className='col-12 mt-2 mb-2 popoverPosition'>
                        <h4 style={{fontWeight:'500', color:'#000000'}} class='font-weight-bold pt-2 pb-2'>Description</h4>
                      </div>
                      <p class="ms-3">{card.description}</p>
                    </div>
                  </div>}
                  {card.indications_and_usage &&
                  <div class="row modal-data p-2">
                    <div className="col-12 mb-3">
                      <div style={{backgroundColor:'#c5ecda'}} className='col-12 mt-2 mb-2 popoverPosition'>
                        <h4 style={{fontWeight:'500', color:'#000000'}} class='font-weight-bold pt-2 pb-2'>Indications and Usage</h4>
                      </div>
                      <p class="ms-3">{card.indications_and_usage}</p>
                    </div>
                  </div>}
                  {card.mechanism_of_action &&
                  <div class="row modal-data p-2">
                    <div className="col-12 mb-3">
                      <div style={{backgroundColor:'#c5ecda'}} className='col-12 mt-2 mb-2 popoverPosition'>
                        <h4 style={{fontWeight:'500', color:'#000000'}} class='font-weight-bold pt-2 pb-2'>Mechanism of Action</h4>
                      </div>
                      <p class="ms-3">{card.mechanism_of_action}</p>
                    </div>
                  </div>}
                  {card.dosage_and_administration &&
                  <div class="row modal-data p-2">
                    <div className="col-12 mb-3">
                      <div style={{backgroundColor:'#c5ecda'}} className='col-12 mt-2 mb-2 popoverPosition'>
                        <h4 style={{fontWeight:'500', color:'#000000'}} class='font-weight-bold pt-2 pb-2'>Dosage and Administration</h4>
                      </div>
                      <p class="ms-3">{card.dosage_and_administration}</p>
                    </div>
                  </div>}
                  {card.clinical_pharmacology &&
                  <div class="row modal-data p-2">
                    <div className="col-12 mb-3">
                      <div style={{backgroundColor:'#c5ecda'}} className='col-12 mt-2 mb-2 popoverPosition'>
                        <h4 style={{fontWeight:'500', color:'#000000'}} class='font-weight-bold pt-2 pb-2'>Clinical Pharmacology</h4>
                      </div>
                      <p class="ms-3">{card.clinical_pharmacology}</p>
                    </div>
                  </div>}
                  {card.clinical_studies &&
                  <div class="row modal-data p-2">
                    <div className="col-12 mb-3">
                      <div style={{backgroundColor:'#c5ecda'}} className='col-12 mt-2 mb-2 popoverPosition'>
                        <h4 style={{fontWeight:'500', color:'#000000'}} class='font-weight-bold pt-2 pb-2'>Clinical Studies</h4>
                      </div>
                      <p class="ms-3">{card.clinical_studies}</p>
                    </div>
                  </div>}
                </CardContent>
              </Card>
            ))}
          </CardContent>
        </Card>
      </Fade>
    </Modal>
  );
};

export default GenericModal;