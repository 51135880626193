import React, { useState } from 'react';
import {
  makeStyles,
  Collapse,
  Paper,
  Typography,
  ClickAwayListener,
} from '@material-ui/core';
// import LightGreenArrowIcon from "../../../assets/img/LightGreenArrowIcon.svg";
// import WhiteArrowIcon from "../../../assets/img/WhiteArrowIcon.svg";
import GlaucomaTreatmentMobile from './GlaucomaTreatmentMobile';
import { isMobile } from 'react-device-detect';
import LightGreenArrowIcon from '../../../assets/img/LightGreenArrowIcon.svg';
import WhiteArrowIcon from '../../../assets/img/WhiteArrowIcon.svg';
// import * as HoverCard from '@radix-ui/react-hover-card';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow:
        'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  main: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #2BB670',
    margin: '0 15px 0 0 ',
    background: '#EEFBF5',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2BB670',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#EEFBF5',
    borderRadius: '200px',
    border: '1px solid #2BB670',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#2BB670',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },

  headingSmallBox: {
    borderRadius: '9px',
    width: 'calc(100% / 4)',
    backgroundColor: '#2BB670',
    border: '2px solid #2BB670',
    color: '#fff',
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '12px',
    marginRight: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:last-child': {
      marginRight: '0',
    },
    '& p': { margin: 0 },
    padding: '0.5rem 1rem',
    [theme.breakpoints.down('sm')]: {
      '& p': {
        marginTop: '10px',
        fontSize: '12px',
      },
    },
  },

  dropdownContainer: {
    width: '100%',
    borderRadius: 9,
    postion: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dropdownHeader: {
    border: '1px solid #2BB670',
    textAlign: 'center',
    width: '100%',
    borderRadius: 9,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem 0 0.5rem 0',
    '& img': {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ddHeadingText: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '0px',
    [theme.breakpoints.down('sm')]: {
      wordBreak: 'break-all',
    },
  },

  // Arrow
  bigArrowCont: {
    width: '100%',
    marginLeft: 'auto',
    position: 'relative',
    '& p': {
      position: 'absolute',
      top: '50%',
      fontWeight: '600',
      fontSize: '11px',
      left: '45%',
      transform: 'translate(-50%,-50%)',
      margin: 0,
      textAlign: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      '& p': {
        top: '50%',
        fontSize: '10px',
      },
    },
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.75rem 0 0.5rem 0',
  },
  arrowtail: {
    background: '#EEE',
    height: '37px',
    width: 'calc(100% - 20px)',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '28px solid transparent',
    borderLeft: '28px solid #eee',
    borderBottom: '28px solid transparent',
  },
  blackDivider: {
    position: 'absolute',
    width: '4px',
    height: '120%',
    backgroundColor: '#000',
  },
  left: {
    position: 'relative',
    '& p': {
      position: 'absolute',
      bottom: '-16%',
      fontSize: '12px',
      marginBottom: '0',
      color: '#2BB670',
    },
  },
  DDArrowIcon: {
    height: '10px',
    width: '20px',
  },
}));

const GreenBox = ({ styleData, blockData, subheadingTextStyle }) => {
  const classes = useStyles();
  const [openDropdown, setDropdown] = useState(false);
  const [drug, setDrug] = useState('');

  const handleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  const handleClickAway = () => {
    setDropdown(false);
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={classes.dropdownContainer}
        style={
          styleData && {
            width: openDropdown ? '100%' : styleData.width,
            marginRight: styleData.marginRight,
            marginLeft: styleData.marginLeft,
          }
        }
      >
        <div
          // className={classes.dropdownHeader}
          onClick={handleDropdown}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '30px',
            border: '1px solid #2BB670',
            textAlign: 'center',
            width: '100%',
            borderRadius: 9,
            cursor: 'pointer',
            alignItems: 'center',
            wordBreak: 'break-word',
            backgroundColor: openDropdown ? '#2BB670' : ' #EEFBF5',
            color: openDropdown ? '#fff' : '#000',
          }}
        >
          <p
            className={classes.ddHeadingText}
            style={subheadingTextStyle ? subheadingTextStyle : {}}
          >
            {blockData.title}
          </p>

          {openDropdown ? (
            <img
              src={WhiteArrowIcon}
              className={classes.DDArrowIcon}
              alt='arrow drop down'
              style={{ width: '10%' }}
            />
          ) : (
            <img
              src={LightGreenArrowIcon}
              className={classes.DDArrowIcon}
              alt='green arrow drop down'
              style={{ width: '10%' }}
            />
          )}
        </div>
        {openDropdown && (
          <Collapse in={openDropdown}>
            <Paper
              className={classes.dropdownBody}
              style={{
                backgroundColor: '#EEFBF5',
                border: '1px solid #000',
                padding: '0.5rem 1rem',
                position: 'absolute',
                zIndex: 1000,
              }}
            >
              <p>{blockData.content}</p>
              {/* <HoverCard.Root openDelay={100}>
                {blockData.Drugs.map((item, index) => (
                  <div key={index}>
                    <div onMouseEnter={() => setDrug(item.name)}>
                      <HoverCard.Trigger>{item.name}</HoverCard.Trigger>
                    </div>
                    {item.name === drug && (
                      <HoverCard.Portal>
                        <HoverCard.Content style={{ zIndex: 900 }} side='right'>
                          <div
                            style={{
                              minHeight: 200,
                              maxHeight: 400,
                              width: 300,
                              overflow: 'scroll',
                              backgroundColor: 'white',
                              border: '1px solid black',
                            }}
                          >
                            <div
                              style={{
                                position: 'sticky',
                                top: 0,
                                backgroundColor: '#2BB670',
                                padding: 10,
                              }}
                            >
                              {item.name}
                            </div>
                            <div
                              style={{
                                paddingRight: 10,
                                paddingLeft: 10,
                                paddingBottom: 10,
                              }}
                            >
                              {item.text}{' '}
                              <a
                                style={{ display: 'block' }}
                                href={item.url}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                Read More
                              </a>
                            </div>
                          </div>
                        </HoverCard.Content>
                      </HoverCard.Portal>
                    )}
                  </div>
                ))}
              </HoverCard.Root> */}
            </Paper>
          </Collapse>
        )}
      </div>
    </ClickAwayListener>
  );
};

const GlaucomaTreatment = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const data = {
    mainTitleText: 'Treatment',
    mainTitleNumber: '8',
    arrowTitle: 'Severity of Vision Loss or Nerve Damage 0%-100%',
    stages: [
      '1st Line',
      'Second Line',
      'Moderate',
      'Severe',
    ],

    blockData: [
      {
        title: 'Prostaglandin analogues',
        content:
          'Prostaglandin analogs bind to specific receptors in the ciliary muscle of the eye, relaxing the muscle and allowing more drainage of aqueous humor through the trabecular meshwork. The increased outflow of aqueous humor leads to lower IOP. Some prostaglandin analogs are: Bimatoprost (Lumigan)Latanoprost (Xalatan)Travoprost (Travatan)Tafluprost (Zioptan)',
        Drugs: [],
      },
      {
        title: 'Beta Blockers',
        content:
          'Beta blockers work by reducing the activity of the ciliary body, which is responsible for producing aqueous humor. This results in a decrease in the amount of fluid in the eye, leading to a reduction in IOP. Some beta blockers are: Timolol (Timoptic, Betimol, Istalol) Levobunolol (Betagan)',
        Drugs: [],
      },
      {
        title: 'Carbonic anhydrase inhibitors',
        content:
          'Carbonic anhydrase is an enzyme that plays a key role in the production of aqueous humor in the eye. By inhibiting the activity of carbonic anhydrase, CAIs reduce the production of aqueous humor, leading to a decrease in IOP. CAIs can be oral or topical (eye drops). Topical CAIs dorzolamide (Trusopt) and brinzolamide (Azopt) Oral CAIs acetazolamide (Diamox) and methazolamide (Neptazane)',
        Drugs: [],
      },
      {
        title: 'Alpha adrenergic agonists',
        content:
          'Alpha-adrenergic agonists bind to receptors on vascular smooth muscles leading to contraction and vasoconstriction. Vasoconstriction reduces the supply of blood to the ciliary muscle, wh ich reduces the production of aqueous humor. Main drugs are: Brimonidine (Alphagan) Apraclonidine (Iopidine)',
        Drugs: [],
      },
      {
        title: 'Rho Kinase Inhibitors',
        content:
          'Rho kinase inhibitors are a new medication that works by inhibiting the Rho kinase enzyme, which plays a role in regulating the contraction and relaxation of cells in the trabecular meshwork, which drains the aqueous humor. Drugs Netarsudil(AR-13503)',
        Drugs: [],
      },
      {
        title: 'Combination Therapies',
        content:
          'There are a number of meds that combine two medications to reduce applications/day and increase cost-effectiveness: Cosopt: timolol and dorzolamide (2/day) Xalacom: timolol and latanoprost (1/day) Simbrinza: brimonidine and brinzolamide (2/day). (new, only one without timolol)',
        Drugs: [],
      },
      {
        title: 'LPI',
        content:
          'Laser peripheral iridotomy (LPI) is the suggested initial treatment for primary angle closure (PAC) and early PACG. Laser Peripheral Iridotomy (LPI) is a type of laser surgery that is used to treat and prevent angle-closure glaucoma. A laser is used to create a small hole in the iris. This hole allows the fluid to flow more freely through the drainage angle and into the trabecular meshwork, the part of the eye responsible for draining fluid. By improving the outflow of fluid, LPI can help lower intraocular pressure and prevent damage to the optic nerve.',
        Drugs: [],
      },
      {
        title: 'Laser trabeculoplasty',
        content:
          'Surgical trabeculoplasty uses a laser to stimulate the tissue to create small openings for fluid to drain. There are two types of surgical trabeculoplasty: argon laser trabeculoplasty (ALT) selective laser trabeculoplasty (SLT). Both trabeculoplasty are intended to improve the flow of fluid out of the eye and reduce intraocular pressure.',
        Drugs: [],
      },
      {
        title: 'MIGS',
        content:
          "MIGS are less invasive surgeries that traditional glaucoma surgeries performed with smaller incisions and specialized instruments, and leading to shorter recovery times. Different MIGS procedures include: Ab-Interno Canaloplasty (ABiC): A tiny catheter to access and dilate Schlemm's canal, the main drainage canal in the eye. CyPass: A small implant that is placed in the eye's drainage canal to help improve fluid outflow Gonio-Assisted Transluminal Trabeculotomy (GATT): A device is used to remove a portion of the trabecular meshwork, similar to trabectome. iStent: A tiny device is implanted in the eye's drainage canal. It is the smallest medical implant approved by the FDA. Trabectome: A small probe is used to remove a portion of the trabecular meshwork All procedures are performed within the eye's anterior chamber, which is the front part of the eye between the cornea and the iris.",
        Drugs: [],
      },
      {
        title: 'Trabeculectomy',
        content:
          'Trabeculectomy involves creating a small flap in the sclera (white part of the eye) and a small drainage channel to help lower intraocular pressure. Trabeculectomy is typically done for patients who do not respond well to treatments like eye drops or laser therapy.',
        Drugs: [],
      },
      {
        title: 'Implants (Shunts & Valves)',
        content:
          "Shunts and valves listed here are not considered minimally invasive glaucoma surgeries (MIGS).  These devices are typically reserved for patients with more advanced or severe glaucoma who have not responded well to other treatments like eye drops or laser therapy. Ahmed Valve: This consists of a small tube and a valve that are implanted in the eye's anterior chamber to allow excess fluid to drain out of the eye. Baerveldt Implant: This implant consists of a small plate and a tube that are implanted in the eye to allow excess fluid to drain out of the eye and into the surrounding tissue. Krupin Implant: This implant consists of a small tube that is implanted in the eye to allow excess fluid to drain out of the eye. Molteno Implant: This is is a long tube that is placed in the eye's anterior chamber and extends into the subconjunctival space, where it allows excess fluid to drain out of the eye.",
        Drugs: [],
      },
      {
        title: 'Cyclophotocoagulation',
        content:
          'Cyclophotocoagulation (CPC) is a type of laser treatment where the laser selectively destroys some of the cells in the ciliary body, the part of the eye that produces aqueous humor, the fluid that fills the eye. CPC is typically reserved for patients with more advanced or severe glaucoma who have not responded well to other treatments like eye drops or laser trabeculoplasty. There are two types of CPC: Transscleral CPC (TSCPC): TSCPC uses a laser to apply energy to the ciliary body through the sclera, the white part of the eye. Endoscopic CPC (ECP): An endoscope visualizes the ciliary body and laser energy is delivered to the target tissue.',
        Drugs: [],
      },
    ],
    complicationText: 'Treatment of Complications',
  };

  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      <div className={classes.main}>
        <div className='right'>
          <div className={classes.bigArrowCont}>
            <div class={classes.arrow}>
              <div class={classes.arrowtail}></div>
              <div class={classes.arrowpoint}></div>
            </div>
            <p>{data.arrowTitle}</p>
          </div>
          <div style={{ display: 'flex' }}>
            {data.stages.map((headTitle, i) => (
              <div className={classes.headingSmallBox} key={i}>
                <p>{headTitle}</p>
              </div>
            ))}
          </div>
          <div
            style={{
              backgroundColor: 'grey',
              color: '#fff',
              textAlign: 'center',
              marginTop: 30,
              marginBottom: 10,
            }}
          >
            Drugs
          </div>
          <div style={{ position: 'relative' }}>
            <div style={{ marginBottom: 10 }}>
              <GreenBox
                blockData={data.blockData[0]}
                styleData={{ width: '100%', marginLeft: 'auto' }}
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <GreenBox
                blockData={data.blockData[1]}
                styleData={{ width: '75%', marginLeft: 'auto' }}
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <GreenBox
                blockData={data.blockData[2]}
                styleData={{ width: '65%', marginLeft: 'auto' }}
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <GreenBox
                blockData={data.blockData[3]}
                styleData={{ width: '65%', marginLeft: 'auto' }}
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <GreenBox
                blockData={data.blockData[4]}
                styleData={{ width: '75%', marginLeft: 'auto' }}
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <GreenBox
                blockData={data.blockData[5]}
                styleData={{ width: '80%', marginLeft: 'auto' }}
              />
            </div>
            <div
              style={{
                backgroundColor: 'grey',
                color: '#fff',
                textAlign: 'center',
                marginBottom: 10,
              }}
            >
              Lasers
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ marginBottom: 10, width: '22%' }}>
                <GreenBox
                  blockData={data.blockData[6]}
                  styleData={{ width: '', marginLeft: 'auto' }}
                />
              </div>
              <div style={{ marginBottom: 10, width: '72%' }}>
                <GreenBox
                  blockData={data.blockData[7]}
                  styleData={{ width: '', marginLeft: 'auto' }}
                />
              </div>
            </div>

            <div
              style={{
                backgroundColor: 'grey',
                color: '#fff',
                textAlign: 'center',
                marginBottom: 10,
              }}
            >
              Procedures
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                style={{
                  marginBottom: 10,
                  width: '50%',
                  marginLeft: '25%',
                }}
              >
                <GreenBox
                  blockData={data.blockData[8]}
                  styleData={{ width: '', marginLeft: 'auto' }}
                />
              </div>
            </div>

            <div style={{ marginBottom: 10 }}>
              <GreenBox
                blockData={data.blockData[9]}
                styleData={{ width: '55%', marginLeft: 'auto' }}
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <GreenBox
                blockData={data.blockData[10]}
                styleData={{ width: '45%', marginLeft: 'auto' }}
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <GreenBox
                blockData={data.blockData[11]}
                styleData={{ width: '30%', marginLeft: 'auto' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlaucomaTreatment;
