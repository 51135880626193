import React, { useState, useEffect, useRef} from 'react';
import { TextField, Select } from '@material-ui/core';
import { debounce } from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';

const AccordionSearchBar = ({findTrials, searchVal}) => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  let search = params.get('search');
  if(search){
    searchVal = search
  }
  const handleSearch = function(val){
    const queryParams = new URLSearchParams();
    queryParams.set('search', val);
    if(val){
      history.push({
        pathname: window.location.pathname,
        search: queryParams.toString(),
      });
    }else{
      history.push({
        pathname: window.location.pathname
      });
    }
    findTrials(val)
  }
  return (
    <>
        <TextField
          className="search-bar-global mt-2"
          placeholder="Search..."
          onChange={debounce((e) => handleSearch(e.target.value), 1000)}
          variant="outlined"
          style={{ width: '100%' }}
          defaultValue={searchVal}
        />
        
    </>
  );
};

export default AccordionSearchBar;
