import React from 'react';

const GlaucomaDiagnosisMobile = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          //   marginBottom: 10,
          padding: '40px',
          paddingBottom: '100px',
          marginBottom: '40px',
          marginLeft: '40px',
          borderRadius: '10px',
          overflow: 'hidden',
          boxShadow:
            'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
        }}
      >
        <div
          style={{
            backgroundColor: '#FFEBEF',
            border: '1px solid #C33C54',
            color: '#C33C54',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <p>Confirmatory Tests</p>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              width: '30%',
              padding: '5px',
              backgroundColor: '#A4C2F4',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
            }}
          >
            Visual fields testing
          </div>
          <div
            style={{
              width: '30%',
              padding: '5px',
              backgroundColor: '#A4C2F4',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
            }}
          >
            Gonioscopy
          </div>
          <div
            style={{
              width: '30%',
              padding: '5px',
              backgroundColor: '#A4C2F4',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
            }}
          >
            OCT/Optic nerve imaging
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '80%',
            alignSelf: 'center',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
              alignSelf: 'center',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
              alignSelf: 'center',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
              alignSelf: 'center',
            }}
          ></div>
        </div>

        <div
          style={{
            width: '80%',
            backgroundColor: '#C33C54',
            height: 1,
            alignSelf: 'center',
          }}
        ></div>

        <div
          style={{
            height: 20,
            width: 1,
            backgroundColor: '#C33C54',
            alignSelf: 'center',
          }}
        ></div>

        <div
          style={{
            width: '50%',
            backgroundColor: '#C33C54',
            height: 1,
            alignSelf: 'center',
          }}
        ></div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '50%',
            alignSelf: 'center',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
              alignSelf: 'center',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
              alignSelf: 'center',
            }}
          ></div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '80%',
            alignSelf: 'center',
          }}
        >
          <p
            style={{
              wordBreak: 'break-word',
              width: '40%',
              border: '3px solid #980000',
              borderRadius: 10,
              padding: '5px 2px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Positive
          </p>
          <p
            style={{
              wordBreak: 'break-word',
              width: '40%',
              border: '3px solid #2BB670',
              borderRadius: 10,
              padding: '5px 2px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Negative
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '50%',
            alignSelf: 'center',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
              alignSelf: 'center',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
              alignSelf: 'center',
            }}
          ></div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '80%',
            alignSelf: 'center',
          }}
        >
          <div
            style={{
              width: '40%',
              padding: '5px',
              backgroundColor: '#A4C2F4',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              backgroundColor: '#980000',
              color: '#fff',
            }}
          >
            Glaucoma
          </div>
          <div
            style={{
              width: '40%',
              padding: '5px',
              backgroundColor: '#A4C2F4',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              backgroundColor: '#2BB670',
              color: '#fff',
            }}
          >
            Glaucoma Negative
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlaucomaDiagnosisMobile;
