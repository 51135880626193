import React, { useState } from 'react';
import {
  makeStyles,
  Collapse,
  Paper,
  Grid,
  ClickAwayListener,
  useMediaQuery,
} from '@material-ui/core';

import LightGreenArrowIcon from '../../../assets/img/LightGreenArrowIcon.svg';
import WhiteArrowIcon from '../../../assets/img/WhiteArrowIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  main: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    // display: "grid",
    // gridTemplateColumns: "20% 80%",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #2BB670',
    margin: '0 15px 0 0 ',
    background: '#EEFBF5',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2BB670',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#EEFBF5',
    borderRadius: '200px',
    border: '1px solid #2BB670',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#2BB670',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },

  headingSmallBox: {
    borderRadius: '9px',
    width: 'calc(100% / 4)',
    height: '60px',
    backgroundColor: '#2BB670',
    border: '2px solid #2BB670',
    color: '#000',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '14px',
    marginRight: '8px',
    '&:last-child': {
      marginRight: '0',
    },
    [theme.breakpoints.down('sm')]: {
      '& p': {
        marginTop: '10px',
        fontSize: '12px',
      },
    },
  },

  smallSubheadingCont: {
    width: '50%',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  // Arrow
  bigArrowCont: {
    width: '100%',
    marginLeft: 'auto',
    position: 'relative',
    '& p': {
      position: 'absolute',
      top: 31,
      fontWeight: '600',
      fontSize: '14px',
      left: '45%',
      transform: 'translateX(-50%)',
    },

    [theme.breakpoints.down('sm')]: {
      '& p': {
        fontSize: '10px',
      },
    },
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.75rem 0 0.5rem 0',
  },
  arrowtail: {
    background: '#EEE',
    height: '34px',
    width: 'calc(100% - 30px)',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '30px solid transparent',
    borderLeft: '30px solid #eee',
    borderBottom: '30px solid transparent',
  },

  darkGrayBar: {
    backgroundColor: '#888',
    height: '30px',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '5px',
    margin: '16px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem 0',
    '& p': {
      margin: '0',
    },
  },

  GreenBox: {
    width: '100%',
    borderRadius: '6px',
    border: '1px solid #EEFBF5 ',
    backgroundColor: '#2BB670',
    fontSize: '14px',
    fontWeight: 'bold',
    marginRight: '8px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
    padding: '1.5rem',
    '& p': {
      margin: '0',
    },
  },

  blueBoxSmall: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#9FCEFC',
    height: '50px',
    width: '100%',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    textAlign: 'center',
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },

  blackDivider: {
    position: 'absolute',
    width: '2px',
    height: '92%',
    backgroundColor: '#000',
    top: 0,
    zIndex: -1,
  },

  dropdownContainer: {
    width: '100%',
    borderRadius: 9,
    margin: '2px 0',
    // backgroundColor: "#EEFBF5",
    position: 'relative',
    '&:first-child': {
      margin: '0px 0',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dropdownHeader: {
    border: '1.5px solid #2BB670',
    borderRadius: '6px',
    width: '100%',
    height: '40px',
    margin: '2px 0',
    '&:first-child': {
      margin: '0px 0',
    },
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ddHeadingText: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '0px',
    [theme.breakpoints.down('sm')]: {
      wordBreak: 'break-all',
      fontSize: '12px',
    },
  },
  dropdownBody: {
    backgroundColor: '#EEFBF5',
    position: 'absolute',
    zIndex: '10',
    border: '1px solid #000',
    padding: '0.5rem 1rem',
    width: '100%',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },
  dropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
  DropdownboxMainCont: {
    display: 'flex',
    position: 'relative',
    marginBottom: '1.5rem',
  },
  dropDownDetailText: {
    fontSize: '12px',
  },
  drugListItemContainer: {
    marginTop: '8px',
    padding: '0 0px 0 18px',
    '& li': {
      fontSize: '12px',
      wordWrap: 'break-word',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 0px 0 16px',
    },
  },
  drugShortDescriptionContainer: {
    backgroundColor: '#EEE',
    fontSize: '14px',
    padding: '1rem',
    borderRadius: '9px',
    width: '100%',
    margin: '0 0 1.5rem 0',
  },
}));

// const DropdownBox = ({
//   diseaseData,
//   styleData,
//   subheadingTextStyle,
//   arrowImgstyle,
// }) => {
//   const classes = useStyles();
//   const [openDropdown, setDropdown] = useState(false);

//   const handleDropdown = () => {
//     setDropdown((prev) => !prev);
//   };

//   const handleClickAway = () => {
//     setDropdown(false);
//   };

//   return (
//     <ClickAwayListener onClickAway={handleClickAway}>
//       <div
//         className={classes.dropdownContainer}
//         style={styleData ? styleData : {}}
//       >
//         <div className={classes.dropdownHeader} onClick={handleDropdown}>
//           <p className={classes.ddHeadingText}>
//             <p style={subheadingTextStyle ? subheadingTextStyle : {}}>
//               {diseaseData.name}
//             </p>
//           </p>

//           <img
//             src={LightGreenArrowIcon}
//             alt="green arrow drop down"
//             style={arrowImgstyle ? arrowImgstyle : {}}
//           />
//         </div>
//         {(diseaseData.description || diseaseData.Drugs) && (
//           <div>
//             <Collapse in={openDropdown}>
//               <Paper className={classes.dropdownBody} elevation={0}>
//                 <div className={classes.drugcontBodyWrap}>
//                   <p>{diseaseData.description}</p>

//                   {Object.keys(diseaseData).includes("Drugs") && (
//                     <>
//                       <h4 style={{ fontSize: "14px", margin: "10px 0 4px 0" }}>
//                         Drugs
//                       </h4>
//                       <p>{diseaseData.Drugs}</p>
//                     </>
//                   )}
//                 </div>
//               </Paper>
//             </Collapse>
//           </div>
//         )}
//       </div>
//     </ClickAwayListener>
//   );
// };

const DropdownBox = ({
  dropdownData,
  styleData,
  subheadingTextStyle,
  arrowImgstyle,
}) => {
  const classes = useStyles();
  const [openDropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown((prev) => !prev);
  };
  const handleClickAway = () => {
    setDropdown(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={classes.dropdownContainer}
        style={
          styleData && {
            width: openDropdown ? '100%' : styleData.width,
            marginRight: styleData.marginRight,
            marginLeft: styleData.marginLeft,
          }
        }
      >
        <div
          className={classes.dropdownHeader}
          onClick={handleDropdown}
          style={{
            backgroundColor: openDropdown ? '#2BB670' : ' #EEFBF5',
            color: openDropdown ? '#fff' : '#000',
          }}
        >
          <p
            className={classes.ddHeadingText}
            style={subheadingTextStyle ? subheadingTextStyle : {}}
          >
            {dropdownData.title}
          </p>

          {openDropdown ? (
            <img
              src={WhiteArrowIcon}
              className={classes.DDArrowIcon}
              alt='arrow drop down'
              style={arrowImgstyle ? arrowImgstyle : {}}
            />
          ) : (
            <img
              src={LightGreenArrowIcon}
              className={classes.DDArrowIcon}
              alt='green arrow drop down'
              style={arrowImgstyle ? arrowImgstyle : {}}
            />
          )}
        </div>

        {openDropdown && dropdownData.desc && (
          <Collapse in={openDropdown}>
            <Paper className={classes.dropdownBody}>
              <div className={classes.drugcontBodyWrap}>
                <p className={classes.dropDownDetailText}>
                  {dropdownData.desc}
                </p>
                <h5>Drugs</h5>
                {dropdownData.drugDetail.length >= 1 && (
                  <ul className={classes.drugListItemContainer}>
                    {dropdownData.drugDetail.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                )}
              </div>
            </Paper>
          </Collapse>
        )}
      </div>
    </ClickAwayListener>
  );
};

const HIVTreatment = ({ noFloat, noNumber }) => {
  const matches = useMediaQuery('(min-width:450px)');
  const classes = useStyles();
  const data = {
    mainTitleText: 'Treatment',
    mainTitleNumber: '8',
    arrowTitle: 'Before Beginning Treatment',
    step2: ['Initial Screening', 'Genetic Testing', 'Resistance Testing'],
    arrow2: 'Resistance to Treatment',
    drugCategories: [
      {
        title: 'NRTI',
        desc: 'These work by adding themselves to the viral chain, terminating of DNA Synthesis and inhibiting replication',
        drugDetail: [
          'Abacavir (Ziagen)',
          'Emtricitabine',
          'Lamivudine (Epivir)',
          'Tenofovir (Viread)',
          'Zidovudine (Retrovir)',
        ],
      },
      {
        title: 'NNRTIs',
        desc: 'These work by blocking the HIV reverse transcriptase (RT) enzyme which is essential for the creation of the HIV DNA chain, inhibiting replication',
        drugDetail: [
          'Efavirenz (Sustiva and Strocin)',
          'Etravirine',
          'Nevirapine (Viramune)',
          'Rilpivirine ',
        ],
      },
      {
        title: 'Protease Inhibitor',
        desc: 'Protease enzyme is necessary for proteolytic cleavage for new viral particles to be formed. PIs binds to the enzyme and inhibits its activity',
        drugDetail: [
          'Atazanavir (Reyataz)',
          'Darunavir (Prezista)',
          'Etravirine',
          'Ritonavir (Norvir)',
        ],
      },
      {
        title: 'Integrase Inhibitor',
        desc: 'Integrase is an enzyme which facilitates the integration of viral genome into the host DNA. These drugs bind to the enzyme and thus, inhibit its activity',
        drugDetail: [
          'Bictegravir',
          'Elvitegravir',
          'Dolutegravir (Tivicay)',
          'Raltegravir (Isentress) ',
        ],
      },
      {
        title: 'Boosters',
        desc: 'They act as pharmacokinetic enhancers. Both are CYP3A4 inhibitors. CYP3A4 is an important enzyme present mainly in liver and small intestine. Unlike Ritonavir, Cobicistat doesn’t have antiviral activity. Cobicistat acts as a more specific CYP3A4 inhibitor than Ritonavir. ',
        drugDetail: ['Ritonavir', 'Cobicistat'],
      },
    ],
    leftSection: {
      title: 'First Line ART',
      items: [
        '2 NRTIs + NNRTI',
        '2 NRTIs + Integrase Inhibitor',
        '2 NRTIs + Protease Inhibitor + Booster',
      ],
    },
    rightSection: {
      title: 'Second Line ART',
      detail: {
        innerTitle: 'Second Line ART',
        description:
          'Dependent on patient’s adherence to treatment, drug resistanc',
      },
    },
  };
  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      <div style={{ display: 'flex' }}>
        <div className={classes.bigArrowCont}>
          <div class={classes.arrow}>
            <div class={classes.arrowtail}></div>
            <div class={classes.arrowpoint}></div>
          </div>
          <p>{data.arrowTitle}</p>
        </div>
      </div>
      <Grid container spacing={2}>
        {data.step2.map((data, i) => (
          <Grid item xs={4} md={4}>
            <div className={classes.blueBoxSmall}>
              <p>{data}</p>
            </div>
          </Grid>
        ))}
      </Grid>
      <div className={classes.darkGrayBar}>DRUG CATEGORIES</div>
      <div
        style={{
          display: 'flex',
          flexFlow: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        {data.drugCategories.map((drugCat, i) => (
          <DropdownBox
            key={i}
            dropdownData={drugCat}
            styleData={{ width: '45%', marginRight: '6px' }}
          />
        ))}
      </div>
      <div className={classes.darkGrayBar}>TREATMENT</div>
      <div style={{ display: 'flex' }}>
        <div className={classes.bigArrowCont}>
          <div class={classes.arrow}>
            <div class={classes.arrowtail}></div>
            <div class={classes.arrowpoint}></div>
          </div>
          <p>{data.arrow2}</p>
        </div>
      </div>
      <Grid container spacing={2} style={{ position: 'relative' }}>
        <div
          className={classes.blackDivider}
          style={{ left: '50%', height: '105%' }}
        ></div>
        <Grid item xs={6}>
          <div className={classes.GreenBox}>
            <p>{data.leftSection.title}</p>
          </div>
          <div style={{ marginTop: '34px' }}>
            {data.leftSection.items.map((item, i) => {
              return (
                <div
                  className={classes.GreenBox}
                  style={{
                    borderColor: '#2BB670',
                    backgroundColor: '#EEFBF5',
                    padding: '0.5rem 0.5rem',
                    margin: '8px 0',
                    width: '100%',
                    color: '#000',
                  }}
                  key={i}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.GreenBox}>
            <p>{data.rightSection.title}</p>
          </div>
          <div
            className={classes.GreenBox}
            style={{
              borderColor: '#2BB670',
              backgroundColor: '#EEFBF5',
              padding: '0.75rem 0.5rem',
              margin: '34px 0 8px 0',
              width: '100%',
              color: '#000',
              borderRadius: '14px',
              height: matches ? 130 : 175,
            }}
          >
            <div>
              <p>{data.rightSection.detail.innerTitle}</p>
              <p style={{ fontWeight: '400', marginTop: '12px' }}>
                {data.rightSection.detail.description}
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HIVTreatment;
