import React from 'react';
import { makeStyles } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import Covid19DiagnosisMobile from '../PatientJourney11Diseases/Covid19DiagnosisMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '2px solid #C33C54',
    margin: '0 15px 0 0 ',
    background: '#FAEFF1',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#C33C54',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#FAEFF1',
    borderRadius: '200px',
    border: '2px solid #C33C54',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#C33C54',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },

  blueBox: {
    backgroundColor: '#9FCEFC',
    borderRadius: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#000',
    fontSize: '12px',
    fontWeight: '600',
    height: '40px',
    width: '140px',
    textAlign: 'center',
    padding: '2px 14px',
    // paddingLeft: "10px",
  },
  TestStepName: {
    color: '#C33C54',
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '0 20px 0 0',
    borderBottom: '1.5px solid #000',
    width: '400px',
    // width: "300px",
  },

  // Arrow
  arrow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    // position: "absolute",
  },
  arrowtail: {
    background: '#C33C54',
    height: '20px',
    width: '1.5px',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '6px solid #C33C54',
    borderRight: '6px solid transparent',
    borderLeft: '6px solid transparent',
  },
  // branch
  branchWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  branchMain: {
    borderRight: '1.5px solid #C33C54',
    borderLeft: '1.5px solid #C33C54',
    borderTop: '1.5px solid #C33C54',
  },
  branchMiddleLine: {
    justifySelf: 'center',
    background: '#C33C54',
  },
  redBorderGrayBox: {
    height: '40px',
    maxWidth: '78px',
    borderRadius: '7px',
    border: '1px solid #C33C54',
    backgroundColor: '#EEF0F0',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    padding: '4px 14px',
    [theme.breakpoints.down('sm')]: {
      width: '40px',
    },
  },
  sideLeftTextBox: {
    height: '130px',
    border: '1.5px solid #C33C54',
    background: '#FAEFF1',
    width: '40px',
    position: 'relative',
  },
  sideLeftTextBoxText: {
    position: 'absolute',
    top: '30%',
    transform: 'translate(-50%,50%) rotate(90deg)',
    color: '#C33C54',
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '0',
    left: '50%',
  },
  bottomBoxWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    // marginTop: "50px",
    marginLeft: '-14px',
  },
  bottomBox: {
    color: '#fff',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      marginTop: '0',
      marginBottom: '0',
      textAlign: 'center',
    },
  },
}));

const Arrow = ({ tailHeight, tailWidth, arrowPointStyle, arrowMainStyle }) => {
  const classes = useStyles();
  return (
    <div class={classes.arrow} style={arrowMainStyle}>
      <div class={classes.arrowtail} style={{ height: tailHeight }}></div>
      <div class={classes.arrowpoint}></div>
    </div>
  );
};

const Branch = ({
  branchStyle,
  middleLineStyle,
  branchClassName,
  middleLineClassName,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.branchWrap}>
      <div className={classes.branchMiddleLine} style={middleLineStyle}></div>
      <div className={classes.branchMain} style={branchStyle}></div>
    </div>
  );
};

const Slide1 = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const data = {
    mainTitleText: 'Diagnosis',
    mainTitleNumber: '3',
    testStep: [
      { title: 'Screening Test', boxtext: 'Screening' },
      { title: 'Confirmatory Test', boxtext: 'Confirmatory Test' },
    ],
    outputResult: [
      { text: 'Covid 19 Positive' },
      { text: 'Monitor & Repeat RTPCR' },
      { text: 'Negative' },
    ],
  };
  return isMobile ? (
    <Covid19DiagnosisMobile />
  ) : (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      <div className={classes.timelineAppBar}>
        {/* <div className={classes.timelineHeading}>
          {!noNumber && (
            <div className={classes.timelineCircleBG}>
              {data.mainTitleNumber}
            </div>
          )}
          <div className={classes.timelineTitle}>{data.mainTitleText}</div>
        </div> */}
        <div style={{ marginTop: '40px' }}>
          <p className={classes.TestStepName}>{data.testStep[0].title}</p>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '40% 60%',
              marginTop: '20px',
            }}
          >
            <div className='left'>
              <div className={classes.sideLeftTextBox}>
                <p className={classes.sideLeftTextBoxText}>
                  {data.testStep[0].boxtext}
                </p>
              </div>
              <div
                style={{
                  position: 'relative',
                  marginTop: '20px',
                }}
              >
                <p
                  className={classes.TestStepName}
                  style={{ position: 'absolute' }}
                >
                  {data.testStep[1].title}
                </p>
              </div>
              <div
                className={classes.sideLeftTextBox}
                style={{ height: '400px', marginTop: '70px' }}
              >
                <p
                  className={classes.sideLeftTextBoxText}
                  style={{ width: '160px' }}
                >
                  {data.testStep[1].boxtext}
                </p>
              </div>
            </div>
            <div className='right' style={{ width: '100%' }}>
              <div>
                <div style={{ width: '140px' }}>
                  <div className={classes.blueBox}>
                    Rapid Antigen (Nasal Swab)
                  </div>
                  <Branch
                    middleLineStyle={{
                      width: '1.5px',
                      height: '14px',
                      marginRight: '40px',
                    }}
                    branchStyle={{
                      width: '100px',
                      height: '24px',
                      marginRight: '40px',
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div style={{ marginLeft: '-48px' }}>
                    <div className={classes.redBorderGrayBox}>Positive</div>
                    <Arrow
                      arrowMainStyle={{ marginLeft: '20px' }}
                      tailHeight={460}
                    />
                  </div>
                  <div style={{ marginLeft: '35px' }}>
                    <div className={classes.redBorderGrayBox}>
                      Intermediate or Negative
                    </div>
                    <Arrow
                      arrowMainStyle={{ marginRight: '180px' }}
                      tailHeight={100}
                    />
                    <div>
                      <div style={{ width: '140px' }}>
                        <div className={classes.blueBox}>
                          RTPCR (Saliva or Blood)
                        </div>
                        <Branch
                          middleLineStyle={{
                            width: '1.5px',
                            height: '24px',
                            marginRight: '65px',
                          }}
                          branchStyle={{
                            width: '100px',
                            height: '24px',
                            marginRight: '65px',
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <div style={{ marginLeft: '-34px' }}>
                          <div className={classes.redBorderGrayBox}>
                            Positive
                          </div>
                          <Arrow
                            arrowMainStyle={{ marginLeft: '-30px' }}
                            tailHeight={226}
                          />
                        </div>
                        <div style={{ marginLeft: '10px' }}>
                          <div className={classes.redBorderGrayBox}>
                            Negative
                          </div>
                          <Arrow
                            arrowMainStyle={{ marginRight: '140px' }}
                            tailHeight={30}
                          />
                          <div>
                            <div
                              style={{ width: '140px', marginLeft: '-30px' }}
                            >
                              <div className={classes.blueBox}>Chest CT</div>
                              <Branch
                                middleLineStyle={{
                                  width: '1.5px',
                                  height: '24px',
                                  marginRight: '0px',
                                }}
                                branchStyle={{
                                  width: '100px',
                                  height: '24px',
                                  marginRight: '0px',
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <div style={{ marginLeft: '-44px' }}>
                                <div className={classes.redBorderGrayBox}>
                                  Positive
                                </div>
                              </div>
                              <div style={{ marginLeft: '10px' }}>
                                <div className={classes.redBorderGrayBox}>
                                  Indeterminant
                                </div>
                              </div>
                              <div
                                className={classes.redBorderGrayBox}
                                style={{ marginLeft: '10px' }}
                              >
                                Negative
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.bottomBoxWrap}>
                {data.outputResult.map((op, i) => (
                  <div
                    className={classes.bottomBox}
                    style={{
                      border: '1px solid #C33C54',
                      backgroundColor:
                        i == 0 ? '#C33C54' : i == 1 ? '#FFA10A' : '#2BB670',
                      width: i == 0 || i == 2 ? '90px' : '124px',
                      height: '50px',
                    }}
                  >
                    <p>{op.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slide1;
