import React from 'react';

const AsthmaDiagnosisMobile = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          //   marginBottom: 10,
        }}
      >
        <div
          style={{
            backgroundColor: '#FFEBEF',
            border: '1px solid #C33C54',
            color: '#C33C54',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <p>Screening Test</p>
        </div>
        <div
          style={{
            width: '80%',
            padding: '5px 10px',
            backgroundColor: '#A4C2F4',
            borderRadius: 5,
            fontWeight: 'bold',
            fontSize: 14,
            textAlign: 'center',
            alignSelf: 'center',
          }}
        >
          <p>Forced Spirometry Test</p>
        </div>
        <div
          style={{
            height: 20,
            width: 1,
            backgroundColor: '#C33C54',
            alignSelf: 'center',
          }}
        ></div>
        <div
          style={{
            width: '50%',
            backgroundColor: '#C33C54',
            height: 1,
            alignSelf: 'center',
          }}
        ></div>
        <div style={{ alignSelf: 'center', width: '50%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '80%',
            alignSelf: 'center',
          }}
        >
          <p
            style={{
              wordBreak: 'break-word',
              width: '40%',
              border: '3px solid #980000',
              borderRadius: 10,
              padding: '5px 2px',
              textAlign: 'center',
            }}
          >
            FVC = normal or decreased FEV1 = decreased FEV1/FVC
            {` <0.7`}
          </p>
          <p
            style={{
              wordBreak: 'break-word',
              width: '40%',
              border: '3px solid #2BB670',
              borderRadius: 10,
              padding: '5px 2px',
              textAlign: 'center',
            }}
          >
            FVC = normal FEV1 = normal FEV1/FVC {`>0.7`}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div
            style={{ display: 'flex', width: '50%', justifyContent: 'center' }}
          >
            <div
              style={{
                height: 80,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '50%',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
            <div
              style={{
                // width: '80%',
                padding: '5px 10px',
                backgroundColor: '#2BB670',
                borderRadius: 5,
                fontWeight: 'bold',
                fontSize: 14,
                textAlign: 'center',
                alignSelf: 'center',
                color: '#fff',
              }}
            >
              <p>Asthma Negative</p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: '#FFEBEF',
          border: '1px solid #C33C54',
          color: '#C33C54',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 10,
        }}
      >
        <p>Confirmatory Test</p>
      </div>
      <div
        style={{
          width: '80%',
          padding: '5px 10px',
          backgroundColor: '#A4C2F4',
          borderRadius: 5,
          fontWeight: 'bold',
          fontSize: 14,
          textAlign: 'center',
          alignSelf: 'center',
        }}
      >
        <p>Spirometry Test (Pre and Post bronchodilator)</p>
      </div>
      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: '#C33C54',
          alignSelf: 'center',
        }}
      ></div>
      <div
        style={{
          width: '50%',
          backgroundColor: '#C33C54',
          height: 1,
          alignSelf: 'center',
        }}
      ></div>
      <div style={{ alignSelf: 'center', width: '50%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '80%',
          alignSelf: 'center',
        }}
      >
        <p
          style={{
            wordBreak: 'break-word',
            width: '40%',
            border: '3px solid #2BB670',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
          }}
        >
          FEV1 increase of ≥200 mL and ≥15%
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '40%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
          }}
        >
          FEV1 not increased by ≥200 mL and ≥15%
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          alignSelf: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex', width: '25%', justifyContent: 'end' }}>
          <div
            style={{
              height: 250,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '70%',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
              marginLeft: '25%',
            }}
          ></div>
          <div
            style={{
              padding: '5px 10px',
              backgroundColor: '#A4C2F4',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              alignSelf: 'center',
            }}
          >
            <p>Bronchial Hyperresponsive study/Methacholine Challenge Test</p>
          </div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              width: '50%',
              backgroundColor: '#C33C54',
              height: 1,
              alignSelf: 'center',
            }}
          ></div>
          <div style={{ alignSelf: 'center', width: '50%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  height: 20,
                  width: 1,
                  backgroundColor: '#C33C54',
                }}
              ></div>
              <div
                style={{
                  height: 20,
                  width: 1,
                  backgroundColor: '#C33C54',
                }}
              ></div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '80%',
              alignSelf: 'center',
            }}
          >
            <p
              style={{
                wordBreak: 'break-word',
                width: '40%',
                border: '3px solid #980000',
                borderRadius: 10,
                padding: '5px 2px',
                textAlign: 'center',
              }}
            >
              PC20 ≤ 8 mg/mL
            </p>
            <p
              style={{
                wordBreak: 'break-word',
                width: '40%',
                border: '3px solid #2BB670',
                borderRadius: 10,
                padding: '5px 2px',
                textAlign: 'center',
              }}
            >
              PC20 {`>8`} mg/mL
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '50%',
            }}
          >
            <div
              style={{
                height: 85,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
            <div
              style={{
                height: 85,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            width: '60%',
            padding: '5px 10px',
            backgroundColor: '#980000',
            borderRadius: 5,
            fontWeight: 'bold',
            fontSize: 14,
            textAlign: 'center',
            alignSelf: 'center',
            color: '#fff',
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>Asthma Negative</p>
        </div>
        <div
          style={{
            width: '30%',
            padding: '5px 10px',
            backgroundColor: '#2BB670',
            borderRadius: 5,
            fontWeight: 'bold',
            fontSize: 14,
            textAlign: 'center',
            alignSelf: 'center',
            color: '#fff',
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>Asthma Negative</p>
        </div>
      </div>
    </div>
  );
};

export default AsthmaDiagnosisMobile;
