import React, { useEffect } from 'react';
import TextPopover from '../../containers/formField/TextPopover';
import { get } from 'lodash';
import Paper from '@material-ui/core/Paper';
import PatientJourneySymptoms from './reusableComponents/PatientJourneySymptoms';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import ClassificationCard from './reusableComponents/ClassificationCard';
import StagingAndProgressionCard from './reusableComponents/StagingAndProgressionCard';
import CareTeamFlowchart from './reusableComponents/CareTeamFlowchart';
import MonitoringFlowchart from './reusableComponents/MonitoringFlowchart';
import DiagnosisFlowchartHIV from './reusableComponents/DiagnosisFlowchartHIV';
import OutcomesFlowchart from './reusableComponents/OutcomesFlowchart';
import PatientJourneyTreatment from './reusableComponents/PatientJourneyTreatment';
import PatientJourneyOverviewFlowchart from './reusableComponents/PatientJourneyOverviewFlowchart';
import { Fab, useMediaQuery } from '@material-ui/core';
import AlzheimersDiagnosis from './PatientJourney11Diseases/AlzheimersDiagnosis';
import AlzheimersTreatment from './PatientJourney11Diseases/AlzheimersTreatment';
import AsthmaDiagnosis from './PatientJourney11Diseases/AsthmaDiagnosis';
import AsthmaTreatment from './PatientJourney11Diseases/AsthmaTreatment';
import AtherosclerosisDiagnosisConfirmatoryTest from './PatientJourney11Diseases/AtherosclerosisDiagnosisConfirmatoryTest';
import AtherosclerosisTreatment from './PatientJourney11Diseases/AtherosclerosisTreatment';
import BreastCancerDiagnosis from './PatientJourney11Diseases/BreastCancerDiagnosis';
import BreastCancerTreatment from './PatientJourney11Diseases/BreastCancerTreatment';
import CopdDiagnosis from './PatientJourney11Diseases/CopdDiagnosis';
import COPDTreatment from './PatientJourney11Diseases/COPDTreatment';
import Covid19Diagnosis from './reusableComponents/Covid19Diagnosis';
import Covid19Treatment from './reusableComponents/Covid19Treatment';
import CysticFibosisDiagnosisChildren from './PatientJourney11Diseases/CysticFibosisDiagnosisChildren';
import CysticFibrosisTreatment from './PatientJourney11Diseases/CysticFibrosisTreatment';
import GlaucomaDiagnosisConfirmatoryTest from './PatientJourney11Diseases/GlaucomaDiagnosisConfirmatoryTest';
import GlaucomaTreatment from './PatientJourney11Diseases/GlaucomaTreatment';
import HIVDiagnosis from './PatientJourney11Diseases/HIVDiagnosis';
import HIVTreatment from './PatientJourney11Diseases/HIVTreatment';
import T2DDiagnosis from './PatientJourney11Diseases/T2DDiagnosis';
import T2DTreatment from './PatientJourney11Diseases/T2DTreatment';
import WetAmdDiagnosis from './PatientJourney11Diseases/WetAmdDiagnosis';
import WetAMDTreatment from './PatientJourney11Diseases/WetAMDTreatment';
import {
  setSelectedOption,
  setSymptomsData,
} from '../../reducers/PatientJourneyReducer';
import { useDispatch, useSelector } from 'react-redux';

const DisplayTextEditorContent = (props) => {
  const { diseaseName, diseaseId } = useParams();
  const { selectedData } = props;
  const matches = useMediaQuery('(min-width:450px)');
  const history = useHistory();

  const introduction = useRouteMatch(
    '/diseases/:diseaseId/:diseaseName/introduction'
  );
  const symptoms = useRouteMatch('/diseases/:diseaseId/:diseaseName/symptoms');
  const diagnosis = useRouteMatch(
    '/diseases/:diseaseId/:diseaseName/diagnosis'
  );
  const classification = useRouteMatch(
    '/diseases/:diseaseId/:diseaseName/classification'
  );
  const stages = useRouteMatch(
    '/diseases/:diseaseId/:diseaseName/Stages Categories'
  );
  const careTeam = useRouteMatch('/diseases/:diseaseId/:diseaseName/Care Team');
  const monitoring = useRouteMatch(
    '/diseases/:diseaseId/:diseaseName/monitoring'
  );
  const treatment = useRouteMatch(
    '/diseases/:diseaseId/:diseaseName/treatment'
  );
  const outcomes = useRouteMatch('/diseases/:diseaseId/:diseaseName/outcomes');
  const innovations = useRouteMatch(
    '/diseases/:diseaseId/:diseaseName/innovationsSummary'
  );
  const trials = useRouteMatch('/diseases/:diseaseId/:diseaseName/trials');

  const dispatch = useDispatch();
  const patientJourney = useSelector((state) => state.patientJourney);

  useEffect(() => {
    dispatch(setSymptomsData(diseaseId));
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      {get(selectedData, 'results', []).length
        ? get(selectedData, 'results', []).map((res, key) => {
            return (
              <>
                {introduction ? (
                  <PatientJourneyOverviewFlowchart />
                ) : symptoms ? (
                  <PatientJourneySymptoms />
                ) : classification ? (
                  <ClassificationCard />
                ) : careTeam ? (
                  <CareTeamFlowchart />
                ) : monitoring ? (
                  <MonitoringFlowchart />
                ) : stages ? (
                  <StagingAndProgressionCard />
                ) : diagnosis && diseaseId === '130' ? (
                  <AlzheimersDiagnosis />
                ) : diagnosis && diseaseId === '121' ? (
                  <AsthmaDiagnosis />
                ) : diagnosis && diseaseId === '122' ? (
                  <AtherosclerosisDiagnosisConfirmatoryTest />
                ) : diagnosis && diseaseId === '123' ? (
                  <BreastCancerDiagnosis />
                ) : diagnosis && diseaseId === '124' ? (
                  <CopdDiagnosis />
                ) : diagnosis && diseaseId === '125' ? (
                  <Covid19Diagnosis />
                ) : diagnosis && diseaseId === '126' ? (
                  <CysticFibosisDiagnosisChildren />
                ) : diagnosis && diseaseId === '128' ? (
                  <GlaucomaDiagnosisConfirmatoryTest />
                ) : diagnosis && diseaseId === '120' ? (
                  <HIVDiagnosis />
                ) : diagnosis && diseaseId === '127' ? (
                  <T2DDiagnosis />
                ) : diagnosis && diseaseId === '129' ? (
                  <WetAmdDiagnosis />
                ) : outcomes ? (
                  <OutcomesFlowchart />
                ) : treatment && diseaseId === '130' ? (
                  <AlzheimersTreatment />
                ) : treatment && diseaseId === '121' ? (
                  <AsthmaTreatment />
                ) : treatment && diseaseId === '122' ? (
                  <AtherosclerosisTreatment />
                ) : treatment && diseaseId === '123' ? (
                  <BreastCancerTreatment />
                ) : treatment && diseaseId === '124' ? (
                  <COPDTreatment />
                ) : treatment && diseaseId === '125' ? (
                  <Covid19Treatment />
                ) : treatment && diseaseId === '126' ? (
                  <CysticFibrosisTreatment />
                ) : treatment && diseaseId === '128' ? (
                  <GlaucomaTreatment />
                ) : treatment && diseaseId === '120' ? (
                  <HIVTreatment />
                ) : treatment && diseaseId === '127' ? (
                  <T2DTreatment />
                ) : treatment && diseaseId === '129' ? (
                  <WetAMDTreatment />
                ) : null}
                <TextPopover
                  text={get(res, 'content', '')}
                  refData={get(res, 'references', '')}
                />
              </>
            );
          })
        : null}
      {/* {(introduction ||
        symptoms ||
        diagnosis ||
        classification ||
        stages ||
        careTeam ||
        monitoring ||
        treatment ||
        outcomes ||
        innovations ||
        trials) && (
        <Fab
          variant='extended'
          size='medium'
          color='primary'
          aria-label='add'
          style={{
            width: 100,
            bottom: 0,
            left: matches ? '90%' : '70%',
          }}
          onClick={() =>
            history.push(
              `/diseases/${diseaseId}/${diseaseName}/${
                introduction
                  ? 'symptoms'
                  : symptoms
                  ? 'diagnosis'
                  : diagnosis
                  ? 'classification'
                  : classification
                  ? 'stagesCategories'
                  : stages
                  ? 'careTeam'
                  : careTeam
                  ? 'monitoring'
                  : monitoring
                  ? 'treatment'
                  : treatment
                  ? 'outcomes'
                  : 'introduction'
              }`
            )
          }
        >
          Next
        </Fab>
      )} */}
    </div>
  );
};

export default DisplayTextEditorContent;
