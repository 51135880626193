import React, { Component } from 'react';
import { CLabel, CButton, CCard, CCardBody, CCol } from '@coreui/react';
import logos from '../../../assets/img/AXLMED.png';
import CIcon from '@coreui/icons-react';
import moment from 'moment';

class PageToPrint extends Component {
  constructor(props) {
    super(props);
  }
  render() {

    return (
      <div className='p-4 d-flex flex-column' media='print'>
        <div id='printarea'>
          <h6>{moment().format("MMM Do YYYY")}</h6>
          <div className='row mb-5'>
            <CIcon
              className='col-sm-2'
              name='logo-negative'
              src={logos}
              height={65}
              width={95}
            />
          </div>
          {/* <div className="row">
        <div className="col-sm-6 d-flex justify-content-start">
        <CLabel><strong><h3>{this.state.reportsItem.condition}&nbsp;:&nbsp;{this.state.reportsItem.category}</h3></strong></CLabel>
       </div>
       
  
  </div> */}
          <div className='row'>
            <div className='col-sm-6'>
              <CLabel>
                <h3>
                  <strong>
                    {this.props.reportsItem.condition}&nbsp;:&nbsp;
                  </strong>
                </h3>
              </CLabel>
              <CLabel>
                <h4>{this.props.reportsItem.category}</h4>
              </CLabel>
            </div>
          </div>

          <CLabel
            dangerouslySetInnerHTML={{
              __html: this.props.pagecontent,
            }}
            style={{ width: '100%' }}
          ></CLabel>
        </div>
        <h6 style={{ alignSelf: "center", }}>&copy; Axlmed | Accelerate Medicine LLC</h6>
      </div>
    );
  }
}

export default PageToPrint;
