import { React, useState } from 'react';
import {
  makeStyles,
  Collapse,
  Paper,
  useMediaQuery,
  ClickAwayListener,
  Grid,
} from '@material-ui/core';
import LightGreenArrowIcon from '../../../assets/img/LightGreenArrowIcon.svg';
// import * as HoverCard from '@radix-ui/react-hover-card';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
    // border: "1px solid #000",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  main: {
    position: 'relative',
    // marginTop: '20px',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #2BB670',
    margin: '0 15px 0 0 ',
    background: '#EEFBF5',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2BB670',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#EEFBF5',
    borderRadius: '200px',
    border: '1px solid #2BB670',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#2BB670',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },
  bigArrowCont: {
    width: '100%',
    marginLeft: 'auto',
    position: 'relative',
    '& p': {
      position: 'absolute',
      top: 22,
      fontWeight: '600',
      fontSize: '14px',
      left: '45%',
      transform: 'translateX(-50%)',
    },

    [theme.breakpoints.down('sm')]: {
      '& p': {
        top: '24px',
        fontSize: '10px',
      },
    },
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.75rem 0 0.5rem 0',
  },
  arrowtail: {
    background: '#EEE',
    height: '24px',
    width: 'calc(100% - 30px)',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '20px solid transparent',
    borderLeft: '20px solid #eee',
    borderBottom: '20px solid transparent',
  },

  grayBoxBarMainWrap: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  dropdownContainer: {
    width: '100%',
    borderRadius: 9,
    margin: '2px 0',
    backgroundColor: '#f0f0f0',
    position: 'relative',
    marginBottom: 10,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dropdownHeader: {
    border: '1.5px solid #000',
    borderRadius: '6px',
    width: '100%',
    height: '30px',
    margin: '2px 0',
    '&:first-child': {
      margin: '0px 0',
    },
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ddHeadingText: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '0px',

    [theme.breakpoints.down('sm')]: {
      wordBreak: 'break-all',
      fontSize: '12px',
    },
  },
  dropdownBody: {
    backgroundColor: '#EEFBF5',
    position: 'absolute',
    zIndex: '10',
    border: '1px solid #000',
    padding: '0.5rem 1rem',
    width: '100%',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },
  dropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
  DropdownboxMainCont: {
    display: 'flex',
    position: 'relative',
    marginBottom: '1.5rem',
  },
  greenStageBox: {
    color: '#fff',
    fontSize: '14px',
    backgroundColor: '#2BB670',
    height: '30px',
    width: '100%',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    textAlign: 'center',
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },

  darkGrayBar: {
    backgroundColor: '#888',
    minHeight: '30px',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '5px',
    margin: '16px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem 0',
    '& p': {
      margin: '0',
    },
  },
}));

const DiseaseDropdown = ({
  diseaseData,
  styleData,
  subheadingTextStyle,
  arrowImgstyle,
}) => {
  const classes = useStyles();
  const [openDropdown, setDropdown] = useState(false);
  const [hover, setHover] = useState(false);
  const [drug, setDrug] = useState('');

  const handleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  const handleClickAway = () => {
    setDropdown(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={classes.dropdownContainer}
        style={
          styleData && {
            width: openDropdown ? '100%' : styleData.width,
            marginRight: styleData.marginRight,
            marginLeft: styleData.marginLeft,
          }
        }
      >
        <div
          // className={classes.dropdownHeader}
          onClick={handleDropdown}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '30px',
            border: '1px solid #2BB670',
            textAlign: 'center',
            width: '100%',
            borderRadius: 9,
            cursor: 'pointer',
            alignItems: 'center',
            wordBreak: 'break-word',
            backgroundColor: openDropdown ? '#2BB670' : '#EEFBF5',
            color: openDropdown ? '#fff' : '#000',
          }}
        >
          <p className={classes.ddHeadingText}>
            <p style={subheadingTextStyle ? subheadingTextStyle : {}}>
              {diseaseData.name}
            </p>
          </p>

          <img
            src={LightGreenArrowIcon}
            alt='green arrow drop down'
            style={arrowImgstyle ? arrowImgstyle : {}}
          />
        </div>
        {(diseaseData.description || diseaseData.Drugs) && (
          <div>
            <Collapse in={openDropdown}>
              <Paper className={classes.dropdownBody} elevation={0}>
                <div className={classes.drugcontBodyWrap}>
                  <p>{diseaseData.description}</p>

                  {/* {Object.keys(diseaseData).includes('Drugs') && (
                    <>
                      <HoverCard.Root openDelay={100}>
                        <h4
                          style={{ fontSize: '14px', margin: '10px 0 4px 0' }}
                        >
                          Drugs
                        </h4>
                        {diseaseData.Drugs.map((item, index) => (
                          <div key={index}>
                            <div onMouseEnter={() => setDrug(item.name)}>
                              <HoverCard.Trigger>{item.name}</HoverCard.Trigger>
                            </div>
                            {item.name === drug && (
                              <HoverCard.Portal>
                                <HoverCard.Content
                                  style={{ zIndex: 900 }}
                                  side='right'
                                >
                                  <div
                                    style={{
                                      minHeight: 200,
                                      maxHeight: 400,
                                      width: 300,
                                      overflow: 'scroll',
                                      backgroundColor: 'white',
                                      border: '1px solid black',
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: 'sticky',
                                        top: 0,
                                        backgroundColor: '#2BB670',
                                        padding: 10,
                                      }}
                                    >
                                      {item.name}
                                    </div>
                                    <div
                                      style={{
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {item.text}{' '}
                                      <a
                                        style={{ display: 'block' }}
                                        href={item.url}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                      >
                                        Read More
                                      </a>
                                    </div>
                                  </div>
                                </HoverCard.Content>
                              </HoverCard.Portal>
                            )}
                          </div>
                        ))}
                      </HoverCard.Root>
                    </>
                  )} */}
                </div>
              </Paper>
            </Collapse>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

const AlzheimersTreatment = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:600px)');
  const data = {
    mainTitleText: 'Treatment',
    mainTitleNumber: '4',
    arrowTitle: 'Declining Cognitive Functions',
    stages: [
      'Mild 1',
      'Moderate (4-5)',
      'Severe (6-7)',
      //   "Stage 4: Blocked Artery",
    ],
    disease: [
      {
        name: 'Cholinesterase inhibitors (ChEI)',
        description:
          "These drugs reduce behaviour and cognitive symptoms in mild to severe Alzheimer's. Inhibiting the enzyme cholinesterase decreases the metabolism of Acetylcholine, and increases its concentration. Acetylcholine is a key neurotransmitter for sending signals between neurons. ",
        Drugs: [
          {
            name: 'Donepezil (Approved for all stages)',
            text: 'Donepezil is used to treat dementia (a brain disorder that affects the ability to remember, think clearly, communicate, and perform daily activities and may cause changes in mood and personality) in people who have Alzheimer"s disease (AD; a brain disease that slowly destroys the memory and the ability to think, learn, communicate and handle daily activities). Donepezil is in a class of medications called cholinesterase inhibitors. It improves mental function (such as memory, attention, the ability to interact with others, speak, think clearly, and perform regular daily activities) by increasing the amount of a certain naturally occurring substance in the brain. Donepezil may improve the ability to think and remember or slow the loss of these abilities in people who have AD. However, donepezil will not cure AD or prevent the loss of mental abilities at some time in the future.',
            url: 'https://medlineplus.gov/druginfo/meds/a697032.html',
          },
          {
            name: 'Rivastigmine (Stage 2 & 3)',
            text: 'Rivastigmine is used to treat dementia (a brain disorder that affects the ability to remember, think clearly, communicate, and perform daily activities and may cause changes in mood and personality) in people with Alzheimer"s disease (a brain disease that slowly destroys the memory and ability to think, learn, communicate and handle daily activities). Rivastigmine is also used to treat dementia in people with Parkinson"s disease (a brain and nervous system disease with symptoms of slowing of movement, muscle weakness, shuffling walk, and loss of memory). Rivastigmine is in a class of medications called cholinesterase inhibitors. It improves mental function (such as memory and thinking) by increasing the amount of a certain natural substance in the brain.',
            url: 'https://medlineplus.gov/druginfo/meds/a602009.html',
          },
          {
            name: 'Galantamine (Stage 2 & 3)',
            text: 'Galantamine is used to treat the symptoms of Alzheimer"s disease (AD; a brain disease that slowly destroys the memory and the ability to think, learn, communicate and handle daily activities). Galantamine is in a class of medications called acetylcholinesterase inhibitors. It works by increasing the amount of a certain natural substance in the brain that is needed for memory and thought. Galantamine may improve the ability to think and remember or slow the loss of these abilities in people who have AD. However, galantamine will not cure AD or prevent the loss of mental abilities at some time in the future.',
            url: 'https://medlineplus.gov/druginfo/meds/a699058.html',
          },
          ,
        ],
      },
      {
        name: 'NMDA Antagonist',
        description:
          'NMDA receptors play an important role in learning and memory. These antagonists inhibit the action of glutamate as glutamate overstimulation results in neuronal damage in AD.',
        Drugs: ['Memantine'],
      },
      {
        name: 'Aducanumab',
        description:
          'Targets amyloid beta plaque formation which is a hallmark of AD. Accumulation of amyloid plaques has cytotoxic effect on neurons. However, this drug has shown very little improvement in cognitive function. ',
        Drugs: ['Aducanumab (2021)'],
      },
      {
        name: 'Lecanemab',
        description:
          'Targets amyloid beta plaque formation which is a hallmark of AD. Accumulation of amyloid plaques has cytotoxic effect on neurons. However, this drug has shown very little improvement in cognitive function. ',
        Drugs: ['Aducanumab (2021)'],
      },
      {
        name: 'Anticonvulsants',
        // description:
        //   "Used in Afib patients who are at risk for stroke and during a heart attack or stroke. A plaque rupture may lead to thrombosis (blood clot). Anticoagulants may break up the blood clot and relieve clogging of the artery. ",
        Drugs: ['Benzodiazepines & Valproate'],
      },
      {
        name: 'Anti Anxiety',
        // description:
        //   "Individuals with elevated triglyceride levels benefit from fibrates. Fibrates inhibits the release of triglycerides from liver. It also increases the synthesis of lipoprotein lipase which is responsible for the breakdown of triglycerides.",
        Drugs: ['Trazodone & Zolpidem (Effective hypnotics)'],
      },
      {
        name: 'Depression',
        // description:
        //   "These drugs are given to prevent reabsorption of bile salts into the enterohepatic circulation. This leads to the depletion of bile salts thereby increasing the metabolism of cholesterol for bile salt synthesis. Hence, reducing LDL levels in plasma",
        Drugs: ['Citalopram (Serotonin reuptake inhibitors)'],
      },
      {
        name: 'Dyspnea (Oxygen)',
        // Drugs:""
      },

      {
        name: 'Antibiotics (Pneumonia)',
        // description:
        //   "Angiography is performed to see where exactly the blockage is present. Once the location of the blockage is known, a catheter (long thin tube) with a deflated balloon at one end is inserted into the blocked artery. The balloon is then inflated leading to compression of the plaque.  ",
      },
      {
        name: 'Painkillers (Pain)',
        // description:
        //   "This technique is used to treat coronary artery disease. In this process, the blood vessels from the leg or sometimes from the chest are used as a graft and attached to the blocked coronary artery in such a way that one end of the graft is above the blockage and another end is placed below. This allows blood to flow around the blocked artery. ",
      },
    ],
    // arrowTitle: "Declining FEV1 & SPO2 (<94%)",
  };

  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      <div className={classes.main}>
        <div style={{ display: 'flex' }}>
          <div className={classes.bigArrowCont}>
            <div class={classes.arrow}>
              <div class={classes.arrowtail}>
                <p>{data.arrowTitle}</p>
              </div>
              <div class={classes.arrowpoint}></div>
            </div>
          </div>
        </div>
        <Grid container spacing={2}>
          {data.stages.map((data, i) => (
            <Grid item xs={4} md={4}>
              <div className={classes.greenStageBox} style={{ padding: 5 }}>
                <p>{data}</p>
              </div>
            </Grid>
          ))}
        </Grid>
        <div className={classes.darkGrayBar}>
          <p>Cognitive Enancers</p>
        </div>

        <div className={classes.grayBoxBarMainWrap}>
          <DiseaseDropdown diseaseData={data.disease[0]} />
          <DiseaseDropdown
            diseaseData={data.disease[1]}
            styleData={{ width: '80%', marginLeft: 'auto' }}
          />
          <div
            className={classes.darkGrayBar}
            style={{
              width: '30%',
              marginRight: 'auto',
              minHeight: '45px',
              marginBottom: 0,
            }}
          >
            <p>Disease Modifying Immunotherapy</p>
          </div>
          <DiseaseDropdown
            diseaseData={data.disease[2]}
            styleData={{ width: '30%', marginRight: 'auto' }}
          />
          <DiseaseDropdown
            diseaseData={data.disease[3]}
            styleData={{ width: '30%', marginRight: 'auto' }}
          />

          <div
            className={classes.darkGrayBar}
            style={{
              width: '70%',
              marginLeft: 'auto',
              marginBottom: 0,
            }}
          >
            <p>Treatment of Specific Symptoms</p>
          </div>
          <DiseaseDropdown
            diseaseData={data.disease[4]}
            styleData={{ width: '70%', marginLeft: 'auto' }}
          />
          <DiseaseDropdown
            diseaseData={data.disease[5]}
            styleData={{ width: '70%', marginLeft: 'auto' }}
          />
          <DiseaseDropdown
            diseaseData={data.disease[6]}
            styleData={{
              width: '50%',
              marginLeft: 'auto',
            }}
          />
          <DiseaseDropdown
            diseaseData={data.disease[7]}
            styleData={{
              width: '50%',
              marginLeft: 'auto',
            }}
          />
          <DiseaseDropdown
            diseaseData={data.disease[8]}
            styleData={{
              width: '50%',
              marginLeft: 'auto',
            }}
          />
          <DiseaseDropdown
            diseaseData={data.disease[9]}
            styleData={{
              width: '50%',
              marginLeft: 'auto',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AlzheimersTreatment;
