import React from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';

const TrialsMobileCard = () => {
  return (
    <div
      style={{
        height: 250,
        width: 300,
        border: '2px solid black',
        borderRadius: 20,
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        margin: 20,
      }}
    >
      <div
        style={{
          padding: '5px 20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid black',
        }}
      >
        <div style={{ fontWeight: 'bold', fontSize: 20 }}>Lumigan</div>
        <div style={{}}>Allergan</div>
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          paddingRight: 20,
          paddingLeft: 20,
        }}
      >
        <div style={{ fontWeight: 'bold' }}>
          <div>Class: Prostaglandin Analog</div>
          <div>Drug: Oral Delivery</div>
        </div>
        <div>
          <div style={{ fontWeight: 'bold' }}>
            <DateRangeIcon /> Phase II started 3/2019
          </div>
          <div style={{}}>
            <PeopleOutlineIcon /> 600
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              width: '47%',
              textAlign: 'center',
              border: '1px solid #1f72df',
              borderRadius: 20,
              backgroundColor: '#e9f7ff',
            }}
          >
            Read More
          </div>
          <div
            style={{
              width: '47%',
              textAlign: 'center',
              border: '1px solid black',
              borderRadius: 20,
              backgroundColor: '#f3f3f3',
            }}
          >
            Website
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrialsMobileCard;
