import React, { useEffect, useState } from 'react';
import { makeStyles, Paper, Collapse, Typography } from '@material-ui/core';
import CareTeamArrow from '../../../assets/img/CareTeamArrow.svg';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCareTeamData } from '../../../reducers/PatientJourneyReducer';
import DropdownBox from './MUI/DropdownBox';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: 0,
    },
    marginBottom: 50,
  },
  main: {
    position: 'relative',
    // marginTop: '40px',
  },
  arrow: {
    height: '100%',
    position: 'absolute',
    top: '33px',
    left: '50px',
  },

  arrowtail: {
    marginTop: '14px',
    width: '6px',
    background: '#696A6C',
    height: '100%',
  },

  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '24px solid #696A6C',
    borderRight: '12px solid transparent',
    borderLeft: '12px solid transparent',

    marginLeft: '-9px',
  },
  subTitleBox: {
    backgroundColor: '#EEF0F0',
    border: '2px solid #696A6C',
    borderRadius: '9px',
    width: 125,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  subTitleText: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: '14px',
  },

  boxCont: {
    display: 'flex',
    marginTop: '42px',
    marginLeft: '30px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
    },
  },
  circle: {
    width: '47px',
    border: '2px solid #2B979E',
    height: '45px',
    display: 'flex',
    zIndex: 99,
    background: '#EFFAFB',
    alignItems: 'center',
    fontWeight: 'bold',
    borderRadius: '50%',
    justifyContent: 'center',
    position: 'relative',
    '&:first-child:before': {
      content: '" "',
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '42px',
      height: '42px',
      left: 35,
    },
  },
  branchBarWrap: {
    width: '68px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '48px',
    },
  },
  branchBar: {
    width: '100%',
    border: '2px solid #696A6C',
    height: '3px',
    backgroundColor: '#696A6C',
    position: 'absolute',
    top: '22px',
  },
  dropdownContainer: {
    backgroundColor: '#EFFAFB',
    width: '280px',
    borderRadius: 9,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '250px',
      minWidth: '200px',
      width: 'unset',
    },
  },
  dropdownHeader: {
    display: 'flex',
    border: '2px solid #2B979E',
    textAlign: 'center',
    width: '280px',
    borderRadius: 9,
    cursor: 'pointer',
    position: 'relative',
    // '& img': {
    //   position: 'absolute',
    //   right: '10px',
    //   top: '40%',
    // },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '250px',
      minWidth: '200px',
      width: 'unset',
      // '& img': {
      //   top: '20%',
      // },
    },
  },
  dropdownBody: {
    backgroundColor: '#EFFAFB',
    padding: '0.5rem 1rem',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },
  dropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
  sometext: {
    marginTop: 0,
    marginBottom: '4px',
    fontSize: 14,
  },
  headingText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '4px',
  },
  timelineAppBar: {
    height: '100%',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '2px solid #2B979E',
    margin: '0 20px 0 0',
    background: '#EFFAFB',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2B979E',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#EFFAFB',
    borderRadius: '200px',
    border: '2px solid #2B979E',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#2B979E',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },
}));

const CareTeamFlowchart = ({ noFloat, noNumber }) => {
  const { diseaseName, diseaseId } = useParams();
  const classes = useStyles();
  const patientJourney = useSelector((state) => state.patientJourney);
  const { careTeam } = patientJourney;
  const careTeamLength = Object.keys(careTeam).length;
  const dispatch = useDispatch();

  console.log(careTeam);

  useEffect(() => {
    dispatch(setCareTeamData(diseaseId));
  }, []);

  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      {/* <div className={classes.timelineAppBar}>
        <div className={classes.timelineHeading}>
          {!noNumber && (
            <div className={classes.timelineCircleBG}>
              {careTeam.TitleNumber}
            </div>
          )}

          <div className={classes.timelineTitle}>{careTeam.TopmainTitle}</div>
        </div>
      </div> */}
      {careTeamLength !== 0 && (
        <div className={classes.main}>
          <div className={classes.subTitleBox}>
            <div className={classes.subTitleText}>{careTeam.SubtitleText}</div>
          </div>
          <div class={classes.arrow}>
            <div class={classes.arrowtail}></div>
            <div class={classes.arrowpoint}></div>
          </div>
          {careTeam.data.map((b, i) => (
            <div className={classes.boxCont} key={i}>
              <div className={classes.circle}>
                <p>{b.circleTitle}</p>
              </div>
              <div className={classes.branchBarWrap}>
                <div className={classes.branchBar}></div>
              </div>
              <DropdownBox careTeam={b} />
              {/* <Dropdown data={b} /> */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CareTeamFlowchart;
