import React from 'react';
import { makeStyles, Grid, useMediaQuery } from '@material-ui/core';
import AsthmaDiagnosisMobile from './AsthmaDiagnosisMobile';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
    // border: "1px solid #000",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },

  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #C33C54',
    margin: '0 15px 0 0 ',
    background: '#FFEBEF',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#C33C54',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#FFEBEF',
    borderRadius: '200px',
    border: '1px solid #C33C54',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#C33C54',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },

  headingBox: {
    backgroundColor: '#FFEBEF',
    border: '1px solid #C33C54',
    width: '100%',
    height: 34,
    color: '#C33C54',
    fontSize: '14px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  blueBoxSmall: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#9FCEFC',
    height: '40px',
    width: '240px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  // Arrow
  arrow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
  },
  arrowtail: {
    background: '#C33C54',
    height: '20px',
    width: '1.5px',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '6px solid #C33C54',
    borderRight: '6px solid transparent',
    borderLeft: '6px solid transparent',
  },
  // branch
  branchWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  branchMain: {
    borderRight: '1.5px solid #C33C54',
    borderLeft: '1.5px solid #C33C54',
    borderTop: '1.5px solid #C33C54',
  },
  branchMiddleLine: {
    justifySelf: 'center',
    background: '#C33C54',
  },
  redBorderGrayBox: {
    width: '144px',
    minHeight: '24px',
    borderRadius: '15px',
    border: '1px solid #C33C54',
    backgroundColor: '#EEF0F0',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      fontSize: '9px',
    },
  },
  GreenBox: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#2BB670',
    height: '30px',
    width: '174px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // marginTop: "103px",
    // marginLeft: "40px",
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      fontSize: '10px',
      //   left: "-18%",
    },
  },

  redBoldTextBig: {
    color: '#C33C54',
    fontWeight: 'bold',
    fontSize: '20px',
    margin: '10px 0',
  },
}));

const Arrow = ({ tailHeight, tailWidth, arrowPointStyle, arrowMainStyle }) => {
  const classes = useStyles();
  return (
    <div class={classes.arrow} style={arrowMainStyle}>
      <div class={classes.arrowtail} style={{ height: tailHeight }}></div>
      <div class={classes.arrowpoint}></div>
    </div>
  );
};

const Branch = ({
  branchStyle,
  middleLineStyle,
  branchClassName,
  middleLineClassName,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.branchWrap}>
      <div className={classes.branchMiddleLine} style={middleLineStyle}></div>
      <div className={classes.branchMain} style={branchStyle}></div>
    </div>
  );
};

const AsthmaDiagnosis = ({ noFloat, noNumber }) => {
  const classes = useStyles();

  const data = { mainTitleText: 'Diagnosis', mainTitleNumber: '3' };
  const matches = useMediaQuery('(max-width:600px)');
  return isMobile ? (
    <AsthmaDiagnosisMobile />
  ) : (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      {/* <div className={classes.timelineAppBar}>
        <div className={classes.timelineHeading}>
          {!noNumber && (
            <div className={classes.timelineCircleBG}>
              {data.mainTitleNumber}
            </div>
          )}
          <div className={classes.timelineTitle}>{data.mainTitleText}</div>
        </div>
      </div> */}
      <div style={{ marginTop: '30px', borderBottom: '1px solid #000' }}>
        <p className={classes.redBoldTextBig}>Screening Test</p>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={2} lg={1}>
          <div style={{ position: 'relative' }}>
            <div
              className={classes.headingBox}
              style={{
                marginTop: '20px',
                height: '280px',
                width: '40px',
              }}
            >
              <p style={{ transform: 'rotate(270deg)' }}>Screening</p>
            </div>
            <div
              className={classes.headingBox}
              style={{
                marginTop: '20px',
                height: '490px',
                width: '40px',
              }}
            >
              <p style={{ transform: 'rotate(270deg)', width: '100px' }}>
                Confirmatory
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={10} lg={11}>
          <div style={{ position: 'relative' }}>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '4px',
                borderBottom: '1px solid #000',
                top: '300px',
              }}
            ></div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              <div>
                <div className={classes.blueBoxSmall}>
                  <p>Forced Spirometry Test</p>
                </div>
                <Branch
                  middleLineStyle={{
                    width: '1.5px',
                    height: '26px',
                  }}
                  branchStyle={{
                    width: matches ? '70%' : '100%',
                    height: '30px',
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    position: 'relative',
                  }}
                >
                  <div>
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        width: '130px',
                        marginLeft: matches ? '-30px' : '-60px',
                      }}
                    >
                      <p>
                        FVC = normal or decreased FEV1 = decreased FEV1/FVC
                        {` <0.7`}
                      </p>
                    </div>
                    <Arrow
                      arrowMainStyle={{
                        left: matches ? '30px' : '0px',
                        top: matches ? '60px' : '80px',
                      }}
                      tailHeight={121}
                    />
                  </div>
                  <div style={{ position: 'relative' }}>
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        width: '130px',
                        height: '80px',
                        position: 'absolute',
                        left: matches ? '-120px' : '-50px',
                      }}
                    >
                      <p>FVC = normal FEV1 = normal FEV1/FVC {`>0.7`}</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{
                        right: matches ? '30px' : '0px',
                        top: '80px',
                      }}
                      tailHeight={59}
                    />
                    <div
                      className={classes.GreenBox}
                      style={{
                        position: 'absolute',
                        left: '-70px',
                        top: '145px',
                      }}
                    >
                      <p>Asthma: Negative</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '135px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '10px',
                }}
              >
                <div>
                  <div
                    className={classes.blueBoxSmall}
                    style={{
                      width: matches ? '250px' : '400px',
                      marginLeft: matches ? '50px' : '0',
                    }}
                  >
                    <p>Spirometry Test (Pre and Post bronchodilator)</p>
                  </div>
                  <Branch
                    middleLineStyle={{
                      width: '1.5px',
                      height: '26px',
                      marginLeft: '-20px',
                    }}
                    branchStyle={{
                      width: '65%',
                      height: '30px',
                      marginLeft: '-20px',
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      position: 'relative',
                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      <div
                        className={classes.redBorderGrayBox}
                        style={{
                          width: '130px',
                          position: 'absolute',
                          left: '10px',
                        }}
                      >
                        <p>FEV1 increase of ≥200 mL and ≥15%</p>
                      </div>
                      <Arrow
                        arrowMainStyle={{
                          left: matches ? '20px' : '50px',
                          top: matches ? '45px' : '41px',
                        }}
                        tailHeight={matches ? 290 : 293}
                      />
                    </div>

                    <div style={{ position: 'relative' }}>
                      <div
                        className={classes.redBorderGrayBox}
                        style={{
                          width: '130px',
                          position: 'absolute',
                          right: matches ? '0px' : '35px',
                        }}
                      >
                        <p>FEV1 not increased by ≥200 mL and ≥15%</p>
                      </div>
                      <Arrow
                        arrowMainStyle={{
                          left: matches ? '210px' : '160px',
                          top: matches ? '45px' : '41px',
                        }}
                        tailHeight={matches ? 80 : 87}
                      />
                      <div style={{ marginTop: '134px' }}>
                        <div
                          className={classes.blueBoxSmall}
                          style={{
                            width: matches ? '240px' : '280px',
                            height: '50px',
                          }}
                        >
                          <p>
                            Bronchial Hyperresponsive study/Methacholine
                            Challenge Test
                          </p>
                        </div>
                        <Branch
                          middleLineStyle={{
                            width: '1.5px',
                            height: '26px',
                          }}
                          branchStyle={{
                            width: matches ? '75%' : '80%',
                            height: '30px',
                          }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            position: 'relative',
                          }}
                        >
                          <div>
                            <div
                              className={classes.redBorderGrayBox}
                              style={{
                                width: '100px',
                                marginLeft: matches ? '0px' : '-30px',
                                height: '45px',
                              }}
                            >
                              <p>PC20 ≤ 8 mg/mL</p>
                            </div>
                            <div
                              style={{
                                position: 'absolute',
                                borderBottom: '1.5px solid #C33C54',
                                width: '60px',
                                // height: "40px",
                                right: '-80px',
                                top: '25px',
                              }}
                            ></div>
                            <div
                              style={{
                                position: 'absolute',
                                borderTop: '1.5px solid #C33C54',
                                width: '80px',
                                // height: "40px",
                                right: '-80px',
                                top: '-75px',
                              }}
                            ></div>
                            <div
                              style={{
                                position: 'absolute',
                                borderRight: '1.5px solid #C33C54',
                                height: '100px',
                                // height: "40px",
                                right: '-80px',
                                top: '-75px',
                              }}
                            ></div>
                            <div
                              style={{
                                position: 'absolute',
                                right: '-70px',
                                top: '-65px',
                                width: '70px',
                              }}
                            >
                              <p style={{ fontSize: '8px' }}>
                                (Repeat after discontinuing corticosteroids and
                                leukotriene antagonists)
                              </p>
                            </div>
                            <Arrow
                              arrowMainStyle={{
                                left: matches ? '30px' : '20px',
                                top: '45px',
                              }}
                              tailHeight={50}
                            />
                            <div
                              className={classes.GreenBox}
                              style={{
                                marginTop: '55px',
                                marginLeft: matches ? '-60px' : '-150px',
                                width: matches ? '180px' : '250px',
                                backgroundColor: '#C33C54',
                                border: '1px solid #FFEBEF',
                                color: '#fff',
                              }}
                            >
                              <p>Asthma: Positive</p>
                            </div>
                          </div>
                          <div style={{ position: 'relative' }}>
                            <div
                              className={classes.redBorderGrayBox}
                              style={{
                                width: '130px',
                                height: '45px',
                                position: 'absolute',
                                right: matches ? '0px' : '-20px',
                              }}
                            >
                              <p>PC20 {`>8`} mg/mL</p>
                            </div>
                            <Arrow
                              arrowMainStyle={{
                                left: matches ? '-30px' : '-35px',
                                top: '44px',
                              }}
                              tailHeight={50}
                            />
                            <div
                              className={classes.GreenBox}
                              style={{
                                position: 'absolute',

                                top: '100px',
                                right: matches ? '0px' : '-20px',
                              }}
                            >
                              <p>Asthma: Negative</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AsthmaDiagnosis;
