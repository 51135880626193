import React, { useEffect, useState } from 'react';
import { makeStyles, Paper, Collapse, Typography } from '@material-ui/core';
import CareTeamArrow from '../../../assets/img/CareTeamArrow.svg';
import { setMonitoringData } from '../../../reducers/PatientJourneyReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DropdownBox from './MUI/DropdownBox';
import OvalDiv from './MUI/OvalDiv';
// import CareTeamArrow from "../../assets/assets/img/img/CareTeamArrow.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow:
        'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: 0,
    },
    marginBottom: 50,
  },
  main: {
    position: 'relative',
    marginTop: '40px',
  },
  arrow: {
    height: '100%',
    position: 'absolute',
    top: '33px',
    left: '52px',
  },

  arrowtail: {
    marginTop: '14px',
    width: '6px',
    background: '#696A6C',
    height: '100%',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '24px solid #696A6C',
    borderRight: '12px solid transparent',
    borderLeft: '12px solid transparent',
    marginLeft: '-9px',
  },
  subTitleBox: {
    backgroundColor: '#EEF0F0',
    border: '2px solid #696A6C',
    borderRadius: '9px',
    width: 125,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  subTitleText: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: '14px',
  },

  boxCont: {
    display: 'flex',
    marginTop: '42px',
    marginLeft: '30px',
  },
  circle: {
    width: 100,
    border: '2px solid #696A6C',
    height: '50px',
    display: 'flex',
    zIndex: 99,
    background: '#EEF0F0',
    alignItems: 'center',
    fontWeight: 'bold',
    borderRadius: 20,
    justifyContent: 'center',
    position: 'relative',
    marginLeft: -20,
    '&:first-child:before': {
      content: '" "',
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: 100,
      height: 40,
      left: '6px',
    },
  },
  branchBarWrap: {
    width: '68px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '38px',
    },
  },
  branchBar: {
    width: '100%',
    border: '2px solid #696A6C',
    height: '3px',
    backgroundColor: '#696A6C',
    position: 'absolute',
    top: '22px',
  },
  dropdownContainer: {
    // backgroundColor: "#674EA7",
    width: '100%',
    borderRadius: 9,
    marginBottom: '1.05rem',
  },
  dropdownHeader: {
    border: '2px solid #674EA7',
    backgroundColor: '#674EA7',
    color: 'white',
    width: '100%',
    borderRadius: 9,
    cursor: 'pointer',
    padding: '10px 12px',
    '& p': {
      margin: '0 !important',
    },
    display: 'flex',
    justifyContent: 'space-between',
  },
  dropdownBody: {
    backgroundColor: '#F3F1F8',
    padding: '0.5rem 1rem',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },
  dropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
  sometext: {
    marginTop: 0,
    marginBottom: '4px',
    fontSize: 14,
  },
  headingText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '4px',
  },
  subHeadingText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: '4px',
    marginTop: '0',
  },
  boxContainer: {
    width: '292px',
  },
  boxContainerHeader: {
    backgroundColor: '#674EA7',
    border: '2px solid #674EA7',
    borderRadius: '9px',
    textAlign: 'center',
    color: 'white',
  },
  boxBody: {
    backgroundColor: '#F3F1F8',
    border: '2px solid #674EA7',
    width: '100%',
    // minHeight: '100%',
    borderBottomLeftRadius: '9px',
    borderBottomRightRadius: '9px',
    padding: '1.25rem 1rem',
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '2px solid #674EA7',
    margin: '0 20px',
    background: '#F3F1F8',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#674EA7',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#F3F1F8',
    borderRadius: '200px',
    border: '2px solid #674EA7',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#674EA7',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },
  monitoringBoxContainerHeader: {
    backgroundColor: '#674EA7',
    border: '2px solid #674EA7',
    borderTopLeftRadius: '9px',
    borderTopRightRadius: '9px',
    textAlign: 'center',
    color: 'white',
  },
}));

const HeyDropdownBox = ({ itemData }) => {
  const classes = useStyles();
  const data = itemData;
  const [openDropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  return (
    <div className={classes.dropdownContainer}>
      <div className={classes.dropdownHeader} onClick={handleDropdown}>
        <p className={classes.headingText}>{data.subtitle}</p>
        <img src={CareTeamArrow} alt='arrow drop down' />
      </div>
      <Collapse in={openDropdown}>
        <Paper className={classes.dropdownBody}>
          <Typography>
            <p className={classes.dropDownbodyText}>
              <b>What is measured: </b>
              {data.what}
            </p>
            <p className={classes.dropDownbodyText}>
              <b>Why:</b>
              {data.why}
            </p>
            <p className={classes.dropDownbodyText}>
              <b>Scale:</b>
              <br />
              {data.scale}
            </p>
          </Typography>
        </Paper>
      </Collapse>
    </div>
  );
};

const BoxContainer = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.boxContainer}>
      <div className={classes.boxContainerHeader}>
        <p className={classes.headingText}>{data.boxTitle}</p>
        <p className={classes.subHeadingText}>{data.statement}</p>
      </div>
      <div className={classes.boxBody}>
        {data.items.map((subItem, i) => {
          return <DropdownBox itemData={subItem} key={i} />;
        })}
      </div>
    </div>
  );
};

const MonitoringFlowchart = ({ noFloat, noNumber }) => {
  const { diseaseName, diseaseId } = useParams();
  const classes = useStyles();
  const patientJourney = useSelector((state) => state.patientJourney);
  const { monitoring } = patientJourney;
  const monitoringLength = Object.keys(monitoring).length;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMonitoringData(diseaseId));
  }, []);

  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      {/* <div className={classes.timelineAppBar}>
        <div className={classes.timelineHeading}>
          {!noNumber && (
            <div className={classes.timelineCircleBG}>
              {monitoring.TitleNumber}
            </div>
          )}

          <div className={classes.timelineTitle}>{monitoring.TopmainTitle}</div>
        </div>
      </div> */}
      {monitoringLength !== 0 && (
        <div className={classes.main}>
          <div className={classes.subTitleBox}>
            <div className={classes.subTitleText}>
              {monitoring.arrowBoxTilte}
            </div>
          </div>
          <div class={classes.arrow}>
            <div class={classes.arrowtail}></div>
            <div class={classes.arrowpoint}></div>
          </div>
          {monitoring.data.map((b, i) => (
            <div className={classes.boxCont} key={i}>
              <div className={classes.circle} style={{ textAlign: 'center' }}>
                <p>{b.circleTitle}</p>
              </div>
              <div className={classes.branchBarWrap}>
                <div className={classes.branchBar}></div>
              </div>
              {/* <HeyDropdownBox data={b} /> */}
              <div className={classes.boxContainer}>
                <div className={classes.monitoringBoxContainerHeader}>
                  {/* {b.boxTitle && ( */}
                  <p
                    className={classes.headingText}
                    style={{ marginBottom: 10 }}
                  >
                    {b.boxTitle}
                  </p>
                  {/* )} */}
                  {/* {b.statement && ( */}
                  <p
                    className={classes.subHeadingText}
                    style={{ marginBottom: 10 }}
                  >
                    {b.statement}
                  </p>
                  {/* )} */}
                </div>
                <div className={classes.boxBody}>
                  {b.items.map((subItem, i) => {
                    return <DropdownBox monitoring={subItem} />;
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MonitoringFlowchart;
