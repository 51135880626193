import React from 'react';
import { makeStyles } from '@material-ui/core';
import AtherosclerosisDiagnosisScreeningTest from './AtherosclerosisDiagnosisScreeningTest';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
    // border: "1px solid #000",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  headingBox: {
    backgroundColor: '#FFEBEF',
    border: '1px solid #C33C54',
    width: '100%',
    height: 34,
    color: '#C33C54',
    fontSize: '14px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  blueBoxSmall: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#9FCEFC',
    height: '50px',
    width: '100%',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  // Arrow
  arrow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
  },
  arrowtail: {
    background: '#C33C54',
    height: '20px',
    width: '1.5px',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '6px solid #C33C54',
    borderRight: '6px solid transparent',
    borderLeft: '6px solid transparent',
  },
  // branch
  branchWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  branchMain: {
    borderRight: '1.5px solid #C33C54',
    borderLeft: '1.5px solid #C33C54',
    borderTop: '1.5px solid #C33C54',
  },
  branchMiddleLine: {
    justifySelf: 'center',
    background: '#C33C54',
  },
  redBorderGrayBox: {
    width: '108px',
    height: '64px',
    borderRadius: '7px',
    border: '1px solid #C33C54',
    backgroundColor: '#EEF0F0',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    textAlign: 'center',
    padding: '1rem',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      fontSize: '12px',
    },
  },
  GreenBox: {
    color: '#fff',
    fontSize: '14px',
    backgroundColor: '#2BB670',
    width: '174px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.25rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '110px',
      fontSize: '12px',
    },
  },
  toplevelWrap: {
    display: 'flex',
    width: '340px',
    justifyContent: 'space-between',
  },
  smallRedBorderBox: {
    width: '140px',
    fontSize: '13px',
    height: '50px',
    borderRadius: '7px',
    border: '1px solid #C33C54',
    backgroundColor: '#EEF0F0',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '120px',
      fontSize: '10px',
    },
  },
}));

const Arrow = ({ tailHeight, tailWidth, arrowPointStyle, arrowMainStyle }) => {
  const classes = useStyles();
  return (
    <div class={classes.arrow} style={arrowMainStyle}>
      <div class={classes.arrowtail} style={{ height: tailHeight }}></div>
      <div class={classes.arrowpoint}></div>
    </div>
  );
};

const Branch = ({
  branchStyle,
  middleLineStyle,
  branchClassName,
  middleLineClassName,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.branchWrap}>
      <div className={classes.branchMiddleLine} style={middleLineStyle}></div>
      <div className={classes.branchMain} style={branchStyle}></div>
    </div>
  );
};

const AtherosclerosisDiagnosis = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const data = {
    pageTitle: 'Atherosclerosis: Diagnosis',
    testName: 'Confirmatory test',
    level1: [{ text: 'CT Angiography' }, { text: 'Doppler Ultrasound' }],
    level2: [{ text: 'Presence or absence of clot' }],
    level3: [{ text: 'Looking for CAD,PAD and RAS' }],
    level4: [
      { text: 'Intravascular Ultrasound(IVUS)' },
      { text: 'Intracoronary Optical Coherence Tomography(IOCT)' },
    ],
    level5: [
      {
        text: 'Imaging techniques to confirm plaque formation/risk of heart disease',
      },
    ],
  };
  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      <AtherosclerosisDiagnosisScreeningTest />
      <h2>{data.pageTitle}</h2>
      <div className={classes.headingBox} style={{ marginTop: '40px' }}>
        <p> {data.testName}</p>
      </div>
      <Branch
        middleLineStyle={{
          width: '1.5px',
          height: '26px',
        }}
        branchStyle={{
          width: '230px',
          height: '50px',
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={classes.toplevelWrap}>
          <div className={classes.redBorderGrayBox}>{data.level1[0].text}</div>
          <div className={classes.redBorderGrayBox}>{data.level1[1].text}</div>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <Branch
          middleLineStyle={{
            width: '0px',
            height: '0px',
          }}
          branchStyle={{
            width: '230px',
            height: '50px',
            borderBottom: '1.5px solid #C33C54',
            borderTop: '0px',
            marginTop: '-0px',
          }}
        />
        <Arrow arrowMainStyle={{ left: '49%', top: '49px' }} tailHeight={50} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className={classes.smallRedBorderBox}
            style={{
              marginTop: '55px',
            }}
          >
            <p>{data.level2[0].text}</p>
          </div>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <Arrow arrowMainStyle={{ left: '49%', top: '-54px' }} tailHeight={49} />
        <div
          className={classes.smallRedBorderBox}
          style={{
            margin: '54px auto 0 auto',
            backgroundColor: '#C33C54',
            color: 'white',
          }}
        >
          <p>{data.level3[0].text}</p>
        </div>
      </div>
      <Branch
        middleLineStyle={{
          width: '1.5px',
          height: '26px',
        }}
        branchStyle={{
          width: '230px',
          height: '50px',
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={classes.toplevelWrap} style={{ width: '360px' }}>
          <div
            className={classes.redBorderGrayBox}
            style={{ width: '150px', height: '70px' }}
          >
            {data.level4[0].text}
          </div>
          <div
            style={{ width: '150px', height: '70px' }}
            className={classes.redBorderGrayBox}
          >
            {data.level4[1].text}
          </div>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <Branch
          middleLineStyle={{
            width: '0px',
            height: '0px',
          }}
          branchStyle={{
            width: '230px',
            height: '50px',
            borderBottom: '1.5px solid #C33C54',
            borderTop: '0px',
            marginTop: '-0px',
          }}
        />
        <Arrow arrowMainStyle={{ left: '49%', top: '50px' }} tailHeight={48} />
      </div>
      <div className={classes.GreenBox} style={{ margin: '54px auto 0 auto' }}>
        <p>{data.level5[0].text}</p>
      </div>
    </div>
  );
};

export default AtherosclerosisDiagnosis;
