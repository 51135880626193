import { createSlice } from '@reduxjs/toolkit';
import SkeletonImage from '../assets/img/SkeletonImage.svg';

const PatientJourneyReducer = createSlice({
  name: 'patientJourney',
  initialState: {
    patientJourneyOverview: {},
    symptoms: {},
    classification: {},
    stages: {},
    careTeam: {},
    monitoring: {},
    outcomes: {},
    options: [],
    selectedOption: '',
  },
  reducers: {
    setPatientJourneyData(state, action) {
      state.patientJourneyOverview =
        action.payload === '120'
          ? {
              mainTitleNumber: '1',
              mainTitleText: 'Overview',
              columnHeading: [
                'Primary Organ or System',
                'Impacted System',
                'Complication / Sequela',
              ],
              columnOneData: [{ title: '', img: SkeletonImage }],
              columnRowData: [
                {
                  colTwoBoxTitle: 'Respiratory',
                  colThree: [{ title: 'Tuberculosis' }, { title: 'Pneumonia' }],
                },
                {
                  colTwoBoxTitle: 'GI (Salivary Glands)',
                  colThree: [
                    { title: 'Candidiasis' },
                    { title: 'Cytomegalovirus' },
                  ],
                },
                {
                  colTwoBoxTitle: 'Skin',
                  colThree: [
                    { title: 'Herpes Simplex' },
                    { title: 'Puritic Papular Eruptions' },
                    { title: 'HPV' },
                    { title: 'Warts' },
                  ],
                },
                {
                  colTwoBoxTitle: 'Liver',
                  colThree: [{ title: 'Hepatitis' }],
                },
              ],
            }
          : action.payload === '125'
          ? {
              mainTitleNumber: '1',
              mainTitleText: 'Overview',
              columnHeading: [
                'Primary Organ or System',
                'Impacted System',
                'Complication / Sequela',
              ],
              columnOneData: [{ title: 'Immune System' }],
              columnRowData: [
                {
                  colTwoBoxTitle: 'Brain',
                  colThree: [{ title: 'Confusion/Delirium' }],
                },
                {
                  colTwoBoxTitle: 'Heart',
                  colThree: [{ title: 'Inflammation' }],
                },
                {
                  colTwoBoxTitle: 'Cardiovascular',
                  colThree: [{ title: 'Clots' }, { title: 'Heart Attack' }],
                },
                {
                  colTwoBoxTitle: 'GI',
                  colThree: [{ title: 'Diarrhea' }],
                },
                {
                  colTwoBoxTitle: 'Immune System',
                  colThree: [{ title: 'MIS-C (children only)' }],
                },
                {
                  colTwoBoxTitle: 'Lungs',
                  colThree: [{ title: 'Pneumonia' }, { title: 'Dyspnea' }],
                },
                {
                  colTwoBoxTitle: 'Kidneys',
                  colThree: [{ title: 'Kidney Damage' }],
                },
                {
                  colTwoBoxTitle: 'Musculoskeletal',
                  colThree: [{ title: 'Chronic Fatigue' }],
                },
              ],
            }
          : action.payload === '121'
          ? {
              mainTitleNumber: '1',
              mainTitleText: 'Overview',
              columnHeading: [
                'Primary Organ or System',
                'Impacted System',
                'Complication / Sequela',
              ],
              columnOneData: [{ title: 'Lung' }],
              columnRowData: [
                {
                  colTwoBoxTitle: 'Upper Respiratory',
                  colThree: [{ title: 'Sinusitis' }],
                },
                {
                  colTwoBoxTitle: 'Lower Respiratory',
                  colThree: [{ title: 'Rhinitis' }],
                },
                {
                  colTwoBoxTitle: 'Lower Respiratory',
                  colThree: [
                    { title: 'Rhinitis' },
                    { title: 'Respiratory Infections' },
                  ],
                },
                {
                  colTwoBoxTitle: 'Stomach',
                  colThree: [{ title: 'GERD' }],
                },
              ],
            }
          : action.payload === '122'
          ? {
              mainTitleNumber: '1',
              mainTitleText: 'Overview',
              columnHeading: [
                'Primary Organ or System',
                'Impacted System',
                'Complication / Sequela',
              ],
              columnOneData: [{ title: 'Heart' }],
              columnRowData: [
                {
                  colTwoBoxTitle: 'Peripheral Artery Disease',
                  colThree: [{ title: 'Heart Attack' }],
                },
                {
                  colTwoBoxTitle: 'Aneurysm',
                  colThree: [{ title: 'Stroke' }],
                },
                {
                  colTwoBoxTitle: 'Kidney',
                  colThree: [{ title: 'Renal Failure' }],
                },
              ],
            }
          : action.payload === '130'
          ? {
              mainTitleNumber: '1',
              mainTitleText: 'Overview',
              columnHeading: [
                'Primary Organ or System',
                'Impacted System',
                'Complication / Sequela',
              ],
              columnOneData: [{ title: 'Brain' }],
              columnRowData: [
                {
                  colTwoBoxTitle: 'Brain',
                  colThree: [
                    { title: 'Depression', percent: '56%' },
                    { title: 'Anxiety' },
                    { title: 'Seizures' },
                    { title: 'Pneumonia' },
                    { title: 'Soft Tissue Infections (due to immobility)' },
                    { title: 'Urinary Tract Infections' },
                  ],
                },
              ],
            }
          : action.payload === '123'
          ? {
              mainTitleNumber: '1',
              mainTitleText: 'Overview',
              columnHeading: [
                'Primary Organ or System',
                'Impacted System',
                'Complication / Sequela',
              ],
              columnOneData: [{ title: 'Breast' }],
              columnRowData: [
                {
                  colTwoBoxTitle: 'Brain',
                  colThree: [{ title: 'Brain Metastasis' }],
                },
                {
                  colTwoBoxTitle: 'Breast',
                  colThree: [
                    { title: 'Lump in breast' },
                    { title: 'Nipple discharage' },
                    { title: 'Breast pain/tenderness' },
                    { title: 'Thickening or fibrocystic changes' },
                  ],
                },
                {
                  colTwoBoxTitle: 'Lymph nodes',
                  colThree: [{ title: 'Distant lymph nodes' }],
                },
                {
                  colTwoBoxTitle: 'Lung',
                  colThree: [{ title: 'Lung Metastasis' }],
                },
                {
                  colTwoBoxTitle: 'Liver',
                  colThree: [{ title: 'Liver Metastasis' }],
                },
                {
                  colTwoBoxTitle: 'Bone',
                  colThree: [{ title: 'Bone Metastasis' }],
                },
              ],
            }
          : action.payload === '124'
          ? {
              mainTitleNumber: '1',
              mainTitleText: 'Overview',
              columnHeading: [
                'Primary Organ or System',
                'Impacted System',
                'Complication / Sequela',
              ],
              columnOneData: [{ title: 'Lung' }],
              columnRowData: [
                {
                  colTwoBoxTitle: 'Respiratory',
                  colThree: [{ title: 'Wheezing' }, { title: 'Chronic Cough' }],
                },
                {
                  colTwoBoxTitle: 'Cardiovascular',
                  colThree: [{ title: 'Copious Sputum (Chr Bronchitis)' }],
                },
                {
                  colTwoBoxTitle: 'Adipose Tissue',
                  colThree: [{ title: 'Atherosclerosis' }],
                },
                {
                  colTwoBoxTitle: 'Circulatory System',
                  colThree: [{ title: 'Dyslipidimia' }],
                },
                {
                  colTwoBoxTitle: 'Kidneys',
                  colThree: [{ title: 'Hypertension' }],
                },
                {
                  colTwoBoxTitle: 'Eyes',
                  colThree: [{ title: 'Glycosuria' }, { title: 'Polyuria' }],
                },
                {
                  colTwoBoxTitle: 'Nervous System',
                  colThree: [{ title: 'Retinopathy' }],
                },
                {
                  colTwoBoxTitle: 'Heart',
                  colThree: [
                    { title: 'Nephropathy' },
                    { title: 'Polyphagia, Polydipsia' },
                    { title: 'Neuropathy' },
                    { title: 'Foot Ulcer' },
                    { title: 'Cardiovascular Diseases' },
                    { title: 'Blindness' },
                    { title: 'Amputation of legs' },
                  ],
                },
              ],
            }
          : action.payload === '126'
          ? {
              mainTitleNumber: '1',
              mainTitleText: 'Overview',
              columnHeading: [
                'Primary Organ or System',
                'Impacted System',
                'Complication / Sequela',
              ],
              columnOneData: [{ title: 'Lung' }],
              columnRowData: [
                {
                  colTwoBoxTitle: 'Brain',
                  colThree: [{ title: 'Depression' }],
                },
                {
                  colTwoBoxTitle: 'Respiratory',
                  colThree: [
                    { title: 'Sinusitis' },
                    { title: 'Pneumonia' },
                    { title: 'Bronchiectasis' },
                  ],
                },
                {
                  colTwoBoxTitle: 'GI',
                  colThree: [
                    { title: 'Malnutrition' },
                    { title: 'Failure to Thrive' },
                  ],
                },
                {
                  colTwoBoxTitle: 'Pancreatic Disease',
                  colThree: [
                    { title: 'CF related diseases' },
                    { title: 'Pancreatic Insufficiency' },
                  ],
                },
                {
                  colTwoBoxTitle: 'Gall Bladder',
                  colThree: [
                    { title: 'Gall Stones' },
                    { title: 'Non-Functioning GB' },
                  ],
                },
                {
                  colTwoBoxTitle: 'Bones',
                  colThree: [
                    { title: 'Osteoporosis & Osteopenia' },
                    { title: 'Fractures' },
                  ],
                },
                {
                  colTwoBoxTitle: 'Male Reproductive System',
                  colThree: [
                    { title: 'Absence of Vas Deferens (Male Infertility)' },
                  ],
                },
              ],
            }
          : action.payload === '128'
          ? {
              mainTitleNumber: '1',
              mainTitleText: 'Overview',
              columnHeading: [
                'Primary Organ or System',
                'Impacted System',
                'Complication / Sequela',
              ],
              columnOneData: [{ title: 'Eyes' }],
              columnRowData: [
                {
                  colTwoBoxTitle: '',
                  colThree: [
                    { title: 'Tunnel vision (Peripheral vision loss)' },
                    { title: 'Blind spots (Scotomas)' },
                    { title: 'Blindness (Total loss of vision)' },
                  ],
                },
              ],
            }
          : action.payload === '127'
          ? {
              mainTitleNumber: '1',
              mainTitleText: 'Overview',
              columnHeading: [
                'Primary Organ or System',
                'Impacted System',
                'Complication / Sequela',
              ],
              columnOneData: [{ title: 'Pancreas' }],
              columnRowData: [
                {
                  colTwoBoxTitle: 'Skeletal Muscle',
                  colThree: [{ title: 'Obesity' }],
                },
                {
                  colTwoBoxTitle: 'Liver',
                  colThree: [
                    { title: 'Inflammatory Diseases' },
                    { title: 'Pneumonia' },
                    { title: 'COPD Exacerbations' },
                    { title: 'Respiratory Failure' },
                    { title: 'Mild Dyspnea' },
                    { title: 'Cor pulmonale (Right Sided Heart failure' },
                  ],
                },
              ],
            }
          : action.payload === '129'
          ? {
              mainTitleNumber: '1',
              mainTitleText: 'Overview',
              columnHeading: [
                'Primary Organ or System',
                'Impacted System',
                'Complication / Sequela',
              ],
              columnOneData: [{ title: 'Eye' }],
              columnRowData: [
                {
                  colTwoBoxTitle: 'Brain',
                  colThree: [{ title: 'Visual Distortions' }],
                },
                {
                  colTwoBoxTitle: 'Eyes',
                  colThree: [
                    { title: 'Reduced Central Vision' },
                    { title: 'Blind spot in Central Vision' },
                    { title: 'Permanent loss of Central Vision' },
                    { title: 'Depression/Social Isolation' },
                    { title: 'Charles Bonnet Syndrome' },
                  ],
                },
              ],
            }
          : {};
    },
    setSymptomsData(state, action) {
      state.symptoms =
        action.payload === '120'
          ? {
              'Stage 1: Acute HIV Infection': [
                {
                  buttonName: 'Early Stage Symptoms',
                  innerData: [
                    { disease: 'Mononucleosis-like Symptoms', percent: '93%' },
                    { disease: 'Fever', percent: 'NA%' },
                    { disease: 'Chills', percent: '40%' },
                    { disease: 'Night Sweats', percent: '40%' },
                    { disease: 'Muscle Aches', percent: ' ' },
                    { disease: 'Sore Throat', percent: ' ' },
                  ],
                },
                {
                  buttonName: 'Second Stage Symptoms',
                  innerData: [
                    { disease: 'Mononucleosis-like Symptoms', percent: '93%' },
                    { disease: 'Fever', percent: 'NA%' },
                    { disease: 'Chills', percent: '40%' },
                    { disease: 'Night Sweats', percent: '40%' },
                    { disease: 'Muscle Aches', percent: ' ' },
                    { disease: 'Sore Throat', percent: ' ' },
                  ],
                },
              ],
              'Stage 2: Clinical Latency': [
                {
                  buttonName: 'Second Stage Symptoms',
                  innerData: [
                    { disease: 'Mononucleosis-like Symptoms', percent: '93%' },
                    { disease: 'Fever', percent: 'NA%' },
                    { disease: 'Chills', percent: '40%' },
                    { disease: 'Night Sweats', percent: '40%' },
                    { disease: 'Muscle Aches', percent: ' ' },
                    { disease: 'Sore Throat', percent: ' ' },
                  ],
                },
              ],
              'Stage 3:  AIDS': [
                {
                  buttonName: 'Second Stage Symptoms',
                  innerData: [
                    { disease: 'Mononucleosis-like Symptoms', percent: '93%' },
                    { disease: 'Fever', percent: 'NA%' },
                    { disease: 'Chills', percent: '40%' },
                    { disease: 'Night Sweats', percent: '40%' },
                    { disease: 'Muscle Aches', percent: ' ' },
                    { disease: 'Sore Throat', percent: ' ' },
                  ],
                },
                {
                  buttonName: 'Second Stage Symptoms',
                  innerData: [
                    { disease: 'Mononucleosis-like Symptoms', percent: '93%' },
                    { disease: 'Fever', percent: 'NA%' },
                    { disease: 'Chills', percent: '40%' },
                    { disease: 'Night Sweats', percent: '40%' },
                    { disease: 'Muscle Aches', percent: ' ' },
                    { disease: 'Sore Throat', percent: ' ' },
                  ],
                },
                {
                  buttonName: 'Second Stage Symptoms',
                  innerData: [
                    { disease: 'Mononucleosis-like Symptoms', percent: '93%' },
                    { disease: 'Fever', percent: 'NA%' },
                    { disease: 'Chills', percent: '40%' },
                    { disease: 'Night Sweats', percent: '40%' },
                    { disease: 'Muscle Aches', percent: ' ' },
                    { disease: 'Sore Throat', percent: ' ' },
                  ],
                },
              ],
            }
          : action.payload === '125'
          ? {
              'Early to Moderate COVID': [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Dry Cough', percent: '40%' },
                    { disease: 'Mucus', percent: ' ' },
                    { disease: 'Shortness of breath', percent: ' ' },
                    { disease: 'Fever', percent: ' ' },
                    { disease: 'Fatigue', percent: ' ' },
                    { disease: 'loss of smell', percent: ' ' },
                    { disease: 'Vomitting', percent: ' ' },
                    { disease: 'Diarrhea', percent: ' ' },
                    { disease: 'Muscle Pain', percent: ' ' },
                  ],
                },
              ],
              'Post Infection': [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Confusion', percent: 'NA%' },
                    { disease: 'Chronic Fatigue', percent: ' ' },
                    { disease: 'Shortness of Breathe', percent: ' ' },
                  ],
                },
              ],
            }
          : action.payload === '121'
          ? {
              'Stage 1: Mild Intermittent Stage 2: Mild Persistent': [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Chest tightness', percent: ' ' },
                    { disease: 'Wheezing', percent: ' ' },
                    { disease: 'Breathlessness', percent: ' ' },
                    { disease: 'Persistent cough w/o sputum', percent: ' ' },
                    { disease: 'Short-flare ups', percent: ' ' },
                    { disease: 'Night time symptoms', percent: ' ' },
                  ],
                },
              ],
              'Stage 3: Moderate Persistent': [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Increased mucus production', percent: ' ' },
                    { disease: 'Post nasal drip', percent: ' ' },
                    { disease: 'Headaches', percent: ' ' },
                  ],
                },
              ],
              'Stage 4: Severe Persistent': [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Lung infection', percent: ' ' },
                    { disease: 'Chronic nasal congestion', percent: ' ' },
                  ],
                },
              ],
            }
          : action.payload === '122'
          ? {
              'Early Stage': [
                {
                  buttonName: '',
                  innerData: [
                    {
                      disease:
                        'In Coronary Arteries Chest pain Breathing problem Abnormal Heartbeat',
                      percent: ' ',
                    },
                    {
                      disease:
                        'In Carotid Arteries Weakness Headache Blurry vision',
                      percent: ' ',
                    },
                    {
                      disease:
                        'In Peripheral Arteries Leg or arm pain Numbness Coldness of extremities',
                      percent: ' ',
                    },
                    {
                      disease:
                        'In Renal Arteries  Problems with urination Itchiness Loss of appetite',
                      percent: ' ',
                    },
                  ],
                },
              ],
              'Final Stage': [
                {
                  buttonName: '',
                  innerData: [
                    {
                      disease: 'In Coronary Arteries Heart Attack',
                      percent: ' ',
                    },
                    { disease: 'In Carotid Arteries Stroke', percent: ' ' },
                    {
                      disease: 'In Peripheral Arteries Acute Limb Ischemia',
                      percent: ' ',
                    },
                    {
                      disease: 'In Renal Arteries Renal infarction',
                      percent: ' ',
                    },
                  ],
                },
              ],
            }
          : action.payload === '130'
          ? {
              Mild: [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Memory Loss', percent: ' ' },
                    { disease: 'Confusion', percent: ' ' },
                    { disease: 'Trouble understanding words', percent: ' ' },
                    { disease: 'Difficulty completing tasks', percent: ' ' },
                    {
                      disease: 'Olfactory (Smell) Dysfunction',
                      percent: ' ',
                    },
                  ],
                },
              ],
              Moderate: [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Mood Swings', percent: ' ' },
                    { disease: 'Behavior Changes', percent: ' ' },
                    { disease: 'Delusions', percent: ' ' },
                  ],
                },
              ],
              Severe: [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Inability to Communicate', percent: ' ' },
                    { disease: 'Infections', percent: ' ' },
                    {
                      disease: 'Inability to Perform Basic Tasks',
                      percent: ' ',
                    },
                    { disease: 'Seizures', percent: '10-20%' },
                    { disease: 'Aggression', percent: '10-20%' },
                    {
                      disease:
                        'Apraxia (Inability to perform familiar movements)',
                      percent: ' ',
                    },
                    { disease: 'Fear of Water', percent: '10-20%' },
                  ],
                },
              ],
            }
          : action.payload === '123'
          ? {
              'Early Stage': [
                {
                  buttonName: '',
                  innerData: [
                    {
                      disease: 'Presence of lump in the breast',
                      percent: '83%',
                    },
                    {
                      disease: 'Swelling in armpits (lymph nodes)',
                      percent: '6-63%',
                    },
                    {
                      disease: 'Nipple discharge (clear or bloody',
                      percent: '3-9%',
                    },
                    {
                      disease: 'Long-term tenderness of breast',
                      percent: '15-25%',
                    },
                  ],
                },
              ],
              'Advanced Stages': [
                {
                  buttonName: '',
                  innerData: [
                    {
                      disease: 'Bone pain (due to metastasis)',
                      percent: ' ',
                    },
                    { disease: 'Shortness of breath', percent: ' ' },
                    { disease: 'Loss of appetite', percent: ' ' },
                    { disease: 'Unintentional weight loss', percent: ' ' },
                    { disease: 'Fatigue', percent: ' ' },
                  ],
                },
              ],
            }
          : action.payload === '124'
          ? {
              'Mild Stage': [
                {
                  buttonName: '',
                  innerData: [{ disease: 'Cough', percent: '59%' }],
                },
              ],
              'Moderate Stage': [
                {
                  buttonName: '',
                  innerData: [{ disease: 'Expectoration', percent: '64%' }],
                },
              ],
              'Severe Stage': [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Dyspnea', percent: '73%' },
                    { disease: 'High wheezing', percent: '49%' },
                    { disease: 'Pneumonia', percent: ' ' },
                    { disease: 'COPD Exacerbation', percent: ' ' },
                  ],
                },
              ],
            }
          : action.payload === '126'
          ? {
              Lung: [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Lung Infection', percent: ' ' },
                    { disease: 'Wheezing', percent: ' ' },
                    { disease: 'Difficulty breathing', percent: ' ' },
                    { disease: 'Persistent cough with phlegm', percent: ' ' },
                  ],
                },
              ],
              Sinusitis: [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Chronic nasal congestion', percent: ' ' },
                    { disease: 'Headaches', percent: ' ' },
                    { disease: 'Post Nasal drip', percent: ' ' },
                  ],
                },
              ],
              Other: [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Salt-tasting skin', percent: ' ' },
                    { disease: 'Gallstones', percent: ' ' },
                    { disease: 'Fractures', percent: ' ' },
                    { disease: 'Chronic diarrhea', percent: ' ' },
                    { disease: 'Pancreatitis', percent: ' ' },
                  ],
                },
              ],
            }
          : action.payload === '128'
          ? {
              'Acute Angle Closure (Initial)': [
                {
                  buttonName: 'Acute Angle Closure',
                  innerData: [
                    { disease: 'Headache and eye pain', percent: ' ' },
                    { disease: 'Nausea / Vomiting', percent: ' ' },
                    { disease: 'Blurred and reduced vision', percent: ' ' },
                    {
                      disease: 'Red eye with dilated, fixed pupil',
                      percent: ' ',
                    },
                  ],
                },
              ],
              'POAG: Early-Stage (Stage 0-1)': [
                {
                  buttonName: 'Primary Open Angle',
                  innerData: [
                    { disease: 'Asymptomatic', percent: ' ' },
                    {
                      disease: 'Tunnel vision (peripheral vision loss)',
                      percent: ' ',
                    },
                    { disease: 'Blind spots (scotomas)', percent: ' ' },
                  ],
                },
              ],
              'POAG: Moderate Stage (Stages 2-3)': [
                {
                  buttonName: 'Primary Open Angle',
                  innerData: [
                    { disease: 'Blurred vision', percent: ' ' },
                    { disease: 'Rainbow Halo', percent: ' ' },
                    { disease: 'Blind spots (scotomas)', percent: ' ' },
                    {
                      disease: 'Tunnel vision (peripheral vision loss)',
                      percent: ' ',
                    },
                  ],
                },
              ],
              'POAG: Severe to End-Stage Disease (Stages 4-5)': [
                {
                  buttonName: 'Primary Open Angle',
                  innerData: [
                    { disease: 'Blindness', percent: ' ' },
                    {
                      disease: 'Significant central vision blind spot',
                      percent: ' ',
                    },
                  ],
                },
              ],
            }
          : action.payload === '127'
          ? {
              'Initial Symptoms': [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Polyphagia', percent: ' ' },
                    { disease: 'Polyuria', percent: ' ' },
                    { disease: 'Dehydration', percent: ' ' },
                    { disease: 'Glucosuria', percent: ' ' },
                    { disease: 'Polydipsia', percent: ' ' },
                    { disease: 'Weight loss', percent: ' ' },
                  ],
                },
              ],
              'Intermediate Stage': [
                {
                  buttonName: '',
                  innerData: [
                    {
                      disease: 'Slow healing of cuts and wounds',
                      percent: ' ',
                    },
                    { disease: 'Blurry vision', percent: ' ' },
                    { disease: 'Noticeable fatigue', percent: ' ' },
                  ],
                },
              ],
              'Final Stage': [
                {
                  buttonName: '',
                  innerData: [
                    {
                      disease: 'Cardiovascular diseases and issues',
                      percent: '65%',
                    },
                    { disease: 'Retinopathy', percent: ' ' },
                    { disease: 'Nephropathy', percent: ' ' },
                    { disease: 'Neuropathy', percent: ' ' },
                    { disease: 'Dyslipidemia', percent: '97%' },
                  ],
                },
              ],
            }
          : action.payload === '129'
          ? {
              'Early Stage': [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Visual Distortions', percent: ' ' },
                    {
                      disease:
                        'Increased difficulty adapting to low light levels',
                      percent: ' ',
                    },
                    {
                      disease: 'need for brighter light while reading',
                      percent: ' ',
                    },
                  ],
                },
              ],
              'Intermediate Stage': [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Reduced Central Vision', percent: ' ' },
                    {
                      disease: 'Increased blurriness of printed words',
                      percent: ' ',
                    },
                  ],
                },
              ],
              'Final Stage': [
                {
                  buttonName: '',
                  innerData: [
                    { disease: 'Blind Spot in central vision', percent: ' ' },
                    {
                      disease: 'Permanent Loss of central vision',
                      percent: ' ',
                    },
                  ],
                },
              ],
            }
          : {};
    },
    setSymptomsOptions(state, action) {
      state.options =
        action.payload === '120'
          ? [
              'Stage 1: Acute HIV Infection',
              'Stage 2: Clinical Latency',
              'Stage 3:  AIDS',
            ]
          : action.payload === '125'
          ? ['Stage 1: Early to Moderate COVID', 'Stage 2: Post Infection']
          : [];
    },
    setSelectedOption(state, action) {
      state.selectedOption = action.payload;
    },
    setClassificationData(state, action) {
      state.classification =
        action.payload === '120'
          ? {
              mainTitleNumber: '4',
              mainTitleText: 'Classification',
              data: [
                {
                  title: 'By Variant',
                  card: [
                    { cardTitle: 'HIV-1', percent: '99.4%' },
                    { cardTitle: 'HIV-2', percent: '0.6%' },
                  ],
                },
                {
                  title: 'By Progression',
                  card: [
                    { cardTitle: 'Typical Progressors', percent: '75%' },
                    { cardTitle: 'Rapid Progressors', percent: '10-15%' },
                    { cardTitle: 'Long term Non Progressors', percent: '5%' },
                  ],
                },
                {
                  title: 'By Viral Load',
                  card: [
                    { cardTitle: 'HIV Controllers (<2000)', percent: '40%' },
                    {
                      cardTitle: 'Elite Controllers or Supressors(<50)',
                      percent: '0.6%',
                    },
                    {
                      cardTitle: 'Viremic Controllers (50 to 2000)',
                      percent: '5%',
                    },
                  ],
                },
              ],
            }
          : action.payload === '125'
          ? {
              mainTitleNumber: '4',
              mainTitleText: 'Classification',
              data: [
                {
                  title: 'By Variant',
                  card: [
                    { cardTitle: 'Alpha (B.1.1.7)', percent: ' ' },
                    {
                      cardTitle: 'Beta (B.1.351, B.1.351.2, B.1.351.3)',
                      percent: ' ',
                    },
                    {
                      cardTitle: 'Delta (B.1.617.2, AY.1, AY.2, AY.3)',
                      percent: ' ',
                    },
                    { cardTitle: 'Gamma (P.1, P.1.1, P.1.2)', percent: ' ' },
                    { cardTitle: 'Omicron', percent: ' ' },
                  ],
                },
              ],
            }
          : action.payload === '121'
          ? {
              mainTitleNumber: '4',
              mainTitleText: 'Classification',
              data: [
                {
                  title: 'ByCause of Asthma',
                  card: [
                    { cardTitle: 'Allergic/Atopic Asthma', percent: '80%' },
                    {
                      cardTitle: 'Non-Allergic/Non-Atopic Asthma',
                      percent: '10%',
                    },
                    { cardTitle: 'Drug Induced Asthma', percent: '0.5%' },
                    { cardTitle: 'Occupational Asthma', percent: '10-15%' },
                  ],
                },
              ],
            }
          : action.payload === '122'
          ? {
              mainTitleNumber: '4',
              mainTitleText: 'Classification',
              data: [
                {
                  title: 'By Histology',
                  card: [
                    { cardTitle: 'Type I Lesion', percent: ' ' },
                    { cardTitle: 'Type II Lesion', percent: ' ' },
                    { cardTitle: 'Type III Lesion', percent: ' ' },
                    { cardTitle: 'Type IV Lesion', percent: ' ' },
                    { cardTitle: 'Type V Lesion', percent: ' ' },
                    { cardTitle: 'Type VI Lesion', percent: ' ' },
                  ],
                },
              ],
            }
          : action.payload === '130'
          ? {
              mainTitleNumber: '4',
              mainTitleText: 'Classification',
              data: [
                {
                  title: 'By Age',
                  card: [
                    { cardTitle: 'Early Onset', percent: '5-10%' },
                    { cardTitle: 'Late Onset', percent: '95%' },
                  ],
                },
              ],
            }
          : action.payload === '123'
          ? {
              mainTitleNumber: '4',
              mainTitleText: 'Classification',
              data: [
                {
                  title: 'By Molecular Subtypes',
                  card: [
                    { cardTitle: ' Luminal A (HR+/HER2)', percent: '40%' },
                    { cardTitle: 'Luminal B (HR+/HER2+)', percent: '20%' },
                    { cardTitle: 'Basal-like (HR-/HER2-)', percent: '10-15%' },
                    {
                      cardTitle: 'HER2-enriched (HR-/HER2+)',
                      percent: '15-20%',
                    },
                  ],
                },
                {
                  title: 'By Regions of Origin (Incl Occurrence)',
                  card: [
                    { cardTitle: 'Common', percent: '' },
                    { cardTitle: 'LCIS', percent: ' ' },
                    { cardTitle: 'DCIS', percent: ' ' },
                    {
                      cardTitle: 'Infiltrating lobular carcinoma',
                      percent: '10-15%',
                    },
                    {
                      cardTitle: 'Infiltrating ductal carcinoma',
                      percent: '80%',
                    },
                    { cardTitle: 'Rare', percent: '' },
                    { cardTitle: 'Medullary carcinoma', percent: '05%' },
                    { cardTitle: 'Mucinous carcinoma', percent: ' ' },
                    { cardTitle: 'Tubular carcinoma', percent: '02%' },
                    { cardTitle: 'Inflammatory breast cancer', percent: '01%' },
                    {
                      cardTitle:
                        "Paget's disease of the nipple', percent: '01%",
                    },
                    { cardTitle: 'Phyllodes tumor', percent: ' ' },
                  ],
                },
                {
                  title: 'By Invasiveness',
                  card: [
                    { cardTitle: 'Preinvasive cancer', percent: '25%' },
                    {
                      cardTitle: 'Ductal carcinoma in situ (DCIS)',
                      percent: '80%',
                    },
                    {
                      cardTitle: 'Lobular carcinoma in situ (LCIS)',
                      percent: '20%',
                    },
                    { cardTitle: 'Invasive cancer', percent: '75%' },
                    {
                      cardTitle: 'Invasive ductal carcinoma (IDC)',
                      percent: '79%',
                    },
                    {
                      cardTitle: 'Invasive lobular carcinoma (ILC)',
                      percent: '10%',
                    },
                  ],
                },
              ],
            }
          : action.payload === '124'
          ? {
              mainTitleNumber: '4',
              mainTitleText: 'Classification',
              data: [
                {
                  title: 'By Disease Pathology (No longer used)',
                  card: [
                    { cardTitle: 'Emphysema', percent: ' ' },
                    { cardTitle: 'Chronic Bronchitis', percent: ' ' },
                    { cardTitle: 'Asthmatic COPD', percent: ' ' },
                  ],
                },
                {
                  title: 'By Severity (%=FEV1)',
                  card: [
                    { cardTitle: 'Mild', percent: '80%' },
                    { cardTitle: 'Moderate', percent: '50-79%' },
                    { cardTitle: 'Severe', percent: '30-49%' },
                    { cardTitle: 'Very Severe', percent: '<30%' },
                  ],
                },
                {
                  title: 'By Gold Classification',
                  card: [
                    { cardTitle: 'A', percent: '' },
                    { cardTitle: 'B', percent: '' },
                    { cardTitle: 'C', percent: '' },
                    { cardTitle: 'D', percent: '' },
                  ],
                },
              ],
            }
          : action.payload === '126'
          ? {
              mainTitleNumber: '4',
              mainTitleText: 'Classification',
              data: [
                {
                  title: 'By Mutations',
                  card: [
                    { cardTitle: 'Protein Synthesis Defect', percent: ' ' },
                    { cardTitle: 'Maturation Defect', percent: ' ' },
                    { cardTitle: 'Gating Defect', percent: ' ' },
                    { cardTitle: 'Conductance Defect', percent: ' ' },
                    { cardTitle: 'Reduced Quantity', percent: ' ' },
                    { cardTitle: 'Reduced Stability', percent: ' ' },
                  ],
                },
              ],
            }
          : action.payload === '128'
          ? {
              mainTitleNumber: '4',
              mainTitleText: 'Classification',
              data: [
                {
                  title: 'By Obstruction of the Drainage System',
                  card: [
                    { cardTitle: ' Open Angle Glaucoma', percent: '95%' },
                    { cardTitle: 'Closed Angle Glaucoma ', percent: '5%' },
                  ],
                },
                {
                  title: 'By IoP Level',
                  card: [
                    {
                      cardTitle: 'Elevated IOP Glaucoma (>22)',
                      percent: '70-80%',
                    },
                    {
                      cardTitle: 'Normal Tension Glaucoma (<22)',
                      percent: '20-30%',
                    },
                  ],
                },
                {
                  title: 'By Age',
                  card: [
                    {
                      cardTitle: 'Congenital Glaucoma (Birth-10 years)',
                      percent: ' ',
                    },
                    {
                      cardTitle: 'Juvenile Glaucoma (10-35 years)',
                      percent: ' ',
                    },
                    {
                      cardTitle: 'Glaucoma (Adult) ( >35 years)',
                      percent: ' ',
                    },
                  ],
                },
              ],
            }
          : action.payload === '127'
          ? {
              mainTitleNumber: '4',
              mainTitleText: 'Classification',
              data: [
                {
                  title: 'By Insulin Activity',
                  card: [
                    { cardTitle: 'Insulin Independent', percent: '13.6%' },
                    { cardTitle: 'Insulin Dependent', percent: '90%' },
                  ],
                },
              ],
            }
          : action.payload === '129'
          ? {
              mainTitleNumber: '4',
              mainTitleText: 'Classification',
              data: [
                {
                  title: 'By Wet Active',
                  card: [
                    {
                      cardTitle:
                        'Classic Choroidal Neovascularization (CNV) (Active CNV)',
                      percent: '25-40%',
                    },
                    { cardTitle: 'Occult CNV (Inactive CNV)', percent: ' ' },
                  ],
                },
                {
                  title: 'By Wet Inactive',
                  card: [
                    {
                      cardTitle:
                        'Classic Choroidal Neovascularization (CNV) (Active CNV)',
                      percent: '25-40%',
                    },
                    { cardTitle: 'Occult CNV (Inactive CNV)', percent: ' ' },
                  ],
                },
              ],
            }
          : {};
    },
    setStagesData(state, action) {
      state.stages =
        action.payload === '120'
          ? {
              titleNumber: '5',
              MainTopTitle: 'Staging & Progression',
              topBoxTitleText: 'Stages',
              bottomBoxTitleText: 'Age at death 9 years less',
              leftArrowTitleText: 'Increasing Viral Load',
              data: [
                {
                  circleTitle: '2-6 wks',
                  boxTitle: 'Stage 1: Acute HIV Infection',
                  statement: '',
                  Duration: '2 to 4 weeks after initial infection',
                  Symptoms:
                    'Influenza-like symptoms, such as fever, rash, sore throat, and chills. ',
                  'What Happens':
                    'Symptoms occur due to normal immune response, as the body produces HIV antibodies as a defense mechanism to the virus (seroconversion). Patients in this stage are at highest risk of transmitting the infection.',
                },
                {
                  circleTitle: '3-20 years',
                  boxTitle: 'Stage 2: Clinical Latency',
                  statement: '',
                  Duration:
                    'From 3 to 20 years. (Average of HIV-1 and HIV-2?). (By gender - HIV1, HIV2)',
                  Symptoms:
                    'Characterized by an absence of any symptoms initially; and can range. Persistent generalized lymphadenopathy (enlargement of lymph nodes) is observed in 50-70% of cases.',
                  'What Happens':
                    'Persistent generalized lymphadenopathy (enlargement of lymph nodes) is observed in 50-70% of cases.',
                },
                {
                  circleTitle: '[3] years',
                  boxTitle: 'Stage 3: AIDS',
                  statement: '',
                  Duration: 'Depends on individual',
                  Symptoms:
                    'Diarrhea, rapid weight loss, pneumonia, rashes, and extreme fatigue are some of the common symptoms seen in patients. ',
                  'What Happens':
                    'The last stage of HIV infection represents the complete manifestation of the disease. It is characterized by opportunistic infections caused by various fungi, bacteria, viruses, and parasites, indicating damage to the immune system. Viral load is maximum at this stage.',
                },
              ],
            }
          : action.payload === '125'
          ? {
              titleNumber: '5',
              MainTopTitle: 'Staging & Progression',
              topBoxTitleText: 'Stages',
              bottomBoxTitleText: 'Infection free in 10-21 days',
              leftArrowTitleText: 'Increasing Viral Load, Decreasing FEV1 SpO2',
              data: [
                {
                  circleTitle: '',
                  boxTitle: 'Stage 1: Viral Entry and Replication',
                  statement:
                    'Children and younger adults (40%) may be asymptomatic No treatment recommended at this stage.',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 2: Viral Dissmeination',
                  statement:
                    'People display one or more of common COVID symptoms. Treatment protocol includes anti-viral drugs and steroids Recommended clinical monitoring is done by intermittent pulse oximetry.',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 3: Multi-System Inflammation',
                  statement:
                    'worsening dyspnea and hypoxia, along with subclinical elevations. Treatment is based on oxygen support, anti-inflammatory and immunomodulatory therapies, anti-thrombotics',
                },
                {
                  circleTitle: '',
                  boxTitle:
                    'Stage 4: Endothelial Damage, Thrombosis, and Multi-organ Dysfunction',
                  statement:
                    'Severe hypoxemic respiratory failure (low O2 levels but normal CO2 levels). Multi-organ failure is seen. Inflammatory response is cause of pathophysiology as high levels of antibodies present without evidence of active viral infection. Limited treatment options since patients are resistant to most remedies available. Patient likely intubated. Multiple organ damage (ACE-2 receptors rich organs: kidney, pancreas and nervous system).',
                },
              ],
            }
          : action.payload === '121'
          ? {
              titleNumber: '5',
              MainTopTitle: 'Staging & Progression',
              topBoxTitleText: 'Stages',
              bottomBoxTitleText: '3.3 years less than healthy individual',
              leftArrowTitleText: 'Decreasing FEV1/FVC Ratio',
              data: [
                {
                  circleTitle: '',
                  boxTitle: 'Stage 1: Mild Intermittent Asthma',
                  statement: '',
                  'What Happens':
                    'Symptoms occur <2 times a week. Short flare-ups lasting a few hours to a few days. Night time symptoms fewer than twice a month.',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 2: Mild Persistent Asthma',
                  statement: '',
                  'What Happens':
                    'Asthmatic symptoms occur >2 times a week, not more than once a day. The person"s activity levels may be affected by the flare-ups.',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 3:Moderate Persistent Asthma',
                  statement: '',
                  'What Happens':
                    'Asthmatic symptoms occur >2 times a week, not more than once a day. The person"s activity levels may be affected by the flare-ups.',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 4:Severe Persistent Asthma',
                  statement: '',
                  'What Happens':
                    'Constant symptoms are seen. The person shows decreased physical activity and has frequent flare-ups. Experiences night-time symptoms frequently.',
                },
              ],
            }
          : action.payload === '122'
          ? {
              titleNumber: '5',
              MainTopTitle: 'Staging & Progression',
              topBoxTitleText: 'Stages',
              bottomBoxTitleText: 'Age at death under 50 years',
              leftArrowTitleText: 'Increasing Age',
              data: [
                {
                  circleTitle: '',
                  boxTitle: 'Stage 1: Fatty Streak',
                  statement: '',
                  Duration: '[to come]',
                  Symptoms: 'to come',
                  'What Happens':
                    "Fatty streaks are composed of lipid-filled cells, white blood cells, and other cellular matter which is found in the major arteries like the aorta. It doesn't produce any symptoms of heart disease but can lead to the formation of fibrous plaque. Develops during the 1st Decade of Patient’s age",
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 2: Plaque Formation',
                  statement: '',
                  Duration: '[to come]',
                  Symptoms: 'to come',
                  'What Happens':
                    'Plaque is made up of cholesterol, white blood cells, and calcium which is formed in the inner lining of the artery. The plaque thickens the artery causing reduced blood flow to the heart muscle',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 3: Stable and Unstable plaque',
                  statement: '',
                  Duration: '[to come]',
                  Symptoms: 'to come',
                  'What Happens':
                    'Plaque can be stable or unstable based on the risk that it will rupture. Stable plaques are less likely to rupture whereas unstable plaques are more likely to rupture',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 4: Blocked Artery',
                  statement: '',
                  Duration: '[to come]',
                  Symptoms: '[to come]',
                  'What Happens':
                    'The rupture of plaques causes blockage in the artery exposing the cholesterol and other matter present under the fibrous cap. In response to this rupture, blood clots are formed which block the blood flow in the artery causing stroke or heart attack.',
                },
              ],
            }
          : action.payload === '130'
          ? {
              titleNumber: '5',
              MainTopTitle: 'Staging & Progression',
              topBoxTitleText: 'Stages',
              bottomBoxTitleText: 'Average patient lives 3-11 years post dx',
              leftArrowTitleText: 'Increasing disease Progression',
              data: [
                {
                  circleTitle: '',
                  boxTitle: 'Stage 1: No Cognitive Decline',
                  statement:
                    'Patients do not experience any functional decline. Hence, No symptoms are seen.',
                },
                {
                  circleTitle: '7 years',
                  boxTitle: 'Stage 2: Very Mild Cognitive Decline',
                  statement:
                    'People may experience memory lapses and forget names of people.',
                },
                {
                  circleTitle: '7 years',
                  boxTitle:
                    'Stage 3: Mild Cognitive Decline or Impairment (MCI)',
                  statement:
                    'It becomes more difficult to retrieve words and social challenges are experienced. AD is often diagnosed at this stage.',
                },
                {
                  circleTitle: '2 years',
                  boxTitle: 'Stage 4: Moderate Cognitive Decline',
                  statement:
                    'There is confusion, increased risk of wandering off and getting lost, changes in sleep patterns and restlessness in the patient.',
                },
                {
                  circleTitle: '2 years',
                  boxTitle: 'Stage 5: Moderately Severe Cognitive Decline',
                  statement:
                    'Difficulty in recognising close family and friends. Struggle to to do basic tasks like dressing up. Emotional changes also occur during this stage including hallucinations and delusions.',
                },
                {
                  circleTitle: '2 years',
                  boxTitle: 'Stage 6: Severe Cognitive Decline',
                  statement:
                    'Significant personality changes occur. Frustration or aggression on the caretaker during increased dependence. Communication is limited to few words only. They also develop fear of water Depression can also develop.',
                },
                {
                  circleTitle: '2 years',
                  boxTitle: 'Stage 7: Very Severe Cognitive Decline',
                  statement:
                    'This stage causes severe physical and mental impairment. Requires around the clock assistance. Loss of movement and ability to respond. Patients are more vulnerable to infections. Pneumonia is the common cause of death.',
                },
              ],
            }
          : action.payload === '123'
          ? {
              titleNumber: '5',
              MainTopTitle: 'Staging & Progression',
              topBoxTitleText: 'Stages',
              bottomBoxTitleText: 'Progression varies by patient',
              leftArrowTitleText: 'Increasing []',
              data: [
                {
                  circleTitle: '',
                  boxTitle: 'Stage 0',
                  statement: '',
                  'What Happens':
                    'The cancer in this stage is in-situ carcinoma. Tumor confined to milk ducts and milk glands and has not invaded surrounding tissues.',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage I',
                  statement: '',
                  'What Happens':
                    "Tumor diameter: < 0.75 inch As cancer progresses and enters stage I, it becomes a localized & regional invasive cancer. Spread: not beyond the breast. ',",
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage IIA',
                  statement: '',
                  'What Happens':
                    'The last stage of HIV infection represents the complete manifestation of the disease. It is characterized by opportunistic infections caused by various fungi, bacteria, viruses, and parasites, indicating damage to the immune system. Viral load is maximum at this stage.',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage IIB',
                  statement: '',
                  'What Happens':
                    'Tumor diameter: 0.75 to 2 inches and Spread: in 1 to 3 lymph nodes in armpit, microscopic amount spread to lymph nodes near breastbone on the same side as the tumor, or both. OR Tumor diameter: 2 inch',
                  Spread: 'Beyond breast',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage IIIA',
                  statement: '',
                  'What Happens': 'Tumor diameter: < 2 inches',
                  Spread:
                    '4 to 9 lymph nodes in armpit or have enlarged at least one lymph node near breastbone on the same side as the tumor. OR Tumor diameter: > 2 inches',
                  Spread:
                    'Up to 9 lymph nodes in armpit, or to lymph nodes near the breastbone',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage IIIB',
                  statement: '',
                  'What Happens':
                    'Spread: Chest wall or skin or has caused breast inflammation',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage IIIC',
                  statement: '',
                  'What Happens':
                    'Tumor diameter: Any size Plus at least one of the following conditions',
                  Spread:
                    'To 10 or more lymph nodes in armpitTo lymph nodes under or above the collar boneTo lymph nodes in armpit and or has enlarged at least one lymph node near breast bone on the same side as the tumorTo 4 or more lymph nodes in armpit and microscopic amounts spread to lymph nodes near breastbone on the same side as the tumor',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage IV',
                  statement: '',
                  'What Happens': 'Tumor diameter: Any size',
                  Spread:
                    'Distant organs and tissues such as, lungs, bones and lymph nodes distant from breasts',
                },
              ],
            }
          : action.payload === '124'
          ? {
              titleNumber: '5',
              MainTopTitle: 'Staging & Progression',
              topBoxTitleText: 'Length of Stage',
              bottomBoxTitleText: '',
              leftArrowTitleText: 'Decreasing FEV1',
              data: [
                {
                  circleTitle: '',
                  boxTitle: 'Stage 1: Mild COPD',
                  statement: '',
                  'What Happens':
                    'Patients experience little to no symptoms, and are able to complete all daily activities. There is a slight limitation in breathing',
                  FEV1: '80%',
                  'FEV1/FEV': '<0.7',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 2: Moderate COPD',
                  statement: '',
                  'What Happens':
                    'Symptoms increase. Cough and mucus production are observed.',
                  FEV1: '50-80%',
                  'FEV1/FEV': '<0.7',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 3: Severe COPD',
                  statement: '',
                  'What Happens':
                    'Lung function continues to decline and breathing becomes worse. Individuals experience severe emphysema',
                  FEV1: '30-50%',
                  'FEV1/FEV': '<0.7',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 4: Very Severe COPD',
                  statement: '',
                  'What Happens':
                    'This stage is also known as end stage COPD. Breathing issues become life threatening and low blood oxygen levels can lead to serious complications like hypoxia.',
                  FEV1: '<30%',
                  'FEV1/FEV': '<0.7',
                },
              ],
            }
          : action.payload === '126'
          ? {
              titleNumber: '5',
              MainTopTitle: 'Staging & Progression',
              topBoxTitleText: 'Stages',
              bottomBoxTitleText: '',
              leftArrowTitleText: 'Declining FEV1',
              data: [
                {
                  circleTitle: '',
                  boxTitle: 'Stage 1: CFTR Mutation',
                  statement: '',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 2: Mucus Dehydration',
                  statement: '',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 3: Infection',
                  statement: '',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 4: Inflammation',
                  statement: '',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 5: Bronchiectasis',
                  statement: '',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 6: Pulmonary exacerbation',
                  statement: '',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 6: Pulmonary exacerbation',
                  statement: '',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 7: Respiratory Failure',
                  statement: '',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 8: Death',
                  statement: '',
                },
              ],
            }
          : action.payload === '128'
          ? {
              titleNumber: '5',
              MainTopTitle: 'Staging & Progression',
              topBoxTitleText: 'Stages',
              bottomBoxTitleText: 'Progression varies by patient',
              leftArrowTitleText: 'Increasing loss of vision or nerve damage',
              data: [
                {
                  circleTitle: '',
                  boxTitle: 'Stage 1 : Ocular hypertension',
                  statement:
                    'Elevated eye pressure without optic nerve damage or visual field loss. Eye pressure consistently higher than 21 mm Hg (normal range: 12-22 mm Hg). No signs of glaucomatous damage or vision loss. Regular monitoring is necessary. ',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 2 : Early glaucoma',
                  statement:
                    'Mild optic nerve damage and visual field loss, typically affecting peripheral vision. May not significantly impact daily activities. Treatment is required to slow progression. VFI: 90-100% / MD: -6 dB or better',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 3 : Moderate glaucoma',
                  statement:
                    'Moderate optic nerve damage and more extensive visual field loss. Some impact on daily activities, but central vision is still preserved. Treatment is crucial to prevent further progression. VFI: 60-89% / MD: -12 to -6 dB',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 4 : Advanced glaucoma',
                  statement:
                    'Severe optic nerve damage and extensive visual field loss. Significant impact on daily activities. Treatment is required to prevent further progression. VFI: 30-59% / MD: -20 to -12 dB',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 5 : End-stage glaucoma',
                  statement:
                    'Near-total or total vision loss due to optic nerve damage. Treatment focuses on maintaining remaining vision and improving the quality of life. VFI: less than 40% / MD: worse than -20 dB',
                },
              ],
            }
          : action.payload === '127'
          ? {
              titleNumber: '5',
              MainTopTitle: 'Staging & Progression',
              topBoxTitleText: 'Length of Stage',
              bottomBoxTitleText: '',
              leftArrowTitleText: 'Increasing X',
              data: [
                {
                  circleTitle: '',
                  boxTitle: 'Stage 1: Insulin Resistance',
                  statement: '',
                  'What Happens':
                    'The pancreas produces insulin but the skeletal muscles, liver cells and adipose tissues are unable to respond to it. The risk is linked to family history, being overweight and physical inactivity. ',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 2: Prediabetes',
                  statement: '',
                  'What Happens':
                    'Glucose levels are higher than normal range, but not high enough to be diagnosed as diabetes. The fasting glucose levels are between 100-125 mg/dL. As the glucose levels start to rise, changes in beta cell function and differentiation occur.',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 3:Type 2 Diabetes',
                  statement: '',
                  'What Happens':
                    'Marked by biochemical elevations in blood glucose levels, increases the risk of patients to move into stage-4. If the blood glucose levels are >125 mg/dL then the disease has progressed into type-2 diabetes. Decrease in beta cell mass and function.',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Stage 4:Vascular complications',
                  statement: '',
                  'What Happens':
                    'This represents the end-stage of type-2 diabetes with the manifestation of microvascular (retinopathy, nephropathy, neuropathy) and macrovascular complications (cardiovascular issues, foot ulcer).',
                },
              ],
            }
          : action.payload === '129'
          ? {
              titleNumber: '5',
              MainTopTitle: 'Staging & Progression',
              topBoxTitleText: 'Stages',
              bottomBoxTitleText: 'Progression varies by patient',
              leftArrowTitleText: 'Drusen size and pigmentary abnormalities',
              data: [
                {
                  circleTitle: 'To come',
                  boxTitle: 'Stage 1: Early AMD',
                  statement: '',
                  'What Happens':
                    'Medium sized deposits (>63 µm and ≤ 125 µm) are formed in the retina which are drusen. There is no pigment changes and no vision loss takes place.',
                },
                {
                  circleTitle: 'to come',
                  boxTitle: 'Stage 2: Intermediate Dry AMD',
                  statement: '',
                  'What Happens':
                    'Drusen deposits grow from medium to large size (>125 µm). There may be mild vision loss in this stage. Some individuals may also seen pigment changes.',
                },
                {
                  circleTitle: 'to come',
                  boxTitle: 'Stage 3:Late AMD',
                  statement: '',
                  'What Happens':
                    'Patient may lose the ability to recognise the faces due to loss of central vision. Dry AMD progresses to wet AMD which causes vision loss.',
                },
              ],
            }
          : {};
    },
    setCareTeamData(state, action) {
      state.careTeam =
        action.payload === '120'
          ? {
              TitleNumber: '6',
              TopmainTitle: 'Care Team',
              SubtitleText: 'Timeline',
              data: [
                {
                  circleTitle: '1X',
                  boxTitle: 'Infectious Disease Specialist',
                  statement: '(Regularly since start)',
                  when: 'From start of diagnosis',
                  what: 'Monitoring of HIV progression, management of opportunistic infections & medication management. ',
                  frequency:
                    'Every 1-3 months initially, then every six months, and eventually once a year.',
                },
                {
                  circleTitle: 'R',
                  boxTitle: 'Primary Care Doctor',
                  statement: '(Opportunistic Infections)',
                  when: 'From start of diagnosis',
                  what: 'Overall monitoring of non-HIV and HIV related health. Some may feel comfortable managing HIV medications. ',
                  frequency:
                    'Annual visit. Frequency may increase if infections occur.',
                },
                {
                  circleTitle: 'AN',
                  boxTitle: 'Ob/Gyn',
                  statement: '(Pregnancy & Cervical Cancer)',
                  when: 'From start of diagnosis',
                  what: 'Management of potential pregnancy, contraception, and cervical cancer screening through an annual pap smear (instead of every 2-3 years) ',
                  frequency: 'No change in frequency.',
                },
                {
                  circleTitle: '1X',
                  boxTitle: 'Dentist',
                  statement: '(Oral Infection)',
                  when: 'From start of diagnosis',
                  what: 'Screening for HIV-related oral health concerns (Gingivitis, Candidiasis, plaque, warts etc) ',
                  frequency:
                    'Twice a year. Adherence to these visits is critical.',
                },
              ],
            }
          : action.payload === '125'
          ? {
              TitleNumber: '6',
              TopmainTitle: 'Care Team',
              SubtitleText: 'Timeline',
              data: [
                {
                  boxTitle: 'Intermist',
                  statement: '(Regularly since start)',
                  when: '[to come]',
                  what: '[to come]',
                  frequency: '[to come]',
                },
                {
                  boxTitle: 'Primary Care Doctor',
                  statement: '(At home care)',
                  when: '[to come]',
                  what: '[to come]',
                  frequency: '[to come]',
                },
                {
                  boxTitle: 'Pulmonologist',
                  statement: '(Consulted)',
                  when: '[to come]',
                  what: '[to come]',
                  frequency: '[to come]',
                },
                {
                  boxTitle: 'Cricitcal Care Specialist',
                  statement: '(If patient moved into ICU)',
                  when: '[to come]',
                  what: '[to come]',
                  frequency: '[to come]',
                },
                {
                  boxTitle: 'Other Specialists',
                  statement: '(Depends on organ failing)',
                  when: '[to come]',
                  what: '[to come]',
                  frequency: '[to come]',
                },
              ],
            }
          : action.payload === '121'
          ? {
              titleNumber: '6',
              MainTopTitle: 'Care Team',
              SubtitleText: 'Timeline',
              data: [
                {
                  circleTitle: '',
                  boxTitle: 'Primary Care Doctor',
                  statement: '(Regularly since start)',
                  when: 'From start of diagnosis',
                  what: 'Overall monitoring including patients history. If uncontrolled or later stage, then tests may include spirometry.',
                  frequency:
                    '0-1 Weekly 1-12 Every 4 weeks 1-5 Every 8 weeks 5+ Every 12 weeks Adult 3-6 months (frequency may increase by stage).',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Allergist',
                  statement: '(To understand triggers)',
                  when: "If a patient has Stage 3 or 4 asthma and wants to understand what's triggering asthma, allergy testing would be done. ",
                  what: 'Allergy testing',
                  frequency: 'Once at most',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Pulmonologist',
                  statement: '(Treats respiratory tract diseases)',
                  when: 'When severe issues occur related to respiratory tract disease',
                  what: 'Emergency care for patients who have trouble breathing',
                  frequency:
                    '1-2x per year depending on symptoms (may be more frequent)',
                },
              ],
            }
          : action.payload === '122'
          ? {
              titleNumber: '6',
              MainTopTitle: 'Care Team',
              SubtitleText: 'Timeline',
              data: [
                {
                  boxTitle: 'Cardiologist',
                  statement: '(Outpatient and In hospital)',
                  when: 'If there is concern for CAD',
                  what: 'Will perform appropriate diagnostic tests (stress, angiography, etc), perform procedures, assesses progression of disease, manage symptoms and medications',
                  frequency: 'at least once per year',
                },
                {
                  boxTitle: 'Primary Care Doctor',
                  statement: '(Opportunistic Infections)',
                  when: 'Throughout disease',
                  what: 'Helps manage comorbidities such as hypertension, diabetes, hyperlipidemia',
                  frequency: 'At least once per year',
                },
                {
                  boxTitle: 'Vascular Surgeon',
                  statement: '',
                  when: 'If there is concern for PAD or CAS',
                  what: 'Will perform angiography to diagnose PAD or ultrasound to diagnose CAS, performs interventional procedures',
                  frequency: '[to come]',
                },
                {
                  boxTitle: 'Nutritionist',
                  statement: '(Consulted)',
                  when: 'At any time, if desired',
                  what: 'Will discuss diet and exercise strategies to help manage comorbidities',
                  frequency: 'As needed',
                },
              ],
            }
          : action.payload === '130'
          ? {
              titleNumber: '6',
              MainTopTitle: 'Care Team',
              SubtitleText: 'Timeline',
              data: [
                {
                  boxTitle: 'Neurologist',
                  statement: '(Regularly since diagnosis)',
                  when: 'at diagnosis of MCI / when a primary care doctor is concerned for dementia',
                  what: 'Performs cognitive and other neurological assessments, orders necessary labs and imaging for diagnosis and monitoring of AD, prescribes medications for AD symptoms',
                  frequency: 'determined by neurologist',
                },
                {
                  boxTitle: 'Primary Care Doctor or Geriatric Doctor',
                  statement: '(Routine Medical Care)',
                  when: 'for routine medical care even before diagnosis of AD',
                  what: 'manages chronic health conditions such as hypertension, often diagnosis mild cognitive impairment',
                  frequency: 'at least annually',
                },
                {
                  boxTitle: 'Nurse',
                  statement: '(At home care)',
                  when: 'progression to moderate to severe AD when patients require assistance with daily tasks ',
                  what: 'administer medications, assist in daily tasks such as eating and bathing',
                  frequency:
                    'dependent on assistance needs, often daily to multiple times per week',
                },
                {
                  boxTitle: 'Psychiatry',
                  statement: '( Consulted )',
                  when: 'after onset of mood symptoms',
                  what: 'assess patients for mood symptoms such as depression and anxiety, assess for delusions and/or hallucinations, provide medications for these symptoms',
                  frequency: 'as needed / determined by psychiatrist',
                },
                {
                  boxTitle: 'Palliative Care',
                  statement:
                    'Symptom Management, Goals of Care, End of life care',
                  when: 'at any stage of AD',
                  what: 'aid in symptom management, support of families and patients, aid in planning of home care or assisted living care, aid in decision making',
                  frequency:
                    'as needed or as determined by family and physician',
                },
              ],
            }
          : action.payload === '123'
          ? {
              titleNumber: '6',
              MainTopTitle: 'Care Team',
              SubtitleText: 'Timeline',
              data: [
                {
                  circleTitle: 'X',
                  boxTitle: 'Oncologist',
                  statement: '(Regularly since start)',
                  when: 'From start of diagnosis',
                  what: 'Overall monitoring ',
                  frequency:
                    '0-1 Weekly1-12 Every 4 weeks1-5 Every 8 weeks5+ Every 12 weeksAdult 3-6 months(frequency may increase by stage)',
                },
                {
                  circleTitle: 'X',
                  boxTitle: 'Plastic surgeon / Surgeon',
                  statement: '(Lumpectomy / Mastectomy or Reconstruction)',
                  when: 'as per requirement',
                  what: 'perform surgery and reconstruct parts of the body',
                  frequency: 'when consulted',
                },
                {
                  circleTitle: 'X',
                  boxTitle: 'Radiation Oncologist',
                  statement: '(Pregnancy & Cervical Cancer)',
                  when: 'as per requirement',
                  what: 'Treats and manages cancer with the help of radiation therapy',
                  frequency: 'when consulted.',
                },
                {
                  circleTitle: 'X',
                  boxTitle: 'Palliative Care',
                  statement: '(End of life)',
                  when: 'End Stage',
                  what: 'End of life planning',
                  frequency: '?',
                },
              ],
            }
          : action.payload === '124'
          ? {
              titleNumber: '6',
              MainTopTitle: 'Care Team',
              SubtitleText: 'Timeline',
              data: [
                {
                  circleTitle: '1',
                  boxTitle: 'Pulmonologist',
                  statement: '(COPD treatment management)',
                  when: 'Usually once diagnosis is confirmed by spirometry, primary care provider may refer to specialist care',
                  what: "Management of COPD therapies and monitoring of disease progression. The pulmonologist is usually the patient's point person for all COPD-related care.",
                  frequency:
                    ' Varies from once a year to every few months depending on severity.',
                },
                {
                  circleTitle: '1',
                  boxTitle: 'Primary Care Doctor',
                  statement:
                    '(Initial diagnosis and referral, lifestyle modifications)',
                  when: 'Regular care',
                  what: "The primary care provider is often the first to suspect COPD and may order spirometry, start initial therapies, and refer to pulmonologist for more advanced level care. In addition, they may play an important part in optimizing the patient's health in other domains, such as smoking cessation, nutrition, and management of comorbidities.",
                  frequency: 'Typically once a year to every few months.',
                },
                {
                  circleTitle: 'AN',
                  boxTitle: 'Social worker',
                  statement: '(Managing Heart Failure)',
                  when: 'If anxiety and depression, for positive social support, help.',
                  what: 'Supplemental O2 or access to other medical equipment, empower and advocate, counselling,etc.',
                  frequency: 'It is personalized.',
                },
                {
                  circleTitle: 'AN',
                  boxTitle: 'Cardiologist',
                  statement: '(Managing Heart Failure)',
                  when: 'Patient may be referred to a cardiologist if patient is exhibiting signs of heart failure, which may be a complication of COPD.',
                  what: 'Evaluation of heart function, management of heart failure as needed.',
                  frequency: 'Typically once a year or more often if needed.',
                },
                {
                  circleTitle: 'AN',
                  boxTitle: 'Surgeon',
                  statement: '(For surgical needs)',
                  when: 'if severe and frequent symptoms, surgery may be suggested.',
                  what: 'To remove the diseased lung tissues so that the remaining healthier portion can perform better',
                  frequency: 'Approx thrice a year',
                },
              ],
            }
          : action.payload === '126'
          ? {
              titleNumber: '6',
              MainTopTitle: 'Care Team',
              SubtitleText: 'Timeline',
              data: [
                {
                  boxTitle: 'Pulmonologist',
                  statement: '(Regularly since start)',
                  when: 'From start to diagnosis',
                  what: 'Overall Monitoring',
                  frequency:
                    '0-1 Weekly 1-12 Every 4 weeks 1-5 Every 8 weeks 5+ Every 12 weeks Adult 3-6 months (frequency may increase by stage)',
                },
                {
                  boxTitle: 'Geneticist',
                  statement: '(Within X months of diagnosis)',
                  when: 'From start of diagnosis',
                  what: 'Overall Monitoring',
                  frequency: 'prenatal testing if both partners are carriers',
                },
                {
                  boxTitle: 'GI',
                  statement: '(Gallbladder/Pancreas)',
                  when: 'When issues occur',
                  what: 'Gallbladder, Pancreas',
                  frequency: 'Based on symptoms',
                },
                {
                  boxTitle: 'Palliative Care',
                  statement: '(End of life)',
                  when: 'End Stage',
                  what: 'Advanced Care Planning',
                  frequency:
                    'severely impaired lung function <30-35%, 24h oxygen requirement, prolonged hospitizaling for CF morbidity',
                },
              ],
            }
          : action.payload === '128'
          ? {
              titleNumber: '6',
              MainTopTitle: 'Care Team',
              SubtitleText: 'Timeline',
              data: [
                {
                  boxTitle: 'Ophthalmologist',
                  circleTitle: 'F',
                  statement: '',
                  when: '',
                  why: '',
                  care: 'In-depth eye examinations, glaucoma diagnosis and staging, measurement of progression, prescription of medications, laser treatments, or surgeries',
                  frequency:
                    'Every 3-6 months, or as recommended.',
                  referral:
                    ' If unexplained red flags (i.e. color vision deficits, RAPD), may refer to neuro-ophthalmology',
                },
                {
                  boxTitle: 'Optometrist',
                  circleTitle: 'A',
                  statement: '',
                  when: '',
                  why: '',
                  care: 'Eye exams, new glasses, monitoring of glaucoma progression, collaboration with ophthalmologist',
                  frequency: 'Every 6-12 months, or as recommended.',
                  referral:
                    ' Will refer to an ophthalmologist if concerned for glaucoma',
                },
                {
                  boxTitle: 'General Physician',
                  circleTitle: 'A',
                  statement: '',
                  when: '',
                  why: '',
                  care: 'A primary care physician can help manage systemic health conditions (e.g., diabetes, hypertension) that impact glaucoma and provide patient education.',
                  frequency: 'Annual check-up or as needed.',
                },
              ],
            }
          : action.payload === '127'
          ? {
              titleNumber: '6',
              MainTopTitle: 'Care Team',
              SubtitleText: 'Timeline',
              data: [
                {
                  circleTitle: '',
                  boxTitle: 'Endocrinologist',
                  statement: '(Uncontrolled) (specialist for hormone diseases)',
                  when: 'Later in disease course if medication regimen is complex',
                  what: 'Understand disease, perform thorough check-up, treatment options and management of disease',
                  frequency: 'Every 3-4 months.',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Primary Care Doctor',
                  statement: '(Glucose Control)',
                  when: 'Regularly since diagnosis',
                  what: 'Regular check-ups, perform blood tests for sugar levels, performs annual foot exam',
                  frequency: 'Check-ups every 3-6 months',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Optometrist',
                  statement: '(Regularly since start)',
                  when: 'Disease is diagnosed',
                  what: 'Vision changes for prescriptions when vision changes',
                  frequency:
                    'As needed (Could be as  early as every 3 months if the prescription is changing).',
                },
              ],
            }
          : action.payload === '129'
          ? {
              titleNumber: '6',
              MainTopTitle: 'Care Team',
              SubtitleText: 'Timeline',
              data: [
                {
                  circleTitle: '',
                  boxTitle: 'Opthamologist',
                  statement: '(Regularly since start)',
                  when: 'From start of diagnosis',
                  what: 'Treatment (Anti VEGF Injections) Monitoring including Testing: Amsler, Visual Acuity, Fundus (maybe OCT or Angio, Fundus photography if progressing) Guidance: Nutritional supplement recommendations',
                  frequency: '1/3/6 months.',
                },
                {
                  circleTitle: '',
                  boxTitle: 'Primary Care Doctor',
                  statement: '(Regularly since start)',
                  when: 'Regular schedule',
                  what: 'PCP role relates to other diseases that may accelerate Wet AMD - Hyperlipidemia, Hypertension and Wellness [Smoking, Diet & Exercise]',
                  frequency: 'Schedule.',
                },
                {
                  circleTitle: 'AN',
                  boxTitle: 'Optometrist',
                  statement: '(Regularly since start)',
                  when: 'Disease is diagnosed',
                  what: 'Vision changes for prescriptions when vision changes',
                  frequency:
                    'As needed (Could be as  early as every 3 months if the prescription is changing).',
                },
              ],
            }
          : {};
    },
    setMonitoringData(state, action) {
      state.monitoring =
        action.payload === '120'
          ? {
              TopmainTitle: 'Monitoring',
              TitleNumber: '7',
              arrowBoxTilte: 'Monitor',
              data: [
                {
                  circleTitle: 'Daily',
                  boxTitle: 'Home Monitoring',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Weight',
                      what: 'Changes in weight',
                      why: 'Indicates therapy is not working',
                      scale: 'Weight change',
                    },
                    {
                      subtitle: 'Resistance',
                      what: 'Presence of genetic mutations against specific drugs',
                      why: 'Blood sample is analysed for the presence of genetic mutations that may cause resistance to specific drugs.',
                      scale: 'Blood Test.',
                    },
                    {
                      subtitle: 'Depression',
                      what: 'Presence of genetic mutations against specific drugs',
                      why: 'If medicines are not taken on a timely basis, the patient develops resistance to the disease because the virus mutates.',
                      scale:
                        'Symptoms, clinical evaluation and tests for diseases.',
                    },
                  ],
                },
                {
                  circleTitle: 'Annual',
                  boxTitle: 'Infectious Disease or PCP Monitoring',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Infections',
                      what: 'Infections. ',
                      why: 'Indicates therapy is not working or staging for AIDS.',
                      scale:
                        'Symptoms, clinical evaluation and tests for diseases',
                    },
                    {
                      subtitle: 'Viral Load',
                      what: 'Viral Load ',
                      why: 'Directly measure the amount of HIV RNA in blood. High count indicates virus is replicating and resistant to therapy.',
                      scale:
                        "High viral load: > 100,000 copies/mLLow viral load: >10,000 copies/mL Undetectable or “virally suppressed”: 40-75 copies/mL'",
                    },
                    {
                      subtitle: 'Adherence',
                      what: '% of medicines taken',
                      why: 'If medicines are not taken on a timely basis, the patient develops resistance to the disease because the virus mutates',
                      scale: 'Adherent (>95% pills taken) or not adherent',
                    },
                    {
                      subtitle: 'CD4',
                      what: 'CD4+ cells',
                      why: 'CD4+ cells are a measure of the measure patient’s immune system function. This test indicates if therapy is working.',
                      scale:
                        "Normal: >500-1500 cells/mm3: Normal. Clinical Latency: 500-700 cells/mm3 AIDS: < 200 cells/mm3',",
                    },
                  ],
                },
              ],
            }
          : action.payload === '125'
          ? {
              TopmainTitle: 'Monitoring',
              TitleNumber: '7',
              arrowBoxTilte: 'Monitor',
              data: [
                {
                  circleTitle: 'Daily',
                  boxTitle: 'Home Monitoring',
                  statement: '',
                  items: [
                    {
                      subtitle: 'SPO2',
                      what: '',
                      why: '',
                      scale: '',
                    },
                    {
                      subtitle: 'Temperature',
                      what: '',
                      why: '',
                      scale: '',
                    },
                    {
                      subtitle: 'Heart Rate',
                      what: '',
                      why: '',
                      scale: '',
                    },
                  ],
                },
                {
                  circleTitle: 'Annual',
                  boxTitle: 'Hospital Monitoring',
                  statement: '',
                  items: [
                    {
                      subtitle: 'X-Ray or CT',
                      what: '',
                      why: '',
                    },
                    {
                      subtitle: 'D-Dimer Levels',
                      what: '',
                      why: '',
                      scale: '',
                    },
                    {
                      subtitle: 'Ventilation',
                      what: '',
                      why: '',
                      scale: '',
                    },
                  ],
                },
              ],
            }
          : action.payload === '121'
          ? {
              TopmainTitle: 'Monitoring',
              TitleNumber: '7',
              arrowBoxTilte: 'Monitor',
              data: [
                {
                  circleTitle: 'Daily',
                  boxTitle: '',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Spirometry Test',
                      'what is detected': 'FVC and FEV1',
                      How: 'Ratio of FEV1 to FVC is taken',
                      Categorization: 'Device',
                      Accuracy: '',
                      Sensitivity: '23.0%',
                      Specificity: '90.0%',
                    },
                  ],
                },
                {
                  circleTitle: 'Annual',
                  boxTitle: '',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Peak Flow Meter',
                      'What is detected':
                        'Monitor the change in asthma-related signs and symptoms during the day or over a period of time',
                      How: 'Estimates the peak expiratory flow rate',
                      Categorisation: 'Device',

                      Sensitivity: '47.0%',
                      Specificity: '87.0%',
                    },
                  ],
                },
              ],
            }
          : action.payload === '122'
          ? {
              TopmainTitle: 'Monitoring',
              TitleNumber: '7',
              arrowBoxTilte: 'Monitor',
              data: [
                {
                  circleTitle: 'Daily/Weekly',
                  boxTitle: 'At lab',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Blood tests',
                      what: 'Fat and cholesterol levels',
                      why: 'Deposition of excess of fat and cholesterol leads to plaque formation',
                      How: 'drawing blood and sending for lab test',
                      scale:
                        'LDL: > 160 mg/dL indicates risk of heart disease HDL: > 45-55 mg/dL indicates no heart disease Triglycerides: > 150 mg/dL indicates heart disease Total Cholesterol: > 240 mg/dL indicates risk of heart disease',
                    },
                    {
                      circleTitle: 'Monthly/Yearly',
                      boxTitle: 'Hospital Monitoring',
                      statement: '',
                      items: [
                        {
                          subtitle: 'MRI',
                          what: 'Plaque progression',
                          why: 'Rupture of plaque can leads to several heart problems like heart attack and stroke',
                          How: 'By imaging the arteries along with detecting their dimensions for analysis of plaque progression',
                        },
                      ],
                    },
                  ],
                },
              ],
            }
          : action.payload === '130'
          ? {
              TopmainTitle: 'Monitoring',
              TitleNumber: '7',
              arrowBoxTilte: 'Monitor',
              data: [
                {
                  circleTitle: 'Monthly',
                  boxTitle: 'At clinic',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Mental Assessment',
                      what: 'Clock-drawing test, MoCA, ADAS-Cog',
                      why: 'People with AD struggle to draw proper shape and time.',
                      How: 'Person is asked to draw a clock showing a specific time. Series of tasks that measure cognitive function and decline',
                      scale: '',
                    },
                  ],
                },
                {
                  circleTitle: 'Monthly/Yearly',
                  boxTitle: 'At hospital',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Imaging (CT/MRI/PET)',
                      what: 'CT, MRI, PET scans',
                      why: 'CT and MRI measure the change in volume of the brain which shrinks in AD. PET scan reveals regions of the brain with reduced activity causing cognitive decline. ',
                      How: 'Imaging techniques',
                      scale: '',
                    },
                  ],
                },
              ],
            }
          : action.payload === '123'
          ? {
              TopmainTitle: 'Monitoring',
              TitleNumber: '7',
              arrowBoxTilte: 'Monitor',
              data: [
                {
                  circleTitle: 'Daily',
                  boxTitle: 'At home',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Self Exam',
                      what: 'Self examination of mammary glands',
                      why: 'to check for discharge and lumps',
                      How: 'Use pads of your fingers around your breast gently covering the entire breast area and armpit. Use light, medium, and firm pressure.',
                      scale: '',
                    },
                  ],
                },
                {
                  circleTitle: 'Monthly',
                  boxTitle: 'At hospital',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Mammogram',
                      what: "designed to take images of the patient's breasts",
                      why: 'in order to detect any tumors or other tissue abnormalities',
                      How: 'Imaging techniques',
                      scale: '',
                    },
                  ],
                },
                {
                  circleTitle: 'Yearly',
                  boxTitle: 'At hospital',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Pelvic Exams',
                      what: 'A physical exam of the vulva, vagina, cervix, uterus, fallopian tubes, ovaries, and rectum.',
                      why: 'in order to check signs of disease. Pelvic exams for endometrial cancer if taking hormonal therapy, tamoxifen or toremifene',
                      How: 'by physical exmination, check redness of vulva, lubricated, gloved fingers are inserted in the vagina and pressed on the lower abdomen.',
                      scale: '',
                    },
                    {
                      subtitle: 'Bone Mineral Density Tests',
                      what: 'to check strength of the bone, dual-energy X-ray absorptiometry',
                      why: 'to definitively diagnose bone loss and osteoporosis. If taking an aromatase inhibitor (anastrozole, letrozole, or exemestane)',
                      How: 'Imaging techniques - X-ray and sound waves',
                      scale: '',
                    },
                  ],
                },
                {
                  circleTitle: 'Monthly/Yearly',
                  boxTitle: 'At hospital',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Imaging',
                      what: 'designed to detect the disease and its stages',
                      why: 'if recurrence is suspected. Not done regularly.',
                      How: 'Imaging techniques - (bone scans, x-rays, or CT or PET scans)',
                      scale: '',
                    },
                    {
                      subtitle: 'Blood Tests',
                      what: 'complete blood count, measure, circulating tumor cell (CTC), tumor marker',
                      why: "not to diagnose but to get a sense of a person's overall health', especially after chemotherapy",
                      How: 'laboratory test by drawing blood from the patient',
                      scale: '',
                    },
                    {
                      subtitle: 'Biopsy & Sequencing',
                      what: 'done in case of recurrence',
                      why: 'in order to detect cancerous cells, mutations, cancer stem cells',
                      How: 'whole exome sequencing, next generation sequencing',
                      scale: '',
                    },
                  ],
                },
              ],
            }
          : action.payload === '124'
          ? {
              TopmainTitle: 'Monitoring',
              TitleNumber: '7',
              arrowBoxTilte: 'Monitor',
              data: [
                {
                  circleTitle: 'Yearly',
                  boxTitle: 'At clinic',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Spirometry test',
                      what: 'Ratio of FEV1 to FVC to  determines lung function',
                      why: 'To assess how well your lungs work by measuring how much air you inhale, how much you exhale and how quickly you exhale.',
                      How: 'Breathing test',
                      scale:
                        'Restricted Breathing: If ratio is >0.7 but FVC is decreased and FEV is normal, it indicates restricted breathing.',
                      Obstruction:
                        'If < 0.7 then it indicates obstruction in the lungs. FVC and FEV1 are decreased.',
                    },
                  ],
                },
                {
                  circleTitle: 'Monthly/Yearly',
                  boxTitle: 'Healthcare provider (questionnaire)',
                  statement: '',
                  items: [
                    {
                      subtitle: 'CAT test',
                      what: 'Score based on 8 questions, measures the severity of COPD.',
                      why: 'to assess patients’ health status, to have quantitative insight into one’s COPD condition',
                      How: 'Questionnaire',
                      scale:
                        '<10 CAT score - Low impact of COPD > 10 CAT score - High impact of COPD',
                    },
                  ],
                },
              ],
            }
          : action.payload === '126'
          ? {
              TopmainTitle: 'Monitoring',
              TitleNumber: '7',
              arrowBoxTilte: 'Monitor',
              data: [
                {
                  circleTitle: 'Daily',
                  boxTitle: 'At home',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Blood tests',
                      what: 'WBC (infections), Serum IgE, Aspergillus Serology, higher than normal levels of a chemical called immunoreactive trypsinogen (IRT)',
                      why: 'carrier screening, routine screen for newborns',
                      How: 'draw blood and send sample to screening lab',
                      scale: '',
                    },
                    {
                      subtitle: 'Diabetes Testing',
                      what: 'using clinical status, checking levels of random blood glucose, fasting plasma glucose, and glycated hemoglobin (HbA1c)',
                      why: 'develop excessive mucus, which in turn can scar the pancreas.',
                      How: 'continuous glucose monitoring (CGM) or an oral glucose tolerance test (OGTT).',
                      scale: '',
                    },
                  ],
                },
                {
                  circleTitle: 'Monthly/Yearly',
                  boxTitle: 'At clinic',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Lung Function Testing',
                      what: 'Spirometry-FVC and FEV1 baseline. Diffusion capacity-lungs move oxygen through the air sacs into the rest of the body.',
                      why: 'measure both the capacity (size) of the lungs and how well the air flows in and out of the lungs. It also helps the medical team understand if the lung disease is restrictive or obstructive',
                      How: 'ratio of FEV1 to FVC is taken using a spirometer',
                      scale: '',
                    },
                    {
                      subtitle: 'Chest X-Ray',
                      what: 'hyperinflation, bronchial thickening and dilatation, peribronchial cuffing, mucoid impaction, cystic radiolucencies.',
                      why: 'to more accurately quantitate the severity of disease',
                      How: 'uses a small dose of ionizing radiation to produce images to evaluate for dilated airways filled with mucus and to evaluate lung condition',
                      scale: '',
                    },
                    {
                      subtitle: 'Liver Function',
                      what: 'Screen for liver infections, such as hepatitis, fibrosis, mucus to build up and block bile ducts in the liver.',
                      why: 'monitor progression of disease and how well how well a treatment is working',
                      How: 'blood test is used to measure the levels of the liver enzymes such as aspartate transaminase (AST), alanine transaminase (ALT) and gamma-glutamyl transpeptidase (GGT).',
                      scale: '',
                    },
                  ],
                },
              ],
            }
          : action.payload === '128'
          ? {
              TopmainTitle: 'Monitoring',
              TitleNumber: '7',
              arrowBoxTilte: 'Monitor',
              data: [
                {
                  circleTitle: 'Every visit',
                  boxTitle: 'At Home',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Tonometry',
                      what: ' Measures the intraocular pressure (IOP)',
                      why: ' To screen for glaucoma and to measure how well glaucoma treatment is working. Pressing of the numbed eye onto the tonometer will measure the amount of pressure that can be applied to flatten the cornea.',
                      How: " Using a tonometer. An anaesthetic and dye are put into the individual's eye, light is passed through the eyes and touched with a tonometer.",
                      scale: '',
                    },
                  ],
                },
                {
                  circleTitle: 'Monthly / Yearly',
                  boxTitle: 'At Opthalmologist',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Perimetry',
                      what: " To tests a patient's visual field",
                      why: ' To map out any blind spots that may be present',
                      How: ' By flashing a light in different quadrants of vision',
                      scale: '',
                    },
                    {
                      subtitle: 'OCT / Optic Nerve Imaging',
                      what: ' Scans the images of the eye',
                      why: ' Allows clinicians to monitor for glaucomatous structural damage involving the optic nerve, peripapillary RNFL and macula, as well as to correlate that structural damage to functional vision loss.',
                      How: ' It includes techniques like optical coherence tomography (OCT). Scans the eye  by passing light rays through the eyes. A baseline test takes approximately 10 minutes. Repeat scans take approximately 5 minutes per eye.',
                      scale: '',
                    },
                    {
                      subtitle: 'Gonioscopy',
                      what: ' Allows the visualization of the anterior chamber of the eye.',
                      why: ' To check if patient has glaucoma and find its stage',
                      How: ' By using a goniolens and slit lamp. ',
                      scale: '',
                    },
                  ],
                },
              ],
            }
          : action.payload === '127'
          ? {
              TopmainTitle: 'Monitoring',
              TitleNumber: '7',
              arrowBoxTilte: 'Monitor',
              data: [
                {
                  circleTitle: 'Daily',
                  boxTitle: 'Home Monitoring',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Glucose (Finger Stick)',
                      what: 'This measures the blood glucose level when the individual has not eaten anything for a period of 8 hours or overnight.',
                      Why: 'Blood sugar level guides insulin dosing',
                      'Normal fasting blood glucose levels': '<99mg/dL',
                      Prediabetes: '100 to 125 mg/dL',
                      Diabetes: '>125 mg/dL',
                    },
                    {
                      subtitle: 'Blood Pressure (BP)',
                      what: 'Measures the pressure in the arteries',
                      why: 'High blood pressure in people with diabetes leads to a higher risk of complications (heart disease, stroke, nephropathy)',
                    },
                  ],
                },
                {
                  circleTitle: 'Monthly/Yearly',
                  boxTitle: 'Hospital Monitoring',
                  statement: '',
                  items: [
                    {
                      subtitle: 'HbA1c',
                      what: 'HbA1c is used both as a diagnostic and monitoring test in type II diabetes. For monitoring, the goal a1c is <7% generally. This goal may be higher in people who are more prone to low blood sugar, such as elderly people.',
                      'No diabetes': 'HbA1c below 42 mmol/L or 5.7%',
                      Prediabetes: 'HbA1c between 42 to 47 mmol/L or 5.7-6.4%',
                      'Type-2 diabetes': 'HbA1c of 48 mmol/L or 6.5% and above',
                      Why: 'This test gives an estimate of the average blood sugar level over three months',
                    },
                    {
                      subtitle: 'Eye Exam (Diabetic Retinopathy)',
                      what: 'An examination of the back of the eye (retina) and its blood vessels',
                      why: 'Diabetes can affect the eye in several ways leading to cataracts, glaucoma and/or blindness.',
                    },
                    {
                      subtitle: 'Urine Protein (Diabetic Nephropathy)',
                      what: 'This test looks at the amount of protein (specifically albumin) in a person’s urine and compares it to the amount of creatinine in the urine. Because diabetes is the most common cause of kidney disease, it is important to assess how the disease is impacting the kidney. An early sign of kidney disease is protein present in urine. This test is done once per year starting at time of diagnosis of type 2 diabetes.',
                      Why: 'Having more albumin in the urine means there is kidney damage.',
                      'Normal urine albumin': '<30 mg/g creatinine',
                      Microalbuminuria: '30-300 mg/g creatinine',
                      Macroalbuminuria: '>300 mg/g creatinine',
                    },
                    {
                      subtitle: 'Foot Exam (Diabetic Neuropathy)',
                      what: 'Tests the sensation in the feet using a small instrument and perform a visual inspection.',
                      why: 'When diabetes affects the feet, it leads to less sensation. It is also important to check for sores on the feet.',
                    },
                    {
                      subtitle: 'Lipid Panel',
                      what: 'Measures the amount of different types of cholesterol in the blood',
                      why: 'Elevated cholesterol in people with diabetes leads to a higher risk of complications (heart disease, stroke, nephropathy, peripheral artery disease)',
                    },
                  ],
                },
              ],
            }
          : action.payload === '129'
          ? {
              TopmainTitle: 'Monitoring',
              TitleNumber: '7',
              arrowBoxTilte: 'Monitor',
              data: [
                {
                  circleTitle: 'Daily',
                  boxTitle: 'Initial/Standard',
                  statement: '',
                  items: [
                    {
                      subtitle: "Amsler's Grid",
                      what: 'Abnormalities in vision due to macular damage',
                      why: 'Good initial test. Presence/absence of distortion and breaks in grid means disease has/progressed respectively.',
                      How: 'a chart containing horizontal and vertical lines that make a grid, with a prominent dot at the centre of the grid. Patients are tested under room lighting  with the grid held approximately 14 to 16 inches away.',
                    },
                    {
                      subtitle: 'Ophthalmoscopy ',
                      what: 'Abnormal/leaky blood vessels around the macula.',
                      why: 'Standard that should always be done.',
                      scale:
                        'Depending on stability of disease, it could range from 3 weeks to once a year.',
                      How: 'Eye test that allows the physician to view the fundus (inner lining of the eye) using ophthalmoscope.',
                    },
                  ],
                },
                {
                  circleTitle: 'Annual',
                  boxTitle: 'Preferential/Complexity',
                  statement: '',
                  items: [
                    {
                      subtitle: 'Angiography',
                      what: 'Any abnormalities in the choroid of the retina',
                      why: 'Physician preference',
                      How: 'Dye with fluorescent properties is injected I.V into the patient, drug binds to blood vessels of the retina, fundus camera is used to take images.',
                    },
                    {
                      subtitle: 'Optical Choherence Tomography (OCT)',
                      what: 'OCT scan of the macula shows the cross-section of all its layers',
                      why: 'This is useful if the patient is not getting better.',
                      How: 'Infra-red light is able to penetrate the vitreous chamber of the eye.',
                    },
                  ],
                },
              ],
            }
          : {};
    },
    setOutcomesData(state, action) {
      state.outcomes =
        action.payload === '120'
          ? {
              mainTitleNumber: '9',
              mainTitleText: 'Outcomes',
              descriptionBoxText:
                'Life expectancy after diagnosis is 9 years less than an HIV-negative person Life expectancy after reaching Stage (AIDS) is 3 years',
              titleBoxText: 'HIV Diagnosis',
              innerData: [
                {
                  sectionTitle: 'Infections',
                  childBox: [
                    { title: 'Tuberculosis', percent: '56%' },
                    { title: 'Cytomegalovirus', percent: '40%' },
                    { title: 'Candidiasis', percent: '43%' },
                  ],
                },
                {
                  sectionTitle: 'Skin',
                  childBox: [
                    { title: 'Herpes Simplex', percent: ' ' },
                    { title: 'Puritic Papular', percent: ' ' },
                    { title: 'Warts', percent: ' ' },
                  ],
                },
                {
                  sectionTitle: 'Other',
                  childBox: [
                    { title: 'Hepatitis', percent: ' ' },
                    { title: 'Depression', percent: ' ' },
                    { title: 'Lorem Ipsum', percent: ' ' },
                  ],
                },
              ],
              cardData: [
                {
                  content: 'Live with Stable Disease',
                  percent: '70 %',
                  bg: '#93C47D',
                  color: '#000000',
                },
                {
                  content: 'Live with Unstable Disease',
                  percent: ' ',
                  bg: '#FFA10A',
                  color: '#000000',
                },
                {
                  content:
                    'Die from complications Life expectancy 9 years less',
                  percent: ' ',
                  bg: '#000000',
                  color: '#ffffff',
                },
              ],
            }
          : action.payload === '125'
          ? {
              mainTitleNumber: '9',
              mainTitleText: 'Outcomes',
              descriptionBoxText:
                'Most patients are expected to recover from COVID-19, Outcomes depend significantly on age, comorbidities, vaccination status and strain',
              titleBoxText: 'COVID-19 Diagnosis',
              innerData: [
                {
                  sectionTitle: 'Symptoms',
                  childBox: [
                    { title: 'Symptomatic', percent: '50%' },
                    { title: 'Asymptomatic', percent: '50%' },
                  ],
                },
                {
                  sectionTitle: 'Severity of Symptoms',
                  childBox: [
                    { title: 'Mild to Moderate', percent: '80%' },
                    { title: 'Severe', percent: '20%' },
                  ],
                },
                {
                  sectionTitle: 'Hospitalization',
                  childBox: [
                    { title: 'Non ICU', percent: '85%' },
                    { title: 'ICU', percent: '15%' },
                  ],
                },
                {
                  sectionTitle: 'Oxygen',
                  childBox: [
                    { title: 'Oxygen', percent: '85%' },
                    { title: 'Ventilator', percent: '15%' },
                  ],
                },
              ],
              cardData: [
                {
                  content: 'Live with Stable Disease',
                  percent: ' ',
                  bg: '#93C47D',
                  color: '#000000',
                },
                {
                  content: 'Live with Unstable Disease',
                  percent: ' ',
                  bg: '#FFA10A',
                  color: '#000000',
                },
                {
                  content:
                    'Die from complications Life expectancy 9 years less',
                  percent: ' ',
                  bg: '#000000',
                  color: '#ffffff',
                },
              ],
            }
          : action.payload === '121'
          ? {
              mainTitleNumber: '9',
              mainTitleText: 'Outcomes',
              descriptionBoxText: '',
              titleBoxText: 'Asthma Diagnosis',
              innerData: [
                {
                  sectionTitle: 'Brain / Mental Health',
                  childBox: [
                    { title: 'Depression', percent: '20%' },
                    { title: 'Anxiety', percent: '20%' },
                  ],
                },
                {
                  sectionTitle: 'Respiratory',
                  childBox: [
                    { title: 'Sinusitis', percent: '75%' },
                    { title: 'Rhinitis', percent: '80%' },
                    { title: 'Respiratory infections', percent: '60%' },
                  ],
                },
                {
                  sectionTitle: 'Stomach',
                  childBox: [{ title: 'GERD', percent: '65%' }],
                },
              ],
            }
          : action.payload === '122'
          ? {
              mainTitleNumber: '9',
              mainTitleText: 'Outcomes',
              descriptionBoxText:
                'Life expectancy after diagnosis is 8-12 years less than an Atherosclerosis-negative person',
              titleBoxText: 'Atherosclerosis Diagnosis',
              innerData: [
                {
                  sectionTitle: 'Complications',
                  childBox: [
                    { title: 'Heart Attack', percent: '75%' },
                    { title: 'Ischemic Stroke', percent: '16%' },
                    { title: 'Limb Ischemia', percent: '90%' },
                    { title: 'Amputation', percent: '5%' },
                    { title: 'Renal Failure', percent: ' ' },
                  ],
                },
              ],
            }
          : action.payload === '130'
          ? {
              mainTitleText: 'Outcomes',
              descriptionBoxText:
                'Life expectancy after diagnosis is 3-11 years on average.',
              titleBoxText: "Alzheimer's Diagnosis",
              innerData: [
                {
                  sectionTitle: 'Severe (Stage 6)',
                  childBox: [
                    { title: 'Aggression ', percent: '15-20%' },
                    { title: 'Fear of Water', percent: ' ' },
                    { title: 'Depression', percent: '40%' },
                    { title: 'Anxiety', percent: '50-80%' },
                    { title: 'Seizures', percent: '10-20%' },
                  ],
                },
              ],
              cardData: [
                {
                  content:
                    'Die of old age (with progressively worsening Alzheimers)',
                  percent: ' ',
                  bg: '#93C47D',
                  color: '#000000',
                },
                {
                  content: 'Die from Alzheimers [20] years post diagnosis',
                  percent: ' ',
                  bg: '#FFA10A',
                  color: '#000000',
                },
              ],
            }
          : action.payload === '123'
          ? {
              mainTitleNumber: '9',
              mainTitleText: 'Outcomes',
              descriptionBoxText:
                'Life expectancy after reaching Stage 4 Breast cancer is 22%, median survival is 3 years ',
              titleBoxText: 'Breast cancer Diagnosis',
              innerData: [
                {
                  sectionTitle: 'General',
                  childBox: [
                    { title: 'Unintentional weight loss', percent: ' ' },
                    { title: 'Loss of appetite', percent: ' ' },
                    { title: 'Weakness', percent: ' ' },
                    { title: 'Headaches', percent: ' ' },
                  ],
                },
                {
                  sectionTitle: 'Bone',
                  childBox: [{ title: 'Bone pain ', percent: ' ' }],
                },
                {
                  sectionTitle: 'Lung',
                  childBox: [
                    { title: 'Pneumonia', percent: ' ' },
                    { title: 'Bronchiectasis', percent: ' ' },
                    { title: 'Shortness of breath', percent: ' ' },
                  ],
                },
              ],
              cardData: [
                {
                  content: 'Live with Cured Disease',
                  percent: ' ',
                  bg: '#93C47D',
                  color: '#000000',
                },
                {
                  content: 'Live with Stable Disease',
                  percent: ' ',
                  bg: '#FFA10A',
                  color: '#000000',
                },
                {
                  content: 'Die from Complications',
                  percent: '2.6%',
                  bg: '#000000',
                  color: '#ffffff',
                },
              ],
            }
          : action.payload === '124'
          ? {
              mainTitleNumber: '9',
              mainTitleText: 'Outcomes',
              descriptionBoxText:
                'Life expectancy after diagnosis is 40-70%. Life expectancy after reaching last stage is 5.8 years.',
              titleBoxText: 'COPD Diagnosis',
              innerData: [
                {
                  sectionTitle: 'Lungs',
                  childBox: [
                    { title: 'Dyspnea', percent: '73%' },
                    { title: 'High Wheezing', percent: '49%' },
                    { title: 'Pneumonia', percent: '43%' },
                    { title: 'COPD exacerbation', percent: ' ' },
                  ],
                },
                {
                  sectionTitle: 'Other',
                  childBox: [{ title: 'Heart failure', percent: ' ' }],
                },
              ],
              cardData: [
                {
                  content: 'Live with Stable Disease',
                  percent: ' ',
                  bg: '#93C47D',
                  color: '#000000',
                },
                {
                  content: 'Live with Unstable Disease',
                  percent: '80 %',
                  bg: '#FFA10A',
                  color: '#000000',
                },
                {
                  content:
                    'Die from complications Life expectancy 2 year survival is 50%',
                  percent: ' ',
                  bg: '#000000',
                  color: '#ffffff',
                },
              ],
            }
          : action.payload === '126'
          ? {
              mainTitleNumber: '9',
              mainTitleText: 'Outcomes',
              descriptionBoxText:
                'Life expectancy after diagnosis is 24 years less than an cystic fibrosis-negative person',
              titleBoxText: 'Cystic Fibrosis Diagnosis',
              innerData: [
                {
                  sectionTitle: 'Lungs',
                  childBox: [
                    { title: 'Lung infection', percent: ' ' },
                    { title: 'Wheezing/constant cough', percent: ' ' },
                    { title: 'Difficulty breathing', percent: ' ' },
                  ],
                },
                {
                  sectionTitle: 'Sinusitis',
                  childBox: [
                    { title: 'Chronic nasal congestion', percent: ' ' },
                    { title: 'Headache', percent: ' ' },
                    { title: 'Post nasal drips', percent: ' ' },
                  ],
                },
                {
                  sectionTitle: 'Other',
                  childBox: [
                    { title: 'Chronic diarrhoea', percent: ' ' },
                    { title: 'Pancreatitis ', percent: ' ' },
                    { title: 'Gall stones', percent: ' ' },
                  ],
                },
              ],
              cardData: [
                {
                  content: 'Live with Stable Disease',
                  percent: '',
                  bg: '#93C47D',
                  color: '#000000',
                },
                {
                  content: 'Live with Unstable Disease',
                  percent: '',
                  bg: '#FFA10A',
                  color: '#000000',
                },
                {
                  content: 'Die from complications',
                  percent: '23.1 %',
                  bg: '#000000',
                  color: '#ffffff',
                },
              ],
            }
          : action.payload === '128'
          ? {
              mainTitleText: 'Outcomes',
              descriptionBoxText: '',
              titleBoxText: 'Glaucoma Diagnosis',
              innerData: [
                {
                  sectionTitle: 'Complications',
                  childBox: [
                    { title: 'Tunnel Vision ', percent: ' ' },
                    { title: 'Blind Spots', percent: ' ' },
                    { title: 'Blindness', percent: ' ' },
                    { title: 'Chronic Eye Pain', percent: ' ' },
                  ],
                },
              ],
              cardData: [
                {
                  content: 'Controlled Glaucoma',
                  percent: ' ',
                  bg: '#93C47D',
                  color: '#000000',
                },
                {
                  content: 'Uncontrolled Disease (Non-severe vision loss)',
                  percent: ' ',
                  bg: '#FFA10A',
                  color: '#000000',
                },
                {
                  content: 'Uncontrolled Glaucoma (Vision Loss or Blindness)',
                  percent: ' ',
                  bg: '#000000',
                  color: '#ffffff',
                },
              ],
            }
          : action.payload === '127'
          ? {
              mainTitleNumber: '9',
              mainTitleText: 'Outcomes',
              descriptionBoxText:
                'Average reduction in life expectancy by 13 years caused by disease',
              titleBoxText: 'Type 2 Diabetes Diagnosis',
              innerData: [
                {
                  sectionTitle: 'Cardiovascular',
                  childBox: [
                    { title: 'Heart Attach', percent: ' ' },
                    { title: 'Atherosclerosis', percent: ' ' },
                    { title: 'Hypertension', percent: ' ' },
                  ],
                },
                {
                  sectionTitle: 'Worst Case Outcomes',
                  childBox: [
                    { title: 'CVD', percent: ' ' },
                    { title: 'PVD', percent: ' ' },
                    { title: 'Inflammatory Diseases', percent: ' ' },
                  ],
                },
                {
                  sectionTitle: 'Other Organs',
                  childBox: [
                    { title: 'Diabetic Retinopathy', percent: ' ' },
                    { title: 'Diabetic Nephropathy', percent: ' ' },
                    { title: 'Amputation of legs/Foot ulcer', percent: ' ' },
                    { title: 'Diabetic Nephropathy', percent: ' ' },
                  ],
                },
              ],
              cardData: [
                {
                  content: 'Live with Stable Disease',
                  percent: ' ',
                  bg: '#93C47D',
                  color: '#000000',
                },
                {
                  content: 'Live with Unstable Disease',
                  percent: ' ',
                  bg: '#FFA10A',
                  color: '#000000',
                },
                {
                  content:
                    'Die from complications. Life expectancy roughly 13 years less (based on age of diagnosis)',
                  percent: ' ',
                  bg: '#000000',
                  color: '#ffffff',
                },
              ],
            }
          : action.payload === '129'
          ? {
              mainTitleNumber: '9',
              mainTitleText: 'Outcomes',
              descriptionBoxText:
                'Life expectancy After diagnosis is 7 years with mono-ocular vision and another 4 years with bilateral blindness.',
              titleBoxText: 'Wet AMD Diagnosis',
              innerData: [
                {
                  sectionTitle: 'Early Stage',
                  childBox: [
                    { title: 'Visual Distortions', percent: '56%' },
                    {
                      title:
                        'Increased difficulty adapting to low light levels',
                      percent: ' ',
                    },
                    { title: 'Bright light while reading', percent: ' ' },
                  ],
                },
                {
                  sectionTitle: 'Intermediate Stage',
                  childBox: [
                    { title: 'Reduced Central Vision', percent: ' ' },
                    { title: 'Bluriness', percent: ' ' },
                    { title: 'Charles Bonnet Syndrome', percent: ' ' },
                  ],
                },
                {
                  sectionTitle: 'Late Stage AMD',
                  childBox: [
                    { title: 'Blind spot in central vision', percent: ' ' },
                    {
                      title: 'Permanant loss of central vision',
                      percent: ' ',
                    },
                  ],
                },
              ],
              cardData: [
                {
                  content: 'Live with Stable Disease',
                  percent: ' ',
                  bg: '#93C47D',
                  color: '#000000',
                },
                {
                  content: 'Live with Unstable Disease',
                  percent: ' ',
                  bg: '#FFA10A',
                  color: '#000000',
                },
                {
                  content:
                    'Die from complications Life expectancy X years less',
                  percent: ' ',
                  bg: '#000000',
                  color: '#ffffff',
                },
              ],
            }
          : {};
    },
  },
});

const { actions } = PatientJourneyReducer;

export const {
  setPatientJourneyData,
  setSymptomsData,
  setSymptomsOptions,
  setClassificationData,
  setStagesData,
  setCareTeamData,
  setMonitoringData,
  setOutcomesData,
  setSelectedOption,
} = actions;

export default PatientJourneyReducer;
