import React, { useState, useEffect } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import '../../scss/report.scss';

const ListPopover = ({list}) => {
  const [showMoreClicked, setshowMoreClicked] = useState(false);
    return (
      <>
        <CloseIcon className={`${showMoreClicked ? '' : 'd-none'}`} onClick={() => setshowMoreClicked(!showMoreClicked)}/>
        <MoreHorizIcon className={`${showMoreClicked ? 'd-none' : ''}`} onClick={() => setshowMoreClicked(!showMoreClicked)} />
        <div style={{zIndex:'99', overflowY:'scroll', height:'250px', right:'25%'}} className={`position-absolute list-group ${showMoreClicked ? '' : 'd-none'}`}>
          <ul>
            {Object.keys(list).map((keyName, i) => (
              list[keyName] && (
                <li class="list-group-item text-capitalize">
                  <Link
                  target="_blank"
                  title={keyName.replace('_',' ')}
                  to={{
                    pathname: list[keyName],
                  }}>
                    {keyName.replace('_',' ')}
                  </Link>
                </li>
              )
            ))}
          </ul>
        </div>
      </>
    )
};

export default ListPopover;
