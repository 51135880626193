import React from 'react';

const Covid19DiagnosisMobile = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          //   marginBottom: 10,
        }}
      >
        <div
          style={{
            backgroundColor: '#FFEBEF',
            border: '1px solid #C33C54',
            color: '#C33C54',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <p>Screening Tests</p>
        </div>
        <div
          style={{
            width: '80%',
            padding: '5px 10px',
            backgroundColor: '#A4C2F4',
            borderRadius: 5,
            fontWeight: 'bold',
            fontSize: 14,
            textAlign: 'center',
            alignSelf: 'center',
          }}
        >
          <p>Rapid Antigen (Nasal Swab)</p>
        </div>
        <div
          style={{
            height: 20,
            width: 1,
            backgroundColor: '#C33C54',
            alignSelf: 'center',
          }}
        ></div>
        <div
          style={{
            width: '50%',
            backgroundColor: '#C33C54',
            height: 1,
            alignSelf: 'center',
          }}
        ></div>
        <div style={{ alignSelf: 'center', width: '50%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '80%',
            alignSelf: 'center',
          }}
        >
          <p
            style={{
              wordBreak: 'break-word',
              width: '40%',
              border: '3px solid #980000',
              borderRadius: 10,
              padding: '5px 2px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Positive
          </p>
          <p
            style={{
              wordBreak: 'break-word',
              width: '40%',
              border: '3px solid #2BB670',
              borderRadius: 10,
              padding: '5px 2px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Intermediate or Negative
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div
            style={{ display: 'flex', width: '50%', justifyContent: 'center' }}
          >
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '50%',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: '#FFEBEF',
            border: '1px solid #C33C54',
            color: '#C33C54',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <p>Confirmatory Tests</p>
        </div>
        <div
          style={{
            width: '80%',
            padding: '5px 10px',
            backgroundColor: '#A4C2F4',
            borderRadius: 5,
            fontWeight: 'bold',
            fontSize: 14,
            textAlign: 'center',
            alignSelf: 'center',
          }}
        >
          <p>RT-PCR (Saliva or Blood)</p>
        </div>
        <div
          style={{
            height: 20,
            width: 1,
            backgroundColor: '#C33C54',
            alignSelf: 'center',
          }}
        ></div>
        <div
          style={{
            width: '50%',
            backgroundColor: '#C33C54',
            height: 1,
            alignSelf: 'center',
          }}
        ></div>
        <div style={{ alignSelf: 'center', width: '50%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '80%',
            alignSelf: 'center',
          }}
        >
          <p
            style={{
              wordBreak: 'break-word',
              width: '40%',
              border: '3px solid #980000',
              borderRadius: 10,
              padding: '5px 2px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Positive
          </p>
          <p
            style={{
              wordBreak: 'break-word',
              width: '40%',
              border: '3px solid #2BB670',
              borderRadius: 10,
              padding: '5px 2px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Negative
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', width: '25%', justifyContent: 'end' }}>
            <div
              style={{
                height: 250,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '70%',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
                marginLeft: '25%',
              }}
            ></div>
            <div
              style={{
                padding: '5px 10px',
                backgroundColor: '#A4C2F4',
                borderRadius: 5,
                fontWeight: 'bold',
                fontSize: 14,
                textAlign: 'center',
                alignSelf: 'center',
              }}
            >
              <p>Chest CT</p>
            </div>
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
              }}
            ></div>
            <div
              style={{
                width: '70%',
                backgroundColor: '#C33C54',
                height: 1,
                alignSelf: 'center',
              }}
            ></div>
            <div style={{ alignSelf: 'center', width: '70%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    height: 20,
                    width: 1,
                    backgroundColor: '#C33C54',
                  }}
                ></div>
                <div
                  style={{
                    height: 20,
                    width: 1,
                    backgroundColor: '#C33C54',
                  }}
                ></div>
                <div
                  style={{
                    height: 20,
                    width: 1,
                    backgroundColor: '#C33C54',
                  }}
                ></div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignSelf: 'center',
              }}
            >
              <p
                style={{
                  wordBreak: 'break-word',
                  width: '30%',
                  border: '3px solid #980000',
                  borderRadius: 10,
                  padding: '5px 2px',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Positive
              </p>
              <p
                style={{
                  wordBreak: 'break-word',
                  width: '30%',
                  border: '3px solid #2BB670',
                  borderRadius: 10,
                  padding: '5px 2px',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Intermittent
              </p>
              <p
                style={{
                  wordBreak: 'break-word',
                  width: '30%',
                  border: '3px solid #2BB670',
                  borderRadius: 10,
                  padding: '5px 2px',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Negative
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '70%',
              }}
            >
              <div
                style={{
                  height: 20,
                  width: 1,
                  backgroundColor: '#C33C54',
                }}
              ></div>
              <div
                style={{
                  height: 20,
                  width: 1,
                  backgroundColor: '#C33C54',
                }}
              ></div>
              <div
                style={{
                  height: 20,
                  width: 1,
                  backgroundColor: '#C33C54',
                }}
              ></div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '70%',
              }}
            >
              <div
                style={{
                  height: '100%',
                  width: 1,
                  backgroundColor: '#C33C54',
                }}
              ></div>
              <div
                style={{
                  padding: '5px 10px',
                  backgroundColor: '#A4C2F4',
                  borderRadius: 5,
                  fontWeight: 'bold',
                  fontSize: 14,
                  textAlign: 'center',
                  alignSelf: 'center',
                  width: '70%',
                }}
              >
                <p>RT-PCR (Monitor & Repeat)</p>
              </div>
              <div
                style={{
                  height: '100%',
                  width: 1,
                  backgroundColor: '#C33C54',
                }}
              ></div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '70%',
              }}
            >
              <div
                style={{
                  height: 35,
                  width: 1,
                  backgroundColor: '#C33C54',
                }}
              ></div>
              <div
                style={{
                  height: '100%',
                  width: 1,
                  backgroundColor: '#C33C54',
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            width: '60%',
            padding: '5px 10px',
            backgroundColor: '#980000',
            borderRadius: 5,
            fontWeight: 'bold',
            fontSize: 14,
            textAlign: 'center',
            alignSelf: 'center',
            color: '#fff',
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>Covid 19 Positive</p>
        </div>
        <div
          style={{
            width: '30%',
            padding: '5px 10px',
            backgroundColor: '#2BB670',
            borderRadius: 5,
            fontWeight: 'bold',
            fontSize: 14,
            textAlign: 'center',
            alignSelf: 'center',
            color: '#fff',
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>Covid 19 Negative</p>
        </div>
      </div>
    </div>
  );
};

export default Covid19DiagnosisMobile;
