import React, {useState} from 'react';
import { Modal, Backdrop, Fade, Card, CardHeader, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { lightGreen } from '@material-ui/core/colors';
import { useParams, Link } from 'react-router-dom';
import { nameToInitials, numberWithCommas } from './Helper';
import CIcon from '@coreui/icons-react';
import ListPopover from '../../containers/formField/ListPopover';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: '80%',
    maxWidth: '70%',
    backgroundColor: '#fff',
    overflowY: 'auto',
    maxHeight: '80%',
    position: 'relative',
    boxShadow: 'none',
  },
  header: {
    textAlign: 'center',
    background: '#2bb670',
    fontWeight: 'bold',
    padding: '10px 0',
    position: 'sticky',
    top: 0,
    '& span': {
      color: '#fff !important',
    }
  },
  cards: {
    marginTop: 20,
    padding: 0,
  },
  cardsHeader: {
    display: 'flex',
    background: '#c5ecda',
    fontWeight: 'bold',
    padding: '10px 10px',
    '& h3' : {
      fontSize: 18,
      fontWeight: 'bold',
      margin: 0,
    },
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  leftCard: {
    width: '70%',
  },
  rightCard: {
    width: '30%',
    textAlign: 'right',
  },
  cardsBody: {
    display: 'flex',
    padding: 0,
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  cardsBodyLeft: {
    width: '40%',
    padding: 10,
  },
  cardsBodyRight: {
    width: '60%',
    padding: 10,
    borderLeft: '1px solid #ccc',
    paddingLeft: 10,
  },
  closeBtn: {
    position: 'fixed',
    top: 0,
    right: 0,
    background: '#c5ecda',
    border: 'none',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#387249',
    padding: '11px 20px',
    cursor: 'pointer',
  },
  bold:{
    fontWeight:'bold'
  },
  fontSize:{
    fontSize:'18px'
  }
}));

function PlayersModal({ item, onClose }) {
  const { diseaseName } = useParams();
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  console.log(item)
  return (
    {item} &&
      <Modal
        className={classes.modal}
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Card className={classes.card}>
            <CardHeader style={{zIndex:'9999'}}
              className={classes.header}
              title={item['company_name']?item['company_name']:item['name']}
            />
            <button className={classes.closeBtn} onClick={handleClose}>x</button>
            <CardContent>
              <Card className={classes.cards}>
                <CardContent style={{padding:0}}>
                  <div className="container">
                    <div className="row pt-3">
                      <div className="col-8">
                            <h1 className="me-3">
                              <span class="me-2">
                              {item['company_name']?item['company_name']:item['name']}
                              </span>
                            </h1>
                      </div>
                    </div>
                    <div className="row pt-3">
                      {item['nextData'] ? (
                      <>
                        <div className="col btn-quick-nav active text-center m-2 p-3">
                          <h1>{numberWithCommas(item['nextData']['treatments'])}</h1>
                          <h4>Approved</h4>
                        </div>
                        <div className="col btn-quick-nav active text-center m-2 p-3">
                          <h1>{numberWithCommas(item['nextData']['trials'])}</h1>
                          <h4>Trials</h4>
                        </div>
                        <div className="col btn-quick-nav active text-center m-2 p-3">
                          <h1>{numberWithCommas(item['nextData']['preclinicals'])}</h1>
                          <h4>Preclinicals</h4>
                        </div>
                        <div className="col btn-quick-nav active text-center m-2 p-3">
                          <h1>{item['nextData']['open_payments']>0?'$':''}{numberWithCommas(item['nextData']['open_payments'])}</h1>
                          <h4>Payments</h4>
                        </div>
                      </>
                      ):''}
                    </div>
                    <div className="row pt-3">
                      { item['public_company'] ? (
                        <div className="col-12 mb-3">
                          <h4>Public Company Details</h4>
                          <table class="table table-bordered modal-table">
                            <tr>
                              <th className={classes.bold}>
                                Stock Ticker
                              </th>
                              <th className={classes.bold}>
                                Stock Price
                              </th>
                              <th className={classes.bold}>
                                Market Cap
                              </th>
                            </tr>
                            <tr>
                              <th className={classes.fontSize}>
                                {item['public_company']['axl_stock_ticker']?item['public_company']['axl_stock_ticker']:'-'}
                              </th>
                              <th className={classes.fontSize}>
                                {item['public_company']['axl_stock_price_usd']?item['public_company']['axl_stock_price_usd']:'-'}
                              </th>
                              <th className={classes.fontSize}>
                                {item['public_company']['axl_market_cap']?item['public_company']['axl_market_cap']:'-'}
                              </th>
                            </tr>
                          </table>
                        </div>
                      ):''}
                      {item['nextData'] && item['nextData']['top_data'].map((top_item) => (
                        <div className="col-12 mb-3">
                          <h4>{top_item['data'].length > 0 && top_item['title']}</h4>
                          <table class="table table-bordered modal-table">
                            {top_item['data'].length > 0 && (
                              <>
                                <tr>
                                  <th className={classes.bold}>
                                    Intervention
                                  </th>
                                  <th className={classes.bold}>
                                    Intervention Category
                                  </th>
                                  <th className={classes.bold}>
                                    {top_item['data_type']=='sum'?'Payment':'Total'}
                                  </th>
                                </tr>
                              </>
                            )}
                            {top_item['data'].map((item) => (
                              <tr>
                                <td className={classes.fontSize}>
                                  {item['name']?item['name']:'Others'} 
                                </td>
                                <td className={classes.fontSize}>
                                  {item['category']?item['category']:'Others'} 
                                </td>
                                <td className={classes.fontSize}>
                                  {top_item['data_type']=='sum'?'$':''}{numberWithCommas(item['total'])}
                                </td>
                              </tr>
                            ))}
                          </table>
                        </div>
                      ))}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
  );
};

export default PlayersModal;