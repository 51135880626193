import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getFilters, getQuestions } from '../apiCalls/backendUrl';

const config = {
  headers: {
    Authorization: `Token 03314be0379da6d92a7ae3e680ab6879f6b00f2c`,
  },
};

const QuestionBankReducer = createSlice({
  name: 'questions',
  initialState: {
    allQuestions: [],
    categories: [],
    filters: { stage: [], specialist: [] },
    questionsToPrint: [],
    displaySelectedQuestions: false,
  },
  reducers: {
    setAllQuestions(state, action) {
      state.allQuestions = [...action.payload];
    },
    setQuestionsCategories(state, action) {
      state.categories = [...action.payload];
    },
    setFilters(state, action) {
      state.filters.stage = [...action.payload.stage];
      state.filters.specialist = [...action.payload.specialist];
    },
    setQuestionsToPrint(state, action) {
      console.log(action.payload);
      state.questionsToPrint = [...state.questionsToPrint, action.payload];
    },
    setDisplaySelectedQuestions(state, action) {
      state.displaySelectedQuestions = !state.displaySelectedQuestions;
    },
  },
});

const { actions } = QuestionBankReducer;

export const {
  setQuestionsCategories,
  setFilters,
  setAllQuestions,
  setQuestionsToPrint,
  setDisplaySelectedQuestions,
} = actions;

export const getQuestionsById = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${getQuestions}/${id}`, config);
    const { data: filters } = await axios.get(`${getFilters}/${id}`, config);
    const categories = [];
    console.log(filters);
    dispatch(setFilters(filters));

    console.log(data);
    dispatch(setAllQuestions(data));

    data.map((item, index) => {
      if (!categories.includes(item.category)) {
        // const category = item.category;
        categories.push(item.category);
      }
      // if (!classification.includes(item.category)) {
      //   subCategories.push(item.subcategory);
      // }
      // if (!stage.includes(item.category)) {
      //   stage.push(item.stage);
      // }
    });

    // data.map((item, index) => {

    // })
    console.log(categories);
    dispatch(setQuestionsCategories(categories));
  } catch (error) {
    console.log(error);
  }
};

export default QuestionBankReducer;
