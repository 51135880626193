import React from 'react';
import { makeStyles, Grid, useMediaQuery } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import T2DDiagnosisMobile from './T2DDiagnosisMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },

    // border: "1px solid #000",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },

  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #C33C54',
    margin: '0 15px 0 0 ',
    background: '#FFEBEF',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#C33C54',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#FFEBEF',
    borderRadius: '200px',
    border: '1px solid #C33C54',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#C33C54',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },

  headingBox: {
    backgroundColor: '#FFEBEF',
    border: '1px solid #C33C54',
    width: '100%',
    height: 34,
    color: '#C33C54',
    fontSize: '14px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  blueBoxSmall: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#9FCEFC',
    height: '40px',
    width: '240px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '1rem',
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  // Arrow
  arrow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
  },
  arrowtail: {
    background: '#C33C54',
    height: '20px',
    width: '1.5px',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '6px solid #C33C54',
    borderRight: '6px solid transparent',
    borderLeft: '6px solid transparent',
  },
  // branch
  branchWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  branchMain: {
    borderRight: '1.5px solid #C33C54',
    borderLeft: '1.5px solid #C33C54',
    borderTop: '1.5px solid #C33C54',
  },
  branchMiddleLine: {
    justifySelf: 'center',
    background: '#C33C54',
  },
  redBorderGrayBox: {
    width: '144px',
    minHeight: '24px',
    borderRadius: '12px',
    border: '1px solid #C33C54',
    backgroundColor: '#EEF0F0',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      fontSize: '9px',
    },
  },
  GreenBox: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#2BB670',
    height: '30px',
    width: '174px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100px',
      fontSize: '10px',
    },
  },

  redBoldTextBig: {
    color: '#C33C54',
    fontWeight: 'bold',
    fontSize: '20px',
    margin: '10px 0',
  },
}));

const Arrow = ({ tailHeight, tailWidth, arrowPointStyle, arrowMainStyle }) => {
  const classes = useStyles();
  return (
    <div class={classes.arrow} style={arrowMainStyle}>
      <div class={classes.arrowtail} style={{ height: tailHeight }}></div>
      <div class={classes.arrowpoint}></div>
    </div>
  );
};

const Branch = ({
  branchStyle,
  middleLineStyle,
  branchClassName,
  middleLineClassName,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.branchWrap}>
      <div className={classes.branchMiddleLine} style={middleLineStyle}></div>
      <div className={classes.branchMain} style={branchStyle}></div>
    </div>
  );
};

const T2DDiagnosis = ({ noFloat, noNumber }) => {
  const classes = useStyles();

  const data = { mainTitleText: 'Diagnosis', mainTitleNumber: '3' };
  const matches = useMediaQuery('(max-width:600px)');
  return isMobile ? (
    <T2DDiagnosisMobile />
  ) : (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      <div className={classes.timelineAppBar}>
        <div className={classes.timelineHeading}>
          {!noNumber && (
            <div className={classes.timelineCircleBG}>
              {data.mainTitleNumber}
            </div>
          )}
          <div className={classes.timelineTitle}>{data.mainTitleText}</div>
        </div>
      </div>
      <div
        style={{
          marginTop: '20px',
          display: 'grid',
          gridTemplateColumns: '10% 90%',
        }}
      >
        <div className='left-grid'>
          <div style={{ position: 'relative' }}>
            <div
              className={classes.headingBox}
              style={{
                marginTop: '20px',
                height: '500px',
                width: '40px',
              }}
            >
              <p style={{ transform: 'rotate(270deg)' }}>Screening</p>
            </div>
            <div
              className={classes.headingBox}
              style={{
                marginTop: '20px',
                height: '600px',
                width: '40px',
              }}
            >
              <p style={{ transform: 'rotate(270deg)', width: '100px' }}>
                Confirmatory
              </p>
            </div>
          </div>
        </div>
        <div className='right-grid' style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '4px',
              borderBottom: '1px solid #000',
              top: '530px',
            }}
          ></div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <div>
              <div className={classes.blueBoxSmall} style={{ width: '180px' }}>
                <p>Fasting Plasma Glucose Test</p>
              </div>
              <Branch
                middleLineStyle={{
                  width: '1.5px',
                  height: '26px',
                  marginLeft: '-30px',
                }}
                branchStyle={{
                  width: matches ? '100%' : '300px',
                  height: '30px',
                  marginLeft: '-30px',
                }}
              />
              <div style={{ position: 'relative' }}>
                <div style={{ display: 'flex' }}>
                  <div
                    className={classes.redBorderGrayBox}
                    style={{
                      // position: "absolute",
                      // left: "-60px",
                      marginLeft: '-40px',
                      width: matches ? '70px' : '100px',
                    }}
                  >
                    <p>≤ 99 mg/dL {`(<5.6 mmol/L)`}</p>
                  </div>
                  <Arrow
                    arrowMainStyle={{
                      left: matches ? '-20px' : '-30px',
                      top: 41,
                    }}
                    tailHeight={68}
                  />
                  <div
                    className={classes.GreenBox}
                    style={{
                      position: 'absolute',
                      left: matches ? '-40px' : '-60px',
                      top: '115px',
                      color: 'white',
                      width: '120px',
                      fontSize: '12px',
                    }}
                  >
                    <p>Diabetes: Negative</p>
                  </div>
                  <div
                    className={classes.redBorderGrayBox}
                    style={{
                      // position: "absolute",
                      // right: "34%",
                      marginLeft: '20px',
                      width: matches ? '70px' : '100px',
                    }}
                  >
                    <p>100-125 mg/dL (5.6-6.9 mmol/L)</p>
                  </div>
                  <div
                    className={classes.redBorderGrayBox}
                    style={{
                      position: 'absolute',
                      right: '-30px',
                      //   marginLeft: "90px",
                      width: matches ? '70px' : '100px',
                      height: matches ? '60px' : '',
                    }}
                  >
                    <p>≥ 126 mg/dL (7 mmol/L)</p>
                  </div>
                </div>

                <Branch
                  middleLineStyle={{
                    width: '0px',
                    height: '26px',
                    marginLeft: '-30px',
                  }}
                  branchStyle={{
                    width: matches ? '70%' : '150px',
                    height: '30px',
                    marginLeft: matches ? '70px' : '110px',
                    borderTop: 0,
                    borderBottom: '1.5px solid #C33C54',
                    marginTop: -26,
                  }}
                />
                <Arrow
                  arrowMainStyle={{
                    right: matches ? '30px' : '30%',
                    top: 70,
                  }}
                  tailHeight={matches ? 90 : 90}
                />
                <div style={{ marginTop: '95px' }}>
                  <div
                    className={classes.blueBoxSmall}
                    style={{
                      position: 'absolute',
                      width: matches ? '150px' : '180px',
                      left: matches ? '90px' : '120px',
                      height: '45px',
                    }}
                  >
                    <p>Random Plasma Glucose Test</p>
                  </div>
                </div>
                <Branch
                  middleLineStyle={{
                    width: '1.5px',
                    height: '26px',
                    marginLeft: '100px',
                    marginTop: '40px',
                  }}
                  branchStyle={{
                    width: matches ? '90%' : '230px',
                    height: '30px',
                    marginLeft: '80px',
                  }}
                />
                <div style={{ position: 'relative' }}>
                  <div style={{ display: 'flex' }}>
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        // position: "absolute",
                        // left: "-60px",
                        marginLeft: matches ? '0px' : '30px',
                        width: '100px',
                      }}
                    >
                      <p>{`< 200 mg/dL (< 11.1 mmol/L)`}</p>
                    </div>
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        position: 'absolute',
                        right: matches ? '-50px' : '-30px',
                        // marginRight: "30px",
                        width: '100px',
                      }}
                    >
                      <p>{`≥ 200 mg/dL
(> 11.1 mmol/L)`}</p>
                    </div>
                  </div>
                  <Branch
                    middleLineStyle={{
                      width: '0px',
                      height: '26px',
                      marginLeft: '-30px',
                    }}
                    branchStyle={{
                      width: matches ? '90%' : '230px',
                      height: '30px',
                      marginLeft: '80px',
                      borderTop: 0,
                      borderBottom: '1.5px solid #C33C54',
                      marginTop: -25,
                    }}
                  />
                  <Arrow
                    arrowMainStyle={{
                      right: matches ? '30px' : '35%',
                      top: matches ? '80px' : 71,
                    }}
                    tailHeight={matches ? 85 : 90}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '95px' }}>
            <div
              className={classes.blueBoxSmall}
              style={{
                position: 'absolute',
                width: '120px',
                left: '45%',
                height: '45px',
              }}
            >
              <p>HbA1c Test</p>
            </div>
          </div>
          <Branch
            middleLineStyle={{
              width: '1.5px',
              height: '26px',
              marginLeft: 62,
              marginTop: '40px',
            }}
            branchStyle={{
              width: matches ? '70%' : '340px',
              height: '30px',
              marginLeft: '20px',
            }}
          />
          <div style={{ position: 'relative' }}>
            <div style={{ display: 'flex' }}>
              <div
                className={classes.redBorderGrayBox}
                style={{
                  marginLeft: '20px',
                  width: matches ? '80px' : '100px',
                  height: '60px',
                }}
              >
                <p>{`<5.5`}</p>
              </div>
              <Arrow
                arrowMainStyle={{
                  left: matches ? '30px' : '15%',
                  top: matches ? '60px' : '60px',
                }}
                tailHeight={61}
              />
              <div
                className={classes.GreenBox}
                style={{
                  position: 'absolute',
                  left: '10px',
                  top: '127px',
                  color: 'white',
                  width: matches ? '90px' : '120px',
                  fontSize: '12px',
                  textAlign: 'center',
                  height: matches ? '40px' : '',
                }}
              >
                <p>Diabetes: Negative</p>
              </div>
              <div
                style={{
                  position: 'absolute',
                  width: '1.5px',
                  height: '30px',
                  backgroundColor: '#C33c54',
                  left: matches ? '59.2%' : '56.2%',
                  top: -30,
                }}
              ></div>
              <div
                className={classes.redBorderGrayBox}
                style={{
                  position: 'absolute',
                  left: '40%',
                  //   marginLeft: "-60px",
                  width: matches ? '80px' : '100px',
                  height: '60px',
                }}
              >
                <p>{`> 6.5% `}</p>
              </div>
              <Arrow
                arrowMainStyle={{
                  right: matches ? '140px' : '45%',
                  top: matches ? '60px' : '60px',
                }}
                tailHeight={61}
              />
              <div
                className={classes.GreenBox}
                style={{
                  position: 'absolute',
                  left: matches ? '110px' : '170px',
                  top: '127px',
                  color: 'white',
                  width: matches ? '90px' : '120px',
                  fontSize: '12px',
                  background: '#C33C54',
                  textAlign: 'center',
                  height: matches ? '40px' : '',
                }}
              >
                <p>Diabetes: Positive</p>
              </div>

              <div
                className={classes.redBorderGrayBox}
                style={{
                  position: 'absolute',
                  right: matches ? '0px' : '30px',
                  width: matches ? '80px' : '100px',
                  height: '60px',
                }}
              >
                <p>5.7-6.4%</p>
              </div>
              <Arrow
                arrowMainStyle={{
                  right: matches ? '30px' : '15%',
                  top: matches ? '60px' : '60px',
                }}
                tailHeight={61}
              />
              <div
                className={classes.GreenBox}
                style={{
                  position: 'absolute',
                  right: matches ? '0px' : '30px',
                  top: '127px',
                  color: 'white',
                  width: matches ? '90px' : '120px',
                  fontSize: '12px',
                  textAlign: 'center',
                  height: matches ? '40px' : '',
                }}
              >
                <p>Pre Diabetes</p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '160px' }}>
            <div
              className={classes.blueBoxSmall}
              style={{
                position: 'absolute',
                width: '120px',
                left: '40%',
                height: '45px',
              }}
            >
              <p>C-Peptide Test</p>
            </div>
          </div>
          <Branch
            middleLineStyle={{
              width: '1.5px',
              height: '46px',
              marginLeft: '20px',
              marginTop: '40px',
            }}
            branchStyle={{
              width: matches ? '70%' : '280px',
              height: '30px',
              marginLeft: '20px',
            }}
          />
          <div style={{ position: 'relative' }}>
            <div style={{ display: 'flex' }}>
              <div
                className={classes.redBorderGrayBox}
                style={{
                  marginLeft: '20px',
                  width: matches ? '70px' : '100px',
                  height: '60px',
                }}
              >
                <p>{`> 250`}</p>
              </div>
              <Arrow
                arrowMainStyle={{
                  left: matches ? '50px' : '15%',
                  top: matches ? '60px' : '60px',
                }}
                tailHeight={61}
              />
              <div
                className={classes.GreenBox}
                style={{
                  position: 'absolute',
                  left: '10px',
                  right: matches ? '0px' : '30px',
                  top: '127px',
                  color: 'white',
                  width: matches ? '90px' : '120px',
                  fontSize: '12px',
                  textAlign: 'center',
                  background: '#C33C54',
                  height: matches ? '40px' : '',
                }}
              >
                <p>Type II</p>
              </div>
              <div
                style={{
                  position: 'absolute',
                  width: '1.5px',
                  height: '30px',
                  backgroundColor: '#C33c54',
                  left: matches ? '53%' : '52%',
                  top: -30,
                }}
              ></div>
              <div
                className={classes.redBorderGrayBox}
                style={{
                  position: 'absolute',
                  left: '40%',
                  //   marginLeft: "-60px",
                  width: matches ? '70px' : '100px',
                  height: '60px',
                }}
              >
                <p>{`81-249 `}</p>
              </div>
              {/* <Arrow
                arrowMainStyle={{
                  right: matches ? "30px" : "45%",
                  top: matches ? "60px" : "60px",
                }}
                tailHeight={matches ? 120 : 60}
              /> */}
              <div
                className={classes.GreenBox}
                style={{
                  position: 'absolute',
                  left: matches ? '120px' : '170px',
                  top: '127px',
                  color: 'white',
                  width: matches ? '90px' : '120px',
                  fontSize: '12px',
                  background: '#C33C54',
                  height: matches ? '40px' : '',
                  textAlign: 'center',
                }}
              >
                <p>MODY or Type I</p>
              </div>
              <Arrow
                arrowMainStyle={{
                  right: matches ? '50px' : '45%',
                  top: matches ? '60px' : '60px',
                }}
                tailHeight={61}
              />
              <div
                className={classes.redBorderGrayBox}
                style={{
                  position: 'absolute',
                  right: '30px',
                  width: matches ? '70px' : '100px',
                  height: '60px',
                }}
              >
                <p>{`< 80`}</p>
              </div>
              <Arrow
                arrowMainStyle={{
                  right: matches ? '45%' : '15%',
                  top: matches ? '60px' : '60px',
                }}
                tailHeight={61}
              />
              <div
                className={classes.GreenBox}
                style={{
                  position: 'absolute',
                  right: matches ? '0px' : '30px',
                  top: '127px',
                  color: 'white',
                  width: matches ? '90px' : '120px',
                  fontSize: '12px',
                  background: '#C33C54',
                  height: matches ? '40px' : '',
                  textAlign: 'center',
                }}
              >
                <p>Type I </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default T2DDiagnosis;
