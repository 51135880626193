import axios from "axios";
import {getReport,getCondition,deleteReport,getPublicCompanies,getCompanyPipeline,deleteReportPermission,deleteDbPermission,getImage,deleteImage} from "../backendUrl"

const serialize = function(obj) {
  var str = [];
  if(obj){
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
      let string = str.join("&");
      let search = ''
      if(Object.keys(obj).length !== 0){
        search = '?'+string
      }
      return search
  }else{
    return ''
  }
}

const getReports = (id) => {

  return axios({
    method: "get",
    url: `${getReport}/${id}`,
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`
    }
   
   
  });
};

const updateReport = (id, data) => {
  return axios({
    method: "patch",
    url: `${getReport}/${id}`,
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`
    },
    data: data
  });
};

const getConditions = () => {
  return axios({
    method: "get",
    url: `${getCondition}?limit=100`,
  });
};

const getConditionsAdmin = (count) => {
  return axios({
    method: "get",
    url: `${getCondition}?limit=100`,
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
    },
   
  });
};

const deleteReports = (id) => {

 
    return axios({
      method: "delete",
      url: `${deleteReport}/${id}`,
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  };

  const getConditionRecord = (id) => {
    
    return axios({
      method: "get",
      url: `${getCondition}/${id}`,
      headers: {
        Authorization:`Token ${localStorage.getItem('token')}`
      },
     
    });
  };

  const getConditionImage = (id) => {
    return axios({
      method: "get",
      url: `${getImage}/${id}/reference`,
      headers: {
        Authorization:`Token ${localStorage.getItem('token')}`
      },
    })
  }

  const ConditionImageDelete = (id,imgid) => {
    return axios({
      method: "delete",
      url: `${deleteImage}/${id}/reference/${imgid}`,
      headers: {
        Authorization:`Token ${localStorage.getItem('token')}`
      },
    })
  }


  const deleteCondition = (id) => {

 
    return axios({
      method: "delete",
      url: `${getCondition}/${id}`,
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  };


  const deleteReportPer = (id) => {

 
    return axios({
      method: "delete",
      url: `${deleteReportPermission}/${id}`,
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  };

  const deleteDBPer = (id) => {

 
    return axios({
      method: "delete",
      url: `${deleteDbPermission}/${id}`,
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });
  };

  const getPublicCompaniesData = (search) => {
    return axios({
      method: "get",
      url: `${getPublicCompanies}?limit=100&search=`+search,
    });
  };

  const getCompanyPipelineData = (searchItems) => {
    let search = serialize(searchItems)
    return axios({
      method: "get",
      url: `${getCompanyPipeline}`+search,
    });
  };


export { getReports,updateReport,getConditions,getPublicCompaniesData,deleteReports,getConditionRecord,deleteCondition,getConditionsAdmin,deleteReportPer,deleteDBPer,getConditionImage,ConditionImageDelete,getCompanyPipelineData};