import React from "react";

const StaticLoadingWidget = () => {
  return (
      <div style={{position:'fixed', top:0, zIndex:9999, left:0,background:'#ccccd0a3',width:'100%', height:'100%', backdropFilter:'blur(3px)'  }}>
        <div class="spinner-border" role="status" style={{width:'40px', height:'40px', marginTop:'22%', marginLeft:'48%'}}>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
  );
};

export default StaticLoadingWidget;
