import React, { useState } from 'react';
import {
  makeStyles,
  Collapse,
  Paper,
  Grid,
  ClickAwayListener,
} from '@material-ui/core';
import LightGreenArrowIcon from '../../../assets/img/LightGreenArrowIcon.svg';
import WhiteArrowIcon from '../../../assets/img//WhiteArrowIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  main: {
    position: 'relative',
    marginTop: '20px',
    width: '100%',
    // display: "grid",
    // gridTemplateColumns: "20% 80%",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #2BB670',
    margin: '0 15px 0 0 ',
    background: '#EEFBF5',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#2BB670',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#EEFBF5',
    borderRadius: '200px',
    border: '1px solid #2BB670',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#2BB670',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },

  headingSmallBox: {
    borderRadius: '9px',
    width: 'calc(100% / 4)',
    height: '60px',
    backgroundColor: '#2BB670',
    border: '2px solid #2BB670',
    color: '#000',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '14px',
    marginRight: '8px',
    '&:last-child': {
      marginRight: '0',
    },
    [theme.breakpoints.down('sm')]: {
      '& p': {
        marginTop: '10px',
        fontSize: '12px',
      },
    },
  },

  smallSubheadingCont: {
    width: '50%',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  darkGrayBar: {
    backgroundColor: '#888',
    height: '30px',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '5px',
    margin: '16px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem 0',
    '& p': {
      margin: '0',
    },
  },

  GreenBox: {
    width: '100%',
    borderRadius: '6px',
    border: '1px solid #EEFBF5 ',
    backgroundColor: '#2BB670',
    fontSize: '14px',
    fontWeight: 'bold',
    marginRight: '8px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
    padding: '1.5rem',
    '& p': {
      margin: '0',
    },
  },

  blueBoxSmall: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#9FCEFC',
    height: '50px',
    width: '100%',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    textAlign: 'center',
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },

  blackDivider: {
    position: 'absolute',
    width: '2px',
    height: '92%',
    backgroundColor: '#000',
    top: 0,
    zIndex: -1,
  },

  dropdownContainer: {
    width: '100%',
    borderRadius: 9,
    margin: '2px 0',
    // backgroundColor: "#EEFBF5",
    position: 'relative',
    // "&:first-child": {
    //   margin: "0px 0",
    // },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dropdownHeader: {
    border: '1.5px solid #2BB670',
    borderRadius: '6px',
    width: '100%',
    height: '40px',
    margin: '2px 0',
    '&:first-child': {
      margin: '0px 0',
    },
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ddHeadingText: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '0px',
    [theme.breakpoints.down('sm')]: {
      wordBreak: 'break-all',
      fontSize: '12px',
    },
  },
  dropdownBody: {
    backgroundColor: '#EEFBF5',
    position: 'absolute',
    zIndex: '10',
    border: '1px solid #000',
    padding: '0.5rem 1rem',
    width: '100%',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },
  dropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
  DropdownboxMainCont: {
    display: 'flex',
    position: 'relative',
    marginBottom: '1.5rem',
  },
  dropDownDetailText: {
    fontSize: '12px',
  },
  drugListItemContainer: {
    marginTop: '8px',
    padding: '0 0px 0 18px',
    '& li': {
      fontSize: '12px',
      wordWrap: 'break-word',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 0px 0 16px',
    },
  },
  drugShortDescriptionContainer: {
    backgroundColor: '#EEE',
    fontSize: '14px',
    padding: '1rem',
    borderRadius: '9px',
    width: '100%',
    margin: '0 0 1.5rem 0',
  },
  groupBox: {
    border: '1px solid #000',
    borderRadius: '28px',
    width: '100%',
    position: 'relative',
    // height: "100px",
  },

  //   ARROW

  arrow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
  },
  arrowtail: {
    background: '#000',
    height: '20px',
    width: '1px',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '4px solid #000',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
  },

  // BIG Arrow
  bigArrowCont: {
    width: '100%',
    marginLeft: 'auto',
    position: 'relative',
    '& p': {
      position: 'absolute',
      top: 19,
      fontWeight: '600',
      fontSize: '14px',
      left: '45%',
      transform: 'translateX(-50%)',
    },

    [theme.breakpoints.down('sm')]: {
      '& p': {
        top: '20px',
        fontSize: '10px',
      },
    },
  },
  bigarrow: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.75rem 0 0.5rem 0',
  },
  bigarrowtail: {
    background: '#EEE',
    height: '34px',
    width: 'calc(100% - 30px)',
  },
  bigarrowpoint: {
    width: 0,
    height: 0,
    borderTop: '30px solid transparent',
    borderLeft: '30px solid #eee',
    borderBottom: '30px solid transparent',
  },
}));

const DropdownBox = ({
  dropdownData,
  styleData,
  subheadingTextStyle,
  arrowImgstyle,
}) => {
  const classes = useStyles();
  const [openDropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown((prev) => !prev);
  };
  const handleClickAway = () => {
    setDropdown(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={classes.dropdownContainer}
        style={
          styleData && {
            width: openDropdown ? '100%' : styleData.width,
            marginRight: styleData.marginRight,
            marginLeft: styleData.marginLeft,
          }
        }
      >
        <div
          className={classes.dropdownHeader}
          onClick={handleDropdown}
          style={{
            backgroundColor: openDropdown ? '#2BB670' : ' #EEFBF5',
            color: openDropdown ? '#fff' : '#000',
          }}
        >
          <p
            className={classes.ddHeadingText}
            style={subheadingTextStyle ? subheadingTextStyle : {}}
          >
            {dropdownData.title}
          </p>

          {openDropdown ? (
            <img
              src={WhiteArrowIcon}
              className={classes.DDArrowIcon}
              alt='arrow drop down'
              style={arrowImgstyle ? arrowImgstyle : {}}
            />
          ) : (
            <img
              src={LightGreenArrowIcon}
              className={classes.DDArrowIcon}
              alt='green arrow drop down'
              style={arrowImgstyle ? arrowImgstyle : {}}
            />
          )}
        </div>

        {openDropdown && dropdownData.desc && (
          <Collapse in={openDropdown}>
            <Paper className={classes.dropdownBody}>
              <div className={classes.drugcontBodyWrap}>
                <p className={classes.dropDownDetailText}>
                  {dropdownData.desc}
                </p>

                {dropdownData.drugDetail &&
                  dropdownData.drugDetail.length >= 1 && (
                    <>
                      <h5>Drugs</h5>
                      <ul className={classes.drugListItemContainer}>
                        {dropdownData.drugDetail.map((item) => (
                          <li>{item}</li>
                        ))}
                      </ul>
                    </>
                  )}
              </div>
            </Paper>
          </Collapse>
        )}
      </div>
    </ClickAwayListener>
  );
};

const Arrow = ({ tailHeight, tailWidth, arrowPointStyle, arrowMainStyle }) => {
  const classes = useStyles();
  return (
    <div class={classes.arrow} style={arrowMainStyle}>
      <div class={classes.arrowtail} style={{ height: tailHeight }}></div>
      <div class={classes.arrowpoint}></div>
    </div>
  );
};

const COPDTreatment = ({ noFloat, noNumber }) => {
  const classes = useStyles();

  const data = {
    mainTitleText: 'Treatment',
    mainTitleNumber: '8',
    arrowTitle: 'Decreasing FEV1',
    drugCategories: [
      {
        title: 'SABA',
        desc: 'Beta-a-agonists acting for short durations (4-6 hours) are called short-acting beta-agonists They work by relaxing the muscles that get tensed during inflammation, hence also called bronchodilators. They also help in widening the airways.',
        drugDetail: ['Salbutamol', 'Terbutaline', 'Fenotero'],
      },
      {
        title: 'LABA',
        desc: 'Beta-a-agonists Acting for long durations (more than 12 hours) are called long-acting beta-agonists (LABA). They work by relaxing the muscles that get tensed during inflammation, hence also called bronchodilators. They also help in widening the airways.',
        drugDetail: ['Salmeterol', 'Formoterol'],
      },
      {
        title: 'LAMA',
        desc: 'Anticholinergics can be short acting (SAMA) as well as long acting (LAMA) depending on their duration of action They inhibit the Acetylcholine (ACh) from binding to muscarinic receptors, thus preventing bronchoconstriction. ',
        drugDetail: ['Aclidinium bromide', 'Glycopyrronium bromide'],
      },
      {
        title: 'ICS',
        desc: `ICS reduces inflammation in the lungs and decreases the immune response of the body
        against bacterial infection. As a result, patients
        treated with ICS often pose the risk of getting
        pneumonia infection.
        It has shown to improve the condition of COPD
        patients when used in combination with LAMA and
        LABA (Triple combination)`,
        // drugDetail: [
        //   "Bictegravir",
        //   "Elvitegravir",
        //   "Dolutegravir (Tivicay)",
        //   "Raltegravir (Isentress) ",
        // ],
      },
    ],
    drugCategories2: [
      {
        title: 'Bullectomy',
        desc: `Bullectomy surgery removes
        dead bullae in the lungs and allows functional
        lung tissue to expand and breathe.
        Surgery done when there is presence of large
        bullae (holes) in the lungs. Bullae results in
        dead air sacs crowding functioning part of
        lungs, and decreasing overall efficiency.
        5 year survival: 88%`,
      },
      {
        title: 'Lung Reduction Surgery (LVRS)',
        desc: `The part of the lung which is
        damaged by COPD, is cut to reduce
        hyperinflation. LVRS allows functional lung
        tissue to expand and breathe.
        It is similar to bullectomy, but not only bullae
        are being removed but due to other issues
        such as inflammation. 
        1 year survival: 90%
        3 year survival: 77%
        5 year survival: 65%`,
      },
      {
        title: 'Lung Transplant',
        desc: `Lung transplant is opted in
        advanced COPD stages. Either single or
        bilateral lung transplant is done and recent
        studies have shown better results for bilateral
        lung transplant. Bilateral lung transplant is to
        remove both the lungs and replace with
        healthy lungs taken mostly from a deceased
        person.
        Survival rate at the end of:
        1 year is 79.5%,
        5 years is 50.6%
        10 years is 30.4%
        `,
      },
    ],
  };
  return (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      {' '}
      <div className={classes.timelineAppBar}>
        <div className={classes.timelineHeading}>
          {!noNumber && (
            <div className={classes.timelineCircleBG}>
              {data.mainTitleNumber}
            </div>
          )}
          <div className={classes.timelineTitle}>{data.mainTitleText}</div>
        </div>
      </div>
      <div style={{ marginLeft: '45px', position: 'relative' }}>
        <div class={classes.arrow} style={{ left: '-35px', top: '160px' }}>
          <div
            // class={classes.arrowpoint}
            style={{
              width: 0,
              height: 0,
              borderBottom: '14px solid #000',
              borderRight: '5px solid transparent',
              borderLeft: '5px solid transparent',
            }}
          ></div>
          <div
            class={classes.arrowtail}
            style={{ height: 360, width: '2px' }}
          ></div>
        </div>
        <p
          style={{
            position: 'absolute',
            fontSize: '12px',
            fontWeight: 'bold',
            transform: 'rotate(270deg)',
            top: '300px',
            left: '-120px',
            margin: '0',
          }}
        >
          EXACERBATION HISTORY
        </p>
        <p
          style={{
            position: 'absolute',
            fontSize: '10px',
            margin: '0',
            transform: 'rotate(270deg)',
            top: '330px',
            left: '-180px',
          }}
        >
          0 or 1 ; not leading to hospitalisation{' '}
          {`≥ 2 or ≥ 1 leading to hospitalisation`}
        </p>
        <div className={classes.darkGrayBar}>DRUG CATEGORIES</div>
        <div style={{ display: 'flex' }}>
          {data.drugCategories.map((drugCat, i) => (
            <DropdownBox
              key={i}
              dropdownData={drugCat}
              styleData={{ width: '25%', marginRight: '6px' }}
            />
          ))}
        </div>
        <div
          className={classes.darkGrayBar}
          style={{ backgroundColor: '#2BB670', color: '#fff' }}
        >
          PHARMACOLOGICAL TREATMENT
        </div>
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className={classes.groupBox} style={{ height: '275px' }}>
              <p
                style={{
                  margin: '10px auto 0px auto',
                  textAlign: 'center',
                  fontWeight: 'Bold',
                  fontSize: '12px',
                }}
              >
                Group C
              </p>
              <DropdownBox
                dropdownData={{ title: 'LAMA' }}
                styleData={{
                  width: '40%',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              />
              <Arrow
                arrowMainStyle={{
                  left: '48.8%',
                  top: '68px',
                }}
                tailHeight={47}
              />
              <p
                style={{
                  fontSize: '10px',
                  position: 'absolute',
                  top: '78px',
                  right: '12px',
                }}
              >
                Further exacerbations
              </p>
              <DropdownBox
                dropdownData={{ title: 'LAMA + LABA' }}
                styleData={{
                  width: '48%',
                  marginRight: '6px',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  marginTop: '50px',
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.groupBox}>
              <p
                style={{
                  margin: '10px auto 0px auto',
                  textAlign: 'center',
                  fontWeight: 'Bold',
                  fontSize: '12px',
                }}
              >
                Group D
              </p>
              <DropdownBox
                dropdownData={{ title: 'LAMA + LABA' }}
                styleData={{
                  width: '52%',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              />
              <Arrow
                arrowMainStyle={{
                  left: '48.8%',
                  top: '109px',
                }}
                tailHeight={13}
              />
              <Arrow
                arrowMainStyle={{
                  left: '48.8%',
                  top: '167px',
                }}
                tailHeight={18}
              />
              <p
                style={{
                  fontSize: '8px',
                  position: 'absolute',
                  top: '164px',
                  right: '2px',
                }}
              >
                If FEV1{`<50`} symptoms persists
              </p>
              <DropdownBox
                dropdownData={{ title: 'LABA + ICS' }}
                styleData={{
                  width: '48%',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              />
              <DropdownBox
                dropdownData={{ title: 'LAMA + LABA + ICS' }}
                styleData={{
                  width: '65%',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  marginTop: '15px',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  marginTop: '20px',
                  justifyContent: 'center',
                }}
              >
                <DropdownBox
                  dropdownData={{ title: 'Roflumilast' }}
                  styleData={{
                    width: '45%',
                    //   marginRight: "auto",
                    //   marginLeft: "auto",
                  }}
                />
                <DropdownBox
                  dropdownData={{ title: 'Macrolide' }}
                  styleData={{
                    width: '45%',
                    //   marginRight: "auto",
                    //   marginLeft: "auto",
                  }}
                />
              </div>
              <DropdownBox
                dropdownData={{ title: 'Surgery' }}
                styleData={{
                  width: '50%',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.groupBox} style={{ height: '180px' }}>
              <p
                style={{
                  margin: '10px auto 0px auto',
                  textAlign: 'center',
                  fontWeight: 'Bold',
                  fontSize: '12px',
                }}
              >
                Group A
              </p>
              <DropdownBox
                dropdownData={{ title: 'SABA or LABA' }}
                styleData={{
                  width: '65%',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  marginTop: '25px',
                }}
              />
            </div>
            <p style={{ textAlign: 'center' }}> {`CAT< 10`}</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.groupBox} style={{ height: '180px' }}>
              <p
                style={{
                  margin: '10px auto 0px auto',
                  textAlign: 'center',
                  fontWeight: 'Bold',
                  fontSize: '12px',
                }}
              >
                Group B
              </p>
              <DropdownBox
                dropdownData={{ title: 'LAMA or LABA' }}
                styleData={{
                  width: '65%',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  marginTop: '25px',
                }}
              />
              <Arrow
                arrowMainStyle={{
                  left: '48.8%',
                  top: '90px',
                }}
                tailHeight={33}
              />
              <p
                style={{
                  fontSize: '8px',
                  position: 'absolute',
                  top: '94px',
                  right: '40px',
                }}
              >
                If symptoms persist
              </p>
              <DropdownBox
                dropdownData={{ title: 'LABA + LABA' }}
                styleData={{
                  width: '65%',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  marginTop: '35px',
                }}
              />
            </div>
            <p style={{ textAlign: 'center' }}> {`CAT ≥ 10`}</p>
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            class={classes.arrowtail}
            style={{ width: '95%', height: '2px', marginTop: '4px' }}
          ></div>
          <div
            // class={classes.arrowpoint}
            style={{
              width: 0,
              height: 0,
              borderLeft: '14px solid #000',
              borderTop: '5px solid transparent',
              borderBottom: '5px solid transparent',
            }}
          ></div>
        </div>
        <div className={classes.bigArrowCont}>
          <div class={classes.bigarrow}>
            <div class={classes.bigarrowtail}></div>
            <div class={classes.bigarrowpoint}></div>
          </div>
          <p>{data.arrowTitle}</p>
        </div>
        <div
          className={classes.darkGrayBar}
          style={{ backgroundColor: '#2BB670', color: '#fff' }}
        >
          SURGICAL TREATMENT FOR ADVANCED COPD (STAGE 4)
        </div>
        <div style={{ display: 'flex' }}>
          {data.drugCategories2.map((drugCat, i) => (
            <DropdownBox
              key={i}
              dropdownData={drugCat}
              styleData={{ width: 'calc(100% / 3)', marginRight: '6px' }}
            />
          ))}
        </div>
        <DropdownBox
          dropdownData={{ title: 'Continuous Oxygen Support' }}
          styleData={{ width: '100%', marginRight: '6px', marginTop: '12px' }}
        />
      </div>
    </div>
  );
};

export default COPDTreatment;
