import React from 'react';
import { makeStyles, Grid, useMediaQuery } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import CysticFibrosisChildrenMobile from './CysticFibrosisChildrenMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },

    // border: "1px solid #000",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },

  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #C33C54',
    margin: '0 15px 0 0 ',
    background: '#FFEBEF',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#C33C54',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#FFEBEF',
    borderRadius: '200px',
    border: '1px solid #C33C54',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#C33C54',
    fontSize: '24px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },

  headingBox: {
    backgroundColor: '#FFEBEF',
    border: '1px solid #C33C54',
    width: '100%',
    height: 34,
    color: '#C33C54',
    fontSize: '14px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  blueBoxSmall: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#9FCEFC',
    height: '40px',
    width: '240px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    textAlign: 'center',
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  // Arrow
  arrow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
  },
  arrowtail: {
    background: '#C33C54',
    height: '20px',
    width: '1.5px',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '6px solid #C33C54',
    borderRight: '6px solid transparent',
    borderLeft: '6px solid transparent',
  },
  // branch
  branchWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  branchMain: {
    borderRight: '1.5px solid #C33C54',
    borderLeft: '1.5px solid #C33C54',
    borderTop: '1.5px solid #C33C54',
  },
  branchMiddleLine: {
    justifySelf: 'center',
    background: '#C33C54',
  },
  redBorderGrayBox: {
    width: '144px',
    minHeight: '24px',
    borderRadius: '7px',
    border: '1px solid #C33C54',
    backgroundColor: '#EEF0F0',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      fontSize: '9px',
    },
  },
  GreenBox: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#2BB670',
    height: '30px',
    width: '174px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // marginTop: "103px",
    // marginLeft: "40px",
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      fontSize: '10px',
      //   left: "-18%",
    },
  },

  redBoldTextBig: {
    color: '#C33C54',
    fontWeight: 'bold',
    fontSize: '20px',
    margin: '10px 0',
  },
}));

const Arrow = ({ tailHeight, tailWidth, arrowPointStyle, arrowMainStyle }) => {
  const classes = useStyles();
  return (
    <div class={classes.arrow} style={arrowMainStyle}>
      <div class={classes.arrowtail} style={{ height: tailHeight }}></div>
      <div class={classes.arrowpoint}></div>
    </div>
  );
};

const Branch = ({
  branchStyle,
  middleLineStyle,
  branchClassName,
  middleLineClassName,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.branchWrap}>
      <div className={classes.branchMiddleLine} style={middleLineStyle}></div>
      <div className={classes.branchMain} style={branchStyle}></div>
    </div>
  );
};

const CysticFibosisDiagnosisChildren = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const data = { mainTitleText: 'Diagnosis: Children', mainTitleNumber: '3' };
  const matches = useMediaQuery('(max-width:600px)');
  return isMobile ? (
    <CysticFibrosisChildrenMobile />
  ) : (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      <div className={classes.timelineAppBar}>
        <div className={classes.timelineHeading}>
          {!noNumber && (
            <div className={classes.timelineCircleBG}>
              {data.mainTitleNumber}
            </div>
          )}
          <div className={classes.timelineTitle}>{data.mainTitleText}</div>
        </div>
      </div>
      <div style={{ marginTop: '30px', borderBottom: '1px solid #000' }}>
        <p className={classes.redBoldTextBig}>Screening Test</p>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={2} lg={1}>
          <div style={{ position: 'relative' }}>
            <div
              className={classes.headingBox}
              style={{
                marginTop: '20px',
                height: '450px',
                width: '40px',
              }}
            >
              <p style={{ transform: 'rotate(270deg)' }}>Screening</p>
            </div>
            <div
              className={classes.headingBox}
              style={{
                marginTop: '70px',
                height: '490px',
                width: '40px',
              }}
            >
              <p style={{ transform: 'rotate(270deg)', width: '100px' }}>
                Confirmatory
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={10} lg={11}>
          <div style={{ position: 'relative', marginTop: '30px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginLeft: '140px',
              }}
            >
              <div>
                <div className={classes.blueBoxSmall}>
                  <p>Chorionic Villus sampling (Prenatal)</p>
                </div>
                <Branch
                  middleLineStyle={{
                    width: '1.5px',
                    height: '26px',
                  }}
                  branchStyle={{
                    width: matches ? '70%' : '80%',
                    height: '30px',
                  }}
                />
                <div style={{ display: 'flex', position: 'relative' }}>
                  <div>
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        width: '100px',
                        marginLeft: '-20px',
                        //   marginLeft: matches ? "-30px" : "-60px",
                      }}
                    >
                      <p>Positive</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{
                        left: matches ? '30px' : '17px',
                        top: matches ? '60px' : '24px',
                      }}
                      tailHeight={matches ? 120 : 59}
                    />
                  </div>
                  <div>
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        position: 'absolute',
                        width: '100px',
                        right: '-20px',
                        // marginLeft: matches ? "-30px" : "-60px",
                      }}
                    >
                      <p>Negative</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{
                        right: matches ? '30px' : '17px',
                        top: matches ? '60px' : '24px',
                      }}
                      tailHeight={matches ? 120 : 264}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: '65px',
                marginLeft: '140px',
              }}
            >
              <div>
                <div
                  className={classes.blueBoxSmall}
                  style={{ width: '165px' }}
                >
                  <p style={{ fontSize: '12px' }}>
                    Immunoreactive Trypsinogen (IRT) test
                  </p>
                </div>
                <Branch
                  middleLineStyle={{
                    width: '1.5px',
                    height: '26px',
                  }}
                  branchStyle={{
                    width: matches ? '70%' : '70%',
                    height: '30px',
                  }}
                />
                <div style={{ display: 'flex', position: 'relative' }}>
                  <div>
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        width: '80px',
                        height: '40px',
                        marginLeft: '-20px',
                        //   marginLeft: matches ? "-30px" : "-60px",
                      }}
                    >
                      <p>Positive</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{
                        left: matches ? '30px' : '17px',
                        top: matches ? '60px' : '40px',
                      }}
                      tailHeight={matches ? 120 : 190}
                    />
                  </div>
                  <div>
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        position: 'absolute',
                        width: '80px',
                        height: '40px',
                        right: '-20px',
                        // marginLeft: matches ? "-30px" : "-60px",
                      }}
                    >
                      <p>Negative</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{
                        right: matches ? '30px' : '7px',
                        top: matches ? '60px' : '42px',
                      }}
                      tailHeight={matches ? 120 : 61}
                    />
                  </div>
                  <div
                    className={classes.GreenBox}
                    style={{
                      position: 'absolute',
                      right: '-150px',
                      top: '109px',
                      width: '200px',
                      height: '45px',
                    }}
                  >
                    <p>Cystic Fibrosis: Negative</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: '130px',
                borderBottom: '1px solid #000',
                marginLeft: '-30px',
              }}
            >
              <p className={classes.redBoldTextBig}>Confirmatory Test</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '25px',
                // marginLeft: "110px",
              }}
            >
              <div>
                <div
                  className={classes.blueBoxSmall}
                  style={{ width: '215px', marginLeft: '60px' }}
                >
                  <p style={{ fontSize: '12px' }}>Sweat Chloride Test</p>
                </div>
                <Branch
                  middleLineStyle={{
                    width: '1.5px',
                    height: '26px',
                  }}
                  branchStyle={{
                    width: matches ? '70%' : '340px',
                    height: '30px',
                  }}
                />
                <div style={{ display: 'flex', position: 'relative' }}>
                  <div>
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        width: '80px',
                        height: '40px',
                        marginLeft: '-20px',
                        //   marginLeft: matches ? "-30px" : "-60px",
                      }}
                    >
                      <p>{`> 60 mmol/L`}</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{
                        left: matches ? '30px' : '0px',
                        top: matches ? '60px' : '40px',
                      }}
                      tailHeight={matches ? 120 : 581}
                    />
                  </div>
                  <div>
                    <Branch
                      middleLineStyle={{
                        width: '1.5px',
                        height: '29px',
                        position: 'absolute',
                        left: '49.85%',
                        top: '-29px',
                      }}
                      branchStyle={{
                        width: '0px',
                        height: '0px',
                        display: 'none',
                      }}
                    />
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        width: '80px',
                        height: '40px',
                        left: '38%',
                        position: 'absolute',
                        //   marginLeft: matches ? "-30px" : "-60px",
                      }}
                    >
                      <p>{`30-59 mmol/L`}</p>
                    </div>
                    <div
                      className={classes.blueBoxSmall}
                      style={{
                        marginTop: '85px',
                        width: '170px',
                        marginLeft: '30px',
                      }}
                    >
                      <p style={{ fontSize: '12px' }}>CF suspected</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{
                        left: matches ? '30px' : '48%',
                        top: matches ? '60px' : '40px',
                      }}
                      tailHeight={matches ? 120 : 39}
                    />
                    <Arrow
                      arrowMainStyle={{
                        left: matches ? '30px' : '48%',
                        top: matches ? '60px' : '125px',
                      }}
                      tailHeight={matches ? 120 : 39}
                    />
                    <div
                      className={classes.blueBoxSmall}
                      style={{
                        marginTop: '45px',
                        width: '170px',
                        marginLeft: '30px',
                      }}
                    >
                      <p style={{ fontSize: '12px' }}>CFTR Genetic Test</p>
                    </div>
                  </div>
                  <div>
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        position: 'absolute',
                        width: '80px',
                        height: '40px',
                        right: '-20px',
                        // marginLeft: matches ? "-30px" : "-60px",
                      }}
                    >
                      <p>{`<29 mmol/L`}</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{
                        right: matches ? '30px' : '7px',
                        top: matches ? '60px' : '40px',
                      }}
                      tailHeight={matches ? 120 : 581}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                // marginTop: "25px",

                // marginLeft: "110px",
              }}
            >
              <div>
                <Branch
                  middleLineStyle={{
                    width: '1.5px',
                    height: '26px',
                  }}
                  branchStyle={{
                    width: matches ? '70%' : '240px',
                    height: '30px',
                  }}
                />
                <div style={{ display: 'flex', position: 'relative' }}>
                  <div>
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        width: '80px',
                        height: '40px',
                        marginLeft: '-30px',
                        //   marginLeft: matches ? "-30px" : "-60px",
                      }}
                    >
                      <p>2 Mutations</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{
                        left: matches ? '30px' : '-7px',
                        top: matches ? '60px' : '40px',
                      }}
                      tailHeight={matches ? 120 : 315}
                    />
                  </div>
                  <div>
                    <Branch
                      middleLineStyle={{
                        width: '1.5px',
                        height: '30px',
                        position: 'absolute',
                        left: '49.5%',
                        top: '-30px',
                      }}
                      branchStyle={{
                        width: '0px',
                        height: '0px',
                        display: 'none',
                      }}
                    />
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        width: '80px',
                        height: '40px',
                        position: 'absolute',
                        left: '80px',
                      }}
                    >
                      <p>1 Mutation</p>
                    </div>
                    <div
                      className={classes.blueBoxSmall}
                      style={{
                        marginTop: '85px',
                        width: '140px',
                        // marginLeft: "30px",
                      }}
                    >
                      <p style={{ fontSize: '12px' }}>CF suspected</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{
                        left: matches ? '30px' : '48%',
                        top: matches ? '60px' : '40px',
                      }}
                      tailHeight={matches ? 120 : 39}
                    />
                    <Arrow
                      arrowMainStyle={{
                        left: matches ? '30px' : '48%',
                        top: matches ? '60px' : '125px',
                      }}
                      tailHeight={matches ? 120 : 39}
                    />
                    <div
                      className={classes.blueBoxSmall}
                      style={{
                        marginTop: '45px',
                        width: '140px',
                        // marginLeft: "30px",
                      }}
                    >
                      <p style={{ fontSize: '12px' }}>
                        CFTR Physiological Test
                      </p>
                    </div>
                  </div>
                  <div>
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        width: '80px',
                        height: '40px',
                        position: 'absolute',
                        right: '-30px',
                        // top: "-5px",
                        //   marginLeft: matches ? "-30px" : "-60px",
                      }}
                    >
                      <p>No Mutation</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{
                        right: matches ? '30px' : '-7px',
                        top: matches ? '60px' : '40px',
                      }}
                      tailHeight={matches ? 120 : 315}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div>
                <Branch
                  middleLineStyle={{
                    width: '1.5px',
                    height: '26px',
                  }}
                  branchStyle={{
                    width: matches ? '70%' : '160px',
                    height: '30px',
                  }}
                />
                <div style={{ display: 'flex', position: 'relative' }}>
                  <div>
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        width: '100px',
                        height: '40px',
                        marginLeft: '-30px',
                        //   marginLeft: matches ? "-30px" : "-60px",
                      }}
                    >
                      <p>CFTR Dysfunction</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{
                        left: matches ? '30px' : '-7px',
                        top: matches ? '60px' : '40px',
                      }}
                      tailHeight={matches ? 120 : 49}
                    />
                  </div>
                  <div>
                    <div
                      className={classes.redBorderGrayBox}
                      style={{
                        width: '110px',
                        height: '40px',
                        right: '-30px',
                        position: 'absolute',
                        //   marginLeft: matches ? "-30px" : "-60px",
                      }}
                    >
                      <p>CFTR Preserved Function</p>
                    </div>
                    <Arrow
                      arrowMainStyle={{
                        right: matches ? '30px' : '-7px',
                        top: matches ? '60px' : '40px',
                      }}
                      tailHeight={matches ? 120 : 49}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '55px',
                position: 'relative',
              }}
            >
              <div
                className={classes.GreenBox}
                style={{
                  color: '#FFF',
                  backgroundColor: '#C33C54',
                  height: '40px',
                  width: '220px',
                  marginLeft: '20px',
                }}
              >
                <p>CF: Positive </p>{' '}
              </div>
              <div
                className={classes.GreenBox}
                style={{
                  height: '40px',
                  position: 'absolute',
                  right: '20px',
                  width: '200px',
                }}
              >
                <p>CF: Negative</p>{' '}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CysticFibosisDiagnosisChildren;
