import axios from "axios";
import { serialize } from '../../views/docterPatients/Helper';
import {
  listOfFeaturedDiseases,
  listOfConditions,
  listOfSpecializations,
  diseasesCategoryInfo,
  organization,
  getReport,
  bookmarkDiseases,
  listOfStatCount,
  getUserSettingsItems,
  getUserSettings
} from "../backendUrl";

const listDiseasesAxios = (user_id, is_featured) => {
  let url = `${listOfFeaturedDiseases}?limit=100`;
  if (user_id) {
    url = `${listOfFeaturedDiseases}?limit=100&user_id=${user_id}`;
  }
  if(is_featured == true){
    url = `${listOfFeaturedDiseases}?limit=100&user_id=${user_id}&is_featured=${is_featured}`;
  }
  return axios({
    method: "get",
    url: url,
    //for dr.and patient not need header
    // headers: {
    //  // Authorization: Token,
    //  Authorization: `${process.env.REACT_APP_AUTH_TOKEN}`,
    // },
  });
};

const listOfSpecializationsAxios = () => {
  return axios({
    method: "get",
    url: `${listOfSpecializations}`,
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
    },
  });
}

const listStatCountAxios = () => {
  return axios({
    method: "get",
    url: `${listOfStatCount}`,
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
    },
  });
}


const listDiseasesAxiosAdmin = () => {
  
  return axios({
    method: "get",
    url: `${listOfConditions}`,
   
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
     
    },
  });
};

const diseaseCategoryDetail = (name, category) => {
  return axios({
    method: "get",
    url: `${diseasesCategoryInfo}?condition__name=${name}&category=${category}`,
    
  });
};

const diseaseInformation = (name, category) => {
  return axios({
    method: "get",
    url: `${getReport}/${name}/introduction`,
   
  });
};

const diseaseOrganization = (name, category) => {
  return axios({
    method: "get",
    url: `${organization}?condition__name=${name}`,
  
  });
};

const bookmarkDiseasesPost = (data) => {
  
  return axios({
    method: "post",
    url: `${bookmarkDiseases}`,
    data:data,
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
     
    },
  });
};

const bookmarkDiseasesDelete = (data) => {
  
  return axios({
    method: "delete",
    url: `${bookmarkDiseases}/${data.bookmark_id}`,
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
     
    },
  });
};

const bookmarkDiseasesChecked = (data) => {
  data = serialize(data)
  return axios({
    method: "get",
    url: `${bookmarkDiseases}${data}`,
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
     
    },
  });
};

const listOfBookmarks = (data) => {
  data = serialize(data)
  return axios({
    method: "get",
    url: `${bookmarkDiseases}${data}`,
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
    },
  });
};

const userSettingsItems = (data) => {
  data = serialize(data)
  return axios({
    method: "get",
    url: `${getUserSettingsItems}${data}`,
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
    },
  });
};

const userSettingSingle = (data) => {
  data = serialize(data)
  return axios({
    method: "get",
    url: `${getUserSettings}${data}`,
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
    },
  });
};

const userSettingsAdd = (data) => {
  // data = serialize(data)
  return axios({
    method: "post",
    url: `${getUserSettings}`,
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
    },
    data:data
  });
};

const userSettingsUpdate = (data,user_setting_id) => {
  // data = serialize(data)
  return axios({
    method: "put",
    url: `${getUserSettings}/${user_setting_id}`,
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
    },
    data:data
  });
};

const userSettingsDelete = (user_setting_id) => {
  // data = serialize(data)
  return axios({
    method: "delete",
    url: `${getUserSettings}/${user_setting_id}`,
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
    }
  });
};

export {
  listDiseasesAxios,
  listOfSpecializationsAxios,
  diseaseCategoryDetail,
  diseaseInformation,
  diseaseOrganization,
  listDiseasesAxiosAdmin,
  bookmarkDiseasesPost,
  bookmarkDiseasesDelete,
  bookmarkDiseasesChecked,
  listStatCountAxios,
  listOfBookmarks,
  userSettingsItems,
  userSettingsAdd,
  userSettingsUpdate,
  userSettingSingle,
  userSettingsDelete
};
