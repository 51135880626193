import React, { useEffect, useState, useSelector, useRef } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useParams } from 'react-router-dom';
import { numberWithCommas, splitText, removeDayFromDate } from '../../Helper';
import ExpandableSecondRow from './ExpandableSecondRow';
import ExpandableMiddleRow from './ExpandableMiddleRow';
import './ExpandableTable.scss';
import StaticLoadingWidget from '../../../../containers/formField/StaticLoadingWidget';
import LeafRow from './LeafRow';
import { Button } from '@material-ui/core';
import AxlChips from '../MUI/AxlChips';


const ExpandableRow = ({options, item, apiCallRequest}) => {
  const [DevicesRowClicked, setDevicesRowClicked] = useState(false);
  const secondRow = options.extraRow ? true : false;
  const columnSize = options.trialType=='phases'?30:45;
  const { interventionCategory } = useParams();
  useEffect(() => {
    if(options.ExpandLevel1=='true' && (options.trialType == 'trials' || options.trialType == 'phases')){
      handleCallRequestExpandLevel1()
    }else{
      if(options.searchVal || options.dataSwitch){
        btnRef.current.setAttribute("apiclick", "no");
        setDevicesRowClicked(false)
      }
    }
  }, [interventionCategory,options.searchVal,options.dataSwitch,options.ExpandLevel1]);
  
  let btnRef = useRef();

  const handleCallRequestExpandLevel1 = () => {
    setDevicesRowClicked(true)
    apiCallRequest(item['name'], 0, 'level2', btnRef.current.getAttribute("apiclick"))
    // if(btnRef.current){
      btnRef.current.setAttribute("apiclick", "yes");
    // }
  }
  const handleCallRequest = () => {
    setDevicesRowClicked(!DevicesRowClicked)
    // if(options.trialType == 'trials'){
      apiCallRequest(item['name'], 0, 'level2', btnRef.current.getAttribute("apiclick"))
    // }
    if(btnRef.current){
      btnRef.current.setAttribute("apiclick", "yes");
    }
  }
  const loadMoreRequest = () =>{
    apiCallRequest(item['name'], item['offset'],'level2', 'no')
  }

  return (
    <>
      <div apiclick="no" ref={btnRef}
          className={`expandable-row appr-subheading expand-row ${DevicesRowClicked ? 'expanded' : ''}`}
          onClick={handleCallRequest}
        >
          <div className='expandable-cell physicians-expandable-cell text-capitalize rrrr'>
            <div style={{ display: 'inline'}}>
              {options.playerType=='category'?"":
                <>
                  {DevicesRowClicked ? (
                    <ExpandMoreIcon
                      style={{ color: DevicesRowClicked ? 'black' : 'black' }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      style={{transform: 'rotate(-90deg)'}}
                    />
                  )}
                </>
              }
            </div>
            {item['physician_table_id']?<a target="_blank" href={`/physician/${item['physician_table_id']}`}>{item['name'] && item['name'].length > columnSize?item['name'].toLowerCase().substring(0,columnSize)+'...':item['name'] && item['name'].toLowerCase()}</a>:(item['name'] && item['name'].length > columnSize?item['name'].toLowerCase().substring(0,columnSize)+'...':item['name'] && splitText(item['name'].toLowerCase(),';'))}
          {item['specialty']&& <><br/><small className='small-70'>{item['specialty']} | {item['state']}</small></>}
          {/* {item['target']&& <> | <small className='small-70 ms-0'>{item['target']}</small></>} */}
          </div>
          {
            options.trialType == 'physicians' &&
            <div className='expandable-cell physicians-expandable-cell'>
              <small title={item['title_display'] ? splitText(item['title_display'],';',2) : splitText(item['title_all'],';',2)}>{item['title_display'] ? splitText(item['title_display'],';',2,65) : splitText(item['title_all'],';',2,65)}</small>
            </div>
          }
          {options.trialType == 'players' || options.trialType == 'deals' ? options.column_keys.map((column_value) => {
            return (
              <div className='expandable-cell physicians-expandable-cell'>
                {
                  options.trialType == 'deals'?
                  column_value == 'axl_deal_date' ? removeDayFromDate(item[column_value]):
                  column_value == 'target_portfolio' ? <div className="chips-container p-0 m-0">
                    <AxlChips options={{chips: item[column_value], size: 'small'}} />
                </div>:
                  column_value == 'target' ? 
                    <span title={item[column_value]}>{splitText(item[column_value],';',2, 30)}</span>:
                    item[column_value]

                  :numberWithCommas(item[column_value])}
              </div>
            )})
          :
            <div
              className='expandable-cell physicians-expandable-cell'
              style={{textAlign: 'right'}}
            >
              <div class="col-12 p-0">
                    <div class="row">
                      {options.trialType == 'phases' ? options.column_keys.map((column_value) => {
                          return (
                            <>
                              <div class="col">
                                {numberWithCommas(item[column_value])}
                              </div>
                            </>
                          );
                          }) : <div class="col">{options.icon?options.icon:''}
                          {numberWithCommas(item['total'])}</div>
                      }
                    </div>
                </div>
            </div>
          }
        </div>
        {DevicesRowClicked && item['nextData'].map((subitem, index) => {
          return (  
            options.trialType == 'deals' || options.trialType == 'trialsDrug' || options.is_leaf == 'true' ? <LeafRow apiCallRequest={apiCallRequest} options={options} item={subitem} /> :(
            secondRow ? <ExpandableSecondRow apiCallRequest={apiCallRequest} options={options} item={subitem} /> :
            <ExpandableMiddleRow apiCallRequest={apiCallRequest} options={options} item={subitem}  />)
          )})
        }
        {item['tot_row_count'] > item['offset'] && <div className='d-flex justify-content-center load-more-btn'>
        {DevicesRowClicked && <Button onClick={loadMoreRequest} style={{
            border : '1px solid',
            borderRadius : '3px',
            padding: '2px 8px',
            margin: '5px 0px',
            color: 'black',
            fontSize:'12px'
          }}> Load More</Button>}
      </div>}
    </>
  )
}

export default ExpandableRow;