import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import GlaucomaDiagnosisConfirmatoryTest from './GlaucomaDiagnosisConfirmatoryTest';
import { isMobile } from 'react-device-detect';
import AlzheimersDiagnosisMobile from './AlzheimersDiagnosisMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    
    // border: "1px solid #000",
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  headingBox: {
    backgroundColor: '#FFEBEF',
    border: '1px solid #C33C54',
    width: '90%',
    height: 34,
    color: '#C33C54',
    fontSize: '14px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  blueBoxSmall: {
    color: '#000',
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: '#9FCEFC',
    textAlign: 'center',

    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.25rem 0.5rem',

    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  // Arrow
  arrow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
  },
  arrowtail: {
    background: '#C33C54',
    height: '20px',
    width: '1.5px',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '6px solid #C33C54',
    borderRight: '6px solid transparent',
    borderLeft: '6px solid transparent',
  },
  // branch
  branchWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  branchMain: {
    borderRight: '1.5px solid #C33C54',
    borderLeft: '1.5px solid #C33C54',
    borderTop: '1.5px solid #C33C54',
  },
  branchMiddleLine: {
    justifySelf: 'center',
    background: '#C33C54',
  },

  diseaseOutputBox: {
    width: '80px',
    border: '1px solid #000',
    borderRadius: '8px',
    padding: '0.5rem',
    textAlign: 'center',
    fontSize: '12px',
    minHeight: '80px',
    '& p': {
      color: 'white',
      margin: '0px',
      fontWeight: 500,
      lineHeight: 1.25,
    },
  },
}));

const Arrow = ({ tailHeight, tailWidth, arrowPointStyle, arrowMainStyle }) => {
  const classes = useStyles();
  return (
    <div class={classes.arrow} style={arrowMainStyle}>
      <div class={classes.arrowtail} style={{ height: tailHeight }}></div>
      <div class={classes.arrowpoint}></div>
    </div>
  );
};

const Branch = ({
  branchStyle,
  middleLineStyle,
  branchClassName,
  middleLineClassName,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.branchWrap}>
      <div className={classes.branchMiddleLine} style={middleLineStyle}></div>
      <div className={classes.branchMain} style={branchStyle}></div>
    </div>
  );
};

const AlzheimersDiagnosis = ({ noFloat, noNumber }) => {
  const classes = useStyles();
  const data = {
    pageTitle: "Alzheimer's: Diagnosis",
    screenTest: 'Screening Test',
    clockDrawingTest: [
      { info: '10: Unlikely CI' },
      { info: '<8: Possible CI' },
      { info: '<5: Possibility of CI higher' },
    ],
    moca: [
      { info: '10-25: Mild CI' },
      { info: '10-17: Moderate CI' },
      { info: '<10: Severe CI' },
    ],
    adasCog: [
      { info: '0: No Errors' },
      { info: '12: Best Cutoff for AD' },
      { info: '70: Severe Impairment' },
    ],

    resultNegative: 'Glaucoma negative',
  };

  return isMobile ? (
    <AlzheimersDiagnosisMobile />
  ) : (
    <>
      <div className={classes.root} style={{ float: !noFloat && 'right' }}>
        <div style={{ position: 'relative' }}>
          <div className={classes.headingBox} style={{ marginTop: '40px' }}>
            <p> {data.screenTest}</p>
          </div>
          <Branch
            middleLineStyle={{
              width: '1.5px',
              height: '24px',
            }}
            branchStyle={{
              width: '280px',
              height: '24px',
            }}
          />
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <div
                  className={classes.blueBoxSmall}
                  style={{
                    width: '120px',
                    marginRight: 'auto',
                    marginLeft: '60px',
                  }}
                >
                  <p>Clock Drawing Test</p>
                </div>
                <Branch
                  middleLineStyle={{
                    width: '1.5px',
                    height: '24px',
                  }}
                  branchStyle={{
                    width: '200px',
                    height: '24px',
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  {data.clockDrawingTest.map((data, i) => (
                    <div
                      className={classes.diseaseOutputBox}
                      key={i}
                      style={{
                        marginLeft: i !== 0 && '6px',
                        borderColor: i !== 0 && '#C33C54',
                        backgroundColor:
                          i === 0 ? '#2BB670' : i === 1 ? 'orange' : '#C33C54',
                        color: i == 0 ? 'black' : 'white',
                      }}
                    >
                      <p>{data.info}</p>
                    </div>
                  ))}
                </div>
              </div>
              <Arrow
                arrowMainStyle={{
                  left: '48.9%',
                  top: '59px',
                }}
                tailHeight={220}
              />
              <div>
                <div
                  className={classes.blueBoxSmall}
                  style={{
                    width: '180px',
                    marginLeft: '20px',
                  }}
                >
                  <p>Montreal Cognitive Assessment Test (MoCA)</p>
                </div>
                <Branch
                  middleLineStyle={{
                    width: '1.5px',
                    height: '24px',
                  }}
                  branchStyle={{
                    width: '200px',
                    height: '24px',
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  {data.moca.map((data, i) => (
                    <div
                      className={classes.diseaseOutputBox}
                      key={i}
                      style={{
                        marginLeft: i !== 0 && '6px',
                        borderColor: i !== 0 && '#C33C54',
                        backgroundColor:
                          i === 0 ? '#2BB670' : i === 1 ? 'orange' : '#C33C54',
                        color: i == 0 ? 'black' : 'white',
                      }}
                    >
                      <p>{data.info}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ marginTop: '25px' }}>
              <div
                className={classes.blueBoxSmall}
                style={{
                  width: '120px',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  height: '36px',
                }}
              >
                <p>ADAS Cog</p>
              </div>
              <Branch
                middleLineStyle={{
                  width: '1.5px',
                  height: '24px',
                }}
                branchStyle={{
                  width: '200px',
                  height: '24px',
                }}
              />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  {data.adasCog.map((data, i) => (
                    <div
                      className={classes.diseaseOutputBox}
                      key={i}
                      style={{
                        marginLeft: i !== 0 && '6px',
                        borderColor: i !== 0 && '#C33C54',
                        backgroundColor:
                          i === 0 ? '#2BB670' : i === 1 ? 'orange' : '#C33C54',
                        color: i == 0 ? 'black' : 'white',
                      }}
                    >
                      <p>{data.info}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlzheimersDiagnosis;
