import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, TableSortLabel } from '@material-ui/core';
import { GetPeopleDispatch } from '../../reducers/DiseasesReducer'
import { useDispatch, useSelector } from 'react-redux';
import PlayersModal from './PlayersModal';
import BrandModal from './BrandModal';
import VentureCapitalModal from './VentureCapitalModal';
import TrialsModal from './ActiveTrialsModal';
import VentureCapitalCompanyDealsModal from './VentureCapitalCompanyDealsModal';
import PeopleCEOModal from './PeopleCEOModal';
import './PeopleTable.scss';
import { numberWithCommas, convertToMimillions, splitText, convertToHumanDate, removeDayFromDate, amountToAbbreviateNumber, isDateString, differenceDays } from './Helper';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import CompanyDealsModal from './CompanyDealsModal';
import LanguageIcon from '@material-ui/icons/Language';
import AxlChips from './reusableComponents/MUI/AxlChips';

const GeneralTable = ({financials, options, apiCallRequest}) => {
    const dealsStore = useSelector((state) => state.diseases);
    const { playersDataLevelLeaf } = dealsStore;

    if(playersDataLevelLeaf['data']){
        options.leafModalData = {
            name:playersDataLevelLeaf['data']['target'],
            nextData:playersDataLevelLeaf['data']
        }
    }

    const [sortField, setSortField] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');
    const [showModal, setShowModal] = useState(false); // State to toggle modal visibility
 
    const handleSort = (field) => {
        const newDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortDirection(newDirection);
    };

    const sortedFinancials = financials.length > 0 && [...financials].map(item => {
        const newItem = {};
        for (const key in item) {
            if (item.hasOwnProperty(key) && typeof item[key] === 'string') {
                if(options.trialType === 'venture_capital' || options.trialType === 'venture_capital_people' || options.trialType === 'brands' || options.trialType === 'mocks' || options.trialType === 'investments' || options.trialType === 'physicians' || options.trialType === 'trials' || options.trialType === 'phases'){
                    newItem[key] = item[key];
                }else{
                    newItem[key] = item[key].toLowerCase();
                }
            } else {
                newItem[key] = item[key];
            }
        }
        return newItem;
    }).sort((a, b) => {
        const valueA = a[sortField];
        const valueB = b[sortField];

        if (valueA < valueB) {
            return sortDirection === 'asc' ? -1 : 1;
        }
        if (valueA > valueB) {
            return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
    });
    
    const handleCallRequest = (value) => {
        if(options.trialType === 'deals' || options.trialType === 'players' || options.trialType === 'brands' || options.trialType === 'mocks' || options.trialType === 'venture_capital' || options.trialType === 'investments' || options.trialType === 'venture_capital_people' || options.trialType === 'physicians' || options.trialType === 'trials' || options.trialType === 'phases' || options.trialType == 'device_510k'){
            apiCallRequest(value)
            setShowModal(true);
        }
      }

    let columns_financials = []
    if(options.trialType === 'brands' ||
        options.trialType==='mocks_investments' ||
        options.trialType === 'patents_exclusivity' ||
        options.trialType === 'venture_capital' ||
        options.trialType === 'venture_capital_people' ||
        options.trialType === 'mocks' ||
        options.trialType === 'deals' ||
        options.trialType === 'investments' ||
        options.trialType === 'physicians' ||
        options.trialType === 'trials' ||
        options.trialType === 'phases' ||
        options.trialType === 'pipeline' ||
        options.trialType == 'device_510k'
    ){
        columns_financials = options.columns
    }else{
        columns_financials = financials[0]
    }

    const getMaxTruePhase = json =>
        Object.keys(json).reverse().find(key => json[key]);

    const getColorForPhase = (phase, column) => {
        const phaseOrder = {
            'Phase 1': 1,
            'Phase 2': 2,
            'Phase 3': 3,
            'Approved': 4
        };
    
        const maxPhase = phaseOrder[phase];
        const currentColumnPhase = phaseOrder[column];
    
        // If column is before or equal to the current phase, return the color of the current phase
        if (currentColumnPhase <= maxPhase) {
            switch (phase) {
                case 'Phase 1':
                    return 'bg-phase1';
                case 'Phase 2':
                    return 'bg-phase2';
                case 'Phase 3':
                    return 'bg-phase3';
                case 'Approved':
                    return 'bg-approved';
                default:
                    return '';
            }
        }
    
        return ''; // Return empty string if column is after the phase
    };
    
    

    return (
        financials.length > 0 && <>
        <Table className='people-table'>
            <TableHead>
            <TableRow className='people-header-row'>
            {/* ${typeof columns_financials[column] === 'number'?'text-end':columns_financials[column]} */}
                {Object.keys(columns_financials).map((column)=>{
                    return column !== 'axl_fda_url' && <TableCell className={`people-cell people-body-column text-capitalize`}>
                        <TableSortLabel
                            active={sortField === column}
                            direction={sortDirection}
                            onClick={() => handleSort(column)}
                        >
                            {
                                column==='roa'?
                                'RoA':
                                column==='mdr'?
                                'MDR':
                                column==='wam_price'?
                                'WAM Price':
                                column==='aca_limit'?
                                'ACA Limit':
                                column==='axl_market_cap'?
                                (column.replaceAll('_',' ').replaceAll('axl ','')+"($M)"):
                                columns_financials[column]?
                                columns_financials[column]:
                                (column.replaceAll('_',' ').replaceAll('axl ','').replaceAll('vc','').replaceAll('new',''))
                            }
                        </TableSortLabel>
                    </TableCell>
                    })
                }
            </TableRow>
            </TableHead>
            <TableBody>
            {/* ${typeof financial[column] === 'number' || isDateString(financial[column]) === true ?'text-end':''} */}
            {sortedFinancials.length > 0 && sortedFinancials.map((financial, index) => (
                <TableRow key={index} className={`people-row people-body-row ${options.trialType==='brands' || options.trialType==='mocks_investments' || options.trialType==='venture_capital' ? 'brands-row' : ''}`} onClick={() => handleCallRequest(financial)}>
                    {Object.keys(columns_financials).map((column,index)=>{
                        return column !== 'axl_fda_url' && <TableCell className={`${options.trialType==='mocks'?'':'people-cell'} text-capitalize ${options.trialType==='pipeline' ? 'pipeline-body-column' : 'people-body-column'} ${options.trialType === 'pipeline' ? column.replace(/\s+/g, '_') : ''} ${getColorForPhase(getMaxTruePhase(financial), column)}`}>
                                {
                                    column==='url'? 
                                        <Link
                                            target="_blank"
                                            to={{
                                            pathname: financial[column],
                                            }}>
                                            <i class="fas fa-external-link-alt"></i>
                                        </Link>:
                                    options.trialType === 'patents_exclusivity'?
                                        <span title={splitText(financial[column],';',10)}>{financial[column] ? splitText(financial[column],';',10):'-'}</span>:
                                    
                                    options.trialType === 'investments' && column==='year'?
                                        removeDayFromDate(financial[column]):
                                    options.trialType === 'investments' && (column==='raised' || column==='total_raised')?
                                        <span title={'$'+numberWithCommas(financial[column])}>{financial[column] ? amountToAbbreviateNumber(financial[column]):'-'}</span>:
                                    options.trialType === 'investments' && column==='investors'?
                                    <span title={splitText(financial[column],';',1000)}>{financial[column] ? splitText(financial[column],';',10):'-'}</span>:
                                    options.trialType === 'investments' && column==='round'?'':
                                    options.trialType === 'deals' && column==='type_of_deal'?'':
                                    options.trialType === 'brands' && column==='axl_generic'?'':
                                    options.trialType === 'brands' && column==='axl_brand_RoA'?'':
                                    column==='axl_brand_name'?
                                        financial['axl_fda_url']?<Link
                                        target="_blank"
                                        to={{
                                        pathname: financial['axl_fda_url'],
                                        }}>
                                            {financial[column]}
                                        </Link>:financial[column]
                                    :
                                    column==='All_Investors' && options.trialType === 'mocks_investments'?
                                    <span title={splitText(financial[column],';',10)}>{splitText(financial[column],';',1)}...</span>:
                                    column==='Company' && options.trialType==='mocks_investments'?
                                        <Link
                                        target="_blank"
                                        to={{
                                        pathname: financial['URL_for_Company'],
                                        }}>
                                            {financial[column]}
                                        </Link>:
                                    column==='axl_vc_name'?
                                        <>
                                        {
                                            financial[column]}
                                        {financial['axl_linkedin_page'] &&
                                            <Link
                                            class="ms-2"
                                            style={{color:'#6b6767'}}
                                            target="_blank"
                                            title="Linkedin"
                                            to={{
                                              pathname: financial['axl_linkedin_page'],
                                            }}>
                                              <LinkedInIcon/>
                                            </Link>}
                                            {financial['axl_bio_page'] &&
                                                <Link
                                                class="ms-2"
                                                target="_blank"
                                                title="Website"
                                                style={{color:'#6b6767'}}
                                                to={{
                                                pathname: financial['axl_bio_page'],
                                                }}>
                                                <LanguageIcon/>
                                                </Link>
                                            }
                                        </>
                                        :
                                        options.trialType==='physicians' && column==='axl_physician_name'?
                                        <>
                                        {
                                            financial[column]}
                                        {financial['linkedin'] &&
                                            <Link
                                            class="ms-2"
                                            style={{color:'#6b6767'}}
                                            target="_blank"
                                            title="Linkedin"
                                            to={{
                                              pathname: financial['linkedin'],
                                            }}>
                                              <LinkedInIcon/>
                                            </Link>}
                                            {financial['website_1'] &&
                                                <Link
                                                class="ms-2"
                                                target="_blank"
                                                title="Website"
                                                style={{color:'#6b6767'}}
                                                to={{
                                                pathname: financial['website_1'],
                                                }}>
                                                <LanguageIcon/>
                                                </Link>
                                            }
                                        </>
                                        :
                                    options.trialType==='venture_capital_people'?
                                        <>
                                        <span title={splitText(financial[column],';',10)}>{splitText(financial[column],';',1)}</span>
                                        {column==='name' && financial['linkedin'] &&
                                            <Link
                                            class="ms-2"
                                            style={{color:'#6b6767'}}
                                            target="_blank"
                                            title="Linkedin"
                                            to={{
                                              pathname: financial['linkedin'],
                                            }}>
                                              <LinkedInIcon/>
                                            </Link>}
                                            {
                                                column==='name' && <><br></br><small class="small-70">{financial['axl_company']}</small></>
                                            }
                                        </>
                                        :
                                    options.trialType==='deals' && column==='deal_date'?
                                        convertToHumanDate(financial[column])
                                    :
                                    options.trialType==='trials' && column==='axl_trial_length'?
                                    <span title={convertToHumanDate(financial['axl_start_date'])+' to '+convertToHumanDate(financial['axl_primary_completion_date'])}>
                                        {differenceDays(financial['axl_start_date'], financial['axl_primary_completion_date'])}
                                        {financial['axl_primary_completion_date'] && <br/>}
                                        {convertToHumanDate(financial['axl_primary_completion_date'])}
                                    </span>
                                    :
                                    options.trialType==='trials' && column==='Early_/_Delay'?
                                    <span title={'Primary Completion Date: ['+convertToHumanDate(financial['axl_primary_completion_date'])+']\nCurrent Completion Date: ['+convertToHumanDate(financial['completion_date'])+']'}>
                                        {differenceDays(financial['completion_date'], financial['axl_primary_completion_date']).replace('-','')+" "}
                                        {
                                            differenceDays(financial['completion_date'], financial['axl_primary_completion_date']) === '-' || Number(differenceDays(financial['completion_date'], financial['axl_primary_completion_date']).replace(" days","")) === 0 ?
                                                <AxlChips options={{chips: 'No Delay', size: 'small', dynamicPage: ''}} />
                                            :
                                                Number(differenceDays(financial['completion_date'], financial['axl_primary_completion_date']).replace(" days","")) > 0 ? 
                                                    <AxlChips options={{chips: 'Early', size: 'small', dynamicPage: ''}} />
                                                : 
                                                    <AxlChips options={{chips: 'Delay', size: 'small', dynamicPage: ''}} />
                                        }
                                    </span>
                                    :
                                    options.trialType==='trials' && column==='ESM'?
                                    financial['enrollment'] && financial['no_of_location'] && Number(differenceDays(financial['axl_start_date'], financial['axl_primary_completion_date']).replace(" days",""))?
                                    Math.round((Number(financial['enrollment'])/Number(financial['no_of_location'])/Number(differenceDays(financial['axl_start_date'], financial['axl_primary_completion_date']).replace(" days","")))*30 * 100)/100:'-'
                                    :
                                    options.trialType==='trials' && column==='axl_start_date'?
                                        convertToHumanDate(financial[column])
                                    :
                                    options.trialType==='trials' && column==='no_of_location'?
                                        <span title={financial['locations']}>
                                            {financial[column]}
                                        </span>
                                    :
                                    options.trialType==='trials' && column==='nct_number'?
                                        <Link
                                        style={{color:'#000000'}}
                                        target="_blank"
                                        to={{
                                        pathname: `https://clinicaltrials.gov/study/${financial[column]}`,
                                        }}>
                                            {financial[column]}
                                        </Link>
                                    :
                                    options.trialType==='trials' && column==='axl_company'?
                                        <Link
                                        style={{color:'#000000'}}
                                        target="_blank"
                                        to={{
                                        pathname: `/Company/${financial['axl_company_id_id']}/${financial[column]}/overview`,
                                        }}>
                                            {financial[column]}
                                        </Link>
                                    :
                                    options.trialType==='deals' && (column==='target' || column==='acquisitions')?
                                        <Link
                                        style={{color:'#000000'}}
                                        target="_blank"
                                        to={{
                                        pathname: `/Company/${financial['axl_target_id']}/${financial['target']}/overview`,
                                        }}>
                                            {financial[column]}
                                        </Link>
                                    :
                                    options.trialType==='deals' && column==='valuation'?
                                        amountToAbbreviateNumber(financial[column])
                                    :
                                    column==='axl_brand_RoA' || column==='axl_vc_category'?
                                        <span title={splitText(financial[column],';',100)}>{splitText(financial[column],';',100)}</span>
                                    :
                                    column==='axl_stock_price_usd'?
                                    (
                                        financial[column]>0?'$'+numberWithCommas(financial[column]):'-'
                                    ):(column==='axl_market_cap'?
                                    (
                                        financial[column]>0?'$'+convertToMimillions(financial[column]):'-'
                                    ):financial[column])
                                }
                                {
                                    options.trialType==='physicians'?
                                        column==='axl_physician_name' && <><br></br>
                                        
                                        {financial['axl_specialization'] && <small title={splitText(financial['axl_specialization'],';',20)} class="small-70">{splitText(financial['axl_specialization'],';',2)+' | '}</small>}

                                        {financial['axl_state'] && <small class="small-70">{financial['axl_state']}</small>}
                                        </>
                                    :
                                        column==='axl_vc_name' && <><br></br><small class="small-70">{financial['axl_vc_title']}</small></>
                                }
                                {
                                    options.trialType==='trials' &&
                                        column==='axl_primary_intervention' && <><br></br>
                                        
                                        {financial['axl_start_date'] && convertToHumanDate(financial['axl_start_date'])+' '}
                                        </>
                                }
                                {
                                    options.showChips && 
                                    Object.keys(options.chipColumn).map((chip_column)=>{
                                        return options.chipColumn[chip_column] === 'company_type'?
                                        column === chip_column && financial[options.chipColumn[chip_column]] &&
                                        <div className="chips-container">
                                            <AxlChips options={{chips: financial[options.chipColumn[chip_column]], size: 'small', dynamicPage: 'VenturnCapital'}} />
                                        </div>
                                        : 
                                        typeof options.chipColumn[chip_column] === "string"? 
                                            chip_column === 'axl_generic'?
                                            column === chip_column && financial[options.chipColumn[chip_column]] &&
                                                <div className="chips-container">
                                                    <AxlChips options={{chips: financial[options.chipColumn[chip_column]], size: 'small', dynamicPage: 'Generic'}} />
                                                </div>
                                            :
                                            column === chip_column && financial[options.chipColumn[chip_column]] && 
                                                <div className="chips-container">
                                                    {options.trialType === 'investments' && column==='round'?
                                                        <AxlChips options={{chips: financial[options.chipColumn[chip_column]].replaceAll("Series","").replaceAll("Round",""), size: 'small', dynamicPage: ''}} />
                                                    :
                                                    <AxlChips options={{chips: financial[options.chipColumn[chip_column]], size: 'small', dynamicPage: ''}} />}
                                                </div>
                                            :
                                            column === chip_column && financial[chip_column] &&
                                            <div className="chips-container">
                                                {options.chipColumn[chip_column].map((value_chip) => {
                                                    if (financial[value_chip]) {
                                                        return <AxlChips options={{chips: financial[value_chip].replaceAll("_"," "), size: 'small', dynamicPage: '', columnName:value_chip.replaceAll("_"," ").replaceAll("axl","").toUpperCase()}} />;
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                    })
                                }
                                {
                                    column === 'Phase 1' || column === 'Phase 2' || column === 'Phase 3' || column === 'Approved'?
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" style={{width: financial[column]}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>:''
                                }

                        </TableCell>
                        })
                    }
                </TableRow>
            ))}
            </TableBody>
        </Table> 
        {showModal && (
        options.leafModalData ? (
            (options.trialType === 'trials' || options.trialType === 'phases') && options.leafModalData['name']? (<TrialsModal item={options.leafModalData} onClose={() => setShowModal(false)} />) :
            options.trialType === 'venture_capital_people' && options.leafModalData['name']? (<PeopleCEOModal item={options.leafModalData} onClose={() => setShowModal(false)} />) :
            options.trialType === 'investments' && options.leafModalData['name']? (<VentureCapitalCompanyDealsModal item={options.leafModalData} onClose={() => setShowModal(false)} />) :
            options.trialType === 'deals' && options.leafModalData['name']? (<CompanyDealsModal item={options.leafModalData} onClose={() => setShowModal(false)} />) :
            options.trialType === 'venture_capital' && options.leafModalData['name']? (<VentureCapitalModal item={options.leafModalData} onClose={() => setShowModal(false)} />) :
            options.trialType === 'players' ? (<PlayersModal item={options.leafModalData} onClose={() => setShowModal(false)} />) : options.trialType === 'brands' || options.trialType === 'mocks' ? (<BrandModal item={options.leafModalData} onClose={() => setShowModal(false)} />) : null ) : null
        )}
        </>
    );
};

export default GeneralTable;
