import React from 'react';

const CysticFibrosisChildrenMobile = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginBottom: 10,
        }}
      >
        <div
          style={{
            backgroundColor: '#FFEBEF',
            border: '1px solid #C33C54',
            color: '#C33C54',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p>Screening Tests</p>
        </div>
      </div>

      <div
        style={{
          width: '80%',
          padding: '5px 10px',
          backgroundColor: '#A4C2F4',
          borderRadius: 5,
          fontWeight: 'bold',
          fontSize: 14,
          textAlign: 'center',
          alignSelf: 'center',
        }}
      >
        <p>Chorionic Villus sampling (Prenatal)</p>
      </div>

      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: '#C33C54',
          alignSelf: 'center',
        }}
      ></div>

      <div
        style={{
          width: '80%',
          backgroundColor: '#C33C54',
          height: 1,
          alignSelf: 'center',
        }}
      ></div>

      <div style={{ alignSelf: 'center', width: '80%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Positive
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #2BB670',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Negative
        </p>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            width: '70%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
              marginRight: '71%',
            }}
          ></div>
          <div
            style={{
              width: '80%',
              padding: '5px 10px',
              backgroundColor: '#A4C2F4',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              alignSelf: 'center',
            }}
          >
            <p>Immunoreactive Trypsinogen (IRT) test</p>
          </div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
              alignSelf: 'center',
            }}
          ></div>

          <div
            style={{
              width: '80%',
              backgroundColor: '#C33C54',
              height: 1,
              alignSelf: 'center',
            }}
          ></div>

          <div style={{ alignSelf: 'center', width: '80%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  height: 20,
                  width: 1,
                  backgroundColor: '#C33C54',
                }}
              ></div>
              <div
                style={{
                  height: 20,
                  width: 1,
                  backgroundColor: '#C33C54',
                }}
              ></div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignSelf: 'center',
            }}
          >
            <p
              style={{
                wordBreak: 'break-word',
                width: '30%',
                border: '3px solid #980000',
                borderRadius: 10,
                padding: '5px 2px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Positive
            </p>
            <p
              style={{
                wordBreak: 'break-word',
                width: '30%',
                border: '3px solid #2BB670',
                borderRadius: 10,
                padding: '5px 2px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Negative
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignSelf: 'center',
            }}
          >
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
                marginLeft: '10%',
              }}
            ></div>
            <div
              style={{
                height: 20,
                width: 1,
                backgroundColor: '#C33C54',
                marginRight: '10%',
              }}
            ></div>
          </div>
        </div>

        <div style={{ width: '25%' }}>
          <div
            style={{
              height: '100%',
              width: 1,
              backgroundColor: '#C33C54',
              marginLeft: '58%',
            }}
          ></div>
        </div>
      </div>

      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%' }}>
          <div
            style={{
              height: '100%',
              width: 1,
              backgroundColor: '#C33C54',
              marginLeft: '14%',
            }}
          ></div>
        </div>
        <div
          style={{
            width: '50%',
            padding: '5px 10px',
            backgroundColor: '#2BB670',
            borderRadius: 5,
            fontWeight: 'bold',
            fontSize: 14,
            textAlign: 'center',
            alignSelf: 'center',
            marginBottom: 10,
          }}
        >
          <p>CF: Negative</p>
        </div>
      </div>

      <div
        style={{
          backgroundColor: '#FFEBEF',
          border: '1px solid #C33C54',
          color: '#C33C54',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 10,
        }}
      >
        <p>Confirmatory Tests</p>
      </div>

      <div
        style={{
          width: '80%',
          padding: '5px 10px',
          backgroundColor: '#A4C2F4',
          borderRadius: 5,
          fontWeight: 'bold',
          fontSize: 14,
          textAlign: 'center',
          alignSelf: 'center',
        }}
      >
        <p>Sweat Chloride Test</p>
      </div>
      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: '#C33C54',
          alignSelf: 'center',
        }}
      ></div>
      <div
        style={{
          width: '80%',
          backgroundColor: '#C33C54',
          height: 1,
          alignSelf: 'center',
        }}
      ></div>

      <div style={{ alignSelf: 'center', width: '80%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {`> 60 mmol/L`}
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {`30-59 mmol/L`}
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #2BB670',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {`<29 mmol/L`}
        </p>
      </div>

      <div style={{ alignSelf: 'center', width: '80%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>

      <div style={{ alignSelf: 'center', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              width: '30%',
              padding: '5px 10px',
              backgroundColor: '#2BB670',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // alignSelf: 'center',
              color: '#fff',
            }}
          >
            <p>CF: Positive</p>
          </div>
          <div
            style={{
              width: '30%',
              padding: '5px 10px',
              backgroundColor: '#980000',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // alignSelf: 'center',
              color: '#fff',
            }}
          >
            <p>CF suspected</p>
          </div>
          <div
            style={{
              width: '30%',
              padding: '5px 10px',
              backgroundColor: '#FFA10A',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // alignSelf: 'center',
              color: '#000',
            }}
          >
            <p>CF: Negative</p>
          </div>
        </div>
      </div>

      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: '#C33C54',
          alignSelf: 'center',
        }}
      ></div>

      <div
        style={{
          width: '80%',
          padding: '5px 10px',
          backgroundColor: '#A4C2F4',
          borderRadius: 5,
          fontWeight: 'bold',
          fontSize: 14,
          textAlign: 'center',
          alignSelf: 'center',
        }}
      >
        <p>CFTR Genetic Test</p>
      </div>

      <div
        style={{
          height: 20,
          width: 1,
          backgroundColor: '#C33C54',
          alignSelf: 'center',
        }}
      ></div>

      <div
        style={{
          width: '80%',
          backgroundColor: '#C33C54',
          height: 1,
          alignSelf: 'center',
        }}
      ></div>

      <div style={{ alignSelf: 'center', width: '80%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
            }}
          ></div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          2 Mutations
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          1 Mutation
        </p>
        <p
          style={{
            wordBreak: 'break-word',
            width: '30%',
            border: '3px solid #980000',
            borderRadius: 10,
            padding: '5px 2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          No Mutations
        </p>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '80%',
          alignSelf: 'center',
        }}
      >
        <div
          style={{
            height: 20,
            width: 1,
            backgroundColor: '#C33C54',
          }}
        ></div>
        <div
          style={{
            height: 20,
            width: 1,
            backgroundColor: '#C33C54',
          }}
        ></div>
        <div
          style={{
            height: 20,
            width: 1,
            backgroundColor: '#C33C54',
          }}
        ></div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '80%',
          alignSelf: 'center',
        }}
      >
        <div
          style={{
            height: 195,
            width: 1,
            backgroundColor: '#C33C54',
          }}
        ></div>
        <div>
          <div
            style={{
              padding: '5px 10px',
              backgroundColor: '#FFA10A',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#000',
            }}
          >
            <p>Indeterminate</p>
          </div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
              margin: 'auto',
            }}
          ></div>
          <div
            style={{
              padding: '5px 10px',
              backgroundColor: '#A4C2F4',
              borderRadius: 5,
              fontWeight: 'bold',
              fontSize: 14,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#000',
            }}
          >
            <p>CFTR Physiological Test</p>
          </div>
          <div
            style={{
              height: 20,
              width: 1,
              backgroundColor: '#C33C54',
              margin: 'auto',
            }}
          ></div>
          <div
            style={{
              width: '80%',
              backgroundColor: '#C33C54',
              height: 1,
              margin: 'auto',
            }}
          ></div>
          <div style={{ alignSelf: 'center', width: '80%', marginLeft: '10%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  height: 20,
                  width: 1,
                  backgroundColor: '#C33C54',
                }}
              ></div>
              <div
                style={{
                  height: 20,
                  width: 1,
                  backgroundColor: '#C33C54',
                }}
              ></div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p
              style={{
                wordBreak: 'break-word',
                width: '40%',
                border: '3px solid #980000',
                borderRadius: 10,
                padding: '5px 2px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              CFTR Dysfunction
            </p>
            <p
              style={{
                wordBreak: 'break-word',
                width: '40%',
                border: '3px solid #980000',
                borderRadius: 10,
                padding: '5px 2px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              CFTR Preserved Function
            </p>
          </div>
          <div style={{ alignSelf: 'center', width: '80%', marginLeft: '10%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  height: 20,
                  width: 1,
                  backgroundColor: '#C33C54',
                }}
              ></div>
              <div
                style={{
                  height: 20,
                  width: 1,
                  backgroundColor: '#C33C54',
                }}
              ></div>
            </div>
          </div>
        </div>
        <div
          style={{
            height: 195,
            width: 1,
            backgroundColor: '#C33C54',
          }}
        ></div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            padding: '5px 10px',
            backgroundColor: '#C33C54',
            borderRadius: 5,
            fontWeight: 'bold',
            fontSize: 14,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
          }}
        >
          <p>CF: Positive</p>
        </div>
        <div
          style={{
            padding: '5px 10px',
            backgroundColor: '#2BB670',
            borderRadius: 5,
            fontWeight: 'bold',
            fontSize: 14,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#000',
          }}
        >
          <p>CF: Negative</p>
        </div>
      </div>
    </div>
  );
};

export default CysticFibrosisChildrenMobile;
