import React, { useEffect } from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import SmallThinArrow from '../../../assets/img/SmallRedArrowIcon.svg';
import ArrowBranch from '../../../assets/img/SmallRedArrowIcon.svg';
import { setOutcomesData } from '../../../reducers/PatientJourneyReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import OblongDiv from './MUI/OblongDiv';
import { isMobile } from 'react-device-detect';
// import SmallThinArrow from "../../assets/assets/img/img/SmallRedArrowIcon.svg";
// import ArrowBranch from "../../assets/assets/img/img/SmallRedArrowIcon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow:
        'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem 0.75rem',
    },
  },
  main: {
    position: 'relative',
    // marginTop: '40px',
    width: '500px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      // padding: "1rem",
    },
  },
  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #595959',
    margin: '0 15px 0 0 ',
    background: '#F5F5F5',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#595959',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#F5F5F5',
    borderRadius: '200px',
    border: '1px solid #595959',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#595959',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },
  topBox: {
    width: '500px',
    border: '1px solid #595959',
    color: '#000',
    borderRadius: '10px',
    fontSize: '14px',
    textAlign: 'center',
    height: '50px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  containerBig: {
    border: '1px solid #595959',
    backgroundColor: '#F5F5F5',
    padding: '12px 10px',
    borderRadius: '9px',
  },
  innersection: {
    marginBottom: '36px',
  },
  innerBox: {
    backgroundColor: '#595959',
    color: '#fff',
    width: '475px',
    height: '48px',
    borderRadius: '9px',
    textAlign: 'center',
    fontSize: '14px',
    border: '1px solid #595959',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  smallInnerBox: {
    backgroundColor: '#FFF',
    borderRadius: 9,
    height: 40,
    position: 'relative',
    border: '1px solid #595959',
    textAlign: 'center',
    marginTop: 20,
    width: '150px',
    marginRight: '12px',
    '&:nth-child(3)': {
      marginRight: '0',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(92% / 3)',
    },
    marginBottom: 20,
  },
  percentContainer: {
    position: 'absolute',
    // bottom: "-15px",
    backgroundColor: 'white',
    borderRadius: 50,
    width: 70,
    height: 30,
    textAlign: 'center',
    border: '1px solid #000',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '-24px',
    color: '#000',
    '& p': { size: '14px', marginTop: 5 },
    [theme.breakpoints.down('sm')]: {
      width: 50,
      height: 25,
      bottom: '-17px',
      '& p': { marginTop: 2 },
    },
  },
  bottomCard: {
    width: '150px',
    // height: '150px',
    position: 'relative',
    color: '#000',
    borderRadius: '9px',
    marginRight: '25px',
    textAlign: 'center',
    padding: '1rem 0.25rem',
    fontSize: '14px',

    '&:nth-child(1)': {
      backgroundColor: '#93C47D      ',
    },
    '&:nth-child(2)': {
      backgroundColor: '#FFA10A      ',
    },

    '&:nth-child(3)': {
      marginRight: '0',
      backgroundColor: '#000000',
      color: '#fff',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(85% / 3)',
    },
  },
}));

const Desktop9 = ({ noFloat, noNumber }) => {
  const { diseaseName, diseaseId } = useParams();
  const classes = useStyles();
  const patientJourney = useSelector((state) => state.patientJourney);
  const { outcomes } = patientJourney;
  const outcomesLength = Object.keys(outcomes).length;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOutcomesData(diseaseId));
  }, []);

  return isMobile ? (
    <Paper elevation={3} style={{ width: '100%' }}>
      {/* <div className={classes.timelineAppBar}>
    <div className={classes.timelineHeading}>
      {!noNumber && (
        <div className={classes.timelineCircleBG}>
          {outcomes.mainTitleNumber}
        </div>
      )}
      <div className={classes.timelineTitle}>{outcomes.mainTitleText}</div>
      {console.log(outcomes)}
    </div>
  </div> */}
      {outcomesLength !== 0 && (
        <div className={classes.main}>
          {outcomes.descriptionBoxText.length >= 1 && (
            <OblongDiv text={outcomes.descriptionBoxText} borderColor='black' />
          )}

          <div style={{ marginTop: 20 }}>
            <OblongDiv
              bg='#F5F5F5'
              text={outcomes.titleBoxText}
              borderColor='black'
            />
          </div>
          {/* <div
        className={classes.topBox}
        style={{
          backgroundColor: '#F5F5F5',
          marginTop: '20px',
          height: 30,
        }}
      >
        <p>{outcomes.titleBoxText}</p>
      </div> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <img src={SmallThinArrow} alt='arrow' />
          </div>
          <div className={classes.containerBig}>
            {outcomes.innerData.map((sec, index) => (
              <div className={classes.innersection} key={index}>
                <OblongDiv
                  text={sec.sectionTitle}
                  bg='#595959'
                  textColor='white'
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                  }}
                >
                  {sec.childBox.map((item, i) => (
                    <div style={{ marginTop: 20 }}>
                      <OblongDiv
                        text={item.title}
                        bg='white'
                        borderColor='black'
                        bottomBoxText={item.percent}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {outcomes.cardData && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={ArrowBranch} alt='aarrow branch' />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
            }}
          >
            {outcomes.cardData &&
              outcomes.cardData.map((cardItem, i) => {
                return isMobile ? (
                  <div style={{ marginTop: i === 0 ? 0 : 20 }}>
                    <OblongDiv
                      text={cardItem.content}
                      bg={cardItem.bg}
                      textColor={cardItem.color}
                      bottomBoxText={cardItem.percent}
                      square
                      maxWidth
                    />
                  </div>
                ) : (
                  <div style={{ marginTop: i === 0 || i === 1 ? 0 : 20 }}>
                    <OblongDiv
                      text={cardItem.content}
                      bg={cardItem.bg}
                      textColor={cardItem.color}
                      bottomBoxText={cardItem.percent}
                      square
                      maxWidth
                    />
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </Paper>
  ) : (
    <div className={classes.root} style={{ float: !noFloat && 'right' }}>
      {/* <div className={classes.timelineAppBar}>
        <div className={classes.timelineHeading}>
          {!noNumber && (
            <div className={classes.timelineCircleBG}>
              {outcomes.mainTitleNumber}
            </div>
          )}
          <div className={classes.timelineTitle}>{outcomes.mainTitleText}</div>
          {console.log(outcomes)}
        </div>
      </div> */}
      {outcomesLength !== 0 && (
        <div className={classes.main}>
          {outcomes.descriptionBoxText.length >= 1 && (
            <OblongDiv text={outcomes.descriptionBoxText} borderColor='black' />
          )}

          <div style={{ marginTop: 20 }}>
            <OblongDiv
              bg='#F5F5F5'
              text={outcomes.titleBoxText}
              borderColor='black'
            />
          </div>
          {/* <div
            className={classes.topBox}
            style={{
              backgroundColor: '#F5F5F5',
              marginTop: '20px',
              height: 30,
            }}
          >
            <p>{outcomes.titleBoxText}</p>
          </div> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <img src={SmallThinArrow} alt='arrow' />
          </div>
          <div className={classes.containerBig}>
            {outcomes.innerData.map((sec, index) => (
              <div className={classes.innersection} key={index}>
                <OblongDiv
                  text={sec.sectionTitle}
                  bg='#595959'
                  textColor='white'
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                  }}
                >
                  {sec.childBox.map((item, i) => (
                    <div style={{ marginTop: 20 }}>
                      <OblongDiv
                        text={item.title}
                        bg='white'
                        borderColor='black'
                        bottomBoxText={item.percent}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {outcomes.cardData && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={ArrowBranch} alt='aarrow branch' />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
            }}
          >
            {outcomes.cardData &&
              outcomes.cardData.map((cardItem, i) => {
                return isMobile ? (
                  <div style={{ marginTop: i === 0 ? 0 : 20 }}>
                    <OblongDiv
                      text={cardItem.content}
                      bg={cardItem.bg}
                      textColor={cardItem.color}
                      bottomBoxText={cardItem.percent}
                      square
                      maxWidth
                    />
                  </div>
                ) : (
                  <div style={{ marginTop: i === 0 || i === 1 ? 0 : 20 }}>
                    <OblongDiv
                      text={cardItem.content}
                      bg={cardItem.bg}
                      textColor={cardItem.color}
                      bottomBoxText={cardItem.percent}
                      square
                      maxWidth
                    />
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Desktop9;
