import React, { useEffect, useState, useRef } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LeafRow from './LeafRow';
import { numberWithCommas, splitText } from '../../Helper';
import { Button } from '@material-ui/core';

const ExpandableMiddleRow = ({options, item, apiCallRequest}) => {
  let [DevicesRowClicked, setDevicesRowClicked] = useState(false);
  let btnRef = useRef();
  // const nextItem = item[1][options.dataSwitch] ? Object.entries(item[1][options.dataSwitch]) : Object.entries(item[1]);
  const columnSize = options.trialType=='phases'?30:45;
  let level = options.extraRow?'level4':'level3';
  let sub_parent = options.extraRow || options.input_type=='treatment'?item['sub_parent']:'';
  const handleCallRequest = () => {
    setDevicesRowClicked(!DevicesRowClicked)
    if(options.trialType != 'deals'){
      apiCallRequest(item['name'], item['id'], item['is_leaf'], 0, level, btnRef.current.getAttribute("apiclick"), item['parent'], sub_parent)
    }
    if(btnRef.current){
      btnRef.current.setAttribute("apiclick", "yes");
    }
  }
  const loadMoreRequest = () =>{
    apiCallRequest(item['name'],item['id'], item['is_leaf'], item['offset'], level, 'no', item['parent'], sub_parent)
  }
  return (
    <>
      <div apiclick="no" ref={btnRef}
        className={`expandable-row appr-subheading expand-middle-row ${DevicesRowClicked ? 'expanded' : ''}`}
        onClick={handleCallRequest}
      >
        <div className='expandable-cell text-capitalize'>
          {options.trialType != 'deals' &&
            <div style={{ display: 'inline' }}>
              {DevicesRowClicked ? (
                <ExpandMoreIcon
                  style={{ color: DevicesRowClicked ? 'black' : 'black' }}
                />
              ) : (
                <ExpandMoreIcon 
                  style={{transform: 'rotate(-90deg)'}}
                />
              )}
            </div>
          }
          {item['physician_table_id']?<a target="_blank" href={`/physician/${item['physician_table_id']}`}>{item['name'].length > columnSize?item['name'].toLowerCase().substring(0,columnSize)+'...':item['name'].toLowerCase()}</a>:(item['name'].length > columnSize?item['name'].toLowerCase().substring(0,columnSize)+'...':item['name'].toLowerCase())}
          {<small title={item['title_display'] ? splitText(item['title_display'],';',2) : splitText(item['title_all'],';',2)}>{item['title_display'] ? splitText(item['title_display'],';',2,65) : splitText(item['title_all'],';',2,65)}</small>}
          {item['specialty']&& <><br/><small className='small-70'>{item['specialty']} | {item['state']}</small></>}
        </div>
        {options.trialType == 'players' || options.trialType == 'deals' ? options.column_keys.map((column_value) => {
            return (
              <div className='expandable-cell'>{options.trialType == 'deals'?item[column_value]:numberWithCommas(item[column_value])}</div>
            )})
          :
            <div
              className='expandable-cell'
              style={{textAlign: 'right'}}
            >
              <div class="col-12 p-0">
                    <div class="row">
                      {options.trialType == 'phases' ? options.column_keys.map((column_value) => {
                          return (
                            <>
                              <div class="col">
                                {numberWithCommas(item[column_value])}
                              </div>
                            </>
                          );
                          }) : <div class="col">{options.icon?options.icon:''}
                          {numberWithCommas(item['total'])}</div>
                      }
                    </div>
                </div>
            </div>
          }
      </div>
      {DevicesRowClicked &&
        item['nextData'].map((subitem, index) => {
        return (
          <LeafRow apiCallRequest={apiCallRequest}options={options} item={subitem} />
        );
      })
    }
      {item['tot_row_count'] > item['offset'] && <div className='d-flex justify-content-center load-more-btn'>
        {DevicesRowClicked && <Button onClick={loadMoreRequest} style={{
            border : '1px solid',
            borderRadius : '3px',
            padding: '2px 8px',
            margin: '5px 0px',
            color: 'black',
            fontSize:'12px'
          }}> Load More</Button>}
      </div>}
    </>
  )
}

export default ExpandableMiddleRow;