import React from 'react';
import { makeStyles, Grid, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },
    // border: "1px solid #000",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },
  headingBox: {
    backgroundColor: '#FFEBEF',
    border: '1px solid #C33C54',
    width: '100%',
    height: 34,
    color: '#C33C54',
    fontSize: '14px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  blueBoxSmall: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#9FCEFC',
    height: '50px',
    width: '100%',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '1rem',
    '& p': {
      margin: 0,
    },
  },
  // Arrow
  arrow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
  },
  arrowtail: {
    background: '#C33C54',
    height: '20px',
    width: '1.5px',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '4px solid #C33C54',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
  },
  // branch
  branchWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  branchMain: {
    borderRight: '1.5px solid #C33C54',
    borderLeft: '1.5px solid #C33C54',
    borderTop: '1.5px solid #C33C54',
  },
  branchMiddleLine: {
    justifySelf: 'center',
    background: '#C33C54',
  },
  redBorderGrayBox: {
    width: '48%',
    borderRadius: '7px',
    border: '1px solid #C33C54',
    backgroundColor: '#EEF0F0',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0.45rem',
    wordBreak: 'break-all',
    minHeight: '102px',
    fontSize: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      fontSize: '12px',
      wordBreak: 'keep-all',
    },
  },

  toplevelWrap: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
}));

const Arrow = ({ tailHeight, tailWidth, arrowPointStyle, arrowMainStyle }) => {
  const classes = useStyles();
  return (
    <div class={classes.arrow} style={arrowMainStyle}>
      <div class={classes.arrowtail} style={{ height: tailHeight }}></div>
      <div class={classes.arrowpoint}></div>
    </div>
  );
};

const Branch = ({
  branchStyle,
  middleLineStyle,
  branchClassName,
  middleLineClassName,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.branchWrap}>
      <div className={classes.branchMiddleLine} style={middleLineStyle}></div>
      <div className={classes.branchMain} style={branchStyle}></div>
    </div>
  );
};

const TestChart = ({ testInfo }) => {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:600px)');
  return (
    <div>
      <div className={classes.blueBoxSmall}>{testInfo.name}</div>
      <Branch
        middleLineStyle={{
          width: '1.5px',
          height: '22px',
        }}
        branchStyle={{
          width: matches ? '60%' : '75%',
          height: '25px',
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={classes.toplevelWrap} style={{ position: 'relative' }}>
          <div className={classes.redBorderGrayBox}>
            {testInfo.testRange[0].text}
          </div>
          <Arrow
            arrowMainStyle={{ left: matches ? '20%' : '15%', top: '102px' }}
            tailHeight={30}
          />
          <div className={classes.redBorderGrayBox}>
            {testInfo.testRange[1].text}
          </div>
          <Arrow
            arrowMainStyle={{ right: matches ? '20%' : '15%', top: '102px' }}
            tailHeight={30}
          />
        </div>
      </div>
      <div className={classes.toplevelWrap} style={{ marginTop: '34px' }}>
        <div
          className={classes.redBorderGrayBox}
          style={{
            backgroundColor: '#C33C54',
            color: 'white',
            minHeight: 'auto',
          }}
        >
          {testInfo.testResult[0].left}
        </div>
        <div
          className={classes.redBorderGrayBox}
          style={{
            backgroundColor: '#2BB670',
            border: '1px solid #2BB670',
            minHeight: 'auto',
          }}
        >
          {testInfo.testResult[1].right}
        </div>
      </div>
    </div>
  );
};

const AtherosclerosisDiagnosisScreeningTest = () => {
  const classes = useStyles();
  const data = {
    pageTitle: 'Atherosclerosis: Diagnosis',
    testName: 'Screening test',
    testStages: [
      {
        name: 'Blood Test',
        testRange: [
          {
            text: 'LDL>160mg/dL HDL<45-55 mg/dL Triglycerides>150mg/dL Total Cholesterol>240mg/dL',
          },
          {
            text: 'LDL<160mg/dL HDL>45-55 mg/dL Triglycerides<150mg/dL Total Cholesterol<240mg/dL',
          },
        ],
        testResult: [
          { left: 'Risk of heart disease' },
          { right: 'No risk of heart disease' },
        ],
      },
      {
        name: 'Ankle Brachial Index (ABI)',
        testRange: [
          {
            text: '1 to 1.4',
          },
          {
            text: '> 1.4=stiff blood vessels <1 suggests levels of PAD ',
          },
        ],
        testResult: [{ left: 'Normal' }, { right: 'Risk of heart disease' }],
      },
      {
        name: 'Stress Test',
        testRange: [
          {
            text: 'Normal blood flow at rest but not during exercise',
          },
          {
            text: 'Low blood flow during both exercise and resting',
          },
        ],
        testResult: [
          { left: 'Blocked artery' },
          { right: 'Coronary artery disease' },
        ],
      },
    ],
  };
  return (
    <div className={classes.root}>
      <h2>{data.pageTitle}</h2>
      <div className={classes.headingBox} style={{ marginTop: '40px' }}>
        <p> {data.testName}</p>
      </div>
      <Grid container spacing={1}>
        {data.testStages.map((test, i) => (
          <Grid item xs={12} sm={4} index={i}>
            <div style={{ marginTop: '30px' }}>
              <TestChart testInfo={test} />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default AtherosclerosisDiagnosisScreeningTest;
