import React, { useState } from 'react';
import {
  makeStyles,
  Collapse,
  Paper,
  ClickAwayListener,
} from '@material-ui/core';
import LightGreenArrowIcon from '../../../assets/img/LightGreenArrowIcon.svg';
import WhiteArrowIcon from '../../../assets/img/WhiteArrowIcon.svg';

const useStyles = makeStyles((theme) => ({
  smallSubheadingCont: {
    width: '50%',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  ddHeadingText: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: '0px',
    marginLeft: '10%',
    [theme.breakpoints.down('sm')]: {
      // wordBreak: 'break-all',
    },
  },
  dropdownBody: {
    backgroundColor: '#EEFBF5',
    padding: '0.5rem 1rem',
    position: 'absolute',
    // top: "30px",
    zIndex: '100',
    // width: "100%",
    marginRight: 'auto',
    '& p': {
      marginTop: 0,
      marginBottom: '4px',
    },
  },
  dropDownbodyText: {
    fontSize: '14px',
    fontWeight: 400,
    '& b': {
      fontWeight: 700,
    },
  },
  DropdownboxMainCont: {
    display: 'flex',
    position: 'relative',
    marginBottom: '0.5rem',
  },
  dropDownDetailText: {
    fontSize: '12px',
  },
  drugListItemContainer: {
    marginTop: '8px',
    padding: '0 0px 0 18px',
    '& li': {
      fontSize: '12px',
      wordWrap: 'break-word',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 0px 0 16px',
    },
  },
  drugShortDescriptionContainer: {
    backgroundColor: '#EEE',
    fontSize: '14px',
    padding: '1rem',
    borderRadius: '9px',
    width: '100%',
    margin: '0 0 1.5rem 0',
  },

  // Arrow
  bigArrowCont: {
    width: '100%',
    marginLeft: 'auto',
    position: 'relative',
    '& p': {
      position: 'absolute',
      top: '17px',
      fontWeight: '600',
      fontSize: '14px',
      left: '45%',
      transform: 'translateX(-50%)',
    },

    [theme.breakpoints.down('sm')]: {
      '& p': {
        top: '20px',
        fontSize: '10px',
      },
    },
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.75rem 0 0.5rem 0',
  },
  arrowtail: {
    background: '#EEE',
    // border: "1px solid #000",
    height: '34px',
    width: 'calc(100% - 20px)',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '28px solid transparent',
    borderLeft: '28px solid #eee',
    borderBottom: '28px solid transparent',
  },

  blackDivider: {
    position: 'absolute',
    width: '2px',
    height: '92%',
    backgroundColor: '#000',
    top: 0,
    zIndex: -1,
  },

  darkGrayBar: {
    backgroundColor: '#d3d3d3',
    // height: "20px",
    fontWeight: 'bold',
    fontSize: '14px',
    textAlign: 'center',
    borderRadius: '5px',
    margin: '6px 0',
  },
  DDArrowIcon: {
    height: '10px',
    width: '20px',
  },
}));

const DropdownBox = ({
  dropdownData,
  styleData,
  subheadingTextStyle,
  arrowImgstyle,
}) => {
  const classes = useStyles();
  const [openDropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown((prev) => !prev);
  };
  const handleClickAway = () => {
    setDropdown(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <div
          // className={classes.dropdownHeader}
          onClick={handleDropdown}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '30px',
            border: '1px solid #2BB670',
            textAlign: 'center',
            width: '100%',
            borderRadius: 9,
            cursor: 'pointer',
            alignItems: 'center',
            wordBreak: 'break-word',
            backgroundColor: openDropdown ? '#2BB670' : ' #EEFBF5',
            color: openDropdown ? '#fff' : '#000',
          }}
        >
          <p
            className={classes.ddHeadingText}
            style={subheadingTextStyle ? subheadingTextStyle : {}}
          >
            {dropdownData.title}
          </p>

          {dropdownData.desc && openDropdown ? (
            <img
              src={WhiteArrowIcon}
              className={classes.DDArrowIcon}
              alt='arrow drop down'
              style={{ width: '10%' }}
            />
          ) : (
            dropdownData.desc && (
              <img
                src={LightGreenArrowIcon}
                className={classes.DDArrowIcon}
                alt='green arrow drop down'
                style={{ width: '10%' }}
              />
            )
          )}
        </div>

        {openDropdown && dropdownData.desc && (
          <Collapse in={openDropdown}>
            <Paper className={classes.dropdownBody}>
              <div className={classes.drugcontBodyWrap}>
                <p className={classes.dropDownDetailText}>
                  {dropdownData.desc}
                </p>

                {dropdownData.drugDetail &&
                  dropdownData.drugDetail.length >= 1 && (
                    <>
                      <h5>Drugs</h5>
                      <ul className={classes.drugListItemContainer}>
                        {dropdownData.drugDetail.map((item) => (
                          <li>{item}</li>
                        ))}
                      </ul>
                    </>
                  )}
              </div>
            </Paper>
          </Collapse>
        )}
      </div>
    </ClickAwayListener>
  );
};

const T2DTreatmentMobile = () => {
  const data = {
    mainTitleText: 'Treatment',
    mainTitleNumber: '8',
    arrowTitle: 'HbA1c Levels Rising',
    stages: ['First Line', 'Second Line', 'Third Line'],
    treatmentData: {
      firstLine: [
        {
          title: 'Biguanides',
          desc: `It is the first line of treatment for
          Type-2 diabetes in the absence of
          contraindications. It decreases blood
          glucose levels by 20% and HbA1c by
          1.5%.
          Metformin decreases gluconeogenesis
          and lipogenesis in the liver. It also
          suppresses monocyte and macrophage
          differentiation, thus decreasing the
          cytokine levels which leads to decrease
          in inflammation. `,
          drugDetail: ['Metformin'],
        },
      ],
      secondLine: [
        {
          title: 'Biguanides',
          desc: `It is the first line of treatment for
            Type-2 diabetes in the absence of
            contraindications. It decreases blood
            glucose levels by 20% and HbA1c by
            1.5%.
            Metformin decreases gluconeogenesis
            and lipogenesis in the liver. It also
            suppresses monocyte and macrophage
            differentiation, thus decreasing the
            cytokine levels which leads to decrease
            in inflammation. `,
          drugDetail: ['Metformin'],
        },
        {
          title: 'DPP-4 Inhibitor',
          desc: `DPP-4 is the enzyme that inactivates and
            rapidly degrades incretins. The
            glucoregulatory effects of incretins are
            increased by the inhibition of the enzyme,
            dipeptidyl peptidase-4 (DPP-4). Agents
            that inhibit the enzyme DPP-4 will activate
            incretins. It also improves islet function
            and glycemic control in T2DM patients`,
          drugDetail: ['Vildagliptin', 'Saxagliptin', 'Linagliptin'],
        },
        { title: 'iSGLT' },
        {
          title: 'Sulfonylurea',
          desc: `Sulfonylurea will not only stimulate
            pancreatic cells to release insulin but also
            decreases the hepatic clearance of insulin. `,
          drugDetail: ['Chlorpropamide', 'Tolbutamide'],
        },
        {
          title: 'Biguanides',
          desc: `It is the first line of treatment for
            Type-2 diabetes in the absence of
            contraindications. It decreases blood
            glucose levels by 20% and HbA1c by
            1.5%.
            Metformin decreases gluconeogenesis
            and lipogenesis in the liver. It also
            suppresses monocyte and macrophage
            differentiation, thus decreasing the
            cytokine levels which leads to decrease
            in inflammation. `,
          drugDetail: ['Metformin'],
        },
        {
          title: 'Sulfonylurea',
          desc: `Sulfonylurea will not only stimulate
            pancreatic cells to release insulin but also
            decreases the hepatic clearance of insulin. `,
          drugDetail: ['Chlorpropamide', 'Tolbutamide'],
        },
        {
          title: 'Thiazolidinedione',
          desc: `Thiazolidinediones are a class of drugs that
            tend to improve the metabolic control in
            type-2 individuals by improving insulin
            sensitivity. These reduce insulin resistance
            in adipose tissue, skeletal muscle and liver.
            TZDs induce the activation of PPAR-𝛾 which
            leads to change in the transcription of
            genes involved in lipid and glucose
            metabolism. These include - lipoprotein
            lipase, fatty acids transporter protein,
            GLUT4 glucose transporter etc. `,
          drugDetail: ['Rosiglitaxone', 'Pioglitazone'],
        },
        {
          title: '⍺ - glucosidase inhibitor',
          desc: `Alpha glucosidase is an enzyme which
            breaks down starch and disaccharides
            into glucose. When this alpha
            glucosidase enzyme is inhibited,
            glucose level doesn’t increase. This
            decreases the post-prandial glucose
            levels. `,
          drugDetail: ['Acarbose', 'Miglitol', 'Voglibose'],
        },
      ],
      thirdline: [
        {
          title: 'Biguanides',
          desc: `It is the first line of treatment for
            Type-2 diabetes in the absence of
            contraindications. It decreases blood
            glucose levels by 20% and HbA1c by
            1.5%.
            Metformin decreases gluconeogenesis
            and lipogenesis in the liver. It also
            suppresses monocyte and macrophage
            differentiation, thus decreasing the
            cytokine levels which leads to decrease
            in inflammation. `,
          drugDetail: ['Metformin'],
        },
        { title: 'Intensive Insulin therapy' },
      ],
    },
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          //   marginBottom: 10,
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              backgroundColor: '#2BB670',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 10,
              borderRadius: 5,
            }}
          >
            <p style={{ fontWeight: 'bold' }}>First Line</p>
          </div>

          <div
            style={{
              backgroundColor: '#d3d3d3',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 5,
              borderRadius: 5,
            }}
          >
            <p style={{ fontWeight: 'bold' }}>Monotherapy</p>
          </div>

          <div>
            <DropdownBox dropdownData={data.treatmentData.firstLine[0]} />
          </div>
        </div>

        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              backgroundColor: '#2BB670',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 10,
              borderRadius: 5,
            }}
          >
            <p style={{ fontWeight: 'bold' }}>Second Line</p>
          </div>

          <div
            style={{
              backgroundColor: '#d3d3d3',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 5,
              borderRadius: 5,
            }}
          >
            <p style={{ fontWeight: 'bold' }}>Combination Therapy</p>
          </div>

          <div>
            <DropdownBox dropdownData={data.treatmentData.firstLine[0]} />
          </div>

          <p style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 5 }}>
            Choose One
          </p>
          <div style={{ marginBottom: 5 }}>
            <DropdownBox dropdownData={data.treatmentData.secondLine[1]} />
          </div>
          <div style={{ marginBottom: 5 }}>
            <DropdownBox dropdownData={data.treatmentData.secondLine[2]} />
          </div>
          <div style={{ marginBottom: 5 }}>
            <DropdownBox dropdownData={data.treatmentData.secondLine[3]} />
          </div>
          <div
            style={{
              backgroundColor: '#d3d3d3',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 5,
              borderRadius: 5,
            }}
          >
            <p style={{ fontWeight: 'bold' }}>Triple Combination Therapy</p>
          </div>
          <div style={{ marginBottom: 5 }}>
            <DropdownBox dropdownData={data.treatmentData.secondLine[4]} />
          </div>
          <div style={{ marginBottom: 5 }}>
            <DropdownBox dropdownData={data.treatmentData.secondLine[5]} />
          </div>
          <p style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 5 }}>
            Choose One
          </p>
          <div style={{ marginBottom: 5 }}>
            <DropdownBox dropdownData={data.treatmentData.secondLine[6]} />
          </div>
          <div style={{ marginBottom: 5 }}>
            <DropdownBox dropdownData={data.treatmentData.secondLine[7]} />
          </div>
        </div>

        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              backgroundColor: '#2BB670',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 10,
              borderRadius: 5,
            }}
          >
            <p style={{ fontWeight: 'bold' }}>Third Line</p>
          </div>

          <div
            style={{
              backgroundColor: '#d3d3d3',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 5,
              borderRadius: 5,
            }}
          >
            <p style={{ fontWeight: 'bold' }}>Combination Therapy</p>
          </div>

          <div style={{ marginBottom: 5 }}>
            <DropdownBox dropdownData={data.treatmentData.thirdline[0]} />
          </div>
          <div style={{ marginBottom: 5 }}>
            <DropdownBox dropdownData={data.treatmentData.thirdline[1]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default T2DTreatmentMobile;
