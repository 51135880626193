import React from 'react';
import { makeStyles, Grid, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      paddingBottom: '100px',
      marginBottom: '40px',
      marginLeft: '40px',
      borderRadius: '10px',
      overflow: 'hidden',
      boxShadow: 'rgb(0 0 0 / 18%) 0px 5px 11px 0px, rgb(0 0 0 / 15%) 0px 4px 15px 0px',
    },

    // border: "1px solid #000",
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },

  timelineAppBar: {
    height: 'auto',
    width: '100%',
  },
  timelineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  timelineCircleBG: {
    width: '50px',
    height: '50px',
    border: '1px solid #C33C54',
    margin: '0 15px 0 0 ',
    background: '#FFEBEF',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#C33C54',
    justifyContent: 'center',
  },
  timelineTitle: {
    width: '276px',
    height: '50px',
    background: '#FFEBEF',
    borderRadius: '200px',
    border: '1px solid #C33C54',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#C33C54',
    fontSize: '28px',
    lineHeight: '33.18px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
  },

  headingBox: {
    backgroundColor: '#FFEBEF',
    border: '1px solid #C33C54',
    width: '100%',
    height: 34,
    color: '#C33C54',
    fontSize: '14px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  blueBoxSmall: {
    color: '#000',
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: '#9FCEFC',
    height: '40px',
    width: '240px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '1rem',
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  // Arrow
  arrow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
  },
  arrowtail: {
    background: '#C33C54',
    height: '20px',
    width: '1.5px',
  },
  arrowpoint: {
    width: 0,
    height: 0,
    borderTop: '6px solid #C33C54',
    borderRight: '6px solid transparent',
    borderLeft: '6px solid transparent',
  },
  // branch
  branchWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  branchMain: {
    borderRight: '1.5px solid #C33C54',
    borderLeft: '1.5px solid #C33C54',
    borderTop: '1.5px solid #C33C54',
  },
  branchMiddleLine: {
    justifySelf: 'center',
    background: '#C33C54',
  },
  redBorderGrayBox: {
    width: '144px',
    minHeight: '24px',
    borderRadius: '12px',
    border: '1px solid #C33C54',
    backgroundColor: '#EEF0F0',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      fontSize: '9px',
    },
  },
  GreenBox: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#2BB670',
    height: '30px',
    width: '174px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100px',
      fontSize: '10px',
    },
  },

  redBoldTextBig: {
    color: '#C33C54',
    fontWeight: 'bold',
    fontSize: '20px',
    margin: '10px 0',
  },
}));

const Arrow = ({ tailHeight, tailWidth, arrowPointStyle, arrowMainStyle }) => {
  const classes = useStyles();
  return (
    <div class={classes.arrow} style={arrowMainStyle}>
      <div class={classes.arrowtail} style={{ height: tailHeight }}></div>
      <div class={classes.arrowpoint}></div>
    </div>
  );
};

const Branch = ({
  branchStyle,
  middleLineStyle,
  branchClassName,
  middleLineClassName,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.branchWrap}>
      <div className={classes.branchMiddleLine} style={middleLineStyle}></div>
      <div className={classes.branchMain} style={branchStyle}></div>
    </div>
  );
};

const WetAmdDiagnosis = ({ noFloat, noNumber }) => {
  const classes = useStyles();

  const data = {
    mainTitleText: 'Diagnosis',
    mainTitleNumber: '3',
    screenTest: 'Screening Tests',
  };
  const matches = useMediaQuery('(max-width:600px)');
  return (
    <div
      className={classes.root}
      style={{ float: !noFloat && 'right', overflow: 'scroll' }}
    >
      <div className={classes.timelineAppBar}>
        <div className={classes.timelineHeading}>
          {!noNumber && (
            <div className={classes.timelineCircleBG}>
              {data.mainTitleNumber}
            </div>
          )}
          <div className={classes.timelineTitle}>{data.mainTitleText}</div>
        </div>
      </div>
      <div className={classes.headingBox} style={{ marginTop: '40px' }}>
        <p> {data.screenTest}</p>
      </div>
      <div
        className='right-grid'
        style={{ position: 'relative', marginTop: '20px' }}
      >
        <div
          style={{
            display: 'flex',
            // justifyContent: "center",
            marginTop: '10px',
          }}
        >
          <div>
            <div
              className={classes.blueBoxSmall}
              style={{ width: '180px', marginLeft: '70px' }}
            >
              <p>Amsler Grid Test (Self Examination)</p>
            </div>
            <Branch
              middleLineStyle={{
                width: '1.5px',
                height: '26px',
                marginLeft: '30px',
              }}
              branchStyle={{
                width: matches ? '100%' : '100%',
                height: '30px',
                marginLeft: '30px',
              }}
            />
            <div style={{ position: 'relative' }}>
              <div style={{ display: 'flex', position: 'relative' }}>
                <div
                  className={classes.redBorderGrayBox}
                  style={{
                    // position: "absolute",
                    // left: "-60px",
                    // marginLeft: "-40px",
                    width: matches ? '70px' : '80px',
                    height: '46px',
                  }}
                >
                  <p>Normal Grid</p>
                </div>

                <div
                  className={classes.redBorderGrayBox}
                  style={{
                    position: 'absolute',
                    right: '-60px',
                    //   marginLeft: "90px",
                    width: matches ? '70px' : '120px',
                    height: matches ? '60px' : '46px',
                  }}
                >
                  <p>Wavy/Distorted or Missing Grid</p>
                </div>
                <Arrow
                  arrowMainStyle={{
                    right: matches ? '-20px' : '-17px',
                    top: matches ? '60px' : '45px',
                    // position: "unset",
                  }}
                  tailHeight={matches ? 50 : 50}
                />
              </div>
              <div
                style={{
                  position: 'relative',
                  marginTop: '55px',
                  //   display: "flex",
                  //   justifyContent: "flex-end",
                }}
              >
                <div
                  className={classes.redBorderGrayBox}
                  style={{
                    position: 'absolute',
                    right: '-60px',
                    // marginLeft: "160px",
                    width: matches ? '70px' : '140px',
                    height: matches ? '60px' : '60px',
                  }}
                >
                  <p>Further Testing by Ophthalmologist Required</p>
                </div>
                <Branch
                  middleLineStyle={{
                    width: '1.5px',
                    height: '26px',
                    // marginLeft: "180px",
                    // right: "0px",
                    marginRight: '-270px',
                    marginTop: '60px',
                  }}
                  branchStyle={{
                    width: matches ? '100%' : '240px',
                    height: '30px',
                    marginRight: '-260px',
                    // position: "unset",
                  }}
                />
                <div style={{ display: 'flex', position: 'relative' }}>
                  <div style={{ marginLeft: '30px' }}>
                    <div
                      className={classes.blueBoxSmall}
                      style={{ width: '140px', marginLeft: '40px' }}
                    >
                      <p>Visual Acuity Test</p>
                    </div>
                    <Branch
                      middleLineStyle={{
                        width: '1.5px',
                        height: '26px',
                        marginLeft: '20px',
                        // right: "0px",
                        // marginRight: "-270px",
                        marginTop: '0px',
                      }}
                      branchStyle={{
                        width: matches ? '100%' : '120px',
                        height: '30px',
                        marginLeft: '20px',
                        // position: "unset",
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        position: 'relative',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        className={classes.redBorderGrayBox}
                        style={{
                          // position: "absolute",
                          // right: "-60px",
                          marginLeft: '20px',
                          width: matches ? '70px' : '70px',
                          height: matches ? '60px' : '40px',
                        }}
                      >
                        <p>{`<20/20`}</p>
                      </div>
                      <Arrow
                        arrowMainStyle={{
                          left: matches ? '-20px' : '34px',
                          top: matches ? '60px' : '40px',
                          // position: "unset",
                        }}
                        tailHeight={matches ? 50 : 180}
                      />
                      <div
                        className={classes.redBorderGrayBox}
                        style={{
                          // position: "absolute",
                          // right: "-60px",
                          marginRight: '-10px',
                          width: matches ? '70px' : '70px',
                          height: matches ? '60px' : '40px',
                        }}
                      >
                        <p>{`20/20`}</p>
                      </div>
                      <Arrow
                        arrowMainStyle={{
                          right: matches ? '-20px' : '14px',
                          top: matches ? '60px' : '40px',
                          // position: "unset",
                        }}
                        tailHeight={matches ? 50 : 80}
                      />
                    </div>
                  </div>
                  <div
                    className={classes.GreenBox}
                    style={{
                      position: 'absolute',
                      left: matches ? '-40px' : '52%',
                      top: '222px',
                      color: 'white',
                      width: '220px',
                      fontSize: '12px',
                    }}
                  >
                    <p>Wet AMD: Negative</p>
                  </div>
                  <div style={{ position: 'relative' }}>
                    <div
                      className={classes.blueBoxSmall}
                      style={{
                        width: '140px',
                        //   marginLeft: "70px",
                        right: -230,
                        position: 'absolute',
                      }}
                    >
                      <p>Fundoscopy</p>
                    </div>
                    <Branch
                      middleLineStyle={{
                        width: '1.5px',
                        height: '26px',
                        // marginLeft: "20px",
                        // right: "0px",
                        // marginRight: "-270px",
                        marginTop: '0px',
                        position: 'absolute',
                        right: '-160px',
                        marginTop: '40px',
                      }}
                      branchStyle={{
                        width: matches ? '100%' : '180px',
                        height: '30px',
                        // marginLeft: "20px",
                        position: 'absolute',
                        right: '-260px',
                        marginTop: '64px',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        position: 'relative',
                        justifyContent: 'space-between',
                        marginTop: '93px',
                        marginRight: '-190px',
                      }}
                    >
                      <div
                        className={classes.redBorderGrayBox}
                        style={{
                          position: 'absolute',
                          right: '-120px',
                          //   marginRight: "-260px",
                          width: matches ? '70px' : '120px',
                          height: matches ? '60px' : '60px',
                        }}
                      >
                        <p>No abnormality in choroid or retina</p>
                      </div>
                      <Arrow
                        arrowMainStyle={{
                          left: matches ? '-20px' : '69px',
                          top: matches ? '60px' : '60px',
                          // position: "unset",
                        }}
                        tailHeight={matches ? 50 : 63}
                      />
                      <div
                        className={classes.redBorderGrayBox}
                        style={{
                          position: 'absolute',
                          right: '20px',
                          //   marginRight: "-20px",
                          width: matches ? '70px' : '120px',
                          height: matches ? '60px' : '60px',
                        }}
                      >
                        <p>Abnormality in the choroid or retina </p>
                      </div>
                      <Arrow
                        arrowMainStyle={{
                          right: matches ? '-20px' : '-79px',
                          top: matches ? '60px' : '60px',
                          // position: "unset",
                        }}
                        tailHeight={matches ? 50 : 163}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.headingBox} style={{ marginTop: '185px' }}>
        <p> {data.screenTest}</p>
      </div>
      <div style={{ display: 'flex', position: 'relative', marginTop: '40px' }}>
        <div>
          <div
            className={classes.blueBoxSmall}
            style={{ width: '180px', marginLeft: '40px' }}
          >
            <p>Optical Coherence Tomography (OCT)</p>
          </div>
          <Branch
            middleLineStyle={{
              width: '1.5px',
              height: '26px',
              marginLeft: '20px',
              // right: "0px",
              // marginRight: "-270px",
              marginTop: '0px',
            }}
            branchStyle={{
              width: matches ? '100%' : '150px',
              height: '30px',
              marginLeft: '20px',
              // position: "unset",
            }}
          />
          <div
            style={{
              display: 'flex',
              position: 'relative',
              justifyContent: 'space-between',
            }}
          >
            <div
              className={classes.redBorderGrayBox}
              style={{
                // position: "absolute",
                // right: "-60px",
                marginLeft: '0px',
                width: matches ? '70px' : '90px',
                height: matches ? '60px' : '45px',
              }}
            >
              <p>Fovea is not disrupted</p>
            </div>
            <Arrow
              arrowMainStyle={{
                left: matches ? '-20px' : '34px',
                top: matches ? '60px' : '44px',
                // position: "unset",
              }}
              tailHeight={matches ? 50 : 75}
            />
            <div
              className={classes.GreenBox}
              style={{
                position: 'absolute',
                left: matches ? '-40px' : '0px',
                top: '125px',
                color: 'white',
                width: '140px',
                fontSize: '12px',
                // backgroundColor: "#C33C54",
              }}
            >
              <p>Wet AMD: Negative</p>
            </div>
            <div
              className={classes.redBorderGrayBox}
              style={{
                // position: "absolute",
                // right: "-60px",
                marginRight: '-10px',
                width: matches ? '70px' : '90px',
                height: matches ? '60px' : '45px',
              }}
            >
              <p>Disrupted Fovea</p>
            </div>
            <Arrow
              arrowMainStyle={{
                right: matches ? '-20px' : '14px',
                top: matches ? '60px' : '45px',
                // position: "unset",
              }}
              tailHeight={matches ? 50 : 75}
            />
          </div>
        </div>
        <div
          className={classes.GreenBox}
          style={{
            position: 'absolute',
            left: matches ? '-40px' : '30%',
            top: '222px',
            color: 'white',
            width: '190px',
            fontSize: '12px',
            backgroundColor: '#C33C54',
          }}
        >
          <p>Wet AMD: Positive</p>
        </div>
        <div style={{ position: 'relative' }}>
          <div
            className={classes.blueBoxSmall}
            style={{
              width: '180px',
              //   marginLeft: "70px",
              right: '-260px',
              position: 'absolute',
            }}
          >
            <p>Fluorescein Angiography</p>
          </div>
          <Branch
            middleLineStyle={{
              width: '1.5px',
              height: '26px',
              // marginLeft: "20px",
              // right: "0px",
              // marginRight: "-270px",
              marginTop: '0px',
              position: 'absolute',
              right: '-160px',
              marginTop: '40px',
            }}
            branchStyle={{
              width: matches ? '100%' : '180px',
              height: '30px',
              // marginLeft: "20px",
              position: 'absolute',
              right: '-260px',
              marginTop: '64px',
            }}
          />
          <div
            style={{
              display: 'flex',
              position: 'relative',
              justifyContent: 'space-between',
              marginTop: '93px',
              marginRight: '-170px',
            }}
          >
            <div
              className={classes.redBorderGrayBox}
              style={{
                position: 'absolute',
                right: '-120px',
                //   marginRight: "-260px",
                width: matches ? '70px' : '120px',
                height: matches ? '60px' : '60px',
              }}
            >
              <p>No abnormality in choroid or retina</p>
            </div>
            <Arrow
              arrowMainStyle={{
                left: matches ? '-20px' : '69px',
                top: matches ? '60px' : '60px',
                // position: "unset",
              }}
              tailHeight={matches ? 50 : 63}
            />
            <div
              className={classes.redBorderGrayBox}
              style={{
                position: 'absolute',
                right: '20px',
                //   marginRight: "-20px",
                width: matches ? '70px' : '120px',
                height: matches ? '60px' : '60px',
              }}
            >
              <p>Abnormality in the choroid or retina </p>
            </div>
            <Arrow
              arrowMainStyle={{
                right: matches ? '-20px' : '-79px',
                top: matches ? '60px' : '60px',
                // position: "unset",
              }}
              tailHeight={matches ? 50 : 63}
            />
            <div
              className={classes.GreenBox}
              style={{
                position: 'absolute',
                right: matches ? '-40px' : '-120px',
                top: '130px',
                color: 'white',
                width: '140px',
                fontSize: '12px',
                // backgroundColor: "#C33C54",
              }}
            >
              <p>Wet AMD: Negative</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WetAmdDiagnosis;
