import React from 'react';
import ReactToPrint from 'react-to-print';
import { CLabel, CButton, CCard, CCardBody, CCol } from '@coreui/react';
import { getReports } from '../../../apiCalls/axiosCall/reportsAxios';
import logos from '../../../assets/img/AXLMED.png';
import CIcon from '@coreui/icons-react';
import PageToPrint from './PageToPrint';
const thStyle = {
  fontFamily: 'Anton',
  fontWeight: 'normal',
  fontStyle: 'normal',
};

class DocServer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      reportsID: this.props.match.params.reportId,
      reportsItem: [],
    };
  }

  getReportsview = () => {
    getReports(this.state.reportsID).then((res) => {
      const data = res.data;
      console.log('Data of Reports', data);
      this.setState({ ...res.data, reportsItem: data, content: data.content });
    });
  };

  print() {
    window.print();
  }

  componentDidMount() {
    this.getReportsview();

    console.log('State', this.state.reportsItem);
  }

  render() {
    console.log('this', this.props.match.params.reportId);
    return (
      <CCol xs='12'>
        <CCard>
          <CCardBody>
            <div className='row'>
              <div className='col-sm-12 d-flex justify-content-end'>
                {/* <div className="col-sm-6">
      <CLabel><strong><h3>{this.state.reportsItem.condition}&nbsp;:-&nbsp;{this.state.reportsItem.category}</h3></strong></CLabel>
      
        </div> */}
                <div className='col-sm-6 d-flex justify-content-end'>
                  <CButton
                    active
                    color='primary'
                    size='md'
                    aria-pressed='true'
                    onClick={this.print.bind(this)}
                  >
                    Print
                  </CButton>
                </div>
              </div>
            </div>
            <ReactToPrint content={() => this.componentRef} />
            <PageToPrint
              ref={(el) => (this.componentRef = el)}
              reportsItem={this.state.reportsItem}
              pagecontent={this.state.content}
            />
          </CCardBody>
        </CCard>
      </CCol>
    );
  }
}

export default DocServer;
